import React from 'react';
import PropTypes from 'prop-types';
import FormValue from '../Components/FormValue';
import SectionHeader from '../Components/SectionHeader';
import Applicant from '../Components/Applicant';
import { View } from '@react-pdf/renderer';
import ApplicantDetails from '../Components/ApplicantDetails';
import LocationDetails from '../Components/LocationDetails';
import ApplicationDetails from '../Components/ApplicationDetails';
import SummaryLayout from '../Components/SummaryLayout';

const PrivateCrossingSummary = ({ appSummary, history }) => {
  return (
    <SummaryLayout
      appSummary={appSummary}
      history={history}
      headerTitle={`Application for ${appSummary.permitType}`}
    >
      <Applicant appSummary={appSummary} />
      <ApplicantDetails appSummary={appSummary} />
      <ApplicationDetails appSummary={appSummary} />
      <LocationDetails appSummary={appSummary} />
      <SectionHeader headerText="Private Crossing" />
      <FormValue
        fieldText="Does the crossing already exist"
        valueText={appSummary.privateCrossing.existing}
      />
      {appSummary.privateCrossing.existing && (
        <>
          <FormValue
            fieldText="Type of crossing"
            valueText={appSummary.privateCrossing.crossingType}
          />
          <View style={{ paddingLeft: '15px' }}>
            <FormValue
              fieldText="Description"
              valueText={appSummary.privateCrossing.otherTypeDescription}
              hideIfEmpty
            />
          </View>
          <FormValue
            fieldText="Width of crossing"
            valueText={appSummary.privateCrossing.crossingWidth}
          />
          <View style={{ paddingLeft: '15px' }}>
            <FormValue
              fieldText="Description"
              valueText={appSummary.privateCrossing.otherWidthDescription}
              hideIfEmpty
            />
          </View>
        </>
      )}
      <FormValue
        fieldText="Time period crossing will be required"
        valueText={appSummary.privateCrossing.yearsRequired}
        unitsLabel="years"
      />
      <FormValue
        fieldText="The crossing is being used for"
        valueText={appSummary.privateCrossing.crossingUsage}
      />
      <FormValue
        fieldText="Number of vehicle crossing a day"
        valueText={appSummary.privateCrossing.vehicleCrossingCnt}
      />
      <FormValue
        fieldText="Type of user vehicle"
        valueText={appSummary.privateCrossing.vehicleUse}
      />
      {appSummary.privateCrossing.industrialVehicleDescription && (
        <View style={{ paddingLeft: '15px' }}>
          <FormValue
            fieldText="Industrial vehicle description"
            valueText={appSummary.privateCrossing.industrialVehicleDescription}
          />
        </View>
      )}
      <FormValue
        fieldText="Distance to nearest public crossing"
        valueText={appSummary.privateCrossing.distanceFromPublic}
      />
      <FormValue
        fieldText="Are there other private crossings in the vicinity"
        valueText={appSummary.privateCrossing.othersInVicinity}
      />
      {appSummary.privateCrossing.othersInVicinity && (
        <View style={{ paddingLeft: '15px' }}>
          <FormValue
            fieldText="Distance/Direction from crossing"
            valueText={appSummary.privateCrossing.distanceFromPrivate}
          />
        </View>
      )}
      <FormValue
        fieldText="Do you own or lease the land on either side of the track"
        valueText={appSummary.privateCrossing.ownershipType}
      />
      {appSummary.privateCrossing.ownershipType.toUpperCase() === 'LEASE' && (
        <View style={{ paddingLeft: '15px' }}>
          <FormValue fieldText="Owner's name" valueText={appSummary.privateCrossing.ownerName} />
          <FormValue fieldText="Phone number" valueText={appSummary.privateCrossing.ownerPhone} />
        </View>
      )}
    </SummaryLayout>
  );
};

PrivateCrossingSummary.propTypes = {
  appSummary: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
};

export default PrivateCrossingSummary;
