import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import FormValue from './FormValue';
import SectionHeader from './SectionHeader';

const ThirdPartyInstaller = ({ appSummary }) => {
  return (
    <View>
      <SectionHeader headerText="3rd Party Installer" />
      <FormValue
        fieldText="Will a 3rd party be installing"
        valueText={appSummary.applicationDetail.thirdPartyInstaller}
      />
      {appSummary.applicationDetail.thirdPartyInstaller && (
        <View>
          <FormValue
            fieldText="Name"
            valueText={appSummary.applicationDetail.thirdPartyContactName}
          />
          <FormValue
            fieldText="Phone"
            valueText={appSummary.applicationDetail.thirdPartyPhoneNumber}
          />
          <FormValue fieldText="Email" valueText={appSummary.applicationDetail.thirdPartyEmail} />
        </View>
      )}
    </View>
  );
};

ThirdPartyInstaller.propTypes = {
  appSummary: PropTypes.object.isRequired,
};

export default ThirdPartyInstaller;
