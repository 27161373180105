import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import MaterialReactTable from 'material-react-table';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import withRoot from '../modules/withRoot';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import AppAppBar from '../modules/views/AppAppBar';
import reportActions from '../actions/reportActions';
import { getLookup } from '../helpers/utils';
import { Link } from 'react-router-dom';
import { Tooltip, CircularProgress, Modal, Button, Typography } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Title from '../ProcessApplication/Title';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  textField: {
    marginRight: 10,
  },
  progress: {
    position: 'absolute',
    top: '40%',
    right: '50%',
  },
}));

function prepareReportData(data, permitTypesLu, engineeringDivisions) {
  if (!data) {
    return [];
  }

  const applicationsAwaitingReview = [];
  data.forEach((backlogData) => {
    const { application, reviewAssignments } = backlogData;
    let { applicationDetail } = backlogData.application;
    if (!applicationDetail) {
      applicationDetail = {};
    }

    let cityState = '';
    if (application.locationDetail && application.locationDetail.nearestTown) {
      cityState = `${application.locationDetail.nearestTown}, ${application.locationDetail.state}`;
    } else if (application.locationDetail && application.locationDetail.state) {
      cityState = application.locationDetail.state;
    }

    const permitTypeLU = getLookup(permitTypesLu, application.permitTypeId);

    const link = (
      <>
        <Grid container spacing={1}>
          <Grid item>
            <Link
              to={`/processapplication/${application.applicationId}`}
              style={{ display: 'block' }}
            >
              {application.businessId}
            </Link>
          </Grid>
          {applicationDetail.rushProcessing && (
            <Grid item>
              <Tooltip title="Rush Processing Requested">
                <PriorityHighIcon fontSize="small" color="error" style={{ display: 'block' }} />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </>
    );
    const engineeringDivision = engineeringDivisions.find(
      (lu) => lu.id === application.locationDetail.engineeringDivisionTerritoryId
    );
    const applicationData = {
      applicationId_: application.applicationId,
      businessId_: application.businessId,
      applicationLink: link,
      permitType: permitTypeLU.name,
      companyName: applicationDetail.legalName,
      cityState: cityState,
      engineeringDivision: engineeringDivision?.name,
      submittalDate: application.submittalDateTime
        ? moment.utc(application.submittalDateTime).local().format('YYYY-MM-DD')
        : null,
      rushProcessing_: applicationDetail.rushProcessing,
    };
    reviewAssignments.forEach((ra) => {
      const reviewerBacklogData = {
        ...applicationData,
        assignedTo: ra.user.email,
        assignedOn: moment.utc(ra.dateTimeUtc).local().format('YYYY-MM-DD'),
      };
      applicationsAwaitingReview.push(reviewerBacklogData);
    });
  });

  return applicationsAwaitingReview.sort((a, b) => a.applicationId_ - b.applicationId_);
}

function ReviewerBacklogRpt({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [reviewGroup, setReviewGroup] = React.useState('');

  useEffect(() => {
    dispatch(reportActions.getReviewerBacklogData(reviewGroup));
  }, [dispatch, reviewGroup]);

  const { permitTypes } = useSelector((state) => state.common.lookups);
  const { reviewerBacklogData, queryingReviewerBacklogData } = useSelector(
    (state) => state.reports
  );
  const { reviewGroups, engineeringDivisions } = useSelector((state) => state.common.lookups);
  const rptData = prepareReportData(reviewerBacklogData, permitTypes, engineeringDivisions);
  const uniqueRecords = [...new Set(rptData.map((item) => item.applicationId_))];

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'applicationLink',
        header: 'Business Id',
        filterFn: (row, id, filterValue) => {
          if (row.original['businessId_']) {
            return row.original['businessId_'].toLowerCase().includes(filterValue.toLowerCase());
          }
          return false;
        },
        sortingFn: (rowA, rowB) => {
          if (rowA.original['businessId_'] > rowB.original['businessId_']) {
            return 1;
          }
          if (rowB.original['businessId_'] > rowA.original['businessId_']) {
            return -1;
          }
          return 0;
        },
      },
      {
        accessorKey: 'assignedTo',
        header: 'Assigned To',
      },
      {
        accessorKey: 'assignedOn',
        header: 'Assigned On',
      },
      {
        accessorKey: 'permitType',
        header: 'Permit Type',
      },
      {
        accessorKey: 'companyName',
        header: 'Company Name',
      },
      {
        accessorKey: 'cityState',
        header: 'Permit Location',
      },
      {
        accessorKey: 'engineeringDivision',
        header: 'Division',
      },
      {
        accessorKey: 'submittalDate',
        header: 'Submittal Date',
      },
    ],
    []
  );
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    filename: 'ReviewerBacklogRpt',
  };
  const csvExporter = new ExportToCsv(csvOptions);

  return (
    <>
      <Modal open={queryingReviewerBacklogData}>
        <div>
          <CircularProgress className={classes.progress} size={90} />
        </div>
      </Modal>

      <AppAppBar pathname={location.pathname} />
      <main className={classes.content}>
        <Container className={classes.container} maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <InputLabel id="review-group-select-label">Review Group</InputLabel>
              <Select
                labelId="review-group-select-label"
                value={reviewGroup}
                onChange={(event) => {
                  setReviewGroup(event.target.value);
                  dispatch(reportActions.getReviewerBacklogData(reviewGroup));
                }}
                autoWidth
                style={{ minWidth: 200 }}
              >
                {reviewGroups.map((g) => (
                  <MenuItem key={g.id} value={g.value}>
                    {g.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="h6" sx={{ paddingTop: '20px' }}>
                {uniqueRecords.length} Applications Awaiting{' '}
                {reviewGroups.find((rg) => rg.value === reviewGroup)?.name} Review
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MaterialReactTable
                columns={columns}
                data={rptData}
                enableStickyHeader
                enableGlobalFilter={false}
                enablePagination={false}
                renderTopToolbarCustomActions={() => {
                  return (
                    <Title>
                      <span style={{ fontSize: '1.2rem', marginRight: 20 }}>Backlog Report</span>
                      <Button
                        color="primary"
                        onClick={() => csvExporter.generateCsv(rptData)}
                        startIcon={<FileDownloadIcon />}
                        variant="outlined"
                      >
                        Export Data
                      </Button>
                    </Title>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}

ReviewerBacklogRpt.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRoot(ReviewerBacklogRpt);
