import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import processApplicationConstants from '../constants/processApplicationConstants';
import formConstants from '../constants/formConstants';
import logConstants from '../constants/logConstants';
import alertConstants from '../constants/alertConstants';
import { acquireAccessToken } from '../Auth/utils';

function* getApplicationReviews(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/reviews`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: processApplicationConstants.getApplicationReviewsSuccess, reviews: data });
  } catch (e) {
    yield put({ type: processApplicationConstants.getApplicationReviewsError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to get application reviews' });
  }
}

function* addReviewGroup(action) {
  const { reviewGroupValue, applicationId, reason } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/reviewgroup/add`,
      data: { reviewGroupValue, reason },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: processApplicationConstants.addReviewGroupSuccess, reviews: data });
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
  } catch (e) {
    yield put({ type: processApplicationConstants.addReviewGroupError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to add review' });
  }
}

function* getAssignmentData(action) {
  const { reviewGroupValue, applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/assignmentdata`,
      params: { reviewGroupValue },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: processApplicationConstants.getAssignmentDataSuccess,
      result: data,
      reviewGroupValue,
    });
  } catch (e) {
    yield put({ type: processApplicationConstants.getAssignmentDataError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to get assignment data' });
  }
}

function* updateReviewAssignments(action) {
  const { reviewGroupValue, applicationId, assignmentUpdates } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/reviewers/update`,
      params: { reviewGroupValue },
      data: { ...assignmentUpdates },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: processApplicationConstants.updateReviewAssignmentsSuccess,
      currentAssignments: data,
      reviewGroupValue,
    });
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
    yield put({ type: alertConstants.success, message: 'Review assignments updated' });
  } catch (e) {
    yield put({ type: processApplicationConstants.updateReviewAssignmentsError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to update assignments' });
  }
}

function* remindReviewGroup(action) {
  const { reviewGroupValue, applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/reviewgroup/remind`,
      params: { reviewGroupValue },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: processApplicationConstants.remindReviewGroupSuccess });
    yield put({ type: alertConstants.success, message: 'Reminder email sent' });
  } catch (e) {
    yield put({ type: processApplicationConstants.remindReviewGroupError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to send reminder' });
  }
}

function* removeReviewGroup(action) {
  const { reviewGroupValue, applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/reviewgroup/remove`,
      params: { reviewGroupValue },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: processApplicationConstants.removeReviewGroupSuccess, reviews: data });
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
  } catch (e) {
    yield put({ type: processApplicationConstants.removeReviewGroupError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to remove reviewer' });
  }
}

function* approveReview(action) {
  const { reviewGroupValue, applicationId, formData } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/approve`,
      data: formData,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: processApplicationConstants.approveReviewSuccess, reviews: data });
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
    if (reviewGroupValue === 'PERMITMANAGER') {
      yield put({ type: formConstants.getApplication, applicationId });
    }
  } catch (e) {
    yield put({ type: processApplicationConstants.approveReviewError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to approve review' });
  }
}

function* denyReview(action) {
  const { reviewGroupValue, applicationId, formData } = action;

  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/deny`,
      data: formData,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: processApplicationConstants.denyReviewSuccess, reviews: data });
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
    if (reviewGroupValue === 'PERMITMANAGER') {
      yield put({ type: formConstants.getApplication, applicationId });
    }
  } catch (e) {
    yield put({ type: processApplicationConstants.denyReviewError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to deny review' });
  }
}

function* reopenReview(action) {
  const { reviewGroupValue, applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/reopenreview`,
      params: { reviewGroupValue },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: processApplicationConstants.reopenReviewSuccess, reviews: data });
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
  } catch (e) {
    yield put({ type: processApplicationConstants.reopenReviewError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to reopen review' });
  }
}

function* reopenApplication(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/reopen`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    //Get updated application reviews
    yield put({ type: processApplicationConstants.getApplicationReviews, applicationId });

    yield put({
      type: processApplicationConstants.reopenApplicationSuccess,
      applicationStatus: data,
    });
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
    yield put({ type: alertConstants.success, message: 'Application reopened' });
  } catch (e) {
    yield put({ type: processApplicationConstants.reopenApplicationError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to reopen application' });
  }
}

function* executeApplication(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/execute`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: processApplicationConstants.executeApplicationSuccess,
      applicationStatus: data,
    });
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
    yield put({ type: alertConstants.success, message: 'Application executed' });
  } catch (e) {
    yield put({ type: processApplicationConstants.executeApplicationError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to execute application' });
  }
}

function* changeApplicationType(action) {
  const { applicationId, newPermitTypeId } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/changeType`,
      params: { permitTypeId: newPermitTypeId },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: processApplicationConstants.changeApplicationTypeSuccess,
      permitTypeId: newPermitTypeId,
    });
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
    yield put({ type: alertConstants.success, message: 'Application type changed' });
  } catch (e) {
    yield put({ type: processApplicationConstants.changeApplicationTypeError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to change application type' });
  }
}

export function* getApplicationHistory(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/application/${applicationId}/history`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: processApplicationConstants.getApplicationHistorySuccess, history: data });
  } catch (e) {
    yield put({ type: processApplicationConstants.getApplicationHistoryError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to get application history' });
  }
}

function* getApplicationHistoryCount(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/history/count`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: processApplicationConstants.getApplicationHistoryCountSuccess,
      historyCount: data,
    });
  } catch (e) {
    yield put({ type: processApplicationConstants.getApplicationHistoryCountError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to get application history count' });
  }
}

export function* getApplicationSummaryData(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/application/${applicationId}/summary`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: processApplicationConstants.getApplicationSummaryDataSuccess,
      summaryData: data,
    });
  } catch (e) {
    yield put({ type: processApplicationConstants.getApplicationSummaryDataError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to get application summary data' });
  }
}

const processApplicationSagas = [
  takeLatest(processApplicationConstants.getApplicationReviews, getApplicationReviews),
  takeEvery(processApplicationConstants.addReviewGroup, addReviewGroup),
  takeEvery(processApplicationConstants.getAssignmentData, getAssignmentData),
  takeEvery(processApplicationConstants.updateReviewAssignments, updateReviewAssignments),
  takeEvery(processApplicationConstants.remindReviewGroup, remindReviewGroup),
  takeEvery(processApplicationConstants.removeReviewGroup, removeReviewGroup),
  takeEvery(processApplicationConstants.approveReview, approveReview),
  takeEvery(processApplicationConstants.denyReview, denyReview),
  takeEvery(processApplicationConstants.reopenReview, reopenReview),
  takeLatest(processApplicationConstants.reopenApplication, reopenApplication),
  takeLatest(processApplicationConstants.executeApplication, executeApplication),
  takeLatest(processApplicationConstants.changeApplicationType, changeApplicationType),
  takeLatest(processApplicationConstants.getApplicationHistory, getApplicationHistory),
  takeLatest(processApplicationConstants.getApplicationHistoryCount, getApplicationHistoryCount),
  takeLatest(processApplicationConstants.getApplicationSummaryData, getApplicationSummaryData),
];

export default processApplicationSagas;
