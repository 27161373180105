const mapDrawConstants = {
  mapCreated: 'MAP_CREATED',
  setLocationDrawOption: 'SET_LOCATION_DRAW_OPTION',
  addPoint: 'ADD_POINT',
  drawPoint: 'DRAW_POINT',
  updatePointCoordinates: 'UPDATE_POINT_COORDINATES',
  removePoint: 'REMOVE_POINT',
  removeAllPoints: 'REMOVE_ALL_POINTS',
  mapDrawUpdateLocationDetail: 'MAP_DRAW_UPDATE_LOCATION_DETAIL',
  highlightPoint: 'HIGHLIGHT_POINT',
  removeHighlights: 'REMOVE_HIGHLIGHTS',
  openConfirmRailroadDialog: 'OPEN_CONFIRM_RAILROAD_DIALOG',
  closeConfirmRailroadDialog: 'CLOSE_CONFIRM_RAILROAD_DIALOG',
};

export default mapDrawConstants;
