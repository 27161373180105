import rules from './RbacRules';

const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

function rbacCan(roles, action, data) {
  if (!roles || !Array.isArray(roles)) {
    return false;
  }
  const allowed = roles.find((role) => check(rules, role, action, data));
  if (allowed) {
    return true;
  }

  return false;
}
export default rbacCan;
