import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Chart } from 'react-google-charts';

export default function CycleTimeChart({ barChartData, timelineData }) {
  return (
    <Grid container item xs={12}>
      <Grid item xs={6}>
        {barChartData.length > 1 && (
          <Chart
            chartType="ColumnChart"
            data={barChartData}
            width="100%"
            height="400px"
            legendToggle
            options={{
              title: 'Average Cycle Days per Review Group',
              chartArea: { width: '80%', height: '80%' },
              vAxis: { title: 'Days per Review Group', minValue: 0 },
              legend: { position: 'none' },
            }}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {timelineData.length > 1 && (
          <Chart
            chartType="Timeline"
            data={timelineData}
            width="100%"
            height="400px"
            options={{
              title: 'Cycle Timeline',
              chartArea: { width: '90%', height: '100%' },
              timeline: { groupByRowLabel: true },
              vAxis: { minValue: 0 },
            }}
            legendToggle
          />
        )}
      </Grid>
    </Grid>
  );
}

CycleTimeChart.propTypes = {
  barChartData: PropTypes.array.isRequired,
  timelineData: PropTypes.array.isRequired,
};
