import mapQueryConstants from '../constants/mapQueryConstants';

function getEsriToken(serviceUrl) {
  return { type: mapQueryConstants.getEsriToken, serviceUrl };
}

const mapQueryActions = {
  getEsriToken,
};

export default mapQueryActions;
