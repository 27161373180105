import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@react-pdf/renderer';

const ChangeTypeHeader = ({ color, children }) => {
  return <Text style={{ color: color, fontSize: '11px', marginBottom: '5px' }}>{children}</Text>;
};
ChangeTypeHeader.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
};

export default ChangeTypeHeader;
