const formGetConstants = {
  getApplication: 'GET_APPLICATION',
  getApplicationSucceeded: 'GET_APPLICATION_SUCCEEDED',
  getApplicationFailed: 'GET_APPLICATION_FAILED',

  getAllApplications: 'GET_ALL_APPLICATIONS',
  getAllApplicationsSucceeded: 'GET_ALL_APPLICATIONS_SUCCEEDED',
  getAllApplicationsFailed: 'GET_ALL_APPLICATIONS_FAILED',

  getPipeline: 'GET_PIPELINE',
  getPipelineSucceeded: 'GET_PIPELINE_SUCCEEDED',
  getPipelineFailed: 'GET_PIPELINE_FAILED',

  getPipelineCasing: 'GET_PIPELINE_CASING',
  getPipelineCasingSucceeded: 'GET_PIPELINE_CASING_SUCCEEDED',
  getPipelineCasingFailed: 'GET_PIPELINE_CASING_FAILED',

  getPipelineCarrier: 'GET_PIPELINE_CARRIER',
  getPipelineCarrierSucceeded: 'GET_PIPELINE_CARRIER_SUCCEEDED',
  getPipelineCarrierFailed: 'GET_PIPELINE_CARRIER_FAILED',

  getWireline: 'GET_WIRELINE',
  getWirelineSucceeded: 'GET_WIRELINE_SUCCEEDED',
  getWirelineFailed: 'GET_WIRELINE_FAILED',

  getOverheadWireline: 'GET_OVERHEAD_WIRELINE',
  getOverheadWirelineSucceeded: 'GET_OVERHEAD_WIRELINE_SUCCEEDED',
  getOverheadWirelineFailed: 'GET_OVERHEAD_WIRELINE_FAILED',

  getPole: 'GET_POLE',
  getPoleSucceded: 'GET_POLE_SUCCEEDED',
  getPoleFailed: 'GET_POLE_FAILED',

  getUndergroundWireline: 'GET_UNDERGROUND_WIRELINE',
  getUndergroundWirelineSucceeded: 'GET_UNDERGROUND_WIRELINE_SUCCEEDED',
  getUndergroundWirelineFailed: 'GET_UNDERGROUND_WIRELINE_FAILED',

  getConduit: 'GET_CONDUIT',
  getConduitSucceded: 'GET_CONDUIT_SUCCEEDED',
  getConduitFailed: 'GET_CONDUIT_FAILED',

  getContract: 'GET_CONTRACT',
  getContractSucceded: 'GET_CONTRACT_SUCCEEDED',
  getContractFailed: 'GET_CONTRACT_FAILED',

  checkApplicationSpecs: 'CHECK_APPLICATION_SPECS',
  checkApplicationSpecsSucceeded: 'CHECK_APPLICATION_SPECS_SUCCEEDED',
  checkApplicationSpecsFailed: 'CHECK_APPLICATION_SPECS_FAILED',
};

const formInsertConstants = {
  insertApplication: 'INSERT_APPLICATION',
  insertApplicationSucceeded: 'INSERT_APPLICATION_SUCCEEDED',
  insertApplicationFailed: 'INSERT_APPLICATION_FAILED',

  insertPipeline: 'INSERT_PIPELINE',
  insertPipelineSucceeded: 'INSERT_PIPELINE_SUCCEEDED',
  insertPipelineFailed: 'INSERT_PIPELINE_FAILED',

  insertPipelineCasing: 'INSERT_PIPELINE_CASING',
  insertPipelineCasingSucceeded: 'INSERT_PIPELINE_CASING_SUCCEEDED',
  insertPipelineCasingFailed: 'INSERT_PIPELINE_CASING_FAILED',

  insertPipelineCarrier: 'INSERT_PIPELINE_CARRIER',
  insertPipelineCarrierSucceeded: 'INSERT_PIPELINE_CARRIER_SUCCEEDED',
  insertPipelineCarrierFailed: 'INSERT_PIPELINE_CARRIER_FAILED',

  insertWireline: 'INSERT_WIRELINE',
  insertWirelineSucceeded: 'INSERT_WIRELINE_SUCCEEDED',
  insertWirelineFailed: 'INSERT_WIRELINE_FAILED',

  insertOverheadWireline: 'INSERT_OVERHEAD_WIRELINE',
  insertOverheadWirelineSucceeded: 'INSERT_OVERHEAD_WIRELINE_SUCCEEDED',
  insertOverheadWirelineFailed: 'INSERT_OVERHEAD_WIRELINE_FAILED',

  insertPole: 'INSERT_POLE',
  insertPoleSucceeded: 'INSERT_POLE_SUCCEEDED',
  insertPoleFailed: 'INSERT_POLE_FAILED',

  insertUndergroundWireline: 'INSERT_UNDERGROUND_WIRELINE',
  insertUndergroundWirelineSucceeded: 'INSERT_UNDERGROUND_WIRELINE_SUCCEEDED',
  insertUndergroundWirelineFailed: 'INSERT_UNDERGROUND_WIRELINE_FAILED',

  insertConduit: 'INSERT_CONDUIT',
  insertConduitSucceeded: 'INSERT_CONDUIT_SUCCEEDED',
  insertConduitFailed: 'INSERT_CONDUIT_FAILED',

  insertTemporaryOccupancy: 'INSERT_TEMPORARYOCCUPANCY',
  insertTemporaryOccupancySucceeded: 'INSERT_TEMPORARYOCCUPANCY_SUCCEEDED',
  insertTemporaryOccupancyFailed: 'INSERT_TEMPORARYOCCUPANCY_FAILED',

  insertPrivateCrossing: 'INSERT_PRIVATECROSSING',
  insertPrivateCrossingSucceeded: 'INSERT_PRIVATECROSSING_SUCCEEDED',
  insertPrivateCrossingFailed: 'INSERT_PRIVATECROSSING_FAILED',

  insertRoadwaySurfacing: 'INSERT_ROADWAYSURFACING',
  insertRoadwaySurfacingSucceeded: 'INSERT_ROADWAYSURFACING_SUCCEEDED',
  insertRoadwaySurfacingFailed: 'INSERT_ROADWAYSURFACING_FAILED',

  insertGeneralLicense: 'INSERT_GENERALLICENSE',
  insertGeneralLicenseSucceeded: 'INSERT_GENERALLICENSE_SUCCEEDED',
  insertGeneralLicenseFailed: 'INSERT_GENERALLICENSE_FAILED',

  insertOversizeLoadMove: 'INSERT_OVERSIZELOADMOVE',
  insertOversizeLoadMoveSucceeded: 'INSERT_OVERSIZELOADMOVE_SUCCEEDED',
  insertOversizeLoadMoveFailed: 'INSERT_OVERSIZELOADMOVE_FAILED',

  insertAssignment: 'INSERT_ASSIGNMENT',
  insertAssignmentSucceeded: 'INSERT_ASSIGNMENT_SUCCEEDED',
  insertAssignmentFailed: 'INSERT_ASSIGNMENT_FAILED',

  insertContract: 'INSERT_CONTRACT',
  insertContractSucceeded: 'INSERT_CONTRACT_SUCCEEDED',
  insertContractFailed: 'INSERT_CONTRACT_FAILED',

  insertEnvironmental: 'INSERT_ENVIRONMENTAL',
  insertEnvironmentalSucceeded: 'INSERT_ENVIRONMENTAL_SUCCEEDED',
  insertEnvironmentalFailed: 'INSERT_ENVIRONMENTAL_FAILED',
};

const formUpdateConstants = {
  updateApplication: 'UPDATE_APPLICATION',
  updateApplicationSucceeded: 'UPDATE_APPLICATION_SUCCEEDED',
  updateApplicationFailed: 'UPDATE_APPLICATION_FAILED',

  updateApplicationDetail: 'UPDATE_APPLICATION_DETAIL',
  updateApplicationDetailSucceeded: 'UPDATE_APPLICATION_DETAIL_SUCCEEDED',
  updateApplicationDetailFailed: 'UPDATE_APPLICATION_DETAIL_FAILED',

  updateLocationDetail: 'UPDATE_LOCATION_DETAIL',
  updateLocationDetailSucceeded: 'UPDATE_LOCATION_DETAIL_SUCCEEDED',
  updateLocationDetailFailed: 'UPDATE_LOCATION_DETAIL_FAILED',

  updatePipeline: 'UPDATE_PIPELINE',
  updatePipelineSucceeded: 'UPDATE_PIPELINE_SUCCEEDED',
  updatePipelineFailed: 'UPDATE_PIPELINE_FAILED',

  updatePipelineCasing: 'UPDATE_PIPELINE_CASING',
  updatePipelineCasingSucceeded: 'UPDATE_PIPELINE_CASING_SUCCEEDED',
  updatePipelineCasingFailed: 'UPDATE_PIPELINE_CASING_FAILED',

  updatePipelineCarrier: 'UPDATE_PIPELINE_CARRIER',
  updatePipelineCarrierSucceeded: 'UPDATE_PIPELINE_CARRIER_SUCCEEDED',
  updatePipelineCarrierFailed: 'UPDATE_PIPELINE_CARRIER_FAILED',

  updateWireline: 'UPDATE_WIRELINE',
  updateWirelineSucceeded: 'UPDATE_WIRELINE_SUCCEEDED',
  updateWirelineFailed: 'UPDATE_WIRELINE_FAILED',

  updateOverheadWireline: 'UPDATE_OVERHEAD_WIRELINE',
  updateOverheadWirelineSucceeded: 'UPDATE_OVERHEAD_WIRELINE_SUCCEEDED',
  updateOverheadWirelineFailed: 'UPDATE_OVERHEAD_WIRELINE_FAILED',

  updatePole: 'UPDATE_POLE',
  updatePoleSucceeded: 'UPDATE_POLE_SUCCEEDED',
  updatePoleFailed: 'UPDATE_POLE_FAILED',

  updateUndergroundWireline: 'UPDATE_UNDERGROUND_WIRELINE',
  updateUndergroundWirelineSucceeded: 'UPDATE_UNDERGROUND_WIRELINE_SUCCEEDED',
  updateUndergroundWirelineFailed: 'UPDATE_UNDERGROUND_WIRELINE_FAILED',

  updateConduit: 'UPDATE_CONDUIT',
  updateConduitSucceeded: 'UPDATE_CONDUIT_SUCCEEDED',
  updateConduitFailed: 'UPDATE_CONDUIT_FAILED',

  updateVariances: 'UPDATE_VARIANCES',
  updateVariancesSucceeded: 'UPDATE_VARIANCES_SUCCEEDED',
  updateVariancesFailed: 'UPDATE_VARIANCES_FAILED',

  submitApplication: 'SUBMIT_APPLICATION',
  submitApplicationSucceeded: 'SUBMIT_APPLICATION_SUCCEEDED',
  submitApplicationFailed: 'SUBMIT_APPLICATION_FAILED',

  updateTemporaryOccupancy: 'UPDATE_TEMPORARYOCCUPANCY',
  updateTemporaryOccupancySucceeded: 'UPDATE_TEMPORARYOCCUPANCY_SUCCEEDED',
  updateTemporaryOccupancyFailed: 'UPDATE_TEMPORARYOCCUPANCY_FAILED',

  updatePrivateCrossing: 'UPDATE_PRIVATECROSSING',
  updatePrivateCrossingSucceeded: 'UPDATE_PRIVATECROSSING_SUCCEEDED',
  updatePrivateCrossingFailed: 'UPDATE_PRIVATECROSSING_FAILED',

  updateRoadwaySurfacing: 'UPDATE_ROADWAYSURFACING',
  updateRoadwaySurfacingSucceeded: 'UPDATE_ROADWAYSURFACING_SUCCEEDED',
  updateRoadwaySurfacingFailed: 'UPDATE_ROADWAYSURFACING_FAILED',

  updateGeneralLicense: 'UPDATE_GENERALLICENSE',
  updateGeneralLicenseSucceeded: 'UPDATE_GENERALLICENSE_SUCCEEDED',
  updateGeneralLicenseFailed: 'UPDATE_GENERALLICENSE_FAILED',

  updateOversizeLoadMove: 'UPDATE_OVERSIZELOADMOVE',
  updateOversizeLoadMoveSucceeded: 'UPDATE_OVERSIZELOADMOVE_SUCCEEDED',
  updateOversizeLoadMoveFailed: 'UPDATE_OVERSIZELOADMOVE_FAILED',

  updateAssignment: 'UPDATE_ASSIGNMENT',
  updateAssignmentSucceeded: 'UPDATE_ASSIGNMENT_SUCCEEDED',
  updateAssignmentFailed: 'UPDATE_ASSIGNMENT_FAILED',

  updateContract: 'UPDATE_CONTRACT',
  updateContractSucceeded: 'UPDATE_CONTRACT_SUCCEEDED',
  updateContractFailed: 'UPDATE_CONTRACT_FAILED',

  updateEnvironmental: 'UPDATE_ENVIRONMENTAL',
  updateEnvironmentalSucceeded: 'UPDATE_ENVIRONMENTAL_SUCCEEDED',
  updateEnvironmentalFailed: 'UPDATE_ENVIRONMENTAL_FAILED',
};

const formDeleteConstants = {
  deleteApplication: 'DELETE_APPLICATION',
  deleteApplicationSucceeded: 'DELETE_APPLICATION_SUCCEEDED',
  deleteApplicationFailed: 'DELETE_APPLICATION_FAILED',

  deletePipeline: 'DELETE_PIPELINE',
  deletePipelineSucceeded: 'DELETE_PIPELINE_SUCCEEDED',
  deletePipelineFailed: 'DELETE_PIPELINE_FAILED',

  deletePipelineCasing: 'DELETE_PIPELINE_CASING',
  deletePipelineCasingSucceeded: 'DELETE_PIPELINE_CASING_SUCCEEDED',
  deletePipelineCasingFailed: 'DELETE_PIPELINE_CASING_FAILED',

  deletePipelineCarrier: 'DELETE_PIPELINE_CARRIER',
  deletePipelineCarrierSucceeded: 'DELETE_PIPELINE_CARRIER_SUCCEEDED',
  deletePipelineCarrierFailed: 'DELETE_PIPELINE_CARRIER_FAILED',

  deleteWireline: 'DELETE_WIRELINE',
  deleteWirelineSucceeded: 'DELETE_WIRELINE_SUCCEEDED',
  deleteWirelineFailed: 'DELETE_WIRELINE_FAILED',

  deleteOverheadWireline: 'DELETE_OVERHEAD_WIRELINE',
  deleteOverheadWirelineSucceeded: 'DELETE_OVERHEAD_WIRELINE_SUCCEEDED',
  deleteOverheadWirelineFailed: 'DELETE_OVERHEAD_WIRELINE_FAILED',

  deletePole: 'DELETE_POLE',
  deletePoleSucceeded: 'DELETE_POLE_SUCCEEDED',
  deletePoleFailed: 'DELETE_POLE_FAILED',

  deleteUndergroundWireline: 'DELETE_UNDERGROUND_WIRELINE',
  deleteUndergroundWirelineSucceeded: 'DELETE_UNDERGROUND_WIRELINE_SUCCEEDED',
  deleteUndergroundWirelineFailed: 'DELETE_UNDERGROUND_WIRELINE_FAILED',

  deleteConduit: 'DELETE_CONDUIT',
  deleteConduitSucceeded: 'DELETE_CONDUIT_SUCCEEDED',
  deleteConduitFailed: 'DELETE_CONDUIT_FAILED',

  deleteContract: 'DELETE_CONTRACT',
  deleteContractSucceeded: 'DELETE_CONTRACT_SUCCEEDED',
  deleteContractFailed: 'DELETE_CONTRACT_FAILED',
};

const formChangeConstants = {
  varianceExplanationChange: 'VARIANCE_EXPLANATION_CHANGE',
  clearSelectedApplication: 'CLEAR_SELECTED_APPLICATION',
};

const formSetConstants = {
  setApplicationDetail: 'SET_APPLICATION_DETAIL',
};

const formConstants = {
  ...formGetConstants,
  ...formInsertConstants,
  ...formUpdateConstants,
  ...formDeleteConstants,
  ...formChangeConstants,
  ...formSetConstants,
};

export default formConstants;
