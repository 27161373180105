import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import commonActions from '../../actions/commonActions';

export default function ContactUs({ open, handleClose }) {
  const dispatch = useDispatch();
  const [description, setDescription] = React.useState('');
  const { name, email } = useSelector((state) => state.user);
  const { application } = useSelector((state) => state.selectedApplication);
  const applicationId = application.applicationId ? application.applicationId : null;

  return (
    <div>
      <Dialog
        id="denial-dialog"
        open={open}
        onClose={(evt, reason) => {
          if (reason === 'backdropClick') {
            evt.preventDefault();
            return;
          }
          handleClose();
        }}
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Contact Us</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'black' }}>
            Please describe below any issues you are encountering and we'll get back to you.
          </DialogContentText>
          <TextField
            label="Description"
            multiline
            rows="4"
            value={description}
            onChange={(evt) => setDescription(evt.target.value)}
            variant="outlined"
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispatch(
                commonActions.sendHelpRequest(
                  name,
                  email,
                  description,
                  window.location.href,
                  applicationId
                )
              );
              setDescription('');
              handleClose();
            }}
            color="primary"
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ContactUs.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
