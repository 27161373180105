import paymentConstants from '../constants/paymentConstants';

const initialState = {
  settingApplicationFees: false,
  applicationFees: [],
  icFlaggingFee: null,
  rushProcessingFee: null,
  gettingUnpaidFees: false,
  gettingPaidApplicationFees: false,
  paidApplicationFees: {},
  updatingPermitFeeIsApplied: false,
  unpaidFees: [],
  fees: [],
  payments: [],
  gettingFeesAndPayments: false,
  gettingConvenienceFee: false,
  convenienceFee: null,
  acceptedTermsAndConditions: false,
  userPaymentData: {
    firstName: '',
    lastName: '',
    cardNumber: '',
    expiryDate: '',
    securityCode: '',
    address: '',
    city: '',
    country: '',
    state: '',
    zipCode: '',
    transactionType: '',
    amount: null,
    invoiceNumber: '',
  },
  makingPayment: false,
  savingPayment: false,
  paymentApprovalResponse: {},
  updatingFee: false,
  deletingFee: false,
  addingFee: false,
  recordingManualPayment: false,
  initiatingPayment: false,
};

function payment(state = initialState, action) {
  switch (action.type) {
    case paymentConstants.setApplicationFees:
      return { ...state, settingApplicationFees: true, applicationFees: [] };
    case paymentConstants.setApplicationFeesSuccess:
      return { ...state, settingApplicationFees: false, applicationFees: action.response };
    case paymentConstants.setApplicationFeesError:
      return { ...state, settingApplicationFees: false };

    case paymentConstants.getAdditionalApplicationFees:
      return { ...state };
    case paymentConstants.getAdditionalApplicationFeesSuccess:
      return {
        ...state,
        icFlaggingFee: action.response.icFlaggingFee,
        rushProcessingFee: action.response.rushProcessingFee,
      };
    case paymentConstants.getAdditionalApplicationFeesError:
      return { ...state };

    case paymentConstants.getUnpaidFees:
      return { ...state, gettingUnpaidFees: true, unpaidFees: [] };
    case paymentConstants.getUnpaidFeesSuccess:
      return { ...state, gettingUnpaidFees: false, unpaidFees: action.response };
    case paymentConstants.getUnpaidFeesError:
      return { ...state, gettingUnpaidFees: false };

    case paymentConstants.getPaidApplicationFees:
      return { ...state, gettingPaidApplicationFees: true, paidApplicationFees: {} };
    case paymentConstants.getPaidApplicationFeesSuccess:
      return { ...state, gettingPaidApplicationFees: false, paidApplicationFees: action.response };
    case paymentConstants.getPaidApplicationFeesError:
      return { ...state, gettingPaidApplicationFees: false };

    case paymentConstants.updatePermitFeeIsApplied:
      return { ...state, updatingPermitFeeIsApplied: true };
    case paymentConstants.updatePermitFeeIsAppliedSuccess:
      const unchangedFees = state.unpaidFees.filter(
        (f) => f.permitFeeId !== action.permitFee.permitFeeId
      );
      return {
        ...state,
        updatingPermitFeeIsApplied: false,
        unpaidFees: [...unchangedFees, action.permitFee],
      };
    case paymentConstants.updatePermitFeeIsAppliedError:
      return { ...state, updatingPermitFeeIsApplied: false };

    case paymentConstants.getConvenienceFee:
      return { ...state, gettingConvenienceFee: true };
    case paymentConstants.getConvenienceFeeSuccess:
      return { ...state, convenienceFee: action.response, gettingConvenienceFee: false };
    case paymentConstants.getConvenienceFeeError:
      return { ...state, gettingConvenienceFee: false };

    case paymentConstants.setAcceptedTermsAndConditions:
      return { ...state, acceptedTermsAndConditions: action.value };

    case paymentConstants.setUserPaymentData:
      return { ...state, userPaymentData: { ...state.userPaymentData, ...action.values } };

    case paymentConstants.initiatePaymentStarted:
      return { ...state, initiatingPayment: true };
    case paymentConstants.initiatePaymentDone:
      return { ...state, initiatingPayment: false };

    case paymentConstants.makePayment:
      return { ...state, makingPayment: true, paymentApprovalResponse: {} };
    case paymentConstants.makePaymentSuccess:
      return { ...state, makingPayment: false, paymentApprovalResponse: action.response };
    case paymentConstants.makePaymentError:
      return { ...state, makingPayment: false };

    case paymentConstants.cancelPayment:
      return { ...state };
    case paymentConstants.cancelPaymentSuccess:
      return { ...state, makingPayment: false };
    case paymentConstants.cancelPaymentError:
      return { ...state };

    case paymentConstants.savePayment:
      return { ...state, savingPayment: true };
    case paymentConstants.savePaymentSuccess:
      return {
        ...state,
        savingPayment: false,
        acceptedTermsAndConditions: false,
      };
    case paymentConstants.savePaymentError:
      return { ...state, savingPayment: false };

    case paymentConstants.getFeesAndPayments:
      return { ...state, gettingFeesAndPayments: true, fees: [] };
    case paymentConstants.getFeesAndPaymentsSuccess:
      return {
        ...state,
        gettingFeesAndPayments: false,
        fees: action.response.fees,
        payments: action.response.payments,
      };
    case paymentConstants.getFeesAndPaymentsError:
      return { ...state, gettingFeesAndPayments: false };

    case paymentConstants.updateFee:
      return { ...state, updatingFee: true };
    case paymentConstants.updateFeeSuccess:
      const updatedFee = action.response;
      const updatedFees = [...state.fees];
      const feeIdx = updatedFees.findIndex((f) => f.permitFeeId === updatedFee.permitFeeId);
      if (feeIdx !== -1) {
        updatedFees[feeIdx].amount = updatedFee.amount;
      }
      return { ...state, updatingFee: false, fees: updatedFees };
    case paymentConstants.updateFeeError:
      return { ...state, updatingFee: false };

    case paymentConstants.deleteFee:
      return { ...state, deletingFee: true };
    case paymentConstants.deleteFeeSuccess:
      const remainingFees = [...state.fees];
      const deletedFeeIdx = remainingFees.findIndex((f) => f.permitFeeId === action.permitFeeId);
      if (deletedFeeIdx !== -1) {
        remainingFees.splice(deletedFeeIdx, 1);
      }
      const remainingUnpaidFees = [...state.unpaidFees];
      const deletedUnpaidFeeIdx = remainingUnpaidFees.findIndex(
        (f) => f.permitFeeId === action.permitFeeId
      );
      if (deletedUnpaidFeeIdx !== -1) {
        remainingUnpaidFees.splice(deletedUnpaidFeeIdx, 1);
      }
      return {
        ...state,
        deletingFee: false,
        fees: remainingFees,
        unpaidFees: remainingUnpaidFees,
      };
    case paymentConstants.deleteFeeError:
      return { ...state, deletingFee: false };

    case paymentConstants.addFee:
      return { ...state, addingFee: true };
    case paymentConstants.addFeeSuccess:
      return { ...state, addingFee: false, fees: [...state.fees, action.response] };
    case paymentConstants.addFeeError:
      return { ...state, addingFee: false };

    case paymentConstants.recordManualPayment:
      return { ...state, recordingManualPayment: true };
    case paymentConstants.recordManualPaymentSuccess:
      return {
        ...state,
        recordingManualPayment: false,
        fees: action.response.fees,
        payments: action.response.payments,
      };
    case paymentConstants.recordManualPaymentError:
      return { ...state, recordingManualPayment: false };

    default:
      return state;
  }
}

export default payment;
