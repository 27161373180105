import React from 'react';
import PropTypes from 'prop-types';
import FormValue from '../Components/FormValue';
import SectionHeader from '../Components/SectionHeader';
import SectionSubHeader from '../Components/SectionSubHeader';
import SummaryTable from '../Components/SummaryTable';

function bottomRailClearance(appSummary) {
  const ft = appSummary.wireline.undergroundWireline.bottomRailClearanceFt || 0;
  const inches = appSummary.wireline.undergroundWireline.bottomRailClearanceIn || 0;

  return (ft + inches / 12).toFixed(2);
}

function groundCover(appSummary) {
  const ft = appSummary.wireline.undergroundWireline.groundCoverFt || 0;
  const inches = appSummary.wireline.undergroundWireline.groundCoverIn || 0;

  return (ft + inches / 12).toFixed(2);
}

function conduitData(appSummary) {
  return appSummary.wireline.undergroundWireline.conduits.map((conduit) => [
    conduit.conduitOccupied ? 'Occupied' : 'Vacant',
    conduit.numberWires,
    `${conduit.conduitSize} in.`,
    conduit.conduitMaterial,
    conduit.conduitCommodity,
    conduit.conduitVoltage,
  ]);
}

const UndergroundWirelineSummary = ({ appSummary }) => {
  return (
    <>
      {appSummary.wireline.undergroundWireline && (
        <>
          <SectionHeader headerText="Underground" />
          <FormValue
            fieldText="Distance from base of rail to top of casing"
            valueText={bottomRailClearance(appSummary)}
          />
          <FormValue
            fieldText="Crossing angle"
            valueText={appSummary.wireline.undergroundWireline.crossingAngle}
          />
          <FormValue fieldText="Ground cover" valueText={groundCover(appSummary)} />
          <FormValue
            fieldText="Conduits are cased"
            valueText={appSummary.wireline.undergroundWireline.conduitsCased}
          />
          {appSummary.wireline.undergroundWireline.conduitsCased && (
            <>
              <FormValue
                fieldText="Casing material"
                valueText={
                  appSummary.wireline.undergroundWireline.casingMaterial
                    ? appSummary.wireline.undergroundWireline.casingMaterial
                    : appSummary.wireline.undergroundWireline.otherCasingMaterial
                }
              />
              <FormValue
                fieldText="Casing grade"
                valueText={appSummary.wireline.undergroundWireline.specificationGrade}
              />
              <FormValue
                fieldText="Casing inside diameter"
                valueText={appSummary.wireline.undergroundWireline.casingInsideDiameter}
                unitsLabel="in."
              />
              <FormValue
                fieldText="Casing wall thickness"
                valueText={appSummary.wireline.undergroundWireline.casingWallThickness}
                unitsLabel="in."
              />
            </>
          )}
          {appSummary.wireline.undergroundWireline.conduits &&
            appSummary.wireline.undergroundWireline.conduits.length && (
              <>
                <SectionSubHeader headerText="Conduits" />
                <SummaryTable
                  headers={['Status', '# Wires', 'Size', 'Material', 'Commodity', 'Voltage']}
                  data={conduitData(appSummary)}
                  leftAlignFirstColumn={false}
                />
              </>
            )}
        </>
      )}
    </>
  );
};

UndergroundWirelineSummary.propTypes = {
  appSummary: PropTypes.object.isRequired,
};

export default UndergroundWirelineSummary;
