import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'debounce';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import withRoot from '../../../modules/withRoot';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../modules/AppInsights';
import ApplicationsMap from './ApplicationsMap';
import { getDateOffset } from '../../../helpers/utils';
import homeActions from '../../../actions/homeActions';

appInsights.trackPageView('ManagementMap');

const useStyles = makeStyles(() => ({
  dateContainer: {
    backgroundColor: 'white',
    padding: '10px 0px',
  },
}));

const defaultDateOffset = -120;

function filterApplications(reviewerApplications, includedApplications) {
  let filteredApplications = [];
  switch (includedApplications) {
    case 'assigned':
      filteredApplications = reviewerApplications.assigned;
      break;
    case 'other':
      filteredApplications = reviewerApplications.other;
      break;
    case 'all':
      filteredApplications = [...reviewerApplications.assigned, ...reviewerApplications.other];
      break;
    default:
      filteredApplications = [...reviewerApplications.assigned, ...reviewerApplications.other];
      break;
  }

  return filteredApplications;
}

function MapContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [includedApplications, setIncludedApplications] = React.useState('assigned');
  const [searchTerm, setSearchTerm] = React.useState('');
  const { reviewerApplications } = useSelector((state) => state.home);

  const initialStartDate = getDateOffset(defaultDateOffset);
  const initialEndDate = getDateOffset(0);
  const [dateExtents, setDateExtents] = useState({
    begin: initialStartDate,
    end: initialEndDate,
  });
  const debounceSetDateExtents = debounce((newExtents) => {
    setDateExtents(newExtents);
    dispatch(homeActions.getReviewerApplications(newExtents.begin, newExtents.end));
  }, 1000);
  const debounceSetSearchTerm = debounce((searchTerm) => {
    setSearchTerm(searchTerm);
  }, 1000);

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Map
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" className={classes.dateContainer} position="static" elevation={0}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <TextField
                id="fromDate"
                label="Begin Date"
                type="date"
                defaultValue={dateExtents.begin}
                style={{ marginRight: 15 }}
                onChange={(event) => {
                  const newExtents = { begin: event.target.value, end: dateExtents.end };
                  debounceSetDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="toDate"
                label="End Date"
                type="date"
                defaultValue={dateExtents.end}
                onChange={(event) => {
                  const newExtents = { begin: dateExtents.begin, end: event.target.value };
                  debounceSetDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                color="primary"
                value={includedApplications}
                exclusive
                onChange={(evt, value) => setIncludedApplications(value)}
              >
                <ToggleButton value="assigned">Assigned</ToggleButton>
                <ToggleButton value="other">Other</ToggleButton>
                <ToggleButton value="all">All</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  <div>
                    <Typography variant="body1">Search by one of the following fields:</Typography>
                    <Typography variant="body1" style={{ marginLeft: 10 }}>
                      Permit Type
                    </Typography>
                    <Typography variant="body1" style={{ marginLeft: 10 }}>
                      Application (Business) Id
                    </Typography>
                    <Typography variant="body1" style={{ marginLeft: 10 }}>
                      Permit Location
                    </Typography>
                    <Typography variant="body1" style={{ marginLeft: 10 }}>
                      Company Name
                    </Typography>
                    <Typography variant="body1" style={{ marginLeft: 10 }}>
                      Status
                    </Typography>
                  </div>
                }
                style={{ backgroundColor: 'black', position: 'absolute', borderRadius: 5 }}
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
              <TextField
                style={{ marginLeft: 24 }}
                placeholder="Search"
                type="search"
                variant="outlined"
                fullWidth
                size="small"
                onChange={(evt) => {
                  debounceSetSearchTerm(evt.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <ApplicationsMap
        applications={filterApplications(reviewerApplications, includedApplications)}
        searchTerm={searchTerm}
      />
    </div>
  );
}

export default withRoot(withAITracking(reactPlugin, MapContainer));
