import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import withRoot from '../../modules/withRoot';

function prepareReportList(reportTypes) {
  const reportData = [];
  reportTypes.forEach((report) => {
    reportData.push({
      name: (
        <Link to={`/rpt/${report.value.toLowerCase()}`} style={{ textDecoration: 'none' }}>
          {report.name}
        </Link>
      ),
      description: report.description,
    });
  });

  return reportData.sort((a, b) => a.name - b.name);
}

function Reports() {
  const reportTypes = useSelector((state) => state.common.lookups.reportTypes);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Report Name',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
    ],
    []
  );

  return (
    <>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ marginBottom: '10px' }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Reports
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <MaterialReactTable
        columns={columns}
        data={prepareReportList(reportTypes)}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enablePagination={false}
      />
    </>
  );
}

Reports.propTypes = {};

export default withRoot(Reports);
