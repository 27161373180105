import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import withRoot from '../../modules/withRoot';
import AssignedApplicationsTable from './AssignedApplicationsTable';
import OtherApplicationsTable from './OtherApplicationsTable';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../modules/AppInsights';

appInsights.trackPageView('ManagementApplications');

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0 15px',
  },
  button: {
    marginTop: 10,
  },
  dateContainer: {
    backgroundColor: 'white',
    padding: '10px 0px',
  },
  tabIndicator: { height: 5 },
}));

function ManagementApplications({ dateFilters, setDateExtents }) {
  const classes = useStyles();
  const [activeTabId, setActiveTab] = useState(0);
  const reviewerApplications = useSelector((state) => state.home.reviewerApplications);
  const { applicationStatuses } = useSelector((state) => state.common.lookups);
  const executedStatus = applicationStatuses.find((status) => status.value === 'EXECUTED');
  const unsubmittedStatus = applicationStatuses.find((status) => status.value === 'UNSUBMITTED');
  const deniedStatus = applicationStatuses.find((status) => status.value === 'DENIED');
  const assignedApps = reviewerApplications.assigned.filter(
    (a) =>
      a.applicationStatusId !== executedStatus?.id &&
      a.applicationStatusId !== unsubmittedStatus?.id &&
      a.applicationStatusId !== deniedStatus?.id
  );
  const executedApps = reviewerApplications.assigned.filter(
    (a) => a.applicationStatusId === executedStatus?.id
  );
  const unsubmittedApps = reviewerApplications.assigned.filter(
    (a) => a.applicationStatusId === unsubmittedStatus?.id
  );
  const deniedApps = reviewerApplications.assigned.filter(
    (a) => a.applicationStatusId === deniedStatus?.id
  );
  const otherApps = [
    ...reviewerApplications.other,
    ...executedApps,
    ...unsubmittedApps,
    ...deniedApps,
  ];

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Applications
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Stack spacing={2} sx={{ padding: '20px' }} direction={{ sm: 'column', md: 'row' }}>
        <TextField
          id="fromDate"
          label="Begin Date"
          type="date"
          defaultValue={dateFilters.initialStartDate}
          onChange={(event) => {
            const newExtents = { ...dateFilters.dateExtents, begin: event.target.value };
            setDateExtents(newExtents);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="toDate"
          label="End Date"
          type="date"
          defaultValue={dateFilters.initialEndDate}
          onChange={(event) => {
            const newExtents = { ...dateFilters.dateExtents, end: event.target.value };
            setDateExtents(newExtents);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Stack>
      <AppBar component="div" position="static" elevation={0}>
        <Tabs
          value={activeTabId}
          textColor="inherit"
          onChange={(evt, value) => setActiveTab(value)}
          classes={{ indicator: classes.tabIndicator }}
        >
          <Tab label="Assigned" />
          <Tab label="Other" />
        </Tabs>
      </AppBar>
      {activeTabId === 0 && (
        <Grid container justifyContent="center" className={classes.wrapper}>
          <Grid item xs={12}>
            <AssignedApplicationsTable applications={assignedApps} />
          </Grid>
        </Grid>
      )}
      {activeTabId === 1 && (
        <Grid container justifyContent="center" className={classes.wrapper}>
          <Grid item xs={12}>
            <OtherApplicationsTable applications={otherApps} />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

ManagementApplications.propTypes = {
  dateFilters: PropTypes.object.isRequired,
  setDateExtents: PropTypes.func.isRequired,
};

export default withRoot(withAITracking(reactPlugin, ManagementApplications));
