import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import ApplicantApplications from './ApplicantApplications';
import UnpaidFees from './UnpaidFees';
import PaymentHistory from './PaymentHistory';
import formGetActions from '../../actions/formGet';
import homeActions from '../../actions/homeActions';
import AcceptDeclineShareRequest from './Sharing/AcceptDeclineShareRequest';

const styles = () => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

function ApplicantHomeRoutes(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(formGetActions.getAllApplications());
    dispatch(homeActions.getAllUnpaidFees());
    dispatch(homeActions.getPaymentHistory());
  }, [dispatch]);

  const { classes } = props;
  const { path } = useRouteMatch();

  return (
    <Paper className={classes.paper}>
      <Switch>
        <Redirect exact from="/home" to="/home/applications" />
        <Route path={`${path}/applications`}>
          <ApplicantApplications />
        </Route>
        <Route path={`${path}/unpaidfees`}>
          <UnpaidFees />
        </Route>
        <Route path={`${path}/paymenthistory`}>
          <PaymentHistory />
        </Route>
        <Route path={`${path}/applicationshare/accept`}>
          <AcceptDeclineShareRequest accept={true} />
        </Route>
        <Route path={`${path}/applicationshare/decline`}>
          <AcceptDeclineShareRequest accept={false} />
        </Route>
      </Switch>
    </Paper>
  );
}

ApplicantHomeRoutes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ApplicantHomeRoutes);
