import React from 'react';
import PropTypes from 'prop-types';
import FormValue from '../Components/FormValue';
import SectionHeader from '../Components/SectionHeader';
import Applicant from '../Components/Applicant';
import { View } from '@react-pdf/renderer';
import ApplicantDetails from '../Components/ApplicantDetails';
import LocationDetails from '../Components/LocationDetails';
import ApplicationDetails from '../Components/ApplicationDetails';
import SummaryLayout from '../Components/SummaryLayout';

const TemporaryOccupancySummary = ({ appSummary, history }) => {
  return (
    <SummaryLayout
      appSummary={appSummary}
      history={history}
      headerTitle={`Application for ${appSummary.permitType}`}
    >
      <Applicant appSummary={appSummary} />
      <ApplicantDetails appSummary={appSummary} />
      <ApplicationDetails appSummary={appSummary} />
      <LocationDetails appSummary={appSummary} />
      <SectionHeader headerText="Temporary Occupancy" />
      <FormValue
        fieldText="Purpose of occupancy"
        valueText={appSummary.temporaryOccupancy.purpose}
      />
      <FormValue
        fieldText="Is the work to be performed within 50ft of the track"
        valueText={appSummary.temporaryOccupancy.workWithin50Ft}
      />
      <FormValue
        fieldText="Percentage of work within 50ft of the track"
        valueText={appSummary.temporaryOccupancy.pctWithin50Ft}
        unitsLabel="%"
        hideIfEmpty
      />
      <FormValue
        fieldText="Will this be for vegetation trimming only (no grubbing, uprooting or removal)"
        valueText={appSummary.temporaryOccupancy.treeTrimmingOnly}
      />
      <FormValue
        fieldText="Area to be occupied"
        valueText={`${appSummary.temporaryOccupancy.occupiedAreaXFt} ft x ${appSummary.temporaryOccupancy.occupiedAreaYFt} ft`}
      />
      <FormValue
        fieldText="Total cost of project"
        valueText={`$${appSummary.temporaryOccupancy.totalCost}`}
      />
      <FormValue fieldText="For a seismic survey" valueText="" />
      <View style={{ paddingLeft: '15px' }}>
        <FormValue
          fieldText="Total # of cables on property"
          valueText={appSummary.temporaryOccupancy.cablesOnProperty}
        />
      </View>
    </SummaryLayout>
  );
};

TemporaryOccupancySummary.propTypes = {
  appSummary: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
};

export default TemporaryOccupancySummary;
