import React from 'react';
import PropTypes from 'prop-types';
import FormValue from '../Components/FormValue';
import SectionHeader from '../Components/SectionHeader';
import Applicant from '../Components/Applicant';
import ApplicantDetails from '../Components/ApplicantDetails';
import LocationDetails from '../Components/LocationDetails';
import SummaryLayout from '../Components/SummaryLayout';
import ApplicationDetails from '../Components/ApplicationDetails';

const GeneralLicenseSummary = ({ appSummary, history }) => {
  return (
    <SummaryLayout
      appSummary={appSummary}
      history={history}
      headerTitle={`Application for ${appSummary.permitType}`}
    >
      <Applicant appSummary={appSummary} />
      <ApplicantDetails appSummary={appSummary} />
      <ApplicationDetails appSummary={appSummary} />
      <LocationDetails appSummary={appSummary} />
      <SectionHeader headerText="General License" />
      <FormValue fieldText="Purpose of license" valueText={appSummary.generalLicense.purpose} />
      <FormValue
        fieldText="Will this project be located entirely within the lmits of a public street"
        valueText={appSummary.generalLicense.onPublicStreet}
      />
      <FormValue
        fieldText="Total cost of project"
        valueText={`$${appSummary.generalLicense.totalCost}`}
      />
      <FormValue
        fieldText="What type of improvements will be located on the property"
        valueText={appSummary.generalLicense.improvementDesc}
      />
    </SummaryLayout>
  );
};

GeneralLicenseSummary.propTypes = {
  appSummary: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
};

export default GeneralLicenseSummary;
