/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MaterialReactTable from 'material-react-table';
import { Typography, AppBar, Toolbar, Grid, Tooltip, IconButton } from '@mui/material';
import withRoot from '../../modules/withRoot';
import makeStyles from '@mui/styles/makeStyles';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptDialog from './ReceiptDialog';
import { getLookup } from '../../helpers/utils';
import { Stack } from '@mui/system';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0 10px',
  },
}));

function prepareTableData(paymentHistory, permitTypes) {
  if (!paymentHistory || !paymentHistory.length) {
    return [];
  }
  const data = paymentHistory.map((payment) => {
    const permitTypeLU = getLookup(permitTypes, payment.application.permitTypeId);
    return {
      businessId: payment.application.businessId,
      permitType: permitTypeLU.name,
      date: payment.ssl_txn_time,
      amount: Number(payment.ssl_amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      payment,
      paidFees: payment.permitFees,
    };
  });
  return data.sort((a, b) => new Date(b.date) - new Date(a.date));
}

function PaymentHistory() {
  const classes = useStyles();

  const { paymentHistory, gettingPaymentHistory } = useSelector((state) => state.home);
  const { fees, permitTypes } = useSelector((state) => state.common.lookups);

  const [receiptDialogState, setReceiptDialogState] = useState({
    open: false,
    payment: {},
    paidFees: [],
    businessId: '',
  });

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'businessId',
        header: 'Application Id',
      },
      {
        accessorKey: 'permitType',
        header: 'Permit Type',
      },
      {
        accessorKey: 'date',
        header: 'Payment Date',
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
      },
    ],
    []
  );

  return (
    <div>
      <ReceiptDialog
        open={receiptDialogState.open}
        handleClose={() => setReceiptDialogState({ ...receiptDialogState, open: false })}
        businessId={receiptDialogState.businessId}
        paymentApprovalResponse={receiptDialogState.payment}
        paidFees={receiptDialogState.paidFees}
      />
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Payment History
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container justifyContent="center" className={classes.wrapper}>
        <Grid item xs={12} style={{ padding: '10px 10px 0px 10px' }}>
          <Typography color="inherit" variant="body1" style={{ marginBottom: 5 }}>
            View payments recorded on applications created by you, and any applications shared with
            you.
          </Typography>
          <Typography color="inherit" variant="body1">
            Click the{' '}
            <ReceiptIcon
              fontSize="medium"
              color="action"
              style={{ position: 'relative', top: 5 }}
            />{' '}
            icon to view receipt.
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <MaterialReactTable
            columns={columns}
            data={prepareTableData(paymentHistory, permitTypes)}
            enableStickyHeader
            enableGlobalFilter={false}
            enableRowActions
            state={{
              isLoading: gettingPaymentHistory,
            }}
            renderRowActions={({ row }) => (
              <Stack direction="row" spacing={1}>
                <Tooltip title="View Receipt">
                  <IconButton
                    onClick={() => {
                      row.original['paidFees'].forEach((pf) => {
                        const feeLu = fees.find((f) => f.feeId === pf.feeId);
                        pf.feeLookup = feeLu;
                      });
                      setReceiptDialogState({
                        ...receiptDialogState,
                        open: true,
                        payment: row.original['payment'],
                        paidFees: row.original['paidFees'],
                        businessId: row.original['businessId'],
                      });
                    }}
                    size="large"
                  >
                    <ReceiptIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}

PaymentHistory.propTypes = {};

export default withRoot(PaymentHistory);
