import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MaterialReactTable from 'material-react-table';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { Button, Container } from '@mui/material';
import withRoot from '../../modules/withRoot';
import homeActions from '../../actions/homeActions';
import { getLookup } from '../../helpers/utils';
import ModalSpinner from '../../modules/components/ModalSpinner';

function prepareApplicationData(data, permitTypesLu, engineeringDivisions) {
  const applications = [];

  data.forEach((application) => {
    const status = application.applicationStatus || {};
    let { applicationDetail } = application;
    if (!applicationDetail) {
      applicationDetail = {};
    }

    let cityState = '';
    if (application.locationDetail && application.locationDetail.nearestTown) {
      cityState = `${application.locationDetail.nearestTown}, ${application.locationDetail.state}`;
    } else if (application.locationDetail && application.locationDetail.state) {
      cityState = application.locationDetail.state;
    }

    const permitTypeLU = getLookup(permitTypesLu, application.permitTypeId);

    const link = (
      <>
        <Grid container spacing={1}>
          <Grid item>
            <Link
              to={`/processapplication/${application.applicationId}`}
              style={{ display: 'block' }}
            >
              {application.businessId}
            </Link>
          </Grid>
          {applicationDetail.rushProcessing && (
            <Grid item>
              <Tooltip title="Rush Processing Requested">
                <PriorityHighIcon fontSize="small" color="error" style={{ display: 'block' }} />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </>
    );
    const engineeringDivision = engineeringDivisions.find(
      (lu) => lu.id === application.locationDetail.engineeringDivisionTerritoryId
    );
    const applicationData = {
      applicationId_: application.applicationId,
      businessId_: application.businessId,
      applicationLink: link,
      permitType: permitTypeLU.name,
      companyName: applicationDetail.legalName,
      cityState: cityState,
      engineeringDivision: engineeringDivision?.name,
      submittalDate: application.submittalDateTime
        ? moment.utc(application.submittalDateTime).local().format('YYYY-MM-DD')
        : null,
      status: status.name,
      rushProcessing_: applicationDetail.rushProcessing,
    };
    applications.push(applicationData);
  });

  return applications
    .sort((a, b) => new Date(a.submittalDate) - new Date(b.submittalDate))
    .reverse();
}

function Search() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(homeActions.getSearchOptions());
  }, [dispatch]);

  const { searchResults, searchingApplications, searchOptions, gettingSearchOptions } = useSelector(
    (state) => state.home
  );
  const { permitTypes, engineeringDivisions } = useSelector((state) => state.common.lookups);

  const [searchParams, setSearchParams] = useState({ businessId: '' });

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'applicationLink',
        header: 'Application Id',
        filterFn: (row, id, filterValue) => {
          if (row.original['businessId_']) {
            return row.original['businessId_'].toLowerCase().includes(filterValue.toLowerCase());
          }
          return false;
        },
        sortingFn: (rowA, rowB) => {
          if (rowA.original['businessId_'] > rowB.original['businessId_']) {
            return 1;
          }
          if (rowB.original['businessId_'] > rowA.original['businessId_']) {
            return -1;
          }
          return 0;
        },
      },
      {
        accessorKey: 'permitType',
        header: 'Permit Type',
      },
      {
        accessorKey: 'companyName',
        header: 'Company Name',
      },
      {
        accessorKey: 'cityState',
        header: 'City State',
      },
      {
        accessorKey: 'engineeringDivision',
        header: 'Division',
      },
      {
        accessorKey: 'submittalDate',
        header: 'Submittal Date',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        filterFn: (row, id, filterValue) => {
          if (row.original['status']) {
            return row.original['status'].toLowerCase().includes(filterValue.toLowerCase());
          }
          return false;
        },
        sortingFn: (rowA, rowB) => {
          if (rowA.original['status'] > rowB.original['status']) {
            return 1;
          }
          if (rowB.original['status'] > rowA.original['status']) {
            return -1;
          }
          return 0;
        },
      },
    ],
    []
  );

  return (
    <>
      <ModalSpinner open={gettingSearchOptions} />
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Search Applications
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div style={{ paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20 }}>
        <Grid container spacing={1} style={{ marginBottom: 30 }}>
          <Grid item xs={12} md={4} lg={3}>
            <Autocomplete
              id="businessId"
              freeSolo
              disabled={searchOptions?.businessIds?.length < 1}
              options={searchOptions?.businessIds || []}
              inputValue={searchParams.businessId}
              onInputChange={(event, newInputValue) => {
                setSearchParams({ ...searchParams, businessId: newInputValue });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Application Id" margin="normal" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatch(homeActions.searchApplications(searchParams))}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        <MaterialReactTable
          columns={columns}
          data={prepareApplicationData(searchResults, permitTypes, engineeringDivisions)}
          enableStickyHeader
          enableGlobalFilter={false}
          state={{
            isLoading: searchingApplications,
          }}
        />
      </div>
    </>
  );
}

Search.propTypes = {};

export default withRoot(Search);
