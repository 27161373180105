import { all, call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import contentDisposition from 'content-disposition';
import documentConstants from '../constants/documentConstants';
import alertConstants from '../constants/alertConstants';
import { acquireAccessToken } from '../Auth/utils';
import getPrintExhibitParams from '../helpers/printExhibitParams';
import config from '../data/config.json';
import { requestMapToken } from './mapQuerySagas';
import esriId from '@arcgis/core/identity/IdentityManager.js';
import { getApplicationHistory, getApplicationSummaryData } from './processApplicationSagas';
import { pdf } from '@react-pdf/renderer';
import SummaryDocument from '../ProcessApplication/Summary/SummaryDocument';
import Invoice from '../Payment/Invoice';

function downloadFile(blob, filename) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Support IE
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }
}

function* uploadDocuments(action) {
  const { documents, applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const results = yield all(
      documents.map((doc) =>
        call(axios, {
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/application/${Number(applicationId)}/document`,
          data: doc,
          headers: !token
            ? {}
            : { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
        })
      )
    );
    const uploadedDocuments = results.map((result) => result.data);
    yield put({ type: documentConstants.uploadDocumentsSuccess, uploadedDocuments });
  } catch (e) {
    yield put({ type: documentConstants.uploadDocumentsError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to upload documents' });
  }
}

function* deleteDocument(action) {
  const { documentId } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/application/document/${Number(documentId)}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: documentConstants.deleteDocumentSuccess, documentId });
  } catch (e) {
    yield put({ type: documentConstants.deleteDocumentError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to delete document' });
  }
}

function* downloadDocument(action) {
  const { documentId, filename } = action;
  try {
    const token = yield acquireAccessToken();
    const response = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/application/document/${Number(documentId)}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
      responseType: 'blob',
    });
    const blob = new Blob([response.data]);
    downloadFile(blob, filename);

    yield put({ type: documentConstants.downloadDocumentSuccess, documentId });
  } catch (e) {
    yield put({ type: documentConstants.downloadDocumentError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to download document' });
  }
}

function* downloadAllAttachments(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const response = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${Number(
        applicationId
      )}/attachments/all`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
      responseType: 'blob',
    });
    const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
    const blob = new Blob([response.data]);
    downloadFile(blob, filename);

    yield put({ type: documentConstants.downloadAllAttachmentsSuccess, applicationId });
  } catch (e) {
    yield put({ type: documentConstants.downloadAllAttachmentsError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to download attachments' });
  }
}

function* downloadAgreement(action) {
  const { applicationId, selectedTemplateId } = action;
  try {
    const token = yield acquireAccessToken();
    const response = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/application/${Number(applicationId)}/agreement`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
      responseType: 'blob',
      params: {
        agreementTemplateId: selectedTemplateId,
      },
    });
    let filename = `Agreement_${applicationId}.docx`;
    const { parameters } = contentDisposition.parse(response.headers['content-disposition']);
    if (parameters && parameters.filename) {
      filename = parameters.filename;
    }
    const blob = new Blob([response.data]);
    downloadFile(blob, filename);

    yield put({ type: documentConstants.downloadAgreementSuccess });
  } catch (e) {
    yield put({ type: documentConstants.downloadAgreementError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to download agreement' });
  }
}

function* getDraftExhibit(action) {
  const { application, lookups } = action;
  try {
    // TODO: replace Geoprocessor with up to date methods from esri js api 4.x
    const [Geoprocessor] = [null];

    const esriToken = yield requestMapToken();

    esriId.registerToken({
      server: config.GeoprocessingServices.Server,
      token: esriToken.token,
    });

    const geoprocessor = new Geoprocessor({
      url: config.GeoprocessingServices.CreateExhibit,
    });
    const params = yield getPrintExhibitParams(application, lookups);
    const jobInfo = yield geoprocessor.submitJob(params);
    yield geoprocessor.waitForJobCompletion(jobInfo.jobId);
    const {
      value: { url },
    } = yield geoprocessor.getResultData(jobInfo.jobId, 'Output_File');

    const response = yield call(axios, {
      method: 'get',
      url: url,
      responseType: 'blob',
    });
    const blob = new Blob([response.data]);
    const filename = `Draft_Exhibit_${application.applicationId}.pdf`;
    downloadFile(blob, filename);

    yield put({ type: documentConstants.getDraftExhibitSuccess });
  } catch (e) {
    yield put({ type: documentConstants.getDraftExhibitError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to get draft exhibit' });
  }
}

function* applicantSummaryDownload(action) {
  const { applicationId } = action;
  try {
    const getSummaryData = call(getApplicationSummaryData, { applicationId });
    const getHistory = call(getApplicationHistory, { applicationId });
    yield all([getSummaryData, getHistory]);
    const history = yield select((state) => state.selectedApplication.history);
    const summaryData = yield select((state) => state.selectedApplication.summaryData);
    const summaryDoc = <SummaryDocument appSummary={summaryData?.application} history={history} />;
    pdf(summaryDoc)
      .toBlob()
      .then((blob) => {
        const filename = `${summaryData.application.businessId}.pdf`;
        downloadFile(blob, filename);
      })
      .catch((e) => {
        console.error(e);
        throw e;
      });

    yield put({ type: documentConstants.applicantSummaryDownloadSuccess });
  } catch (e) {
    yield put({ type: documentConstants.applicantSummaryDownloadError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to download applicant summary' });
  }
}

function* downloadInvoice(action) {
  const { businessId, unpaidFees, railroad } = action;
  try {
    const summaryDoc = (
      <Invoice railroad={railroad} businessId={businessId} unpaidFees={unpaidFees} />
    );
    pdf(summaryDoc)
      .toBlob()
      .then((blob) => {
        const filename = `${businessId}.pdf`;
        downloadFile(blob, filename);
      })
      .catch((e) => {
        console.error(e);
        throw e;
      });

    yield put({ type: documentConstants.downloadInvoiceSuccess });
  } catch (e) {
    yield put({ type: documentConstants.downloadInvoiceError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to download invoice' });
  }
}

const documentSagas = [
  takeEvery(documentConstants.uploadDocuments, uploadDocuments),
  takeEvery(documentConstants.deleteDocument, deleteDocument),
  takeEvery(documentConstants.downloadDocument, downloadDocument),
  takeLatest(documentConstants.downloadAllAttachments, downloadAllAttachments),
  takeEvery(documentConstants.downloadAgreement, downloadAgreement),
  takeLatest(documentConstants.getDraftExhibit, getDraftExhibit),
  takeEvery(documentConstants.applicantSummaryDownload, applicantSummaryDownload),
  takeEvery(documentConstants.downloadInvoice, downloadInvoice),
];

export default documentSagas;
