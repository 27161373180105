import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import ManagementApplications from './ManagementApplications';
import Reports from './Reports';
import Search from './Search';
import MapContainer from './Map/MapContainer';
import { getDateOffset } from '../../helpers/utils';
import { debounce } from 'debounce';
import homeActions from '../../actions/homeActions';
import AdminApplications from './Admin/AdminApplications';
import Users from './Admin/Users';

const styles = () => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

const defaultDateOffset = -120;

function ManagementHomeRoutes(props) {
  const dispatch = useDispatch();
  const initialStartDate = getDateOffset(defaultDateOffset);
  const initialEndDate = getDateOffset(0);
  useEffect(() => {
    dispatch(homeActions.getReviewerApplications(initialStartDate, initialEndDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [dateExtents, setDateExtents] = useState({
    begin: initialStartDate,
    end: initialEndDate,
  });
  const debounceSetDateExtents = debounce((newExtents) => {
    setDateExtents(newExtents);
    dispatch(homeActions.getReviewerApplications(newExtents.begin, newExtents.end));
  }, 1000);

  const { classes } = props;
  const { path } = useRouteMatch();

  const user = useSelector((state) => state.user);

  let redirectUrl = '/home/submittedapplications';
  if (user.roles.includes('ADMIN')) {
    redirectUrl = '/home/admin/applications';
  }

  return (
    <Paper className={classes.paper}>
      <Switch>
        <Redirect exact from="/home" to={redirectUrl} />
        <Route path={`${path}/submittedapplications`}>
          <ManagementApplications
            dateFilters={{ initialStartDate, initialEndDate, dateExtents }}
            setDateExtents={debounceSetDateExtents}
          />
        </Route>
        <Route path={`${path}/reports`}>
          <Reports />
        </Route>
        <Route path={`${path}/search`}>
          <Search />
        </Route>
        <Route path={`${path}/map`}>
          <MapContainer />
        </Route>
        <Route path={`${path}/admin/users`}>
          <Users />
        </Route>
        <Route path={`${path}/admin/applications`}>
          <AdminApplications />
        </Route>
      </Switch>
    </Paper>
  );
}

ManagementHomeRoutes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManagementHomeRoutes);
