import commonConstants from '../constants/commonConstants';

function getLookups() {
  return { type: commonConstants.getLookups };
}

function getConfig() {
  return { type: commonConstants.getConfig };
}

function sendHelpRequest(userName, userEmail, description, pageUrl, applicationId) {
  return {
    type: commonConstants.sendHelpRequest,
    userName,
    userEmail,
    description,
    pageUrl,
    applicationId,
  };
}

function hideMessageBanner() {
  return { type: commonConstants.hideMessageBanner };
}

const commonActions = {
  getLookups,
  getConfig,
  sendHelpRequest,
  hideMessageBanner,
};

export default commonActions;
