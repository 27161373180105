import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Typography from './Typography';

const PageTitle = ({ classes, title, children }) => (
  <div className={classes.pageTitleContainer}>
    <Typography className={classes.typo} variant="h3" size="sm">
      {title}
    </Typography>
    {children}
  </div>
);

const styles = (theme) => ({
  pageTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  typo: {
    color: theme.palette.text.hint,
  },
});

PageTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.object,
};

export default withStyles(styles)(PageTitle);
