import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Button,
  Link,
  CardActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withRoot from '../../modules/withRoot';
import PageTitle from '../../modules/components/PageTitle';
import formInsertActions from '../../actions/formInsert';
import alertActions from '../../actions/alertActions';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../modules/AppInsights';

appInsights.trackPageView('SelectPermitType');

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: 30,
  },
  card: {
    background: theme.palette.secondary.light,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
}));

function ConfirmPermitSelection({
  open,
  handleClose,
  permitType,
  handleConfirm,
  insertingApplication,
  uapDocumentLink,
}) {
  return (
    <div>
      <Dialog
        id="reopen-dialog"
        open={open}
        onClose={(evt, reason) => {
          if (reason === 'backdropClick') {
            evt.preventDefault();
            return;
          }
          handleClose();
        }}
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title">Selected {permitType.name}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'black' }}>
            Please be sure to read and understand the utility accommodation policy linked below
            before submitting application.
          </DialogContentText>
          <DialogContentText>
            <a target="_blank" rel="noopener noreferrer" href={uapDocumentLink}>
              Utility Accommodation Policy
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary" disabled={insertingApplication}>
            Cancel
          </Button>
          <Button
            id={'confirm-permit-type'}
            onClick={() => handleConfirm()}
            color="primary"
            disabled={insertingApplication}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function GetInstructionJSX(permitType, text = 'Instructions') {
  return (
    permitType.instructions && (
      <Button size="small" color="primary">
        <Link
          target="_blank"
          href={`${process.env.PUBLIC_URL}/Instructions/${permitType.instructions}`}
          underline="hover"
        >
          {text}
        </Link>
      </Button>
    )
  );
}

ConfirmPermitSelection.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  permitType: PropTypes.object.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  insertingApplication: PropTypes.bool.isRequired,
  uapDocumentLink: PropTypes.string.isRequired,
};

function SelectPermitType({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { permitTypes } = useSelector((state) => state.common.lookups);
  const { uapDocumentLink } = useSelector((state) => state.common.config);
  const { insertingApplication } = useSelector((state) => state.selectedApplication);
  const [dialogProperties, setDialogProperties] = React.useState({ open: false, permitType: {} });

  return (
    <div>
      <ConfirmPermitSelection
        open={dialogProperties.open}
        handleClose={() => setDialogProperties({ open: false, permitType: {} })}
        permitType={dialogProperties.permitType}
        insertingApplication={insertingApplication}
        uapDocumentLink={uapDocumentLink}
        handleConfirm={async () => {
          const promise = new Promise((resolve, reject) => {
            dispatch(
              formInsertActions.insertApplication(dialogProperties.permitType, resolve, reject)
            );
          });
          try {
            const newApplicationId = await promise;
            history.push(`/application/${newApplicationId}`);
          } catch (e) {
            dispatch(alertActions.error('Failed to create application'));
          }
        }}
      />
      <Grid container justifyContent="center" style={{ padding: '30px 20px' }}>
        <Grid item xs={12}>
          <PageTitle title="Select Permit Type" />
          <Typography
            variant="h6"
            sx={{ marginTop: '-15px', marginBottom: '10px', fontSize: '1.3rem' }}
          >
            Please download the
            <Button size="small" color="primary" sx={{ font: 'inherit' }}>
              <Link target="_blank" href={uapDocumentLink}>
                Utility Accomodation Policy
              </Link>
            </Button>
            for reference before starting an application.
          </Typography>
        </Grid>
        <Grid container spacing={3} justifyContent="flex-start">
          {permitTypes
            .filter((permit) => permit.isVisible)
            .map((type, idx) => (
              <Grid item xs={12} md={6} xl={4} key={idx}>
                <Card variant="outlined" classes={{ root: classes.card }}>
                  <CardContent>
                    <Typography variant="h4" gutterBottom>
                      {type.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                      {type.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      id={`select-${type.value}`}
                      onClick={() => {
                        setDialogProperties({ open: true, permitType: type });
                      }}
                      disabled={type.isEnabled === false}
                    >
                      Select
                    </Button>
                    {GetInstructionJSX(type)}
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </div>
  );
}

SelectPermitType.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRoot(withAITracking(reactPlugin, SelectPermitType));
