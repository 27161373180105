import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  categoryContainer: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    boxShadow: '0 -1px 0 #394375 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  itemCategoryChild: {
    backgroundColor: theme.palette.primary.main,
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: theme.palette.secondary.main,
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const { classes, categories, ...other } = props;
  const { url } = useRouteMatch();
  const location = useLocation();

  const { railroadName } = useSelector((state) => state.common.config);

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          style={{ whiteSpace: 'pre-line' }}
          className={clsx(classes.firebase, classes.categoryContainer, classes.itemCategory)}
        >
          {`${railroadName}\nRail Permitting`}
        </ListItem>

        <ListItem></ListItem>

        {categories.map(({ id, children }) => (
          <div key={id} className={classes.itemCategoryChild}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, href }) => {
              const to = `${url}${href}`;
              const active = to === location.pathname;
              return (
                <ListItemButton
                  key={childId}
                  className={clsx(classes.item, active && classes.itemActiveItem)}
                  component={Link}
                  to={to}
                >
                  <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {childId}
                  </ListItemText>
                </ListItemButton>
              );
            })}

            <Divider className={classes.divider} />
          </div>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
};

export default withStyles(styles)(Navigator);
