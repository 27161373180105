const adminConstants = {
  getAdminApplications: 'GET_ADMIN_APPLICATIONS',
  getAdminApplicationsSucceeded: 'GET_ADMIN_APPLICATIONS_SUCCEEDED',
  getAdminApplicationsFailed: 'GET_ADMIN_APPLICATIONS_FAILED',

  submitApplication: 'ADMIN_SUBMIT_APPLICATION',
  submitApplicationSucceeded: 'ADMIN_SUBMIT_APPLICATION_SUCCEEDED',
  submitApplicationFailed: 'ADMIN_SUBMIT_APPLICATION_FAILED',

  changeApplicationOwner: 'CHANGE_APPLICATION_OWNER',
  changeApplicationOwnerSucceeded: 'CHANGE_APPLICATION_OWNER_SUCCEEDED',
  changeApplicationOwnerFailed: 'CHANGE_APPLICATION_OWNER_FAILED',

  undoPMApproval: 'UNDO_PM_APPROVAL',
  undoPMApprovalSucceeded: 'UNDO_PM_APPROVAL_SUCCEEDED',
  undoPMApprovalFailed: 'UNDO_PM_APPROVAL_FAILED',

  getUsers: 'GET_USERS',
  getUsersSucceeded: 'GET_USERS_SUCCEEDED',
  getUsersFailed: 'GET_USERS_FAILED',

  deactivateUser: 'DEACTIVATE_USER',
  deactivateUserSucceeded: 'DEACTIVATE_USER_SUCCEEDED',
  deactivateUserFailed: 'DEACTIVATE_USER_FAILED',

  reactivateUser: 'REACTIVATE_USER',
  reactivateUserSucceeded: 'REACTIVATE_USER_SUCCEEDED',
  reactivateUserFailed: 'REACTIVATE_USER_FAILED',

  getRoles: 'GET_ROLES',
  getRolesSucceeded: 'GET_ROLES_SUCCEEDED',
  getRolesFailed: 'GET_ROLES_FAILED',

  getClaims: 'GET_CLAIMS',
  getClaimsSucceeded: 'GET_CLAIMS_SUCCEEDED',
  getClaimsFailed: 'GET_CLAIMS_FAILED',

  getTerritories: 'GET_TERRITORIES',
  getTerritoriesSucceeded: 'GET_TERRITORIES_SUCCEEDED',
  getTerritoriesFailed: 'GET_TERRITORIES_FAILED',

  getTerritoryTypes: 'GET_TERRITORY_TYPES',
  getTerritoryTypesSucceeded: 'GET_TERRITORY_TYPES_SUCCEEDED',
  getTerritoryTypesFailed: 'GET_TERRITORY_TYPES_FAILED',

  getUserAssignedPermitTypes: 'GET_USER_ASSIGNED_PERMIT_TYPES',
  getUserAssignedPermitTypesSucceeded: 'GET_USER_ASSIGNED_PERMIT_TYPES_SUCCEEDED',
  getUserAssignedPermitTypesFailed: 'GET_USER_ASSIGNED_PERMIT_TYPES_FAILED',

  getUserAssignedTerritories: 'GET_USER_ASSIGNED_TERRITORIES',
  getUserAssignedTerritoriesSucceeded: 'GET_USER_ASSIGNED_TERRITORIES_SUCCEEDED',
  getUserAssignedTerritoriesFailed: 'GET_USER_ASSIGNED_TERRITORIES_FAILED',

  saveUserEdits: 'SAVE_USER_EDITS',
  saveUserEditsSucceeded: 'SAVE_USER_EDITS_SUCCEEDED',
  saveUserEditsFailed: 'SAVE_USER_EDITS_FAILED',
};

export default adminConstants;
