import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import adminActions from '../../../actions/adminActions';
import { DialogContentText } from '@mui/material';

export default function SubmitApplicationDialog({ open, handleClose, applicationId, businessId }) {
  const dispatch = useDispatch();
  const { lookups } = useSelector((state) => state.common);
  const submittedStatus = lookups.applicationStatuses.find((lu) => lu.value === 'SUBMITTED');

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Submit?</DialogTitle>
      <DialogContent>
        <DialogContentText color="black">
          Are you sure you want to submit application {businessId}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          No
        </Button>
        <Button
          onClick={() => {
            dispatch(adminActions.submitApplication(applicationId, submittedStatus));
            handleClose();
          }}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SubmitApplicationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  applicationId: PropTypes.number.isRequired,
  businessId: PropTypes.string.isRequired,
};
