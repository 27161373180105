import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { acquireAccessToken } from '../Auth/utils';
import mapQueryConstants from '../constants/mapQueryConstants';

export async function requestMapToken(serviceUrl) {
  try {
    const token = await acquireAccessToken();
    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/application/map/token`,
      params: { serviceUrl },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

function* getEsriToken(action) {
  const { mapUrl } = action;
  try {
    const esriToken = yield requestMapToken(mapUrl);
    yield put({ type: mapQueryConstants.getEsriTokenSuccess, esriToken });
  } catch (e) {
    yield put({ type: mapQueryConstants.getEsriTokenFailed, error: e });
  }
}

const mapQuerySagas = [takeLatest(mapQueryConstants.getEsriToken, getEsriToken)];

export default mapQuerySagas;
