import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SectionHeader from '../Components/SectionHeader';
import Applicant from '../Components/Applicant';
import ApplicantDetails from '../Components/ApplicantDetails';
import SummaryLayout from '../Components/SummaryLayout';
import SummaryTable from '../Components/SummaryTable';

const contractTableData = (contracts) => {
  return contracts.map((contract) => [
    contract.contractNo,
    contract.licenseeNo,
    moment(contract.contractDate).format('MM/DD/YYYY'),
    contract.contractCity,
    contract.contractCounty,
    contract.contractState,
    contract.contractType,
  ]);
};

const AssignmentSummary = ({ appSummary, history }) => {
  return (
    <SummaryLayout
      appSummary={appSummary}
      history={history}
      headerTitle={`Application for ${appSummary.permitType}`}
    >
      <Applicant appSummary={appSummary} />
      <ApplicantDetails appSummary={appSummary} />
      <SectionHeader headerText="Contract Details" />
      <SummaryTable
        headers={[
          'Contract #',
          "Licensee's #",
          'Date',
          'City',
          'County',
          'State',
          'Agreement Type',
        ]}
        data={contractTableData(appSummary.assignment.contracts)}
        leftAlignFirstColumn={false}
      />
    </SummaryLayout>
  );
};

AssignmentSummary.propTypes = {
  appSummary: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
};

export default AssignmentSummary;
