import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ExportToCsv } from 'export-to-csv';
import MaterialReactTable from 'material-react-table';
import makeStyles from '@mui/styles/makeStyles';
import withRoot from '../modules/withRoot';
import Container from '@mui/material/Container';
import { Grid, Typography, Divider, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AppAppBar from '../modules/views/AppAppBar';
import reportActions from '../actions/reportActions';
import { getDateOffset } from '../helpers/utils';
import { formatCellCurrency } from './FinanceReport';
import Title from '../ProcessApplication/Title';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(1),
    maxWidth: '96vw',
  },
  paddingTopBottom10: {
    padding: '10px 0',
  },
  textField: {
    marginRight: 10,
  },
}));

function prepareReportData(data) {
  if (!data) {
    return {
      data: [],
      totals: null,
      grandTotal: null,
    };
  }

  const newData = [];

  data.forEach((item) => {
    newData.push({
      paymentId_: item.paymentId,
      businessId: item.businessId,
      legalName: item.legalName,
      permitType: item.permitType,
      dateTimeUTC: moment.utc(item.dateTimeUTC).format('MM-DD-YYYY'),
      category: item.category,
      detailAmount: item.detailAmount,
      feeType: item.feeType,
      cityState: `${item.city ? item.city : 'unknown'}, ${item.state}`,
      paymentMethod: item.paymentMethod,
      checkNumber: item.checkNumber,
    });
  });

  const totals = resetTotals(data);

  return {
    data: newData.sort((a, b) => a.applicant - b.applicant),
    totals: totals.categoryTotals,
    grandTotal: totals.grandTotal,
  };
}

function resetTotals(data) {
  const categories = {};
  data.forEach((item) => {
    const arrayByCategory = categories[item.detailCategory] || [];
    arrayByCategory.push(item.detailAmount);
    categories[item.detailCategory] = arrayByCategory;
  });

  const results = Object.keys(categories).map((category) => ({
    category,
    amount: categories[category].reduce((a, b) => a + b),
  }));

  const grandTotal = results.reduce((a, b) => a + b.amount, 0);
  return { categoryTotals: results, grandTotal: grandTotal };
}

function PaymentRpt({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialStartDate = getDateOffset(-60);
  const initialEndDate = getDateOffset(0);
  useEffect(() => {
    dispatch(reportActions.getPaymentRptData(initialStartDate, initialEndDate));
  }, [dispatch, initialEndDate, initialStartDate]);
  const [dateExtents, setDateExtents] = useState({
    begin: initialStartDate,
    end: initialEndDate,
  });

  const reportData = useSelector((state) => state.reports.paymentRptData);
  const preparedReportData = prepareReportData(reportData);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'businessId',
        header: 'Business Id',
      },
      {
        accessorKey: 'legalName',
        header: 'Applicant',
      },
      {
        accessorKey: 'permitType',
        header: 'Permit Type',
      },
      {
        accessorKey: 'cityState',
        header: 'City, State',
      },
      {
        accessorKey: 'feeType',
        header: 'Fee Type',
      },
      {
        accessorKey: 'dateTimeUTC',
        header: 'Payment Date',
      },
      {
        accessorKey: 'paymentMethod',
        header: 'Payment Method',
      },
      {
        accessorKey: 'checkNumber',
        header: 'Check #',
      },
      {
        accessorKey: 'detailAmount',
        header: 'Amount',
        Cell: ({ cell }) => formatCellCurrency(cell),
      },
    ],
    []
  );
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    filename: 'PaymentRpt',
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const getTotalsJSX = (totals) => {
    return totals ? (
      totals.map((total, idx) => {
        return (
          <Grid container key={idx}>
            <Grid item xs={8}>
              <Typography variant="h6">{total.category}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6">
                {total.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </Typography>
            </Grid>
          </Grid>
        );
      })
    ) : (
      <></>
    );
  };

  return (
    <>
      <AppAppBar pathname={location.pathname} />
      <main className={classes.content}>
        <Container className={classes.container}>
          <Grid container item xs={6} className={classes.paddingTopBottom10}>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.paddingTopBottom10}>
                Payment Category Totals
              </Typography>
            </Grid>
            {getTotalsJSX(preparedReportData.totals)}
            <Divider style={{ height: 3 }} />
            <Typography
              variant="h5"
              className={classes.paddingTopBottom10}
              style={{ fontWeight: 'bold' }}
            >
              Total:{' '}
              {preparedReportData.grandTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="fromDate"
                label="Begin Date"
                type="date"
                defaultValue={getDateOffset(-60)}
                className={classes.textField}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, begin: event.target.value };
                  setDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="toDate"
                label="End Date"
                type="date"
                defaultValue={getDateOffset(0)}
                className={classes.textField}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, end: event.target.value };
                  setDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  dispatch(reportActions.getPaymentRptData(dateExtents.begin, dateExtents.end));
                }}
              >
                Get Payment Data
              </Button>
            </Grid>
            <Grid item xs={12}>
              <MaterialReactTable
                columns={columns}
                data={preparedReportData.data}
                enableStickyHeader
                enableGlobalFilter={false}
                enablePagination={false}
                renderTopToolbarCustomActions={() => {
                  return (
                    <Title>
                      <span style={{ fontSize: '1.2rem', marginRight: 20 }}>
                        Utility Permitting Finance Report
                      </span>
                      <Button
                        color="primary"
                        onClick={() => csvExporter.generateCsv(preparedReportData.data)}
                        startIcon={<FileDownloadIcon />}
                        variant="outlined"
                      >
                        Export Data
                      </Button>
                    </Title>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}

PaymentRpt.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRoot(PaymentRpt);
