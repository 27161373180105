import Query from '@arcgis/core/rest/support/Query.js';
import * as query from '@arcgis/core/rest/query.js';
import * as geometryEngineAsync from '@arcgis/core/geometry/geometryEngineAsync.js';
import { locationToAddress, addressToLocations } from '@arcgis/core/rest/locator.js';
import config from '../../data/config.json';

export async function queryLocation(startPoint) {
  const serviceUrl = 'http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer';
  try {
    const params = {
      location: startPoint,
    };

    const response = await locationToAddress(serviceUrl, params);
    const { attributes } = response;

    const addressResults = await addressToLocations(serviceUrl, {
      address: {
        CountryCode: attributes.CountryCode,
        Region: attributes.Region,
        Subregion: attributes.Subregion,
        City: attributes.City,
      },
      maxLocations: 1,
      outSpatialReference: startPoint.spatialReference,
    });

    if (!addressResults.length) {
      return { state: attributes.Region, county: attributes.Subregion, city: attributes.City };
    }

    const nearestCity = addressResults[0];
    const distanceToNearestCity = await geometryEngineAsync.distance(
      startPoint,
      nearestCity.location,
      'miles'
    );
    if (distanceToNearestCity > 5.0) {
      return { state: attributes.Region, county: attributes.Subregion, city: attributes.Subregion };
    }

    return { state: attributes.Region, county: attributes.Subregion, city: attributes.City };
  } catch (e) {
    console.error(e);
  }
}

export async function queryPlss(startPoint) {
  try {
    const queryUrl = `${config.MapServices.Location_Reference.url}/${config.MapServices.Location_Reference.layers.PLSS.id}`;
    const queryObject = new Query();
    queryObject.outFields = ['*'];
    queryObject.spatialRelationship = 'intersects';
    queryObject.geometry = startPoint;
    const result = await query.executeQueryJSON(queryUrl, queryObject);
    if (result.features.length) {
      const attributes = result.features[0].attributes;
      const section = attributes['sectn'];
      const township = `${attributes['twnshp_num']}-${attributes['twnshp_dir']}`;
      const range = `${attributes['range_num']}-${attributes['range_dir']}`;
      return `${section} / ${township} / ${range}`;
    }
    return null;
  } catch (e) {
    console.error(e);
  }
}

export async function queryRailroad(startPoint) {
  try {
    const queryUrl = `${config.Queries.Railroad.serviceUrl}/${config.Queries.Railroad.layerId}`;
    const queryObject = new Query();
    queryObject.outFields = config.Queries.Railroad.outFields;
    queryObject.spatialRelationship = 'intersects';
    queryObject.geometry = startPoint;
    queryObject.returnGeometry = true;
    queryObject.distance = 1;
    queryObject.units = 'miles';
    const result = await query.executeQueryJSON(queryUrl, queryObject);
    if (result.features.length) {
      const distances = await Promise.all(
        result.features.map((f) => geometryEngineAsync.distance(startPoint, f.geometry, 'feet'))
      );
      let closestCrossingIdx = 0;
      let closestCrossingDistance = 0;
      distances.forEach((dist, idx) => {
        if (idx === 0) {
          closestCrossingDistance = dist;
        } else if (dist < closestCrossingDistance) {
          closestCrossingDistance = dist;
          closestCrossingIdx = idx;
        }
      });
      return result.features[closestCrossingIdx].attributes[
        config.Queries.Railroad.railroadOwnerField
      ];
    }
    return null;
  } catch (e) {
    console.error(e);
  }
}

export async function queryNearestCrossing(startPoint) {
  try {
    const queryUrl = `${config.MapServices.FRAGradeXing.url}/${config.MapServices.FRAGradeXing.layers.RailCrossings.id}`;
    const queryObject = new Query();
    queryObject.outFields = ['*'];
    queryObject.spatialRelationship = 'intersects';
    queryObject.geometry = startPoint;
    queryObject.returnGeometry = true;
    queryObject.distance = 2;
    queryObject.units = 'miles';
    const result = await query.executeQueryJSON(queryUrl, queryObject);
    if (result.features.length) {
      const distances = await Promise.all(
        result.features.map((f) => geometryEngineAsync.distance(startPoint, f.geometry, 'feet'))
      );
      let closestCrossingIdx = 0;
      let closestCrossingDistance = 0;
      distances.forEach((dist, idx) => {
        if (idx === 0) {
          closestCrossingDistance = dist;
        } else if (dist < closestCrossingDistance) {
          closestCrossingDistance = dist;
          closestCrossingIdx = idx;
        }
      });
      return {
        distanceToCenterlineFt: Math.trunc(closestCrossingDistance),
        distanceToCenterlineIn: Math.trunc((closestCrossingDistance % 1) * 12),
        nearestRoadwayCrossing: result.features[closestCrossingIdx].attributes['STREET'],
        dotNumber: result.features[closestCrossingIdx].attributes['CROSSING'],
      };
    }
    return { dotNumber: null, railroad: null };
  } catch (e) {
    console.error(e);
  }
}

export async function getPointOfIntersection(locationGeometry) {
  try {
    const queryUrl = `${config.MapServices.MainLineRail.url}/${config.MapServices.MainLineRail.layers.MainLineRail.id}`;
    const queryObject = new Query();
    queryObject.outFields = [];
    queryObject.spatialRelationship = 'intersects';
    queryObject.geometry = locationGeometry;
    queryObject.returnGeometry = true;
    const result = await query.executeQueryJSON(queryUrl, queryObject);
    if (result.features.length) {
      const trackSegment = result.features[0].geometry;
      const segments = await geometryEngineAsync.cut(trackSegment, locationGeometry);
      const leftSegmentFirstPoint = segments[0].getPoint(0, 0);
      const leftSegmentLastPoint = segments[0].getPoint(0, segments[0].paths[0].length - 1);
      const dist1 = await geometryEngineAsync.distance(leftSegmentFirstPoint, locationGeometry);
      const dist2 = await geometryEngineAsync.distance(leftSegmentLastPoint, locationGeometry);
      if (dist1 < dist2) {
        return leftSegmentFirstPoint;
      } else {
        return leftSegmentLastPoint;
      }
    }
    return null;
  } catch (e) {
    console.error(e);
  }
}

async function queryMilepostAndTrackLine(locationGeometry) {
  try {
    const queryUrl = `${config.MapServices.MainLineRail.url}/${config.MapServices.MainLineRail.layers.MainLineRail.id}`;
    const queryObject = new Query();
    queryObject.outFields = ['*'];
    queryObject.spatialRelationship = 'intersects';
    queryObject.geometry = locationGeometry;
    queryObject.returnGeometry = true;
    const result = await query.executeQueryJSON(queryUrl, queryObject);
    if (result.features.length) {
      const primaryRoutes = result.features.filter((f) => f.attributes['PRI_RTE_CD'] === 'Y');
      let feature = null;
      if (primaryRoutes.length) {
        feature = primaryRoutes[0];
      } else {
        feature = result.features[0];
      }
      const trackSegment = feature.geometry;
      const segments = await geometryEngineAsync.cut(trackSegment, locationGeometry);
      const beginMP = Number.parseFloat(feature.attributes['MILE_POST_BEG']);
      let milepost = beginMP;
      // Figure out which end of the track was cut
      const trackBeginPoint = trackSegment.getPoint(0, 0);
      const leftBeginPoint = segments[0].getPoint(0, 0);
      // Compare each begin point to the original track begin point
      const pointsAreEqual = await geometryEngineAsync.equals(leftBeginPoint, trackBeginPoint);
      if (pointsAreEqual) {
        milepost += await geometryEngineAsync.geodesicLength(segments[0], 'miles');
      } else {
        milepost += await geometryEngineAsync.geodesicLength(segments[1], 'miles');
      }
      const trackLine = String(feature.attributes['LINE_SEG_NBR']);
      return { milepost: milepost.toFixed(4), trackLine };
    }
    return { milepost: null, trackLine: null };
  } catch (e) {
    console.error(e);
  }
}

export async function queryLocationDetailInfo(locationGeometry, queryForMilepost = false) {
  try {
    let pointOfIntersection = null;
    if (locationGeometry.type === 'polyline') {
      pointOfIntersection = await getPointOfIntersection(locationGeometry);
    }
    if (pointOfIntersection === null) {
      if (locationGeometry.type === 'polyline') {
        // Utility location does not appear to cross track
        // Use first drawn point in place of track interection point
        pointOfIntersection = locationGeometry.getPoint(0, 0);
      } else if (locationGeometry.type === 'polygon') {
        pointOfIntersection = locationGeometry.centroid;
      } else if (locationGeometry.type === 'point') {
        pointOfIntersection = locationGeometry;
      }
    }
    const [location, encroachmentLocation, crossingResults, railroad, milepostTrackLine] =
      await Promise.all([
        queryLocation(pointOfIntersection),
        queryPlss(pointOfIntersection),
        queryNearestCrossing(pointOfIntersection),
        queryRailroad(pointOfIntersection),
        queryForMilepost
          ? queryMilepostAndTrackLine(locationGeometry)
          : { milepost: null, trackLine: null },
      ]);

    return {
      state: location.state,
      county: location.county,
      nearestTown: location.city,
      encroachmentLocation,
      // distanceToCenterlineFt: crossingResults && crossingResults.distanceToCenterlineFt,
      // distanceToCenterlineIn: crossingResults && crossingResults.distanceToCenterlineIn,
      nearestRoadwayCrossing: crossingResults && crossingResults.nearestRoadwayCrossing,
      dotNumber: crossingResults && crossingResults.dotNumber,
      railroad,
      milepost: milepostTrackLine.milepost,
      line: milepostTrackLine.trackLine,
    };
  } catch (e) {
    throw e;
  }
}
