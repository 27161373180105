import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useMsal } from '@azure/msal-react';
import { b2cPolicies } from '../../Auth/authConfig';
import { getClaims } from '../../Auth/utils';

const useStyles = makeStyles((theme) => ({
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headerMenu: {
    marginTop: 10,
  },
  headerMenuItem: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  profileMenuItem: {
    color: theme.palette.text.primary,
    size: 16,
  },
  profileMenuLink: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    color: 'inherit',
  },
}));

function AccountButton() {
  const classes = useStyles();
  const [evtTgt, openProfileMenu] = useState(null);
  const { instance } = useMsal();
  const theme = useTheme();
  const userClaims = getClaims();
  const isLocalAccount = userClaims['authenticationSource'] === 'localAccountAuthentication';

  const user = useSelector((state) => {
    return state.user;
  });

  const profileIconClick = (evt) => {
    openProfileMenu(evt.target);
  };

  return (
    <div className={classes.right}>
      <IconButton
        aria-haspopup="true"
        color="inherit"
        className={classes.headerMenuButton}
        aria-controls="profile-menu"
        onClick={profileIconClick}
        size="large"
      >
        {user && user.name && <Avatar>{user.name[0]}</Avatar>}
      </IconButton>
      <Menu
        id="profile-menu"
        open={Boolean(evtTgt)}
        anchorEl={evtTgt}
        onClose={() => openProfileMenu(null)}
        className={classes.headerMenu}
        disableAutoFocusItem
      >
        <MenuItem>{user.name}</MenuItem>
        <List
          component="nav"
          sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}
        >
          <ListItemButton
            onClick={() => {
              instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
            }}
          >
            <ListItemIcon>
              <BorderColorOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Edit Profile" />
          </ListItemButton>

          {isLocalAccount && (
            <ListItemButton
              onClick={() => {
                instance.acquireTokenRedirect(b2cPolicies.authorities.changePassword);
              }}
            >
              <ListItemIcon>
                <VpnKeyOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Change Password" />
            </ListItemButton>
          )}

          {isLocalAccount && (
            <ListItemButton
              onClick={() => {
                instance.acquireTokenRedirect(b2cPolicies.authorities.changeSigninName);
              }}
            >
              <ListItemIcon>
                <ContactMailOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Change Email" />
            </ListItemButton>
          )}

          <ListItemButton
            onClick={() => {
              instance.logout();
            }}
          >
            <ListItemIcon>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Menu>
    </div>
  );
}

AccountButton.propTypes = {};

AccountButton.defaultProps = {};

export default AccountButton;
