import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import loginErrorImg from '../img/login_error.png';

export default function LoginError() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography variant="h1">Error</Typography>
            <Typography variant="h6" sx={{ marginTop: '20px', marginBottom: '20px' }}>
              An error occurred while attempting to log you in.
            </Typography>
            <Button
              variant="contained"
              onClick={async () => {
                window.location.reload();
              }}
            >
              Return to Login
            </Button>
          </Grid>
          <Grid item xs={6}>
            <img src={loginErrorImg} alt="" width={200} height={200} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
