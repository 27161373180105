import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import logConstants from '../constants/logConstants';
import alertConstants from '../constants/alertConstants';
import { acquireAccessToken } from '../Auth/utils';

function* getLogs(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/logs`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: logConstants.getLogsSuccess, logs: data });
  } catch (e) {
    yield put({ type: logConstants.getLogsError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to get logs' });
  }
}

function* insertLog(action) {
  const { applicationId, message } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/${applicationId}/log`,
      data: { message },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: logConstants.insertLogSuccess, log: data });
  } catch (e) {
    yield put({ type: logConstants.insertLogError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to add log' });
  }
}

function* updateLog(action) {
  const { log, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/log/${Number(log.processLogId)}`,
      data: log,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: logConstants.updateLogSuccess, log });
    if (resolve) {
      yield call(resolve);
    }
  } catch (e) {
    yield put({ type: logConstants.updateLogError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to update log' });
    if (reject) {
      yield call(reject);
    }
  }
}

const logSagas = [
  takeLatest(logConstants.getLogs, getLogs),
  takeLatest(logConstants.insertLog, insertLog),
  takeLatest(logConstants.updateLog, updateLog),
];

export default logSagas;
