import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import adminActions from '../../../actions/adminActions';
import { Card, CardContent, DialogContentText, Typography } from '@mui/material';

export default function ReactivateUserDialog({ open, handleClose, user }) {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Reactivate?</DialogTitle>
      <DialogContent>
        <DialogContentText color="black">
          Are you sure you want to reactivate the following user?
        </DialogContentText>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography variant="body1">
              Name: {user.name}
              <br />
              Email: {user.email}
              <br />
              Company: {user.company}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          No
        </Button>
        <Button
          onClick={() => {
            dispatch(adminActions.reactivateUser(user.id));
            handleClose();
          }}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ReactivateUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
