import mapDrawConstants from '../constants/mapDrawConstants';
import formConstants from '../constants/formConstants';

const initialState = {
  mapView: null,
  locationDrawOption: 'drawonmap',
  points: [],
  identifiedRailroad: '',
  confirmRailroadDialogOpen: false,
};

function mapDraw(state = initialState, action) {
  switch (action.type) {
    case mapDrawConstants.mapCreated:
      return { ...state, mapView: action.mapView };

    case mapDrawConstants.setLocationDrawOption:
      return { ...state, locationDrawOption: action.drawOption };

    case mapDrawConstants.addPoint:
      return {
        ...state,
        points: [
          ...state.points,
          { latitude: action.latitude, longitude: action.longitude, uniqueId: action.uniqueId },
        ],
      };

    case mapDrawConstants.updatePointCoordinates:
      return {
        ...state,
        points: state.points.map((p) => {
          if (p.uniqueId === action.uniqueId) {
            return {
              latitude: action.latitude,
              longitude: action.longitude,
              uniqueId: action.uniqueId,
            };
          }
          return p;
        }),
      };

    case mapDrawConstants.removePoint:
      return { ...state, points: state.points.filter((point, index) => index !== action.index) };

    case mapDrawConstants.removeAllPoints:
      return { ...state, points: [] };

    case formConstants.clearSelectedApplication:
      return initialState;

    case mapDrawConstants.openConfirmRailroadDialog:
      return {
        ...state,
        confirmRailroadDialogOpen: true,
        identifiedRailroad: action.identifiedRailroad,
      };

    case mapDrawConstants.closeConfirmRailroadDialog:
      return { ...state, confirmRailroadDialogOpen: false, identifiedRailroad: '' };

    default:
      return state;
  }
}

export default mapDraw;
