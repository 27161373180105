import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import formConstants from '../constants/formConstants';
import { acquireAccessToken } from '../Auth/utils';
import alertConstants from '../constants/alertConstants';

function* updateApplication(action) {
  const { application, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/application/${Number(application.applicationId)}`,
      data: application,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateApplicationSucceeded, application });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateApplicationFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateApplicationDetail(action) {
  const { applicationDetail, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/application/applicationdetail/${Number(
        applicationDetail.applicationDetailId
      )}`,
      data: applicationDetail,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateApplicationDetailSucceeded, applicationDetail });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateApplicationDetailFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateLocationDetail(action) {
  const { locationDetail, resolve, reject, populateEngDivision } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/application/locationdetail/${Number(
        locationDetail.locationDetailId
      )}`,
      data: locationDetail,
      params: { populateEngDivision },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
    yield put({ type: formConstants.updateLocationDetailSucceeded, locationDetail });
  } catch (e) {
    yield put({ type: formConstants.updateLocationDetailFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updatePipeline(action) {
  const { pipeline, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/pipeline/${Number(pipeline.pipelineId)}`,
      data: pipeline,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updatePipelineSucceeded, pipeline });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updatePipelineFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updatePipelineCasing(action) {
  const { pipelineCasing, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/pipeline/casing/${Number(
        pipelineCasing.pipelineCasingId
      )}`,
      data: pipelineCasing,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updatePipelineCasingSucceeded, pipelineCasing });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updatePipelineCasingFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updatePipelineCarrier(action) {
  const { pipelineCarrier, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/pipeline/carrier/${Number(
        pipelineCarrier.pipelineCarrierId
      )}`,
      data: pipelineCarrier,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updatePipelineCarrierSucceeded, pipelineCarrier });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updatePipelineCarrierFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateWireline(action) {
  const { wireline, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/wireline/${Number(wireline.wirelineId)}`,
      data: wireline,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateWirelineSucceeded, wireline });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateWirelineFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateUndergroundWireline(action) {
  const { undergroundWireline, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/wireline/underground/${Number(
        undergroundWireline.undergroundWirelineId
      )}`,
      data: undergroundWireline,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateUndergroundWirelineSucceeded, undergroundWireline });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateUndergroundWirelineFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateOverheadWireline(action) {
  const { overheadWireline, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/wireline/overhead/${Number(
        overheadWireline.overheadWirelineId
      )}`,
      data: overheadWireline,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateOverheadWirelineSucceeded, overheadWireline });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateOverheadWirelineFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updatePole(action) {
  const { pole, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/wireline/overhead/pole/${Number(pole.poleId)}`,
      data: pole,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updatePoleSucceeded, pole });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updatePoleFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateConduit(action) {
  const { conduit, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/wireline/underground/conduit/${Number(
        conduit.undergroundWirelineConduitId
      )}`,
      data: conduit,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateConduitSucceeded, conduit });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateConduitFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateVariances(action) {
  const { applicationId, variances, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/application/${Number(applicationId)}/variances`,
      data: variances,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateVariancesSucceeded });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateVariancesFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* submitApplication(action) {
  const { applicationId, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/application/${Number(applicationId)}/submit`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.submitApplicationSucceeded, applicationStatus: data });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.submitApplicationFailed, message: e.message });
    yield put({
      type: alertConstants.error,
      message: 'Failed to submit application.',
    });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateTemporaryOccupancy(action) {
  const { temporaryOccupancy, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/temporaryoccupancy/${Number(
        temporaryOccupancy.temporaryOccupancyId
      )}`,
      data: temporaryOccupancy,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateTemporaryOccupancySucceeded, temporaryOccupancy });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateTemporaryOccupancyFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updatePrivateCrossing(action) {
  const { privateCrossing, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/privatecrossing/${Number(
        privateCrossing.privateCrossingId
      )}`,
      data: privateCrossing,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updatePrivateCrossingSucceeded, privateCrossing });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updatePrivateCrossingFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateRoadwaySurfacing(action) {
  const { roadwaySurfacing, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/roadwaysurfacing/${Number(
        roadwaySurfacing.roadwaySurfacingId
      )}`,
      data: roadwaySurfacing,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateRoadwaySurfacingSucceeded, roadwaySurfacing });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateRoadwaySurfacingFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateGeneralLicense(action) {
  const { generalLicense, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/generallicense/${Number(
        generalLicense.generalLicenseId
      )}`,
      data: generalLicense,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateGeneralLicenseSucceeded, generalLicense });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateGeneralLicenseFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateOversizeLoadMove(action) {
  const { oversizeLoadMove, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/oversizeloadmove/${Number(
        oversizeLoadMove.oversizeLoadMoveId
      )}`,
      data: oversizeLoadMove,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: formConstants.updateOversizeLoadMoveSucceeded,
      oversizeLoadMove,
    });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateOversizeLoadMoveFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateAssignment(action) {
  const { assignment, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/assignment/${Number(assignment.assignmentId)}`,
      data: assignment,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateAssignmentSucceeded, assignment });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateAssignmentFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateContract(action) {
  const { contract, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/assignment/contract/${Number(
        contract.assignmentContractId
      )}`,
      data: contract,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateContractSucceeded, contract });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateContractFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

function* updateEnvironmental(action) {
  const { environmental, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/environmental/${Number(
        environmental.environmentalId
      )}`,
      data: environmental,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.updateEnvironmentalSucceeded, environmental });
    if (resolve) {
      yield call(resolve);
    } else {
      console.warn('No "resolve" callback provided for current action', action);
    }
  } catch (e) {
    yield put({ type: formConstants.updateGeneralLicenseFailed, message: e.message });
    if (reject) {
      yield call(reject, e);
    } else {
      console.warn('No "reject" callback provided for current action', action);
    }
  }
}

const formUpdateSagas = [
  takeLatest(formConstants.updateApplication, updateApplication),
  takeLatest(formConstants.updateApplicationDetail, updateApplicationDetail),
  takeEvery(formConstants.updateLocationDetail, updateLocationDetail),
  takeEvery(formConstants.updatePipeline, updatePipeline),
  takeEvery(formConstants.updatePipelineCasing, updatePipelineCasing),
  takeEvery(formConstants.updatePipelineCarrier, updatePipelineCarrier),
  takeEvery(formConstants.updateWireline, updateWireline),
  takeEvery(formConstants.updateUndergroundWireline, updateUndergroundWireline),
  takeEvery(formConstants.updateOverheadWireline, updateOverheadWireline),
  takeEvery(formConstants.updatePole, updatePole),
  takeEvery(formConstants.updateConduit, updateConduit),
  takeEvery(formConstants.updateVariances, updateVariances),
  takeEvery(formConstants.submitApplication, submitApplication),
  takeEvery(formConstants.updateTemporaryOccupancy, updateTemporaryOccupancy),
  takeEvery(formConstants.updatePrivateCrossing, updatePrivateCrossing),
  takeEvery(formConstants.updateRoadwaySurfacing, updateRoadwaySurfacing),
  takeEvery(formConstants.updateGeneralLicense, updateGeneralLicense),
  takeEvery(formConstants.updateOversizeLoadMove, updateOversizeLoadMove),
  takeEvery(formConstants.updateAssignment, updateAssignment),
  takeEvery(formConstants.updateEnvironmental, updateEnvironmental),
  takeEvery(formConstants.updateContract, updateContract),
];

export default formUpdateSagas;
