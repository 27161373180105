import { createTheme } from '@mui/material/styles';
import { green, grey, red } from '@mui/material/colors';

const rawTheme = createTheme({
  palette: {
    primary: {
      main: '#071d49',
      light: '#394375',
      dark: '#000022',
    },
    secondary: {
      main: '#84bd00',
      light: '#ebf4d7',
      dark: '#518c00',
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      main: '#4caf50',
      xLight: green[50],
      dark: green[700],
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
    useNextVariants: true,
  },
  customShadows: {
    widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: '3rem',
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: '2rem',
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: '1.64rem',
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: '1.5rem',
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: '1.285rem',
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: '1.142rem',
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: '1.142rem',
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: '0.999rem',
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: '.856rem',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: `${rawTheme.palette.secondary.main} !important`,
          padding: '10px !important',
        },
      },
    },
  },
};

export default theme;
