import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialReactTable from 'material-react-table';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import adminActions from '../../../actions/adminActions';
import { AppBar, Box, Container, IconButton, Toolbar, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChangeApplicationOwnerDialog({
  open,
  handleClose,
  applicationId,
  businessId,
  applications,
}) {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.admin);

  React.useEffect(() => {
    if (users.length === 0) {
      dispatch(adminActions.getUsers());
    }
  }, [dispatch, users]);

  const [rowSelection, setRowSelection] = React.useState({});

  const application = applications.find((a) => a.applicationId === applicationId);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
    ],
    []
  );

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color="white">
            Change application owner
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              const userId = Object.keys(rowSelection)[0];
              const user = users.find((u) => u.id === userId);
              dispatch(adminActions.changeApplicationOwner(applicationId, user));
              setRowSelection({});
              handleClose();
            }}
            disabled={Object.keys(rowSelection).length === 0}
          >
            Save Ownership Change
          </Button>
        </Toolbar>
      </AppBar>
      <Container>
        <DialogContent>
          {application && (
            <Container>
              <Box>
                <Typography variant="body1">{businessId} is currently owned by:</Typography>
                <Container>
                  <Typography variant="body1">
                    Name: {application.user.name.trim()}
                    <br />
                    Email: {application.user.email}
                    <br />
                    Company: {application.user.company}
                  </Typography>
                </Container>
              </Box>
            </Container>
          )}
          <Container sx={{ paddingTop: '20px' }}>
            <Typography color="black" variant="body1">
              Select an applicant in the table below to transfer ownership of {businessId} to.
            </Typography>
          </Container>
        </DialogContent>
        <MaterialReactTable
          columns={columns}
          data={users}
          enableStickyHeader
          enableGlobalFilter={true}
          initialState={{ density: 'compact' }}
          getRowId={(row) => row.id}
          enableMultiRowSelection={false}
          enableRowSelection
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
        />
      </Container>
    </Dialog>
  );
}

ChangeApplicationOwnerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  applicationId: PropTypes.number.isRequired,
  businessId: PropTypes.string.isRequired,
  applications: PropTypes.array.isRequired,
};
