/* eslint-disable no-console */

export function updateWirelinePermitType(permitTypeLU, wireline) {
  const permitTypeCopy = { ...permitTypeLU };
  if (!wireline || permitTypeCopy.value !== 'WIRELINE') {
    return permitTypeCopy;
  }

  if (wireline.installationType === 'UNDERGROUND') {
    permitTypeCopy.name = 'Underground Wireline';
  } else if (wireline.installationType === 'OVERHEAD') {
    permitTypeCopy.name = 'Overhead Wireline';
  } else if (wireline.installationType === 'BOTH') {
    permitTypeCopy.name = 'Combined Overhead Underground Wireline';
  }

  return permitTypeCopy;
}

export function getLookup(lookupRecords, lookupId) {
  if (!lookupRecords || !Array.isArray(lookupRecords) || !lookupRecords.length) {
    console.warn('No lookup records provided');
    return {};
  }
  const lookup = lookupRecords.find((value) => value.id === lookupId);
  if (!lookup) {
    console.warn('No matching lookup found', lookupRecords, lookupId);
    return {};
  }
  return lookup;
}

export function applicationIsEditable(applicationStatusId, applicationStatusesLu) {
  if (
    applicationStatusId === null ||
    applicationStatusId === undefined ||
    !applicationStatusesLu ||
    !applicationStatusesLu.length
  ) {
    return false;
  }

  const unsubmittedStatusLu = applicationStatusesLu.find((s) => s.value === 'UNSUBMITTED');
  if (unsubmittedStatusLu === undefined) {
    return false;
  }

  // Application is editable if application is unsubmitted
  return applicationStatusId === unsubmittedStatusLu.id;
}

export function validateRequiredFields(values) {
  let hasErrors = false;
  Object.keys(values).forEach((key) => {
    // getElementsByName returns Array-like object so convert to actual array
    const fields = [...document.getElementsByName(key)];

    if (fields.length < 1) {
      return;
    }

    if (fields[0].type === 'radio') {
      const unchecked = fields.every((field) => !field.checked);
      if (unchecked) {
        hasErrors = true;
        fields.forEach((field) => (field.parentNode.style.border = '2px solid #d32f2f'));
      } else {
        fields.forEach((field) => (field.parentNode.style.border = null));
      }
    } else {
      const field = fields[0];
      if (field.required && !field.value) {
        hasErrors = true;
        field.style.border = '2px solid #d32f2f';
      } else {
        field.style.border = null;
      }
    }
  });

  return hasErrors;
}

export function checkLocationGraphicCreated(permitTypeValue, map) {
  const locationLayer = map.findLayerById('location');
  if (!locationLayer) {
    return { created: false, message: '' };
  }
  if (locationLayer.graphics.length === 0) {
    return {
      created: false,
      message: 'Location of permit request must be specified before continuing.',
    };
  }
  const graphic = locationLayer.graphics.getItemAt(0);
  const geometryType = graphic.geometry.type;
  switch (permitTypeValue) {
    case 'PIPELINE':
    case 'WIRELINE':
    case 'ROADWAYSURFACING':
    case 'PRIVATECROSSING':
    case 'OVERSIZELOADMOVE':
      if (geometryType === 'polyline') {
        return { created: true, message: '' };
      }
      if (geometryType === 'point' && locationLayer.graphics.length < 1) {
        return {
          created: false,
          message: 'Minimum of one point must be entered before continuing',
        };
      }
      return { created: true, message: '' };
    case 'TEMPORARYOCCUPANCY':
    case 'GENERALLICENSE':
    case 'ENVIRONMENTALRIGHTOFWAY':
      // The only requirement for these 3 cases is that a minimum of 1 graphic has been created.
      // The graphics.length === 0 is checked above already so this always returns created: true
      return {
        created: true,
        message: '',
      };
    default:
      return { created: false, message: 'Case not handled' };
  }
}

export function getDateOffset(offset) {
  const d = new Date();
  d.setDate(d.getDate() + offset);
  const dstring = d.toISOString().split('T')[0];

  return dstring;
}

export function getReview(reviewGroupValue, applicationReviews, reviewGroups) {
  if (!Array.isArray(applicationReviews) || !Array.isArray(reviewGroups)) {
    return {};
  }
  const groupLu = reviewGroups.find((g) => g.value === reviewGroupValue);
  const review = applicationReviews.find((r) => r.reviewGroupId === groupLu.id);

  return review;
}

export function getReviewGroupStatusLu(
  reviewGroupValue,
  applicationReviews,
  reviewGroups,
  reviewStatuses
) {
  const review = getReview(reviewGroupValue, applicationReviews, reviewGroups);
  if (!review) {
    return {};
  }
  const statusLu = getLookup(reviewStatuses, review.reviewStatusId);

  return statusLu;
}
