import React from 'react';
import { useSelector } from 'react-redux';
import ReorderIcon from '@mui/icons-material/Reorder';
import HistoryIcon from '@mui/icons-material/History';
import BarChartIcon from '@mui/icons-material/BarChart';
import PaymentIcon from '@mui/icons-material/Payment';
import SearchIcon from '@mui/icons-material/Search';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MapIcon from '@mui/icons-material/Map';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import withRoot from '../modules/withRoot';
import Can from '../Auth/Can';
import Home from './Home';
import ManagementHomeRoutes from './Management/Routes';
import ApplicantHomeRoutes from './Applicant/Routes';

const applicantNavCategories = [
  {
    id: 'Permits',
    children: [
      { id: 'Applications', icon: <ReorderIcon />, href: '/applications' },
      { id: 'Unpaid Fees', icon: <PaymentIcon />, href: '/unpaidfees' },
      { id: 'Payment History', icon: <HistoryIcon />, href: '/paymenthistory' },
    ],
  },
];

const managementNavCategories = [
  {
    id: 'Permits',
    children: [
      { id: 'Submitted', icon: <ReorderIcon />, href: '/submittedapplications' },
      { id: 'Search', icon: <SearchIcon />, href: '/search' },
      { id: 'Reports', icon: <BarChartIcon />, href: '/reports' },
      { id: 'Map', icon: <MapIcon />, href: '/map' },
    ],
  },
];

const adminNavCategories = [
  {
    id: 'Administration',
    children: [
      { id: 'Users', icon: <PeopleAltIcon />, href: '/admin/users' },
      { id: 'Applications', icon: <AutoAwesomeMotionIcon />, href: '/admin/applications' },
    ],
  },
  ...managementNavCategories,
];

function HomeRoute() {
  const user = useSelector((state) => state.user);

  return (
    <>
      {user && user.roles.length ? (
        <Can
          roles={user.roles}
          action="management-home:visit"
          yes={() => (
            <Can
              roles={user.roles}
              action="admin-home:visit"
              yes={() => (
                <Home navCategories={adminNavCategories} routes={<ManagementHomeRoutes />} />
              )}
              no={() => (
                <Home navCategories={managementNavCategories} routes={<ManagementHomeRoutes />} />
              )}
            />
          )}
          no={() => (
            <Home navCategories={applicantNavCategories} routes={<ApplicantHomeRoutes />} />
          )}
        />
      ) : null}
    </>
  );
}

HomeRoute.propTypes = {};

export default withRoot(HomeRoute);
