/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Typography, AppBar, Toolbar, Grid, Container, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import applicantActions from '../../../actions/applicantActions';
import ModalSpinner from '../../../modules/components/ModalSpinner';

function AcceptDeclineShareRequest({ accept }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { acceptDeclineInProgress, acceptDeclineError, acceptDeclineSuccessMessage } = useSelector(
    (state) => state.applicant
  );

  const code = new URLSearchParams(location.search).get('code');

  React.useEffect(() => {
    dispatch(applicantActions.acceptDeclinedApplicationShare(accept, code));
  }, [accept, code, dispatch]);

  return (
    <>
      <ModalSpinner oepn={acceptDeclineInProgress} />
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {accept ? 'Accept Application Share' : 'Decline Application Share'}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container sx={{ padding: '30px 10px' }}>
        {acceptDeclineError && (
          <Alert severity="error">An error occured while processing your request.</Alert>
        )}
        {acceptDeclineSuccessMessage && (
          <Alert severity="success">{acceptDeclineSuccessMessage}</Alert>
        )}
      </Container>
    </>
  );
}

AcceptDeclineShareRequest.propTypes = { accept: PropTypes.bool.isRequired };

export default AcceptDeclineShareRequest;
