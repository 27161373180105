const reportConstants = {
  getCycleTimeRptData: 'GET_CYCLETIMERPTDATA',
  getCycleTimeRptDataSucceeded: 'GET_CYCLETIMERPTDATA_SUCCEEDED',
  getCycleTimeRptDataFailed: 'GET_CYCLETIMERPTDATA_FAILED',

  getApplicationCycleTime: 'GET_APPLICATION_CYCLE_TIME',
  getApplicationCycleTimeSucceeded: 'GET_APPLICATION_CYCLE_TIME_SUCCEEDED',
  getApplicationCycleTimeFailed: 'GET_APPLICATION_CYCLE_TIME_FAILED',

  getVarianceRptData: 'GET_VARIANCERPTDATA',
  getVarianceRptDataSucceeded: 'GET_VARIANCERPTDATA_SUCCEEDED',
  getVarianceRptDataFailed: 'GET_VARIANCERPTDATA_FAILED',

  getPaymentRptData: 'GET_PAYMENTRPTDATA',
  getPaymentRptDataSucceeded: 'GET_PAYMENTRPTDATA_SUCCEEDED',
  getPaymentRptDataFailed: 'GET_PAYMENTRPTDATA_FAILED',

  getSummaryRptData: 'GET_SUMMARYRPTDATA',
  getSummaryRptDataSucceeded: 'GET_SUMMARYRPTDATA_SUCCEEDED',
  getSummaryRptDataFailed: 'GET_SUMMARYRPTDATA_FAILED',

  getFinanceRptData: 'GET_FINANCERPTDATA',
  getFinanceRptDataSucceeded: 'GET_FINANCERPTDATA_SUCCEEDED',
  getFinanceRptDataFailed: 'GET_FINANCERPTDATA_FAILED',

  getReviewerBacklogData: 'GET_REVIEWERBACKLOGDATA',
  getReviewerBacklogDataSucceeded: 'GET_REVIEWERBACKLOGDATA_SUCCEEDED',
  getReviewerBacklogDataFailed: 'GET_REVIEWERBACKLOGDATA_FAILED',

  getTerritoryAssignmentsData: 'GET_TERRITORYASSIGNMENTSDATA',
  getTerritoryAssignmentsDataSucceeded: 'GET_TERRITORYASSIGNMENTSDATA_SUCCEEDED',
  getTerritoryAssignmentsDataFailed: 'GET_TERRITORYASSIGNMENTSDATA_FAILED',

  getFirstSubmissionData: 'GET_FIRSTSUBMISSIONDATA',
  getFirstSubmissionDataSucceeded: 'GET_FIRSTSUBMISSIONDATA_SUCCEEDED',
  getFirstSubmissionDataFailed: 'GET_FIRSTSUBMISSIONDATA_FAILED',
};

export default reportConstants;
