const logConstants = {
  getLogs: 'GET_LOGS',
  getLogsSuccess: 'GET_LOGS_SUCCESS',
  getLogsError: 'GET_LOGS_ERROR',

  insertLog: 'INSERT_LOG',
  insertLogSuccess: 'INSERT_LOG_SUCCESS',
  insertLogError: 'INSERT_LOG_ERROR',

  updateLog: 'UPDATE_LOG',
  updateLogSuccess: 'UPDATE_LOG_SUCCESS',
  updateLogError: 'UPDATE_LOG_ERROR',

  deleteLog: 'DELETE_LOG',
  deleteLogSuccess: 'DELETE_LOG_SUCCESS',
  deleteLogError: 'DELETE_LOG_ERROR',
};

export default logConstants;
