import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import FormValue from './FormValue';
import SectionHeader from './SectionHeader';

const ApplicantDetails = ({ appSummary }) => {
  return (
    <View>
      <SectionHeader headerText="Applicant Details" />
      {appSummary.permitTypeValue === 'PIPELINE' && (
        <FormValue
          fieldText="Legal Name of company/municipality who will own the pipeline"
          valueText={appSummary.applicationDetail.legalName}
        />
      )}
      {appSummary.permitTypeValue === 'WIRELINE' && (
        <FormValue
          fieldText="Legal Name of company/municipality who will own the wireline"
          valueText={appSummary.applicationDetail.legalName}
        />
      )}
      {(appSummary.permitTypeValue === 'ROADWAYSURFACING' ||
        appSummary.permitTypeValue === 'TEMPORARYOCCUPANCY' ||
        appSummary.permitTypeValue === 'PRIVATECROSSING' ||
        appSummary.permitTypeValue === 'OVERSIZELOADMOVE' ||
        appSummary.permitTypeValue === 'GENERALLICENSE' ||
        appSummary.permitTypeValue === 'ENVIRONMENTALRIGHTOFWAY') && (
        <FormValue
          fieldText="Legal Name of company/municipality who will occupy the property"
          valueText={appSummary.applicationDetail.legalName}
        />
      )}
      {appSummary.permitTypeValue === 'ASSIGNMENT' && (
        <FormValue
          fieldText="Legal Name of company/municipality assigning the agreement"
          valueText={appSummary.applicationDetail.legalName}
        />
      )}
      <FormValue
        fieldText="State in which incorporated"
        valueText={appSummary.applicationDetail.stateIncorporated}
      />
      <FormValue
        fieldText="Name of owners or partners if not incorporated"
        valueText={appSummary.applicationDetail.ownersPartnersName}
      />
      <FormValue
        fieldText="Name of contact for ownership entity"
        valueText={`${appSummary.applicationDetail.ownershipContactFirstName} ${appSummary.applicationDetail.ownershipContactLastName}`}
      />
      <FormValue fieldText="Phone number" valueText={appSummary.applicationDetail.phoneNumber} />
      <FormValue fieldText="Ext" valueText={appSummary.applicationDetail.extension} hideIfEmpty />
      <FormValue fieldText="Email" valueText={appSummary.applicationDetail.email} />
      <FormValue
        fieldText="Mailing address"
        valueText={`${appSummary.applicationDetail.street} ${appSummary.applicationDetail.city}, ${appSummary.applicationDetail.state} ${appSummary.applicationDetail.zipCode}`}
      />
      {appSummary.permitTypeValue === 'ROADWAYSURFACING' && (
        <FormValue
          fieldText="Legal name of roadway authority requesting work"
          valueText={appSummary.applicationDetail.legalName2}
        />
      )}
      {appSummary.permitTypeValue === 'ASSIGNMENT' && (
        <FormValue
          fieldText="Legal Name of company/municipality who will own the facility"
          valueText={appSummary.applicationDetail.legalName2}
        />
      )}
      {appSummary.permitTypeValue === 'ROADWAYSURFACING' ||
        (appSummary.permitTypeValue === 'ASSIGNMENT' && (
          <>
            <FormValue
              fieldText="State in which incorporated"
              valueText={appSummary.applicationDetail.stateIncorporated2}
            />
            <FormValue
              fieldText="Name of owners or partners if not incorporated"
              valueText={appSummary.applicationDetail.ownersPartnersName2}
            />
            <FormValue
              fieldText="Name of contact for ownership entity"
              valueText={appSummary.applicationDetail.ownershipContact2}
            />
            <FormValue
              fieldText="Phone number"
              valueText={appSummary.applicationDetail.phoneNumber2}
            >
              <FormValue
                fieldText="Ext"
                valueText={appSummary.applicationDetail.extension2}
                hideIfEmpty
              />
            </FormValue>
            <FormValue fieldText="Email" valueText={appSummary.applicationDetail.email2} />
            <FormValue
              fieldText="Mailing address"
              valueText={`${appSummary.applicationDetail.street2} ${appSummary.applicationDetail.city2}, ${appSummary.applicationDetail.state2} ${appSummary.applicationDetail.zipCode2}`}
            />
          </>
        ))}
    </View>
  );
};

ApplicantDetails.propTypes = {
  appSummary: PropTypes.object.isRequired,
};

export default ApplicantDetails;
