import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import MaterialReactTable from 'material-react-table';
import { Grid, Tooltip, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import withRoot from '../../../modules/withRoot';
import { getLookup } from '../../../helpers/utils';
import homeActions from '../../../actions/homeActions';
import alertActions from '../../../actions/alertActions';
import ModalSpinner from '../../../modules/components/ModalSpinner';
import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';
import Badge from '@mui/material/Badge';
import SharedWithDialog from './SharedWithDialog';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0px 10px',
  },
  text: {
    fontSize: '1.05rem',
    marginBottom: 10,
  },
}));

function SharedWithIcon(
  setSharedWithDialogOpen,
  setSharedWithDialogData,
  applicationShares,
  applicationId
) {
  return (
    <IconButton
      color="primary"
      onClick={() => {
        setSharedWithDialogData({
          applicationId,
        });
        setSharedWithDialogOpen(true);
      }}
      disabled={applicationShares === null || applicationShares.length === 0}
      size="large"
    >
      <Badge badgeContent={applicationShares?.length} color="primary">
        <PeopleIcon fontSize="large" />
      </Badge>
    </IconButton>
  );
}

function prepareApplicationData(
  data,
  permitTypes,
  selectedApplicationIds,
  setSharedWithDialogOpen,
  setSharedWithDialogData
) {
  const applicationData = [];
  data.forEach((application) => {
    const status = application.applicationStatus || {};
    let { applicationDetail } = application;
    applicationDetail = applicationDetail || {};

    const sharedWith = SharedWithIcon(
      setSharedWithDialogOpen,
      setSharedWithDialogData,
      application.applicationShares,
      application.applicationId
    );

    const link = (
      <>
        <Grid container spacing={1}>
          <Grid item>
            <Link to={`/application/${application.applicationId}`} style={{ display: 'block' }}>
              {application.businessId}
            </Link>
          </Grid>
          {applicationDetail.rushProcessing && (
            <Grid item>
              <Tooltip title="Rush Processing Requested">
                <PriorityHighIcon fontSize="small" color="error" style={{ display: 'block' }} />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </>
    );

    let cityState = '';
    if (application.locationDetail && application.locationDetail.nearestTown) {
      cityState = `${application.locationDetail.nearestTown}, ${application.locationDetail.state}`;
    } else if (application.locationDetail && application.locationDetail.state) {
      cityState = application.locationDetail.state;
    }

    const permitTypeLU = getLookup(permitTypes, application.permitTypeId);

    applicationData.push({
      sharedWith: sharedWith,
      applicationId: application.applicationId,
      businessId: application.businessId,
      applicationLink: link,
      permitType: permitTypeLU.name,
      cityState: cityState,
      status: status.name,
      tableData: { checked: selectedApplicationIds.includes(application.applicationId) },
    });
  });

  return applicationData.sort((a, b) => a.applicationId - b.applicationId);
}

function Share() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const emailsInputRef = React.useRef();

  const allApplications = useSelector((state) => state.home.applications);
  const { user } = useSelector((state) => state);
  const userApplications = allApplications.filter((a) => a.user.email === user.email);
  const { permitTypes } = useSelector((state) => state.common.lookups);
  const { gettingApplications, sharingApplications, deletingApplicationShare } = useSelector(
    (state) => state.home
  );

  const [selectedApplicationIds, setSelectedApplicationIds] = useState([]);
  const [emailAddressesEmpty, setEmailAddressesEmpty] = useState(true);
  const [sharedWithDialogOpen, setSharedWithDialogOpen] = useState(false);
  const [sharedWithDialogData, setSharedWithDialogData] = useState({
    applicationId: -1,
  });

  const [rowSelection, setRowSelection] = useState({});
  React.useEffect(() => {
    const selectionKeys = Object.keys(rowSelection).map((k) => Number(k));
    setSelectedApplicationIds(selectionKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection, dispatch]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'sharedWith',
        header: 'Shared With',
      },
      {
        accessorKey: 'applicationLink',
        header: 'Application Id',
        filterFn: (row, id, filterValue) => {
          if (row.original['businessId']) {
            return row.original['businessId'].toLowerCase().includes(filterValue.toLowerCase());
          }
          return false;
        },
        sortingFn: (rowA, rowB) => {
          if (rowA.original['businessId'] > rowB.original['businessId']) {
            return 1;
          }
          if (rowB.original['businessId'] > rowA.original['businessId']) {
            return -1;
          }
          return 0;
        },
      },
      {
        accessorKey: 'permitType',
        header: 'Permit Type',
      },
      {
        accessorKey: 'cityState',
        header: 'Permit Location',
      },
      {
        accessorKey: 'status',
        header: 'Status',
      },
    ],
    []
  );

  return (
    <Grid container justifyContent="center" className={classes.wrapper}>
      <SharedWithDialog
        open={sharedWithDialogOpen}
        handleClose={() => setSharedWithDialogOpen(false)}
        applicationId={sharedWithDialogData.applicationId}
      />
      <ModalSpinner open={sharingApplications || deletingApplicationShare} />
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.text}>
          Enter one or more email addresses (separated by commas) of other Rail Permitting users and
          select application(s) below to share.
        </Typography>
        <Typography variant="body1" className={classes.text}>
          Users will be able to view, edit, and pay fees on shared applications.
        </Typography>
        <Typography variant="body1" className={classes.text}>
          Please ensure that all email addresses entered have created an account with this site. If
          they have not, the application(s) will not be shared.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <TextField
          required
          id="addresses"
          label="Email Addresses"
          style={{ minWidth: '50%' }}
          multiline
          onChange={(e) => {
            const { value } = e.target;
            if (emailAddressesEmpty && value.trim().length > 0) {
              setEmailAddressesEmpty(false);
            } else if (value.trim().length === 0) {
              setEmailAddressesEmpty(true);
            }
          }}
          inputRef={emailsInputRef}
          helperText={'Separate addresses with commas'}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          disabled={selectedApplicationIds.length === 0 || emailAddressesEmpty}
          onClick={() => {
            const text = emailsInputRef.current.value;
            const emailAddresses = text
              .split(',')
              .map((e) => e.trim())
              .filter((e) => e.length > 0);
            dispatch(alertActions.clear());
            dispatch(homeActions.shareApplications(selectedApplicationIds, emailAddresses));
          }}
        >
          {`Share ${
            selectedApplicationIds.length ? selectedApplicationIds.length : ''
          } Application${selectedApplicationIds.length > 1 ? 's' : ''}`}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <MaterialReactTable
          columns={columns}
          data={prepareApplicationData(
            userApplications,
            permitTypes,
            selectedApplicationIds,
            setSharedWithDialogOpen,
            setSharedWithDialogData
          )}
          enableStickyHeader
          enableGlobalFilter={false}
          initialState={{ density: 'compact' }}
          getRowId={(row) => row.applicationId}
          enableRowSelection
          onRowSelectionChange={setRowSelection}
          state={{
            rowSelection,
            isLoading: gettingApplications,
          }}
        />
      </Grid>
    </Grid>
  );
}

Share.propTypes = {};

export default withRoot(Share);
