import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Text, View } from '@react-pdf/renderer';
import { getChangesets } from '../../../History/ApplicationChanges';
import SectionHeader from '../../Components/SectionHeader';
import SectionSubHeader from '../../Components/SectionSubHeader';
import SummaryTable from '../../Components/SummaryTable';
import ArrayChanges from './ArrayChanges';
import ChangeTypeHeader from './ChangeTypeHeader';

const ChangesSummary = ({ history }) => {
  if (!history && !history.length) {
    return null;
  }

  const changesets = getChangesets(history);
  if (!changesets || !changesets.length) {
    return null;
  }

  return (
    <View>
      <SectionHeader headerText="Changes" />
      {changesets.map((changeset, changesetIndex) => {
        return (
          <View
            key={`changeset_${changesetIndex}`}
            style={{ marginBottom: '15px', border: '1px solid black', padding: '5px' }}
          >
            <SectionSubHeader
              headerText={`Changeset ${changesetIndex + 1} on ${moment(
                changeset.currentVersionDate
              ).format('MM/DD/YYYY')}`}
            />
            {changeset.permitTypeHasChanged && (
              <Text style={{ color: 'red', fontSize: '13px', marginBottom: '5px' }}>
                Permit Type Changed
              </Text>
            )}
            <View>
              {Object.entries(changesets[changesetIndex].changeset.simpleChanges).map(
                ([, formChange], objectIndex) => {
                  const filteredChange = formChange.changes.filter((c) => c.hideField === false);
                  if (filteredChange.length === 0) {
                    return null;
                  }
                  return (
                    <View key={`object_${objectIndex}`} wrap={true}>
                      <SectionSubHeader headerText={formChange.rootDescription} />
                      <ChangeTypeHeader color="blue">Updated</ChangeTypeHeader>
                      <SummaryTable
                        headers={['Field', 'Old Value', 'New Value']}
                        data={formChange.changes
                          .filter((c) => c.hideField === false)
                          .map((formChange) => {
                            return [formChange.description, formChange.oldValue, formChange.value];
                          })}
                        valuesColor="blue"
                      />
                    </View>
                  );
                }
              )}
            </View>
            <View>
              {Object.entries(changesets[changesetIndex].changeset.arrayChanges).map(
                ([, arrayChange], objectIndex) => (
                  <View key={`object_${objectIndex}`} wrap={true}>
                    <SectionSubHeader headerText={arrayChange.rootDescription} />
                    <ArrayChanges changes={arrayChange.changes} />
                  </View>
                )
              )}
            </View>
          </View>
        );
      })}
    </View>
  );
};

ChangesSummary.propTypes = { history: PropTypes.array.isRequired };

export default ChangesSummary;
