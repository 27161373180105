const paymentConstants = {
  setApplicationFees: 'SET_APPLICATION_FEES',
  setApplicationFeesSuccess: 'SET_APPLICATION_FEES_SUCCESS',
  setApplicationFeesError: 'SET_APPLICATION_FEES_ERROR',

  getAdditionalApplicationFees: 'GET_ADDITIONAL_APPLICATION_FEES',
  getAdditionalApplicationFeesSuccess: 'GET_ADDITIONAL_APPLICATION_FEES_SUCCESS',
  getAdditionalApplicationFeesError: 'GET_ADDITIONAL_APPLICATION_FEES_ERROR',

  getUnpaidFees: 'GET_UNPAID_FEES',
  getUnpaidFeesSuccess: 'GET_UNPAID_FEES_SUCCESS',
  getUnpaidFeesError: 'GET_UNPAID_FEES_ERROR',

  getPaidApplicationFees: 'GET_PAID_APPLICATION_FEES',
  getPaidApplicationFeesSuccess: 'GET_PAID_APPLICATION_FEES_SUCCESS',
  getPaidApplicationFeesError: 'GET_PAID_APPLICATION_FEES_ERROR',

  updatePermitFeeIsApplied: 'UPDATE_PERMIT_FEE_IS_APPLIED',
  updatePermitFeeIsAppliedSuccess: 'UPDATE_PERMIT_FEE_IS_APPLIED_SUCCESS',
  updatePermitFeeIsAppliedError: 'UPDATE_PERMIT_FEE_IS_APPLIED_ERROR',

  getConvenienceFee: 'GET_CONVENIENCE_FEE',
  getConvenienceFeeSuccess: 'GET_CONVENIENCE_FEE_SUCCESS',
  getConvenienceFeeError: 'GET_CONVENIENCE_FEE_ERROR',

  setAcceptedTermsAndConditions: 'SET_ACCEPTED_TERMS_AND_CONDITIONS',

  setUserPaymentData: 'SET_USER_PAYMENT_DATA',

  getPaymentToken: 'GET_PAYMENT_TOKEN',
  getPaymentTokenSuccess: 'GET_PAYMENT_TOKEN_SUCCESS',
  getPaymentTokenError: 'GET_PAYMENT_TOKEN_ERROR',

  makePayment: 'MAKE_PAYMENT',
  makePaymentSuccess: 'MAKE_PAYMENT_SUCCESS',
  makePaymentError: 'MAKE_PAYMENT_ERROR',

  initiatePaymentStarted: 'INITIATE_PAYMENT_STARTED',
  initiatePaymentDone: 'INITIATE_PAYMENT_DONE',

  cancelPayment: 'CANCEL_PAYMENT',
  cancelPaymentSuccess: 'CANCEL_PAYMENT_SUCCESS',
  cancelPaymentError: 'CANCEL_PAYMENT_ERROR',

  savePayment: 'SAVE_PAYMENT',
  savePaymentSuccess: 'SAVE_PAYMENT_SUCCESS',
  savePaymentError: 'SAVE_PAYMENT_ERROR',

  getFeesAndPayments: 'GET_FEES_AND_PAYMENTS',
  getFeesAndPaymentsSuccess: 'GET_FEES_AND_PAYMENTS_SUCCESS',
  getFeesAndPaymentsError: 'GET_FEES_AND_PAYMENTS_ERROR',

  updateFee: 'UPDATE_FEE',
  updateFeeSuccess: 'UPDATE_FEE_SUCCESS',
  updateFeeError: 'UPDATE_FEE_ERROR',

  deleteFee: 'DELETE_FEE',
  deleteFeeSuccess: 'DELETE_FEE_SUCCESS',
  deleteFeeError: 'DELETE_FEE_ERROR',

  addFee: 'ADD_FEE',
  addFeeSuccess: 'ADD_FEE_SUCCESS',
  addFeeError: 'ADD_FEE_ERROR',

  recordManualPayment: 'RECORD_MANUAL_PAYMENT',
  recordManualPaymentSuccess: 'RECORD_MANUAL_PAYMENT_SUCCESS',
  recordManualPaymentError: 'RECORD_MANUAL_PAYMENT_ERROR',
};

export default paymentConstants;
