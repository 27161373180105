import { combineReducers } from 'redux';
import alert from './alertReducer';
import selectedApplication from './selectedApplicationReducer';
import home from './homeReducer';
import common from './commonReducer';
import user from './userReducer';
import payment from './paymentReducer';
import mapDraw from './mapDrawReducer';
import mapQuery from './mapQueryReducer';
import documents from './documentsReducer';
import reports from './reportsReducer';
import admin from './adminReducer';
import applicant from './applicantReducer';

const rootReducer = combineReducers({
  selectedApplication,
  home,
  alert,
  common,
  user,
  payment,
  mapDraw,
  mapQuery,
  documents,
  reports,
  admin,
  applicant,
});

export default rootReducer;
