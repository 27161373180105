import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import homeConstants from '../constants/homeConstants';
import { acquireAccessToken } from '../Auth/utils';
import alertConstants from '../constants/alertConstants';
import PeopleIcon from '@mui/icons-material/People';

function* getReviewerApplications(action) {
  const { startDate, endDate } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/all`,
      params: { startDate, endDate },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: homeConstants.getReviewerApplicationsSuccess, result: data });
  } catch (e) {
    yield put({ type: homeConstants.getReviewerApplicationsError, error: e });
  }
}

function* getAllUnpaidFees() {
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/payment/allunpaidfees`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: homeConstants.getAllUnpaidFeesSuccess, unpaidFees: data });
  } catch (e) {
    yield put({ type: homeConstants.getAllUnpaidFeesError, error: e });
  }
}

function* getPaymentHistory() {
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/payment/history`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: homeConstants.getPaymentHistorySuccess, paymentHistory: data });
  } catch (e) {
    yield put({ type: homeConstants.getPaymentHistoryError, error: e });
  }
}

function* getSearchOptions() {
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/searchoptions`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: homeConstants.getSearchOptionsSuccess, searchOptions: data });
  } catch (e) {
    yield put({ type: homeConstants.getSearchOptionsError, error: e });
  }
}

function* searchApplications(action) {
  const { searchParams } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/search`,
      params: { ...searchParams },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: homeConstants.searchApplicationsSuccess, applications: data });
  } catch (e) {
    yield put({ type: homeConstants.searchApplicationsError, error: e });
    yield put({ type: alertConstants.error, message: 'Error occured searching applications' });
  }
}

function* shareApplications(action) {
  const { applicationIds, emailAddresses } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/application/share`,
      data: { applicationIds, emailAddresses },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: homeConstants.shareApplicationsSuccess, applicationShares: data });
    yield put({
      type: alertConstants.success,
      message: (
        <div>
          <div>Finished! Your application was shared with {data?.length || 0} users.</div>
          <div>
            If this number is less than you expected it is likely because some of the users
            specified haven't created an account with this site.
          </div>
          <div>
            You can view who your application is shared with by clicking the{' '}
            <span style={{ position: 'relative', top: 5 }}>
              <PeopleIcon />
            </span>{' '}
            icon in the "Shared With" column.
          </div>
        </div>
      ),
    });
  } catch (e) {
    yield put({ type: homeConstants.shareApplicationsError, error: e });
    yield put({ type: alertConstants.error, message: 'Error occured sharing applications' });
  }
}

function* deleteApplicationShare(action) {
  const { applicationShareId, applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/application/share/${applicationShareId}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: homeConstants.deleteApplicationShareSuccess,
      applicationShareId,
      applicationId,
    });
  } catch (e) {
    yield put({ type: homeConstants.deleteApplicationShareError, error: e });
    yield put({ type: alertConstants.error, message: 'Error occured removing application share.' });
  }
}

function* deleteApplication(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/application/${applicationId}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: homeConstants.deleteApplicationSuccess,
      applicationId,
    });
  } catch (e) {
    yield put({ type: homeConstants.deleteApplicationError, error: e });
    yield put({ type: alertConstants.error, message: 'Error occured deleting application.' });
  }
}

const homeSagas = [
  takeLatest(homeConstants.getReviewerApplications, getReviewerApplications),
  takeLatest(homeConstants.getAllUnpaidFees, getAllUnpaidFees),
  takeLatest(homeConstants.getPaymentHistory, getPaymentHistory),
  takeLatest(homeConstants.getSearchOptions, getSearchOptions),
  takeLatest(homeConstants.searchApplications, searchApplications),
  takeLatest(homeConstants.shareApplications, shareApplications),
  takeLatest(homeConstants.deleteApplicationShare, deleteApplicationShare),
  takeLatest(homeConstants.deleteApplication, deleteApplication),
];

export default homeSagas;
