/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import MaterialReactTable from 'material-react-table';
import { Grid, IconButton, Tooltip } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ReorderIcon from '@mui/icons-material/Reorder';
import ShareIcon from '@mui/icons-material/Share';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import withRoot from '../../modules/withRoot';
import StatusDetails from '../StatusDetails';
import documentActions from '../../actions/documentActions';
import { getLookup } from '../../helpers/utils';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../modules/AppInsights';
import SelectPermitType from './SelectPermitType';
import Share from './Sharing/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteApplicationDialog from './DeleteApplicationDialog';

appInsights.trackPageView('ApplicantApplications');

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0px 10px',
  },
  button: {
    marginTop: 10,
  },
  tabIndicator: { height: 5 },
}));

function prepareApplicationData(data, unpaidFees, permitTypes, toggleStatusDetails) {
  const applicationData = [];
  data.forEach((application) => {
    const status = application.applicationStatus || {};
    let { applicationDetail } = application;
    applicationDetail = applicationDetail || {};

    const statusDetails = (
      <>
        <Link
          to="#"
          onClick={(evt) => {
            toggleStatusDetails({ open: true, applicationReviews: application.applicationReviews });
            evt.preventDefault();
          }}
          style={{ textDecoration: 'none' }}
        >
          {status.name}
        </Link>
      </>
    );

    const link = (
      <>
        <Grid container spacing={1}>
          <Grid item>
            <Link to={`/application/${application.applicationId}`} style={{ display: 'block' }}>
              {application.businessId || `Temp ID: ${application.applicationId}`}
            </Link>
          </Grid>
          {applicationDetail.rushProcessing && (
            <Grid item>
              <Tooltip title="Rush Processing Requested">
                <PriorityHighIcon fontSize="small" color="error" style={{ display: 'block' }} />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </>
    );

    let cityState = '';
    if (application.locationDetail && application.locationDetail.nearestTown) {
      cityState = `${application.locationDetail.nearestTown}, ${application.locationDetail.state}`;
    } else if (application.locationDetail && application.locationDetail.state) {
      cityState = application.locationDetail.state;
    }

    const permitTypeLU = getLookup(permitTypes, application.permitTypeId);

    const fees = unpaidFees.filter((fee) => fee.applicationId === application.applicationId);

    applicationData.push({
      applicationId_: application.applicationId,
      businessId_: application.businessId,
      applicationLink: link,
      createdBy: application.user?.name,
      createdByEmail_: application.user?.email,
      permitType: permitTypeLU.name,
      companyName: applicationDetail.legalName,
      cityState: cityState,
      applicationDate_: moment.utc(application.dateTimeUtc).local().format('YYYY-MM-DD'),
      submittalDate: application.submittalDateTime
        ? moment.utc(application.submittalDateTime).local().format('YYYY-MM-DD')
        : null,
      status_: status.name,
      statusValue_: status.value,
      status: statusDetails,
      fees: fees || [],
    });
  });

  const applicationsWithFees = applicationData
    .filter((application) => application.fees.length > 0)
    .sort((a, b) => moment(b.submittalDate).diff(moment(a.submittalDate)));
  const applicationsWithoutFees = applicationData
    .filter((application) => application.fees.length === 0)
    .sort((a, b) => moment(b.submittalDate).diff(moment(a.submittalDate)));

  return [...applicationsWithFees, ...applicationsWithoutFees];
}

function ApplicantApplications() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state);
  const { applications, unpaidFees } = useSelector((state) => state.home);
  const { downloadingAgreement, gettingApplications, deletingApplication } = useSelector(
    (state) => state.home
  );
  const { applicantDownloadingSummary } = useSelector((state) => state.documents);
  const { permitTypes } = useSelector((state) => state.common.lookups);
  const [statusDetails, toggleStatusDetails] = useState({ open: false, applicationReviews: [] });
  const [deleteAppDialogData, setDeleteAppDialogData] = useState({
    open: false,
    applicationid: -1,
    businessId: '',
  });

  const [activeTabId, setActiveTab] = useState(0);
  const handleTabChange = (evt, value) => {
    setActiveTab(value);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'applicationLink',
        header: 'Application Id',
        filterFn: (row, id, filterValue) => {
          if (row.original['businessId_']) {
            return row.original['businessId_'].toLowerCase().includes(filterValue.toLowerCase());
          }
          return false;
        },
        sortingFn: (rowA, rowB) => {
          if (rowA.original['businessId_'] > rowB.original['businessId_']) {
            return 1;
          }
          if (rowB.original['businessId_'] > rowA.original['businessId_']) {
            return -1;
          }
          return 0;
        },
      },
      {
        accessorKey: 'createdBy',
        header: 'Created By',
      },
      {
        accessorKey: 'permitType',
        header: 'Permit Type',
      },
      {
        accessorKey: 'companyName',
        header: 'Company Name',
      },
      {
        accessorKey: 'cityState',
        header: 'City State',
      },
      {
        accessorKey: 'submittalDate',
        header: 'Submittal Date',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        filterFn: (row, id, filterValue) => {
          if (row.original['status_']) {
            return row.original['status_'].toLowerCase().includes(filterValue.toLowerCase());
          }
          return false;
        },
        sortingFn: (rowA, rowB) => {
          if (rowA.original['status_'] > rowB.original['status_']) {
            return 1;
          }
          if (rowB.original['status_'] > rowA.original['status_']) {
            return -1;
          }
          return 0;
        },
      },
    ],
    []
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Applications
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" position="static" elevation={0}>
        <Tabs
          value={activeTabId}
          textColor="inherit"
          onChange={handleTabChange}
          classes={{ indicator: classes.tabIndicator }}
        >
          <Tab label="Existing" icon={<ReorderIcon />} />
          <Tab label="Share" icon={<ShareIcon />} />
          <Tab label="Create New" icon={<AddIcon />} />
        </Tabs>
      </AppBar>
      {activeTabId === 0 && (
        <Grid container justifyContent="center" className={classes.wrapper}>
          <DeleteApplicationDialog
            open={deleteAppDialogData.open}
            applicationId={deleteAppDialogData.applicationId || -1}
            businessId={deleteAppDialogData.businessId}
            handleClose={() => setDeleteAppDialogData({ open: false })}
          />
          <Grid item xs={12}>
            <MaterialReactTable
              columns={columns}
              data={prepareApplicationData(
                applications,
                unpaidFees,
                permitTypes,
                toggleStatusDetails
              )}
              enableStickyHeader
              enableGlobalFilter={false}
              enableRowActions
              state={{
                isLoading:
                  deletingApplication ||
                  gettingApplications ||
                  downloadingAgreement ||
                  applicantDownloadingSummary,
              }}
              renderRowActions={({ row }) => (
                <Stack direction="row" spacing={1}>
                  {row.original['statusValue_'] !== 'UNSUBMITTED' && (
                    <Tooltip title="Download Application Summary">
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={(evt) => {
                          dispatch(
                            documentActions.applicantSummaryDownload(row.original['applicationId_'])
                          );
                          evt.stopPropagation();
                        }}
                        size="large"
                      >
                        <SaveAltIcon sx={{ color: '#212121' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {row.original['fees'].length > 0 && (
                    <Tooltip title="Pay">
                      <IconButton
                        sx={{ padding: 0 }}
                        component={Link}
                        to={`/application/${row.original['applicationId_']}/payment`}
                        size="large"
                      >
                        <PaymentIcon sx={{ color: '#212121' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {row.original['submittalDate'] === null &&
                    user.email === row.original['createdByEmail_'] && (
                      <Tooltip title="Delete Application">
                        <IconButton
                          sx={{ padding: 0 }}
                          onClick={() => {
                            setDeleteAppDialogData({
                              open: true,
                              applicationId: row.original['applicationId_'],
                              businessId: row.original['businessId_'],
                            });
                          }}
                          size="large"
                        >
                          <DeleteIcon sx={{ color: '#212121' }} />
                        </IconButton>
                      </Tooltip>
                    )}
                </Stack>
              )}
            />
          </Grid>
          <StatusDetails
            open={statusDetails.open}
            applicationReviews={statusDetails.applicationReviews}
            close={() => {
              toggleStatusDetails({ open: false, applicationReviews: [] });
            }}
          />
        </Grid>
      )}
      {activeTabId === 1 && <Share />}
      {activeTabId === 2 && <SelectPermitType history={history} />}
    </div>
  );
}

ApplicantApplications.propTypes = {};

export default withRoot(withAITracking(reactPlugin, ApplicantApplications));
