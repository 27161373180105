import React from 'react';
import { useSelector } from 'react-redux';
import MaterialReactTable from 'material-react-table';
import { IconButton, Stack, Tooltip } from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeactivateUserDialog from './DeactivateUserDialog';
import EditUser from './EditUser';
import ModalSpinner from '../../../modules/components/ModalSpinner';

export default function ActiveUsers() {
  const { users, deactivatingUser, gettingRoles, gettingTerritories, gettingTerritoryTypes } =
    useSelector((state) => state.admin);
  const activeUsers = users.filter((u) => u.isDeactivated === false);

  const [currentUser, setCurrentUser] = React.useState({});
  const [dialogState, setDialogState] = React.useState({
    deactivateUserOpen: false,
    editUserOpen: false,
  });

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
    ],
    []
  );

  if (gettingRoles || gettingTerritories || gettingTerritoryTypes) {
    return <ModalSpinner open />;
  }

  return (
    <>
      {dialogState.deactivateUserOpen && (
        <DeactivateUserDialog
          open={dialogState.deactivateUserOpen}
          handleClose={() => setDialogState({ ...dialogState, deactivateUserOpen: false })}
          user={currentUser}
        />
      )}
      {dialogState.editUserOpen && (
        <EditUser
          open={dialogState.editUserOpen}
          handleClose={() => setDialogState({ ...dialogState, editUserOpen: false })}
          user={currentUser}
        />
      )}
      <MaterialReactTable
        columns={columns}
        data={activeUsers}
        enableStickyHeader
        enableGlobalFilter={true}
        initialState={{ density: 'compact' }}
        state={{ isLoading: deactivatingUser }}
        enableRowActions
        renderRowActions={({ row }) => (
          <Stack direction="row" spacing={1}>
            {
              <Tooltip title="Deactivate User">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    setCurrentUser(row.original);
                    setDialogState({ ...dialogState, deactivateUserOpen: true });
                  }}
                  size="large"
                >
                  <PersonOffIcon sx={{ color: '#212121' }} />
                </IconButton>
              </Tooltip>
            }
            {
              <Tooltip title="Edit User">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    setCurrentUser(row.original);
                    setDialogState({ ...dialogState, editUserOpen: true });
                  }}
                  size="large"
                >
                  <ModeEditIcon sx={{ color: '#212121' }} />
                </IconButton>
              </Tooltip>
            }
          </Stack>
        )}
      />
    </>
  );
}

ActiveUsers.propTypes = {};
