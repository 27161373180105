import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PrintIcon from '@mui/icons-material/Print';
import bnsfLogo from '../img/bnsf_logo.png';

const styles = (theme) => ({
  listItem: {
    padding: theme.spacing(0.5, 0),
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing(2),
  },
});

const getPageMargins = () => {
  return `
    @page { margin: ${30} ${20} ${30} ${20} !important; } 

    @media print {
      .print-hide {
        display: none;
      }
    }
  `;
};

class Receipt extends React.PureComponent {
  componentRef = null;
  constructor(props) {
    super(props);

    this.isManualPayment = this.props.paymentApprovalResponse.ssl_card_type === 'MANUALRECORD';
  }

  render() {
    return (
      <div ref={(el) => (this.componentRef = el)}>
        <style>{getPageMargins()}</style>
        <Grid container spacing={1} sx={{ padding: '20px' }}>
          {this.props.railroad === 'BNSF' && (
            <Grid item xs={12}>
              <img src={bnsfLogo} alt="" />
            </Grid>
          )}
          <Grid item xs={12} style={{ marginBottom: 15 }}>
            <Typography variant="h4">Thank you for your payment</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Date: {this.props.paymentApprovalResponse.ssl_txn_time}</Typography>
          </Grid>
          {this.isManualPayment === false && (
            <Grid item xs={12}>
              <Typography>
                Approval Code: {this.props.paymentApprovalResponse.ssl_approval_code}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>Invoice Number: {this.props.businessId}</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10, paddingBottom: 0 }}>
            <Typography variant="h6">Purchase summary</Typography>
          </Grid>

          <Grid item xs={12}>
            <List disablePadding style={{ maxWidth: 400 }}>
              {this.props.paidFees.map((fee) => {
                return (
                  <ListItem className={this.props.classes.listItem} key={fee.permitFeeId}>
                    <ListItemText primary={fee.feeLookup.name} secondary="" />
                    <Typography variant="body2">
                      {fee.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </Typography>
                  </ListItem>
                );
              })}
              <ListItem className={this.props.classes.listItem}>
                <ListItemText primary="Total" />
                <Typography variant="subtitle1" className={this.props.classes.total}>
                  {Number(this.props.paymentApprovalResponse.ssl_amount).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Typography>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom className={this.props.classes.title}>
              Payment details
            </Typography>
          </Grid>
          {this.isManualPayment === false && (
            <>
              <Grid item xs={6}>
                <Typography gutterBottom>Card Holder:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  gutterBottom
                >{`${this.props.paymentApprovalResponse.ssl_first_name} ${this.props.paymentApprovalResponse.ssl_last_name}`}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>Card Number:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>
                  {this.props.paymentApprovalResponse.ssl_card_number}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>Card Type:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>
                  {this.props.paymentApprovalResponse.ssl_card_short_description}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>Expiration Date:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>
                  {this.props.paymentApprovalResponse.ssl_exp_date}
                </Typography>
              </Grid>
            </>
          )}
          {this.isManualPayment === true && (
            <Grid item xs={6}>
              <Typography>Payment was manually recorded</Typography>
              <Typography
                gutterBottom
              >{`Check number: ${this.props.paymentApprovalResponse.checkNumber}`}</Typography>
            </Grid>
          )}
          <Grid item xs={12} className="print-hide">
            <ReactToPrint
              trigger={() => {
                return (
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PrintIcon />}
                    style={{ marginTop: 15 }}
                  >
                    Print Receipt
                  </Button>
                );
              }}
              content={() => this.componentRef}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  railroad: state.common.config.railroad,
});

Receipt.propTypes = {
  paymentApprovalResponse: PropTypes.object.isRequired,
  paidFees: PropTypes.array.isRequired,
  businessId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  railroad: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(Receipt));
