import reportConstants from '../constants/reportConstants';

const initialState = {
  cycleTimeRptData: [],
  varianceRptData: [],
  paymentRptData: [],
  financeRptData: [],
  reviewerBacklogData: [],
  territoryAssignmentsData: [],
  firstSubmissionData: [],
  summaryRptData: { events: [], applications: [], documents: [], payments: [] },
  queryingSummaryRptData: false,
  queryingReviewerBacklogData: false,
  queryingTerritoryAssignmentsData: false,
  queryingFirstSubmissionData: false,
  queryingApplicationCycleTime: false,
  applicationCycleTimeData: [],
};

function reports(state = initialState, action) {
  switch (action.type) {
    case reportConstants.getCycleTimeRptData:
      return { ...state, cycleTimeRptDataLoaded: false };
    case reportConstants.getCycleTimeRptDataSucceeded:
      return { ...state, cycleTimeRptData: action.cycleTimeRptData, cycleTimeRptDataLoaded: true };
    case reportConstants.getCycleTimeRptDataFailed:
      return { ...state };

    case reportConstants.getApplicationCycleTime:
      return { ...state, applicationCycleTimeData: [], queryingApplicationCycleTime: true };
    case reportConstants.getApplicationCycleTimeSucceeded:
      return {
        ...state,
        applicationCycleTimeData: action.cycleTime,
        queryingApplicationCycleTime: false,
      };
    case reportConstants.getApplicationCycleTimeFailed:
      return { ...state, queryingApplicationCycleTime: false };

    case reportConstants.getVarianceRptData:
      return { ...state, varianceRptDataLoaded: false };
    case reportConstants.getVarianceRptDataSucceeded:
      return { ...state, varianceRptData: action.varianceRptData, varianceRptDataLoaded: true };
    case reportConstants.getVarianceRptDataFailed:
      return { ...state };

    case reportConstants.getPaymentRptData:
      return { ...state, paymentRptDataLoaded: false };
    case reportConstants.getPaymentRptDataSucceeded:
      return { ...state, paymentRptData: action.paymentRptData, paymentRptDataLoaded: true };
    case reportConstants.getPaymentRptDataFailed:
      return { ...state };

    case reportConstants.getSummaryRptData:
      return { ...state, queryingSummaryRptData: true };
    case reportConstants.getSummaryRptDataSucceeded:
      return { ...state, summaryRptData: action.summaryRptData, queryingSummaryRptData: false };
    case reportConstants.getSummaryRptDataFailed:
      return {
        ...state,
        summaryRptData: { events: [], applications: [], documents: [], payments: [] },
        queryingSummaryRptData: false,
      };

    case reportConstants.getFinanceRptData:
      return { ...state };
    case reportConstants.getFinanceRptDataSucceeded:
      return { ...state, financeRptData: action.data };
    case reportConstants.getFinanceRptDataFailed:
      return { ...state };

    case reportConstants.getReviewerBacklogData:
      return { ...state, queryingReviewerBacklogData: true };
    case reportConstants.getReviewerBacklogDataSucceeded:
      return {
        ...state,
        reviewerBacklogData: action.reviewerBacklogData,
        queryingReviewerBacklogData: false,
      };
    case reportConstants.getReviewerBacklogDataFailed:
      return { ...state, queryingReviewerBacklogData: false };

    case reportConstants.getTerritoryAssignmentsData:
      return { ...state, queryingTerritoryAssignmentsData: true };
    case reportConstants.getTerritoryAssignmentsDataSucceeded:
      return {
        ...state,
        territoryAssignmentsData: action.territoryAssignmentsData,
        queryingTerritoryAssignmentsData: false,
      };
    case reportConstants.getTerritoryAssignmentsDataFailed:
      return { ...state, queryingTerritoryAssignmentsData: false };

    case reportConstants.getFirstSubmissionData:
      return { ...state, queryingFirstSubmissionData: true };
    case reportConstants.getFirstSubmissionDataSucceeded:
      return {
        ...state,
        firstSubmissionData: action.firstSubmissionData,
        queryingFirstSubmissionData: false,
      };
    case reportConstants.getFirstSubmissionDataFailed:
      return { ...state, queryingFirstSubmissionData: false };

    default:
      return state;
  }
}

export default reports;
