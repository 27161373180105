import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import formConstants from '../constants/formConstants';
import alertConstants from '../constants/alertConstants';
import { acquireAccessToken } from '../Auth/utils';

function* getAllApplications(action) {
  const { startDate, endDate } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/application/all`,
      params: { startDate, endDate },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.getAllApplicationsSucceeded, applications: data });
  } catch (e) {
    yield put({ type: formConstants.getAllApplicationsFailed, error: e });
  }
}

function* getApplication(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/application/${Number(applicationId)}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.getApplicationSucceeded, application: data });
  } catch (e) {
    yield put({ type: formConstants.getApplicationFailed, error: e });
  }
}

function* checkApplicationSpecs(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/application/${Number(applicationId)}/specchecker`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.checkApplicationSpecsSucceeded, variances: data });
  } catch (e) {
    yield put({
      type: alertConstants.error,
      message:
        'Failed to check application specifications. Application cannot be submitted without specification checks. If error continues please contact support.',
    });
    yield put({ type: formConstants.checkApplicationSpecsFailed, error: e });
  }
}

const formGetSagas = [
  takeLatest(formConstants.getAllApplications, getAllApplications),
  takeLatest(formConstants.getApplication, getApplication),
  takeLatest(formConstants.checkApplicationSpecs, checkApplicationSpecs),
];

export default formGetSagas;
