/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import {
  Typography,
  AppBar,
  Toolbar,
  Grid,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import withRoot from '../../modules/withRoot';
import makeStyles from '@mui/styles/makeStyles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { getLookup } from '../../helpers/utils';
import { Stack } from '@mui/system';
import documentActions from '../../actions/documentActions';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0 10px',
  },
  table: {
    maxWidth: 450,
    marginLeft: 110,
  },
}));

function FeePanel({ rowData }) {
  const classes = useStyles();
  return (
    <Grid container direction="row">
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body1">Fee</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">Amount</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">Optional</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.original['fees'].map((fee, id) => (
            <TableRow key={id}>
              <TableCell>{fee.feeLookup.name}</TableCell>
              <TableCell>
                {fee.amount.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </TableCell>
              <TableCell>{fee.feeLookup.isOptional ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

FeePanel.propTypes = {
  rowData: PropTypes.object.isRequired,
};

function prepareTableData(unpaidFees, permitTypes) {
  const businessIds = new Set();
  unpaidFees.forEach((f) => {
    businessIds.add(f.application.businessId);
  });
  const data = [];
  businessIds.forEach((id) => {
    const rowData = { applicationId: null, businessId: id, total: null, fees: [] };
    const feeTotal = unpaidFees
      .filter((f) => f.application.businessId === id)
      .map((f) => {
        rowData.fees.push(f);
        rowData.applicationId = f.application.applicationId;
        const permitTypeLU = getLookup(permitTypes, f.application.permitTypeId);
        rowData.permitType = permitTypeLU.name;
        return f;
      })
      .reduce((prev, current) => prev + current.amount, 0);
    rowData.total = feeTotal.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    data.push(rowData);
  });
  return data.sort((a, b) => a.applicationId - b.applicationId);
}

function ReceiptComponent({ row, railroad, dispatch }) {
  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Pay">
        <IconButton
          sx={{ padding: 0 }}
          component={Link}
          to={`/application/${row.original['applicationId']}/payment`}
          size="large"
        >
          <CreditCardIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Download Invoice">
        <IconButton
          sx={{ padding: 0 }}
          onClick={(evt) => {
            dispatch(
              documentActions.downloadInvoice(
                row.original['businessId'],
                row.original['fees'],
                railroad
              )
            );
            evt.stopPropagation();
          }}
          size="large"
        >
          <ReceiptIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

function UnpaidFees() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { unpaidFees, gettingAllUnpaidFees } = useSelector((state) => state.home);
  const { permitTypes } = useSelector((state) => state.common.lookups);
  const { railroad } = useSelector((state) => state.common.config);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'businessId',
        header: 'Application Id',
      },
      {
        accessorKey: 'permitType',
        header: 'Permit Type',
      },
    ],
    []
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Unpaid Fees
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container justifyContent="center" className={classes.wrapper}>
        <Grid item xs={12} style={{ padding: '10px 10px 0px 10px' }}>
          <Typography color="inherit" variant="body1" style={{ marginBottom: 5 }}>
            Applications created by you, or shared with you, that have unpaid fees are listed in the
            table below.
          </Typography>
          <Typography color="inherit" variant="body1">
            Click the{' '}
            <CreditCardIcon
              fontSize="medium"
              color="action"
              style={{ position: 'relative', top: 5 }}
            />{' '}
            icon to pay.
          </Typography>
          <Typography color="inherit" variant="body1">
            Click the{' '}
            <ReceiptIcon
              fontSize="medium"
              color="action"
              style={{ position: 'relative', top: 5 }}
            />{' '}
            icon to download invoice.
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <MaterialReactTable
            columns={columns}
            data={prepareTableData(unpaidFees, permitTypes)}
            enableStickyHeader
            enableGlobalFilter={false}
            enableRowActions
            state={{
              isLoading: gettingAllUnpaidFees,
            }}
            renderDetailPanel={({ row }) => <FeePanel rowData={row} />}
            renderRowActions={({ row }) => (
              <ReceiptComponent row={row} railroad={railroad} dispatch={dispatch} />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}

UnpaidFees.propTypes = {};

export default withRoot(UnpaidFees);
