import rules from './CbacRules';

const check = (rules, claims, action, data) => {
  const rule = rules[action];
  if (!rule) {
    // rule is not present in the rules
    return false;
  }

  return rule(claims, data);
};

function cbacCan(claims, action, data) {
  if (!claims || !Array.isArray(claims)) {
    return false;
  }
  const allowed = check(rules, claims, action, data);
  if (allowed) {
    return true;
  }

  return false;
}

export default cbacCan;
