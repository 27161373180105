import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  sectionHeader: {
    fontSize: 12,
    marginTop: 5,
    marginBottom: 10,
  },
});

const SectionSubHeader = ({ headerText }) => {
  return <Text style={styles.sectionHeader}>{headerText}</Text>;
};

SectionSubHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
};

export default SectionSubHeader;
