import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MaterialReactTable from 'material-react-table';
import homeActions from '../../../actions/homeActions';

function prepareTableData(applicationShares) {
  const data = [];
  applicationShares.forEach((share) => {
    let response = 'No Response';
    if (share.accepted === true) {
      response = 'Accepted';
    }
    if (share.response === false) {
      response = 'Declined';
    }
    data.push({
      email: share.user.email,
      response: response,
      applicationShareId: share.applicationShareId,
    });
  });

  return data;
}

export default function SharedWithDialog({ open, handleClose, applicationId }) {
  const dispatch = useDispatch();

  const allApplications = useSelector((state) => state.home.applications);
  const application = allApplications.find((a) => a.applicationId === applicationId);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'response',
        header: 'User Response',
      },
    ],
    []
  );

  return (
    <Dialog
      open={open}
      onClose={(evt, reason) => {
        if (reason === 'backdropClick') {
          evt.preventDefault();
          return;
        }
        handleClose();
      }}
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">{application?.businessId} Shared With</DialogTitle>
      <DialogContent>
        <MaterialReactTable
          columns={columns}
          data={prepareTableData(application ? application.applicationShares : [])}
          enableStickyHeader
          enableTopToolbar={false}
          enableRowActions
          renderRowActions={({ row }) => (
            <Tooltip title="Remove Share">
              <IconButton
                onClick={() => {
                  dispatch(
                    // eslint-disable-next-line react/prop-types
                    homeActions.deleteApplicationShare(
                      row.original['applicationShareId'],
                      applicationId
                    )
                  );
                }}
                size="large"
              >
                <RemoveCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SharedWithDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  applicationId: PropTypes.number.isRequired,
};
