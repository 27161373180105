import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import FormValue from '../Components/FormValue';
import SectionHeader from '../Components/SectionHeader';
import SectionSubHeader from '../Components/SectionSubHeader';

function overheadWireClearance(appSummary) {
  const ft = appSummary.wireline.overheadWireline.topOfRailClearanceFt || 0;
  const inches = appSummary.wireline.overheadWireline.topOfRailClearanceIn || 0;

  return (ft + inches / 12).toFixed(2);
}

function numberOfPoles(appSummary) {
  if (appSummary.wireline.overheadWireline.poles) {
    return appSummary.wireline.overheadWireline.poles.length;
  }
  return 0;
}

function poleDistanceFromTrack(pole) {
  const ft = pole.poleTrackDistanceFt || 0;
  const inches = pole.poleTrackDistanceIn || 0;

  return (ft + inches / 12).toFixed(2);
}

const OverheadWirelineSummary = ({ appSummary }) => {
  return (
    <>
      {appSummary.wireline.overheadWireline && (
        <>
          <View wrap={false}>
            <SectionHeader headerText="Overhead" />
            <FormValue
              fieldText="Wire clearance from top of rail"
              valueText={overheadWireClearance(appSummary)}
              unitsLabel="ft"
            />
            <FormValue
              fieldText="Crossing angle"
              valueText={appSummary.wireline.overheadWireline.crossingAngle}
              hideIfEmpty
            />
            <FormValue
              fieldText="Voltage"
              valueText={appSummary.wireline.overheadWireline.lineVoltage}
            >
              <FormValue
                fieldText="Phase"
                valueText={appSummary.wireline.overheadWireline.linePhase}
              >
                <FormValue
                  fieldText="Cycle"
                  valueText={appSummary.wireline.overheadWireline.lineCycle}
                />
              </FormValue>
            </FormValue>
          </View>
          <SectionSubHeader headerText="Poles" />
          <FormValue
            fieldText="Number of poles on RR property"
            valueText={numberOfPoles(appSummary)}
          />
          {numberOfPoles(appSummary) > 0 &&
            appSummary.wireline.overheadWireline.poles.map((pole, index) => (
              <View
                style={{ border: '1px solid black', padding: 5, marginBottom: 10 }}
                key={index}
                wrap={false}
              >
                <Text style={{ fontSize: 11, marginBottom: 10 }}>{`Pole ${index + 1}`}</Text>
                <FormValue fieldText="Kind" valueText={pole.poleKind}>
                  <FormValue fieldText="Size" valueText={pole.poleSize}>
                    <FormValue fieldText="Class" valueText={pole.poleClass}></FormValue>
                  </FormValue>
                </FormValue>
                <FormValue fieldText="Height" valueText={pole.poleHeight} unitsLabel="ft">
                  <FormValue
                    fieldText="Distance from track"
                    valueText={poleDistanceFromTrack(pole)}
                  ></FormValue>
                </FormValue>
                <FormValue fieldText="Is existing" valueText={pole.isExisting}>
                  <FormValue
                    fieldText="Under agreement"
                    valueText={pole.underAgreement}
                  ></FormValue>
                </FormValue>
                <FormValue fieldText="Is guyed" valueText={pole.poleIsGuyed}></FormValue>
                {pole.poleIsGuyed && (
                  <View style={{ border: '1px solid black', paddingHorizontal: 5, paddingTop: 5 }}>
                    <Text style={{ fontSize: 11, marginBottom: 5 }}>Guy Details</Text>
                    <FormValue fieldText="Number overhead" valueText={pole.numberOverhead}>
                      <FormValue fieldText="Number down" valueText={pole.numberDown}></FormValue>
                    </FormValue>
                    <FormValue fieldText="Kind" valueText={pole.guyWireKind}>
                      <FormValue fieldText="Size" valueText={pole.guyWireSize}>
                        <FormValue fieldText="Class" valueText={pole.guyWireClass}></FormValue>
                      </FormValue>
                    </FormValue>
                  </View>
                )}
              </View>
            ))}
        </>
      )}
    </>
  );
};

OverheadWirelineSummary.propTypes = {
  appSummary: PropTypes.object.isRequired,
};

export default OverheadWirelineSummary;
