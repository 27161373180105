import commonConstants from '../constants/commonConstants';

const initialState = {
  lookups: {},
  lookupsLoaded: false,
  config: {},
  sendingHelpRequest: false,
  cycleTimeRptData: [],
  varianceRptData: [],
  paymentRptData: [],
  messageBannerHidden: false,
};

function common(state = initialState, action) {
  switch (action.type) {
    case commonConstants.getLookups:
      return { ...state, lookupsLoaded: false };
    case commonConstants.getLookupsSucceeded:
      return { ...state, lookups: action.lookups, lookupsLoaded: true };
    case commonConstants.getLookupsFailed:
      return { ...state };

    case commonConstants.getConfig:
      return state;
    case commonConstants.getConfigSucceeded:
      return { ...state, config: action.config };
    case commonConstants.getConfigFailed:
      return { ...state, config: {} };

    case commonConstants.sendHelpRequest:
      return { ...state, sendingHelpRequest: true };
    case commonConstants.sendHelpRequestSucceeded:
      return { ...state, sendingHelpRequest: false };
    case commonConstants.sendHelpRequestFailed:
      return { ...state, sendingHelpRequest: false };

    case commonConstants.hideMessageBanner:
      return { ...state, messageBannerHidden: true };

    default:
      return state;
  }
}

export default common;
