import documentConstants from '../constants/documentConstants';

const initialState = {
  downloadingAgreement: false,
  downloadingAllAttachments: false,
  applicantDownloadingSummary: false,
  downloadingInvoice: false,
};

function documents(state = initialState, action) {
  switch (action.type) {
    case documentConstants.downloadAgreement:
      return { ...state, downloadingAgreement: true };
    case documentConstants.downloadAgreementSuccess:
      return { ...state, downloadingAgreement: false };
    case documentConstants.downloadAgreementError:
      return { ...state, downloadingAgreement: false };

    case documentConstants.downloadAllAttachments:
      return { ...state, downloadingAllAttachments: true };
    case documentConstants.downloadAllAttachmentsSuccess:
      return { ...state, downloadingAllAttachments: false };
    case documentConstants.downloadAllAttachmentsError:
      return { ...state, downloadingAllAttachments: false };

    case documentConstants.applicantSummaryDownload:
      return { ...state, applicantDownloadingSummary: true };
    case documentConstants.applicantSummaryDownloadSuccess:
      return { ...state, applicantDownloadingSummary: false };
    case documentConstants.applicantSummaryDownloadError:
      return { ...state, applicantDownloadingSummary: false };

    case documentConstants.downloadInvoice:
      return { ...state, downloadingInvoice: true };
    case documentConstants.downloadInvoiceSuccess:
      return { ...state, downloadingInvoice: false };
    case documentConstants.downloadInvoiceError:
      return { ...state, downloadingInvoice: false };

    default:
      return state;
  }
}

export default documents;
