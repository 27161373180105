import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers/rootReducer';
import defaultSaga from '../sagas/sagas';

const middleware = [];

if (process.env.NODE_ENV === 'development') {
  middleware.push(createLogger());
}
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

const store = createStore(rootReducer, applyMiddleware(...middleware));

sagaMiddleware.run(defaultSaga);

if (window.Cypress) {
  window.store = store;
}

export default store;
