import reportConstants from '../constants/reportConstants';

function getCycleTimeRptData(startDate, endDate) {
  return { type: reportConstants.getCycleTimeRptData, startDate, endDate };
}

function getApplicationCycleTime(applicationId) {
  return { type: reportConstants.getApplicationCycleTime, applicationId };
}

function getVarianceRptData(startDate, endDate) {
  return { type: reportConstants.getVarianceRptData, startDate, endDate };
}

function getPaymentRptData(startDate, endDate) {
  return { type: reportConstants.getPaymentRptData, startDate, endDate };
}

function getSummaryRptData(startDate, endDate) {
  return { type: reportConstants.getSummaryRptData, startDate, endDate };
}

function getFinanceRptData(startDate, endDate) {
  return { type: reportConstants.getFinanceRptData, startDate, endDate };
}

function getReviewerBacklogData(reviewGroupValue) {
  return { type: reportConstants.getReviewerBacklogData, reviewGroupValue };
}

function getTerritoryAssignmentsData() {
  return { type: reportConstants.getTerritoryAssignmentsData };
}

function getFirstSubmissionData(startDate, endDate) {
  return { type: reportConstants.getFirstSubmissionData, startDate, endDate };
}

const reportActions = {
  getCycleTimeRptData,
  getApplicationCycleTime,
  getVarianceRptData,
  getPaymentRptData,
  getSummaryRptData,
  getFinanceRptData,
  getReviewerBacklogData,
  getTerritoryAssignmentsData,
  getFirstSubmissionData,
};

export default reportActions;
