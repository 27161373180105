import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Receipt from '../../Payment/Receipt';

export default function ReceiptDialog({
  open,
  handleClose,
  paymentApprovalResponse,
  paidFees,
  businessId,
}) {
  return (
    <Dialog
      open={open}
      onClose={(evt, reason) => {
        if (reason === 'backdropClick') {
          evt.preventDefault();
          return;
        }
        handleClose();
      }}
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">Receipt</DialogTitle>
      <DialogContent id="receipt-content">
        <Receipt
          paymentApprovalResponse={paymentApprovalResponse}
          businessId={businessId}
          paidFees={paidFees}
        />
      </DialogContent>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ReceiptDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  paymentApprovalResponse: PropTypes.object.isRequired,
  paidFees: PropTypes.array.isRequired,
  businessId: PropTypes.string.isRequired,
};
