import PropTypes from 'prop-types';
import { Page, Document, Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 30,
    paddingHorizontal: 30,
  },
});

function Invoice({ unpaidFees, businessId, railroad }) {
  let header = 'Rail Permitting';
  if (railroad === 'BNSF') {
    header = 'BNSF Railway Company';
  }

  const total = unpaidFees
    .reduce((prevValue, currentValue) => prevValue + currentValue.amount, 0)
    .toLocaleString('en-US', { style: 'currency', currency: 'USD' });

  return (
    <Document title={`Invoice ${businessId}`}>
      <Page size="LETTER" style={styles.page}>
        <View
          style={{
            height: '0.5in',
            border: '1px solid black',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '10px',
            paddingRight: '10px',
            flexDirection: 'row',
          }}
        >
          <Text>{header}</Text>
          <Text>Invoice</Text>
        </View>
        {railroad === 'BNSF' && (
          <>
            <View
              style={{
                height: '1.4in',
                border: '1px solid black',
                borderTop: 'none',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
                paddingLeft: '10px',
                paddingRight: '10px',
                flexDirection: 'column',
                fontSize: '12px',
              }}
            >
              <Text>MAKE CHECK PAYABLE TO:</Text>
              <Text>BNSF RAILWAY COMPANY</Text>
              <Text>C/O JONES LANG LASALLE</Text>
              <Text>2650 LOU MENK DRIVE – MOB2</Text>
              <Text>FORT WORTH, TX 76131</Text>
            </View>
            <View
              style={{
                height: '0.9in',
                border: '1px solid black',
                borderTop: 'none',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
                paddingLeft: '10px',
                paddingRight: '10px',
                flexDirection: 'column',
                fontSize: '12px',
              }}
            >
              <Text>To ensure proper credit of your account</Text>
              <Text>Please include a copy of this invoice with check payment</Text>
              <Text>Write the invoice number on your check</Text>
            </View>
          </>
        )}
        <View
          style={{
            height: '1.5in',
            border: '1px solid black',
            borderTop: 'none',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View style={{ flexBasis: '55%' }}></View>
          <View style={{ flexBasis: '45%', borderLeft: '1px sold black' }}>
            <View style={{ height: '0.5in', borderBottom: '1px solid black' }}>
              <Text style={{ fontSize: '12px', marginTop: '5px', marginLeft: '5px' }}>
                Invoice #
              </Text>
              <Text style={{ textAlign: 'center', fontSize: '11px' }}>{businessId}</Text>
            </View>
            <View style={{ height: '0.5in', borderBottom: '1px solid black' }}>
              <Text style={{ fontSize: '12px', marginTop: '5px', marginLeft: '5px' }}>
                Invoice Date
              </Text>
              <Text style={{ textAlign: 'center', fontSize: '11px' }}>
                {moment().local().format('MM/DD/YYYY')}
              </Text>
            </View>
            <View style={{ height: '0.5in' }}>
              <Text style={{ fontSize: '12px', marginTop: '5px', marginLeft: '5px' }}>
                Balance Due
              </Text>
              <Text style={{ textAlign: 'center', fontSize: '11px' }}>{total}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            height: '0.25in',
            border: '1px solid black',
            borderTop: 'none',
          }}
        />
        {unpaidFees.map((fee) => (
          <View
            style={{
              height: '0.25in',
              border: '1px solid black',
              borderTop: 'none',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View style={{ flexBasis: '55%', display: 'flex', justifyContent: 'center' }}>
              <Text style={{ fontSize: '11px', marginLeft: '5px' }}>{fee.feeLookup.name}</Text>
            </View>
            <View
              style={{
                flexBasis: '45%',
                borderLeft: '1px solid black',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Text style={{ fontSize: '11px', marginLeft: '5px' }}>
                {fee.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </Text>
            </View>
          </View>
        ))}
        <View
          style={{
            height: '0.25in',
            border: '1px solid black',
            borderTop: 'none',
          }}
        />
        <View
          style={{
            height: '0.25in',
            border: '1px solid black',
            borderTop: 'none',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View style={{ flexBasis: '55%', display: 'flex', justifyContent: 'center' }}>
            <Text style={{ fontSize: '11px', marginLeft: '5px' }}>Balance Due</Text>
          </View>
          <View
            style={{
              flexBasis: '45%',
              borderLeft: '1px solid black',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Text style={{ fontSize: '11px', marginLeft: '5px' }}>{total}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

Invoice.propTypes = {
  businessId: PropTypes.string.isRequired,
  unpaidFees: PropTypes.array.isRequired,
  railroad: PropTypes.string.isRequired,
};

export default Invoice;
