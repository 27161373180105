import React from 'react';
import PropTypes from 'prop-types';
import PipelineSummary from './ApplicationSummaries/PipelineSummary';
import WirelineSummary from './ApplicationSummaries/WirelineSummary';
import RoadwaySurfacingSummary from './ApplicationSummaries/RoadwaySurfacingSummary';
import TemporaryOccupancySummary from './ApplicationSummaries/TemporaryOccupancySummary';
import PrivateCrossingSummary from './ApplicationSummaries/PrivateCrossingSummary';
import OversizeLoadMoveSummary from './ApplicationSummaries/OversizeLoadMoveSummary';
import GeneralLicenseSummary from './ApplicationSummaries/GeneralLicenseSummary';
import EnvironmentalRightOfWaySummary from './ApplicationSummaries/EnvironmentalRightOfWaySummary';
import AssignmentSummary from './ApplicationSummaries/AssignmentSummary';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 55,
    paddingHorizontal: 35,
  },
});

function SummaryDocument({ appSummary, history }) {
  let summaryDoc = null;
  switch (appSummary?.permitTypeValue) {
    case 'PIPELINE':
      summaryDoc = <PipelineSummary appSummary={appSummary} history={history} />;
      break;
    case 'WIRELINE':
      summaryDoc = <WirelineSummary appSummary={appSummary} history={history} />;
      break;
    case 'ROADWAYSURFACING':
      summaryDoc = <RoadwaySurfacingSummary appSummary={appSummary} history={history} />;
      break;
    case 'TEMPORARYOCCUPANCY':
      summaryDoc = <TemporaryOccupancySummary appSummary={appSummary} history={history} />;
      break;
    case 'PRIVATECROSSING':
      summaryDoc = <PrivateCrossingSummary appSummary={appSummary} history={history} />;
      break;
    case 'OVERSIZELOADMOVE':
      summaryDoc = <OversizeLoadMoveSummary appSummary={appSummary} history={history} />;
      break;
    case 'GENERALLICENSE':
      summaryDoc = <GeneralLicenseSummary appSummary={appSummary} history={history} />;
      break;
    case 'ENVIRONMENTALRIGHTOFWAY':
      summaryDoc = <EnvironmentalRightOfWaySummary appSummary={appSummary} history={history} />;
      break;
    case 'ASSIGNMENT':
      summaryDoc = <AssignmentSummary appSummary={appSummary} history={history} />;
      break;
    default:
      summaryDoc = null;
      break;
  }

  return (
    <Document title="Pipline">
      <Page size="LETTER" style={styles.page}>
        {summaryDoc}
      </Page>
    </Document>
  );
}

SummaryDocument.propTypes = {
  appSummary: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
};

export default SummaryDocument;
