import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Collapse, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Typography from './Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import commonActions from '../../actions/commonActions';

const MessageBanner = ({ classes, message, type, style, bannerColor }) => {
  const theme = useTheme();
  let backgroundColor = null;
  const dispatch = useDispatch();
  const { messageBannerHidden } = useSelector((state) => state.common);

  switch (type) {
    case 'Warning':
      backgroundColor = theme.palette.warning.main;
      break;
    case 'Error':
      backgroundColor = theme.palette.error.main;
      break;
    default:
      break;
  }
  if (bannerColor) {
    backgroundColor = bannerColor;
  }
  return (
    <Collapse in={!messageBannerHidden} unmountOnExit collapsedSize={0} style={style}>
      <Grid container className={classes.messageBanner} style={{ backgroundColor }}>
        <Grid item xs={11} style={{ display: 'flex' }}>
          <Typography variant="h6" component="p">
            {message}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => dispatch(commonActions.hideMessageBanner())}
            style={{ float: 'left' }}
            size="large"
          >
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Collapse>
  );
};

const styles = (theme) => ({
  messageBanner: {
    padding: '5px 20px',
    position: 'static',
    width: '100%',
    display: 'flex',
    zIndex: 1100,
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

MessageBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['Warning', 'Error']),
  style: PropTypes.object,
  bannerColor: PropTypes.string,
};

MessageBanner.defaultProps = {
  type: 'Warning',
  style: null,
  bannerColor: null,
};

export default withStyles(styles)(MessageBanner);
