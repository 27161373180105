import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import MaterialReactTable from 'material-react-table';
import makeStyles from '@mui/styles/makeStyles';
import withRoot from '../modules/withRoot';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AppAppBar from '../modules/views/AppAppBar';
import reportActions from '../actions/reportActions';
import { getDateOffset } from '../helpers/utils';
import Title from '../ProcessApplication/Title';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(4),
  },
  textField: {
    marginRight: 10,
  },
}));

function prepareReportData(data) {
  let nextFakeId = Number.MAX_SAFE_INTEGER;

  const tableData = [];

  data.forEach((item) => {
    let varianceIndex = tableData.findIndex(function (element) {
      return element.variance === item.specName;
    });

    if (varianceIndex === -1) {
      const newNode = {
        varianceId_: --nextFakeId,
        variance: item.specName,
        subRows: [],
      };
      varianceIndex = tableData.push(newNode) - 1;
    }

    const parentRow = tableData[varianceIndex];

    const subRowData = {
      varianceId_: item.varianceId,
      applicationId_: item.applicationId,
      application: item.permitType + '_' + getDateString(item.submittalDateTime, 0),
      applicant: item.applicant,
      status: item.status,
      value: item.formValue,
      explanation: item.explanation,
    };
    parentRow.subRows.push(subRowData);
  });

  return tableData.sort((a, b) => a.applicant - b.applicant);
}

const getDateString = (inDate, offset) => {
  const d = new Date(inDate);
  d.setDate(d.getDate() + offset);
  const dstring = d.toISOString().split('T')[0];

  return dstring;
};

function VarianceRpt({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialStartDate = getDateOffset(-60);
  const initialEndDate = getDateOffset(0);
  useEffect(() => {
    dispatch(reportActions.getVarianceRptData(initialStartDate, initialEndDate));
  }, [dispatch, initialEndDate, initialStartDate]);
  const [dateExtents, setDateExtents] = useState({
    begin: initialStartDate,
    end: initialEndDate,
  });

  const reportData = useSelector((state) => state.reports.varianceRptData);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'variance',
        header: 'Variance',
      },
      {
        accessorKey: 'applicant',
        header: 'Applicant',
      },
      {
        accessorKey: 'application',
        header: 'Application',
      },
      {
        accessorKey: 'status',
        header: 'Status',
      },
      {
        accessorKey: 'value',
        header: 'Value',
      },
      {
        accessorKey: 'explanation',
        header: 'Explanation',
      },
    ],
    []
  );

  return (
    <>
      <AppAppBar pathname={location.pathname} />
      <main className={classes.content}>
        <Container className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="fromDate"
                label="Begin Date"
                type="date"
                defaultValue={getDateOffset(-60)}
                className={classes.textField}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, begin: event.target.value };
                  setDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="toDate"
                label="End Date"
                type="date"
                defaultValue={getDateOffset(0)}
                className={classes.textField}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, end: event.target.value };
                  setDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  dispatch(reportActions.getVarianceRptData(dateExtents.begin, dateExtents.end));
                }}
              >
                Get Variance Data
              </Button>
            </Grid>
            <Grid item xs={12}>
              <MaterialReactTable
                columns={columns}
                data={prepareReportData(reportData)}
                enableStickyHeader
                enableGlobalFilter={false}
                enableExpanding
                enablePagination={false}
                getSubRows={(originalRow) => originalRow.subRows}
                filterFromLeafRows
                renderTopToolbarCustomActions={() => {
                  return (
                    <Title>
                      <span style={{ fontSize: '1.2rem', marginRight: 20 }}>Variance Report</span>
                    </Title>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}

VarianceRpt.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRoot(VarianceRpt);
