import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './helpers/store';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// import * as serviceWorker from './serviceWorker';
import theme from './modules/theme';
import './App.css';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig, b2cPolicies } from './Auth/authConfig';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }

  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
    event.payload.account
  ) {
    /**
     * For the purpose of setting an active account for UI update, we want to consider only the auth
     * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
     * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
     * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
     */
    if (
      (event.payload.idTokenClaims['acr'].toUpperCase() ===
        b2cPolicies.names.editProfile.toUpperCase() ||
        event.payload.idTokenClaims['acr'].toUpperCase() ===
          b2cPolicies.names.changePassword.toUpperCase() ||
        event.payload.idTokenClaims['acr'].toUpperCase() ===
          b2cPolicies.names.changeSigninName.toUpperCase()) &&
      // eslint-disable-next-line prettier/prettier
      msalInstance.getAllAccounts().length > 1
    ) {
      // retrieve the account from initial sing-in to the app
      const originalSignInAccount = msalInstance
        .getAllAccounts()
        .find(
          (account) =>
            account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
            account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
            account.idTokenClaims['acr'].toUpperCase() ===
              b2cPolicies.names.signUpSignIn.toUpperCase()
        );
      if (originalSignInAccount) {
        msalInstance.setActiveAccount(originalSignInAccount);
      }
    }

    /**
     * Below we are checking if the user is returning from the reset password flow.
     * If so, we will ask the user to reauthenticate with their new password.
     * If you do not want this behavior and prefer your users to stay signed in instead,
     * you can replace the code below with the same pattern used for handling the return from
     * profile edit flow
     */
    // if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
    //   const signUpSignInFlowRequest = {
    //     authority: b2cPolicies.authorities.signUpSignIn.authority,
    //   };
    //   instance.loginRedirect(signUpSignInFlowRequest);
    // }
  }
});

// eslint-disable-next-line no-console
console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// Uncomment the line above that imports the serviceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
// serviceWorker.unregister();
