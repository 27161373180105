import formConstants from '../constants/formConstants';

function updateApplication(application, resolve, reject) {
  return { type: formConstants.updateApplication, application, resolve, reject };
}

function updateApplicationDetail(applicationDetail, resolve, reject) {
  return { type: formConstants.updateApplicationDetail, applicationDetail, resolve, reject };
}

function updateLocationDetail(locationDetail, resolve, reject, populateEngDivision = false) {
  return {
    type: formConstants.updateLocationDetail,
    locationDetail,
    resolve,
    reject,
    populateEngDivision,
  };
}

function updatePipeline(pipeline, resolve, reject) {
  return { type: formConstants.updatePipeline, pipeline, resolve, reject };
}

function updatePipelineCasing(pipelineCasing, resolve, reject) {
  return { type: formConstants.updatePipelineCasing, pipelineCasing, resolve, reject };
}

function updatePipelineCarrier(pipelineCarrier, resolve, reject) {
  return { type: formConstants.updatePipelineCarrier, pipelineCarrier, resolve, reject };
}

function updateWireline(wireline, resolve, reject) {
  return { type: formConstants.updateWireline, wireline, resolve, reject };
}

function updateUndergroundWireline(undergroundWireline, resolve, reject) {
  return { type: formConstants.updateUndergroundWireline, undergroundWireline, resolve, reject };
}

function updateOverheadWireline(overheadWireline, resolve, reject) {
  return { type: formConstants.updateOverheadWireline, overheadWireline, resolve, reject };
}

function updatePole(pole, resolve, reject) {
  return { type: formConstants.updatePole, pole, resolve, reject };
}

function updateConduit(conduit, resolve, reject) {
  return { type: formConstants.updateConduit, conduit, resolve, reject };
}

function updateContract(contract, resolve, reject) {
  return { type: formConstants.updateContract, contract, resolve, reject };
}

function updateVariances(applicationId, variances, resolve, reject) {
  return { type: formConstants.updateVariances, applicationId, variances, resolve, reject };
}

function submitApplication(applicationId, resolve, reject) {
  return { type: formConstants.submitApplication, applicationId, resolve, reject };
}

function updateTemporaryOccupancy(temporaryOccupancy, resolve, reject) {
  return { type: formConstants.updateTemporaryOccupancy, temporaryOccupancy, resolve, reject };
}

function updatePrivateCrossing(privateCrossing, resolve, reject) {
  return { type: formConstants.updatePrivateCrossing, privateCrossing, resolve, reject };
}

function updateRoadwaySurfacing(roadwaySurfacing, resolve, reject) {
  return { type: formConstants.updateRoadwaySurfacing, roadwaySurfacing, resolve, reject };
}

function updateGeneralLicense(generalLicense, resolve, reject) {
  return { type: formConstants.updateGeneralLicense, generalLicense, resolve, reject };
}

function updateOversizeLoadMove(oversizeLoadMove, resolve, reject) {
  return {
    type: formConstants.updateOversizeLoadMove,
    oversizeLoadMove: oversizeLoadMove,
    resolve,
    reject,
  };
}

function updateAssignment(assignment, resolve, reject) {
  return { type: formConstants.updateAssignment, assignment, resolve, reject };
}

function updateEnvironmental(environmental, resolve, reject) {
  return { type: formConstants.updateEnvironmental, environmental, resolve, reject };
}

const formUpdateActions = {
  updateApplication,
  updateApplicationDetail,
  updateLocationDetail,
  updatePipeline,
  updatePipelineCasing,
  updatePipelineCarrier,
  updateWireline,
  updateUndergroundWireline,
  updateOverheadWireline,
  updatePole,
  updateConduit,
  updateVariances,
  submitApplication,
  updateTemporaryOccupancy,
  updatePrivateCrossing,
  updateRoadwaySurfacing,
  updateGeneralLicense,
  updateOversizeLoadMove: updateOversizeLoadMove,
  updateAssignment,
  updateEnvironmental,
  updateContract,
};

export default formUpdateActions;
