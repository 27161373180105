import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AccountButton from '../modules/views/AccountButton';

function Header() {
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <AccountButton />
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {};

export default Header;
