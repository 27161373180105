import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import homeActions from '../../actions/homeActions';
import { DialogContentText } from '@mui/material';

export default function DeleteApplicationDialog({ open, handleClose, applicationId, businessId }) {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Delete?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete application {businessId}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          No
        </Button>
        <Button
          onClick={() => {
            dispatch(homeActions.deleteApplication(applicationId));
            handleClose();
          }}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteApplicationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  applicationId: PropTypes.number.isRequired,
  businessId: PropTypes.string.isRequired,
};
