import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress } from '@mui/material';
import theme from '../../../modules/theme';
import { getLookup, updateWirelinePermitType } from '../../../helpers/utils';
import Legend from '@arcgis/core/widgets/Legend.js';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer.js';
import esriConfig from '@arcgis/core/config';
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle.js';
import Search from '@arcgis/core/widgets/Search.js';
import MapView from '@arcgis/core/views/MapView';
import Color from '@arcgis/core/Color.js';
import Map from '@arcgis/core/Map.js';

const useStyles = makeStyles(() => ({
  progress: {
    position: 'absolute',
    top: '45%',
    right: '35%',
    zIndex: 10000,
  },
}));

function getSymbolCategory(permitTypeValue) {
  switch (permitTypeValue) {
    case 'PIPELINE':
      return permitTypeValue;
    case 'WIRELINE':
      return permitTypeValue;
    default:
      return 'OTHER';
  }
}

const layerId = 'applications';

function createFeatures(applications, lookups) {
  const { permitTypes, applicationStatuses } = lookups;

  let features = applications.map((a) => {
    let permitTypeLU = getLookup(permitTypes, a.permitTypeId);
    const statusLU = getLookup(applicationStatuses, a.applicationStatusId);
    let cityState = '';
    if (a.locationDetail && a.locationDetail.nearestTown) {
      cityState = `${a.locationDetail.nearestTown}, ${a.locationDetail.state}`;
    } else if (a.locationDetail && a.locationDetail.state) {
      cityState = a.locationDetail.state;
    }

    if (permitTypeLU.value === 'WIRELINE') {
      permitTypeLU = updateWirelinePermitType(permitTypeLU, a.wireline);
    }

    return {
      geometry: {
        type: 'point',
        x: a.locationDetail?.longitude,
        y: a.locationDetail?.latitude,
      },
      attributes: {
        ObjectId: a.applicationId,
        BusinessId: a.businessId,
        PermitType: permitTypeLU.name,
        SymbolCategory: getSymbolCategory(permitTypeLU.value),
        Status: statusLU.name,
        SubmittalDate: moment.utc(a.submittalDateTime).local().format('YYYY-MM-DD'),
        Location: cityState,
        CompanyName: a.applicationDetail?.legalName,
      },
    };
  });
  features = features.filter((f) => f.geometry.x !== null && f.geometry.y !== null);

  return features;
}

export default function ApplicationsMap(props) {
  const mapViewContainerRef = React.useRef(null);
  const classes = useStyles();
  const { lookups, esriApiKey } = useSelector((state) => state.common);
  const { gettingApplications } = useSelector((state) => state.home);
  const { applications, searchTerm } = props;

  const [view, setView] = React.useState(null);
  const [applicationsLayer, setApplicationsLayer] = React.useState(null);
  const [creatingLayer, setCreatingLayer] = React.useState(false);
  const [viewUpdating, setViewUpdating] = React.useState(false);

  //Recreate layer if applications list changes
  React.useEffect(() => {
    if (view === null) {
      return;
    }
    const features = createFeatures(applications, lookups);
    if (features.length === 0) {
      return;
    }

    const createLayer = async (features) => {
      setCreatingLayer(true);
      setApplicationsLayer(null);
      const existingLayer = view.map.findLayerById(layerId);
      if (existingLayer) {
        view.map.remove(existingLayer);
      }
      const layer = new FeatureLayer({
        id: layerId,
        source: features,
        objectIdField: 'ObjectId',
        fields: [
          {
            name: 'ObjectId',
            alias: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'SymbolCategory',
            alias: 'SymbolCategory',
            type: 'string',
          },
          {
            name: 'PermitType',
            alias: 'Permit Type',
            type: 'string',
          },
          {
            name: 'BusinessId',
            alias: 'Business Id',
            type: 'string',
          },
          {
            name: 'Status',
            alias: 'Status',
            type: 'string',
          },
          {
            name: 'SubmittalDate',
            alias: 'Submittal Date',
            type: 'string',
          },
          {
            name: 'Location',
            alias: 'Location',
            type: 'string',
          },
          {
            name: 'CompanyName',
            alias: 'Company Name',
            type: 'string',
          },
        ],
        popupTemplate: {
          title: '{PermitType} ({BusinessId})',
          content: [
            {
              type: 'fields',
              fieldInfos: [
                {
                  fieldName: 'Status',
                  label: 'Status',
                },
                {
                  fieldName: 'CompanyName',
                  label: 'Company Name',
                },
                {
                  fieldName: 'Location',
                  label: 'Location',
                },
                {
                  fieldName: 'SubmittalDate',
                  label: 'SubmittalDate',
                },
              ],
            },
            {
              type: 'text',
              text: `<a href="${window.location.origin}/processapplication/{ObjectId}" target="_blank">Open Application</a>`,
            },
          ],
        },
        renderer: {
          type: 'unique-value',
          legendOptions: {
            title: 'Permit Type',
          },
          field: 'SymbolCategory',
          uniqueValueInfos: [
            {
              value: 'PIPELINE',
              symbol: {
                type: 'simple-marker',
                size: '10px',
                color: 'blue',
              },
            },
            {
              value: 'WIRELINE',
              symbol: {
                type: 'simple-marker',
                size: '10px',
                color: 'yellow',
              },
            },
            {
              value: 'OTHER',
              symbol: {
                type: 'simple-marker',
                size: '10px',
                color: 'red',
              },
            },
          ],
        },
      });
      view.map.add(layer);
      setApplicationsLayer(layer);
      setCreatingLayer(false);
    };
    createLayer(features).catch((e) => {
      setCreatingLayer(false);
      console.error(e);
    });
  }, [view, applications, lookups]);

  // Update graphic visibility if searchTerm changes
  React.useEffect(() => {
    if (view === null || applicationsLayer === null) {
      return;
    }
    let definitionExpression = '';
    if (searchTerm) {
      applicationsLayer.fields.forEach((f) => {
        if (f.type === 'string') {
          definitionExpression += `${definitionExpression.length ? ' OR ' : ''} upper(${
            f.name
          }) like '%${searchTerm.toUpperCase()}%'`;
        }
      });
    }
    applicationsLayer.definitionExpression = definitionExpression;
  }, [searchTerm, applicationsLayer, view]);

  // Initialize map
  React.useEffect(() => {
    if (!mapViewContainerRef.current) {
      return;
    }
    esriConfig.apiKey = esriApiKey;
    const map = new Map({
      basemap: 'streets-vector',
    });

    const view = new MapView({
      container: mapViewContainerRef.current,
      map: map,
      center: [-95.71, 37.09],
      zoom: 3,
      highlightOptions: {
        color: new Color(theme.palette.secondary.main),
        fillOpacity: 0.25,
        haloOpacity: 1,
      },
    });
    const basemapToggle = new BasemapToggle({
      view,
      visibleElements: { title: true },
      nextBasemap: 'hybrid',
    });
    const searchWidget = new Search({
      view: view,
    });
    view.ui.add(basemapToggle, 'bottom-right');
    view.ui.add(searchWidget, 'top-right');
    view.ui.add(new Legend({ view }), 'bottom-left');

    view.when(() => {
      view.on('click', async (event) => {
        const response = await view.hitTest(event);
        if (response.results.length && response.results[0].graphic.layer.type === 'feature') {
          const graphic = response.results[0].graphic;
          view.popup.open({
            location: graphic.geometry.centroid,
            features: [graphic],
          });
        }
      });
      view.watch('updating', (updating) => {
        if (updating === false) {
          setViewUpdating(updating);
        }
        if (view.interacting === false && updating === true) {
          setViewUpdating(updating);
        }
      });
      setView(view);
    });
  }, [esriApiKey]);

  return (
    <div style={{ width: '100%', height: 'calc(100vh - 300px)', marginTop: 15 }}>
      {(gettingApplications || creatingLayer || viewUpdating) && (
        <CircularProgress className={classes.progress} size={90} />
      )}
      <div ref={mapViewContainerRef} style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
}

ApplicationsMap.propTypes = {
  applications: PropTypes.array,
  searchTerm: PropTypes.string,
};

ApplicationsMap.defaultProps = {
  applications: [],
  searchTerm: null,
};
