function hasClaim(claim, claims) {
  return claims.find((c) => c.claimValue === claim);
}

function canAddReviewer(
  reviewStatusValue,
  permitManagerReviewStatusValue,
  requiredClaim,
  userClaims
) {
  if (!reviewStatusValue || !permitManagerReviewStatusValue || !Array.isArray(userClaims))
    return false;
  return (
    reviewStatusValue === 'UNASSIGNED' &&
    permitManagerReviewStatusValue === 'PENDINGREVIEW' &&
    hasClaim(requiredClaim, userClaims)
  );
}

function canRemoveReviewer(
  reviewStatusValue,
  permitManagerReviewStatusValue,
  requiredClaim,
  userClaims
) {
  if (!reviewStatusValue || !permitManagerReviewStatusValue || !Array.isArray(userClaims))
    return false;
  return (
    reviewStatusValue === 'PENDINGREVIEW' &&
    permitManagerReviewStatusValue === 'PENDINGREVIEW' &&
    hasClaim(requiredClaim, userClaims)
  );
}

const rules = {
  'document:delete': (userClaims) => {
    return hasClaim('CanRemoveAnyDocument', userClaims);
  },
  'insuranceReviewer:add': (userClaims, { reviewStatusValue, permitManagerReviewStatusValue }) => {
    return canAddReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanAddReviewers',
      userClaims
    );
  },
  'insuranceReviewer:remove': (
    userClaims,
    { reviewStatusValue, permitManagerReviewStatusValue }
  ) => {
    return canRemoveReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanRemoveReviewers',
      userClaims
    );
  },
  'exhibitReviewer:add': (userClaims, { reviewStatusValue, permitManagerReviewStatusValue }) => {
    return canAddReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanAddReviewers',
      userClaims
    );
  },
  'exhibitReviewer:remove': (userClaims, { reviewStatusValue, permitManagerReviewStatusValue }) => {
    return canRemoveReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanRemoveReviewers',
      userClaims
    );
  },
  'realEstateReviewer:add': (userClaims, { reviewStatusValue, permitManagerReviewStatusValue }) => {
    return canAddReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanAddReviewers',
      userClaims
    );
  },
  'realEstateReviewer:remove': (
    userClaims,
    { reviewStatusValue, permitManagerReviewStatusValue }
  ) => {
    return canRemoveReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanRemoveReviewers',
      userClaims
    );
  },
  'legalReviewer:add': (userClaims, { reviewStatusValue, permitManagerReviewStatusValue }) => {
    return canAddReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanAddReviewers',
      userClaims
    );
  },
  'legalReviewer:remove': (userClaims, { reviewStatusValue, permitManagerReviewStatusValue }) => {
    return canRemoveReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanRemoveReviewers',
      userClaims
    );
  },
  'engineeringReviewer:add': (
    userClaims,
    { reviewStatusValue, permitManagerReviewStatusValue }
  ) => {
    return canAddReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanAddReviewers',
      userClaims
    );
  },
  'engineeringReviewer:remove': (
    userClaims,
    { reviewStatusValue, permitManagerReviewStatusValue }
  ) => {
    return canRemoveReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanRemoveReviewers',
      userClaims
    );
  },
  'geotechnicalReviewer:add': (
    userClaims,
    { reviewStatusValue, permitManagerReviewStatusValue }
  ) => {
    return canAddReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanAddReviewers',
      userClaims
    );
  },
  'geotechnicalReviewer:remove': (
    userClaims,
    { reviewStatusValue, permitManagerReviewStatusValue }
  ) => {
    return canRemoveReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanRemoveReviewers',
      userClaims
    );
  },
  'environmentalReviewer:add': (
    userClaims,
    { reviewStatusValue, permitManagerReviewStatusValue }
  ) => {
    return canAddReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanAddReviewers',
      userClaims
    );
  },
  'environmentalReviewer:remove': (
    userClaims,
    { reviewStatusValue, permitManagerReviewStatusValue }
  ) => {
    return canRemoveReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanRemoveReviewers',
      userClaims
    );
  },
  'telecomReviewer:add': (userClaims, { reviewStatusValue, permitManagerReviewStatusValue }) => {
    return canAddReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanAddReviewers',
      userClaims
    );
  },
  'telecomReviewer:remove': (userClaims, { reviewStatusValue, permitManagerReviewStatusValue }) => {
    return canRemoveReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanRemoveReviewers',
      userClaims
    );
  },
  'railroadReviewer:add': (userClaims, { reviewStatusValue, permitManagerReviewStatusValue }) => {
    return canAddReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanAddReviewers',
      userClaims
    );
  },
  'railroadReviewer:remove': (
    userClaims,
    { reviewStatusValue, permitManagerReviewStatusValue }
  ) => {
    return canRemoveReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanRemoveReviewers',
      userClaims
    );
  },
  'signalReviewer:add': (userClaims, { reviewStatusValue, permitManagerReviewStatusValue }) => {
    return canAddReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanAddReviewers',
      userClaims
    );
  },
  'signalReviewer:remove': (userClaims, { reviewStatusValue, permitManagerReviewStatusValue }) => {
    return canRemoveReviewer(
      reviewStatusValue,
      permitManagerReviewStatusValue,
      'CanRemoveReviewers',
      userClaims
    );
  },
};

export default rules;
