import alertConstants from '../constants/alertConstants';

const initialState = {};

function alert(state = initialState, action) {
  switch (action.type) {
    case alertConstants.success:
      return {
        type: 'success',
        message: action.message,
      };
    case alertConstants.error:
      return {
        type: 'error',
        message: action.message,
      };
    case alertConstants.warning:
      return {
        type: 'warning',
        message: action.message,
      };
    case alertConstants.info:
      return {
        type: 'info',
        message: action.message,
      };
    case alertConstants.clear:
      return {};
    default:
      return state;
  }
}

export default alert;
