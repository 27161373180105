import documentConstants from '../constants/documentConstants';

function uploadDocuments(applicationId, documents) {
  return { type: documentConstants.uploadDocuments, applicationId, documents };
}

function deleteDocument(documentId) {
  return { type: documentConstants.deleteDocument, documentId };
}

function downloadDocument(documentId, filename) {
  return { type: documentConstants.downloadDocument, documentId, filename };
}

function downloadAgreement(applicationId, selectedTemplateId) {
  return { type: documentConstants.downloadAgreement, applicationId, selectedTemplateId };
}

function getDraftExhibit(application, lookups) {
  return { type: documentConstants.getDraftExhibit, application, lookups };
}

function downloadAllAttachments(applicationId) {
  return { type: documentConstants.downloadAllAttachments, applicationId };
}

function applicantSummaryDownload(applicationId) {
  return { type: documentConstants.applicantSummaryDownload, applicationId };
}

function downloadInvoice(businessId, unpaidFees, railroad) {
  return { type: documentConstants.downloadInvoice, businessId, unpaidFees, railroad };
}

const documentActions = {
  uploadDocuments,
  deleteDocument,
  downloadDocument,
  downloadAgreement,
  getDraftExhibit,
  downloadAllAttachments,
  applicantSummaryDownload,
  downloadInvoice,
};

export default documentActions;
