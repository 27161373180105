import React from 'react';
import PropTypes from 'prop-types';
import FormValue from '../Components/FormValue';
import SectionHeader from '../Components/SectionHeader';
import Applicant from '../Components/Applicant';
import ApplicantDetails from '../Components/ApplicantDetails';
import ThirdPartyInstaller from '../Components/ThirdPartyInstaller';
import LocationDetails from '../Components/LocationDetails';
import ApplicationDetails from '../Components/ApplicationDetails';
import SummaryTable from '../Components/SummaryTable';
import VariancesTable from '../Components/VariancesTable';
import SummaryLayout from '../Components/SummaryLayout';

function prepareCarrierCasingData(appSummary) {
  const carrierCasingData = [
    [
      'Inside diameter of pipe',
      `${appSummary.pipeline.pipelineCarrier.pipeInnerDiameter} in.`,
      appSummary.pipeline.pipelineCasing
        ? `${appSummary.pipeline.pipelineCasing.pipeInnerDiameter} in.`
        : '',
    ],
    [
      'Pipe material',
      appSummary.pipeline.pipelineCarrier.pipelineCarrierMaterial
        ? appSummary.pipeline.pipelineCarrier.pipelineCarrierMaterial
        : appSummary.pipeline.pipelineCarrier.otherPipeMaterial,
      appSummary.pipeline.pipelineCasing
        ? appSummary.pipeline.pipelineCasing.pipelineCasingMaterial
          ? appSummary.pipeline.pipelineCasing.pipelineCasingMaterial
          : appSummary.pipeline.pipelineCasing.otherPipeMaterial
        : '',
    ],
    [
      'Specification & grade',
      appSummary.pipeline.pipelineCarrier.specificationGrade,
      appSummary.pipeline.pipelineCasing
        ? appSummary.pipeline.pipelineCasing.specificationGrade
        : '',
    ],
    [
      'Wall thickness',
      `${appSummary.pipeline.pipelineCarrier.wallThickness} in.`,
      appSummary.pipeline.pipelineCasing
        ? `${appSummary.pipeline.pipelineCasing.wallThickness} in.`
        : '',
    ],
    [
      'Actual working pressure',
      `${appSummary.pipeline.pipelineCarrier.workingPressure} psi`,
      appSummary.pipeline.pipelineCasing
        ? `${appSummary.pipeline.pipelineCasing.workingPressure} psi`
        : '',
    ],
    [
      'Type of joint',
      appSummary.pipeline.pipelineCarrier.pipelineJointType,
      appSummary.pipeline.pipelineCasing
        ? appSummary.pipeline.pipelineCasing.pipelineJointType
        : '',
    ],
    [
      'Coating',
      appSummary.pipeline.pipelineCarrier.coatingDescription,
      appSummary.pipeline.pipelineCasing
        ? appSummary.pipeline.pipelineCasing.coatingDescription
        : '',
    ],
    [
      'Distance from base of rail to top of pipe',
      appSummary.pipeline.pipelineCarrier.railToPipeFt &&
        `${appSummary.pipeline.pipelineCarrier.railToPipeFt} ft`,
      appSummary.pipeline.pipelineCasing
        ? appSummary.pipeline.pipelineCasing.railToPipeFt &&
          `${appSummary.pipeline.pipelineCasing.railToPipeFt} ft`
        : '',
    ],
    [
      'Minimum ground cover on property',
      appSummary.pipeline.pipelineCarrier.minimumGroundCover &&
        `${appSummary.pipeline.pipelineCarrier.minimumGroundCover} ft`,
      appSummary.pipeline.pipelineCasing
        ? appSummary.pipeline.pipelineCasing.minimumGroundCover &&
          `${appSummary.pipeline.pipelineCasing.minimumGroundCover} ft`
        : '',
    ],
    [
      'Cathodic protection',
      appSummary.pipeline.pipelineCarrier.cathodicProtection ? 'Yes' : 'No',
      appSummary.pipeline.pipelineCasing
        ? appSummary.pipeline.pipelineCasing.cathodicProtection
          ? 'Yes'
          : 'No'
        : '',
    ],
  ];

  return carrierCasingData;
}

const PipelineSummary = ({ appSummary, history }) => {
  const carrierCasingData = prepareCarrierCasingData(appSummary);

  return (
    <SummaryLayout
      appSummary={appSummary}
      history={history}
      headerTitle={`Application for ${appSummary.permitType} - ${appSummary.pipeline.encroachmentType}`}
    >
      <Applicant appSummary={appSummary} />
      <ApplicantDetails appSummary={appSummary} />
      <ThirdPartyInstaller appSummary={appSummary} />
      <ApplicationDetails appSummary={appSummary} />
      <LocationDetails appSummary={appSummary} />
      <SectionHeader headerText="Pipeline" />
      <FormValue
        fieldText="Method of installation"
        valueText={
          appSummary.pipeline.installationMethod
            ? appSummary.pipeline.installationMethod
            : appSummary.pipeline.otherPipelineInstallationMethod
        }
      />
      <FormValue
        fieldText="Contents to be handled through the pipeline"
        valueText={
          appSummary.pipeline.pipelineCommoditySubtype
            ? appSummary.pipeline.pipelineCommoditySubtype
            : appSummary.pipeline.otherPipelineCommodity
        }
      />
      <FormValue fieldText="Bore in ROW" valueText={appSummary.pipeline.boreInRW} />
      <FormValue
        fieldText="Bore distance to track"
        valueText={appSummary.pipeline.boreDistanceToTrack}
        unitsLabel="ft"
      />
      <FormValue
        fieldText="Length of pipe on railroad property"
        valueText={appSummary.pipeline.pipeLength}
        unitsLabel="ft"
      />
      <FormValue
        fieldText="Angle of crossing"
        valueText={appSummary.pipeline.crossingAngle}
        hideIfEmpty
      />
      <SummaryTable headers={['', 'CARRIER', 'CASING']} data={carrierCasingData} />
      <FormValue
        fieldText="Type of insulators or support"
        valueText={appSummary.pipeline.pipelineCasing?.typeOfInsulatorSupport || 'None'}
      >
        <FormValue
          fieldText="Size"
          valueText={appSummary.pipeline.pipelineCasing?.insulatorSupportSize}
          unitsLabel="in."
          hideIfEmpty
        >
          <FormValue
            fieldText="Spacing"
            valueText={appSummary.pipeline.pipelineCasing?.insulatorSupportSpacing}
            unitsLabel="in."
            hideIfEmpty
          />
        </FormValue>
      </FormValue>
      <FormValue
        fieldText="Number of vents"
        valueText={appSummary.pipeline.pipelineCasing?.ventNumber || 0}
      >
        <FormValue
          fieldText="Size"
          valueText={appSummary.pipeline.pipelineCasing?.ventSize}
          unitsLabel="in."
          hideIfEmpty
        >
          <FormValue
            fieldText="Height above ground"
            valueText={appSummary.pipeline.pipelineCasing?.ventHeight}
            unitsLabel="ft"
            hideIfEmpty
          />
        </FormValue>
      </FormValue>
      <FormValue
        fieldText="Does pipeline support an oil or gas well"
        valueText={appSummary.pipeline.pipelineCarrier.pipelineSupportsOilGasWell}
      >
        <FormValue
          fieldText="Distance from RR property"
          valueText={appSummary.pipeline.pipelineCarrier.wellDistanceFromPropertyFt}
          unitsLabel="ft"
          hideIfEmpty
        >
          <FormValue
            fieldText="Well name"
            valueText={appSummary.pipeline.pipelineCarrier.wellName}
            hideIfEmpty
          />
        </FormValue>
      </FormValue>
      <FormValue fieldText="Abandoning pipes" valueText={appSummary.pipeline.abandoningPipes}>
        <FormValue
          fieldText="Abandonments are under agreement"
          valueText={appSummary.pipeline.pipelineCarrier.abandonmentsUnderAgreement}
          hideIfEmpty
        />
      </FormValue>
      <VariancesTable variances={appSummary.variances} />
    </SummaryLayout>
  );
};

PipelineSummary.propTypes = {
  appSummary: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
};

export default PipelineSummary;
