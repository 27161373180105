import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as moment from 'moment';
import MaterialReactTable from 'material-react-table';
import makeStyles from '@mui/styles/makeStyles';
import withRoot from '../modules/withRoot';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { CircularProgress, Modal } from '@mui/material';
import { CSVLink } from 'react-csv';
import AppAppBar from '../modules/views/AppAppBar';
import reportActions from '../actions/reportActions';
import documentActions from '../actions/documentActions';
import Title from '../ProcessApplication/Title';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(4),
  },
  textField: {
    marginRight: 10,
  },
  csvLink: {
    fontWeight: 'bold',
  },
  progress: {
    position: 'absolute',
    top: '40%',
    right: '50%',
  },
}));

function prepareTableData(summaryData) {
  if (!summaryData.applications) {
    return [];
  }
  const tableData = [];
  summaryData.applications.forEach((application) => {
    const event = summaryData.events.find(
      (event) => event.applicationId === application.applicationId
    );
    tableData.push({
      applicationId_: application.applicationId,
      businessId: application.businessId,
      ownershipContact: application.ownershipContact,
      modified: moment(event.eventDate).format('MM/DD/YYYY'),
      documents: summaryData.documents.filter(
        (doc) => doc.applicationId === application.applicationId
      ),
    });
  });
  return tableData;
}

function DocumentsPanel({ row, dispatch }) {
  return (
    <div
      style={{
        fontSize: 100,
        textAlign: 'center',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div>
            <List dense={true}>
              {row.original['documents'].map((document, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <Tooltip title="Download">
                      <IconButton
                        aria-label="download"
                        onClick={() => {
                          dispatch(
                            documentActions.downloadDocument(document.documentId, document.filename)
                          );
                        }}
                        size="large"
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={document.filename} />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
DocumentsPanel.propTypes = {
  row: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};
function SummaryRpt({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialStartDate = moment().subtract(1, 'days').format('MM/DD/YYYY');
  const initialEndDate = moment().format('MM/DD/YYYY');
  useEffect(() => {
    dispatch(reportActions.getSummaryRptData(initialStartDate, initialEndDate));
  }, [dispatch, initialEndDate, initialStartDate]);
  const [dateExtents, setDateExtents] = useState({
    begin: moment(initialStartDate).format('YYYY-MM-DD'),
    end: moment(initialEndDate).format('YYYY-MM-DD'),
  });

  const { summaryRptData, queryingSummaryRptData } = useSelector((state) => state.reports);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'businessId',
        header: 'Business Id',
      },
      {
        accessorKey: 'ownershipContact',
        header: 'Contact',
      },
      {
        accessorKey: 'modified',
        header: 'Modified',
      },
    ],
    []
  );

  return (
    <>
      <Modal open={queryingSummaryRptData}>
        <div>
          <CircularProgress className={classes.progress} size={90} />
        </div>
      </Modal>

      <AppAppBar pathname={location.pathname} />
      <main className={classes.content}>
        <Container className={classes.container} maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="fromDate"
                label="Begin Date"
                type="date"
                defaultValue={dateExtents.begin}
                className={classes.textField}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, begin: event.target.value };
                  setDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="toDate"
                label="End Date"
                type="date"
                defaultValue={dateExtents.end}
                className={classes.textField}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, end: event.target.value };
                  setDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: 10 }}
                onClick={() =>
                  dispatch(reportActions.getSummaryRptData(dateExtents.begin, dateExtents.end))
                }
              >
                Get Summary Data
              </Button>
            </Grid>
            <div style={{ marginTop: 20, width: '100%' }}></div>
            <Grid item xs={12} md={3}>
              <CSVLink
                data={summaryRptData.applications}
                filename="Applications_Summary_Report.csv"
                className={classes.csvLink}
              >
                Export applications summary to CSV
              </CSVLink>
            </Grid>
            <Grid item xs={12} md={3}>
              <CSVLink
                data={summaryRptData.documents}
                filename="Documents_Summary_Report.csv"
                className={classes.csvLink}
              >
                Export documents summary to CSV
              </CSVLink>
            </Grid>
            <Grid item xs={12} md={3}>
              <CSVLink
                data={summaryRptData.payments}
                filename="Payments_Summary_Report.csv"
                className={classes.csvLink}
              >
                Export payments summary to CSV
              </CSVLink>
            </Grid>
            <Grid item xs={12}>
              <MaterialReactTable
                columns={columns}
                data={prepareTableData(summaryRptData)}
                enableStickyHeader
                enableGlobalFilter={false}
                renderDetailPanel={({ row }) => <DocumentsPanel row={row} dispatch={dispatch} />}
                renderTopToolbarCustomActions={() => {
                  return (
                    <Title>
                      <span style={{ fontSize: '1.2rem', marginRight: 20 }}>Summary Report</span>
                    </Title>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}

SummaryRpt.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRoot(SummaryRpt);
