import React from 'react';
import PropTypes from 'prop-types';
import FormValue from '../Components/FormValue';
import SectionHeader from '../Components/SectionHeader';
import Applicant from '../Components/Applicant';
import ApplicantDetails from '../Components/ApplicantDetails';
import ThirdPartyInstaller from '../Components/ThirdPartyInstaller';
import LocationDetails from '../Components/LocationDetails';
import ApplicationDetails from '../Components/ApplicationDetails';
import SummaryTable from '../Components/SummaryTable';
import VariancesTable from '../Components/VariancesTable';
import SummaryLayout from '../Components/SummaryLayout';
import OverheadWirelineSummary from './OverheadWirelineSummary';
import UndergroundWirelineSummary from './UndergroundWirelineSummary';

function prepareWireTypeData(appSummary) {
  const data = [];
  if (appSummary.wireline.communicationCommodity) {
    data.push(['Communication', appSummary.wireline.communicationWireCount]);
  }
  if (appSummary.wireline.electricalCommodity) {
    data.push(['Electrical', appSummary.wireline.electricalWireCount]);
  }
  if (appSummary.wireline.signalCommodity) {
    data.push(['Signal', appSummary.wireline.signalWireCount]);
  }
  if (appSummary.wireline.otherCommodity) {
    data.push([appSummary.wireline.otherCommodityDescription, appSummary.wireline.otherWireCount]);
  }
  if (appSummary.wireline.fiberOpticCommodity) {
    data.push(['Fiber Optic', appSummary.wireline.fiberOpticWireCount]);
  }
  if (appSummary.wireline.neutralCommodity) {
    data.push(['Neutral', appSummary.wireline.neutralWireCount]);
  }
  return data;
}

const WirelineSummary = ({ appSummary, history }) => {
  return (
    <SummaryLayout
      appSummary={appSummary}
      history={history}
      headerTitle={`Application for ${appSummary.permitType} - ${appSummary.wireline.encroachmentType}`}
    >
      <Applicant appSummary={appSummary} />
      <ApplicantDetails appSummary={appSummary} />
      <ThirdPartyInstaller appSummary={appSummary} />
      <ApplicationDetails appSummary={appSummary} />
      <LocationDetails appSummary={appSummary} />

      <SectionHeader headerText="Wireline" />
      <FormValue
        fieldText="Installation method(s)"
        valueText={appSummary.wireline.installationMethods}
      />
      <FormValue
        fieldText="Length of encroachment"
        valueText={appSummary.wireline.encroachmentLengthFt}
        unitsLabel="ft"
      />
      <FormValue
        fieldText="Length of span accross tracks"
        valueText={appSummary.wireline.trackSpanLengthFt}
        unitsLabel="ft"
      />
      <FormValue
        fieldText="Adjacent spans"
        valueText={appSummary.wireline.adjacentSpansFt}
        unitsLabel="ft"
      />
      <FormValue
        fieldText="Appurtenances on RR Co. property"
        valueText={appSummary.wireline.appurtenancesDescription}
      />
      <SummaryTable
        headers={['Wire Type', 'Wire Count']}
        data={prepareWireTypeData(appSummary)}
        leftAlignFirstColumn={false}
      />
      {appSummary.wireline.overheadWireline && <OverheadWirelineSummary appSummary={appSummary} />}
      {appSummary.wireline.undergroundWireline && (
        <UndergroundWirelineSummary appSummary={appSummary} />
      )}

      <VariancesTable variances={appSummary.variances} />
    </SummaryLayout>
  );
};

WirelineSummary.propTypes = {
  appSummary: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
};

export default WirelineSummary;
