import React from 'react';
import { useSelector } from 'react-redux';
import MaterialReactTable from 'material-react-table';
import { IconButton, Stack, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ReactivateUserDialog from './ReactivateUserDialog';

export default function DeactivatedUsers() {
  const { users, reactivatingUser } = useSelector((state) => state.admin);
  const deactivatedUsers = users.filter((u) => u.isDeactivated === true);

  const [currentUser, setCurrentUser] = React.useState({});
  const [dialogState, setDialogState] = React.useState({
    reactivateUserOpen: false,
  });

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
    ],
    []
  );

  return (
    <>
      {dialogState.reactivateUserOpen && (
        <ReactivateUserDialog
          open={dialogState.reactivateUserOpen}
          handleClose={() => setDialogState({ ...dialogState, reactivateUserOpen: false })}
          user={currentUser}
        />
      )}
      <MaterialReactTable
        columns={columns}
        data={deactivatedUsers}
        enableStickyHeader
        enableGlobalFilter={true}
        initialState={{ density: 'compact' }}
        state={{ isLoading: reactivatingUser }}
        enableRowActions
        renderRowActions={({ row }) => (
          <Stack direction="row" spacing={1}>
            {
              <Tooltip title="Reactivate User">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    setCurrentUser(row.original);
                    setDialogState({ ...dialogState, reactivateUserOpen: true });
                  }}
                  size="large"
                >
                  <PersonIcon sx={{ color: '#212121' }} />
                </IconButton>
              </Tooltip>
            }
          </Stack>
        )}
      />
    </>
  );
}

DeactivatedUsers.propTypes = {};
