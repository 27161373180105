function canAddReviewer(reviewStatusValue, permitManagerReviewStatusValue) {
  if (!reviewStatusValue || !permitManagerReviewStatusValue) return false;
  return reviewStatusValue === 'UNASSIGNED' && permitManagerReviewStatusValue === 'PENDINGREVIEW';
}

function canRemoveReviewer(reviewStatusValue, permitManagerReviewStatusValue) {
  if (!reviewStatusValue || !permitManagerReviewStatusValue) return false;
  return (
    reviewStatusValue === 'PENDINGREVIEW' && permitManagerReviewStatusValue === 'PENDINGREVIEW'
  );
}

function canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue) {
  if (!reviewStatusValue || !permitManagerReviewStatusValue) return false;
  return (
    reviewStatusValue === 'PENDINGREVIEW' && permitManagerReviewStatusValue === 'PENDINGREVIEW'
  );
}

function canReopenReview(reviewStatusValue, permitManagerReviewStatusValue) {
  if (!reviewStatusValue || !permitManagerReviewStatusValue) return false;
  return (
    (reviewStatusValue === 'DENIED' || reviewStatusValue === 'APPROVED') &&
    permitManagerReviewStatusValue === 'PENDINGREVIEW'
  );
}

const rules = {
  Applicant: {
    static: [],
  },
  ADMIN: {
    static: ['management-home:visit', 'admin-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
    },
  },
  INSURANCE: {
    static: ['management-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
      'application:approve_deny': ({ reviewStatusValue, reviewGroupValue }) => {
        if (!reviewStatusValue || !reviewGroupValue) return false;
        return reviewStatusValue === 'PENDINGREVIEW' && reviewGroupValue === 'INSURANCE';
      },
      'insuranceReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
    },
  },
  EXHIBIT: {
    static: ['management-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
      'application:approve_deny': ({ reviewStatusValue, reviewGroupValue }) => {
        if (!reviewStatusValue || !reviewGroupValue) return false;
        return reviewStatusValue === 'PENDINGREVIEW' && reviewGroupValue === 'EXHIBIT';
      },
      'exhibitReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
    },
  },
  REALESTATE: {
    static: ['management-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
      'application:approve_deny': ({ reviewStatusValue, reviewGroupValue }) => {
        if (!reviewStatusValue || !reviewGroupValue) return false;
        return reviewStatusValue === 'PENDINGREVIEW' && reviewGroupValue === 'REALESTATE';
      },
      'realEstateReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
    },
  },
  LEGAL: {
    static: ['management-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
      'application:approve_deny': ({ reviewStatusValue, reviewGroupValue }) => {
        if (!reviewStatusValue || !reviewGroupValue) return false;
        return reviewStatusValue === 'PENDINGREVIEW' && reviewGroupValue === 'LEGAL';
      },
      'legalReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
    },
  },
  ENGINEERING: {
    static: ['management-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
      'application:approve_deny': ({ reviewStatusValue, reviewGroupValue }) => {
        if (!reviewStatusValue || !reviewGroupValue) return false;
        return reviewStatusValue === 'PENDINGREVIEW' && reviewGroupValue === 'ENGINEERING';
      },
      'engineeringReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
    },
  },
  GEOTECHNICAL: {
    static: ['management-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
      'application:approve_deny': ({ reviewStatusValue, reviewGroupValue }) => {
        if (!reviewStatusValue || !reviewGroupValue) return false;
        return reviewStatusValue === 'PENDINGREVIEW' && reviewGroupValue === 'GEOTECHNICAL';
      },
      'geotechnicalReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
    },
  },
  ENVIRONMENTAL: {
    static: ['management-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
      'application:approve_deny': ({ reviewStatusValue, reviewGroupValue }) => {
        if (!reviewStatusValue || !reviewGroupValue) return false;
        return reviewStatusValue === 'PENDINGREVIEW' && reviewGroupValue === 'ENVIRONMENTAL';
      },
      'environmentalReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
    },
  },
  TELECOM: {
    static: ['management-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
      'application:approve_deny': ({ reviewStatusValue, reviewGroupValue }) => {
        if (!reviewStatusValue || !reviewGroupValue) return false;
        return reviewStatusValue === 'PENDINGREVIEW' && reviewGroupValue === 'TELECOM';
      },
      'telecomReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
    },
  },
  RAILROAD: {
    static: ['management-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
      'application:approve_deny': ({ reviewStatusValue, reviewGroupValue }) => {
        if (!reviewStatusValue || !reviewGroupValue) return false;
        return reviewStatusValue === 'PENDINGREVIEW' && reviewGroupValue === 'RAILROAD';
      },
      'railroadReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
    },
  },
  SIGNAL: {
    static: ['management-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
      'application:approve_deny': ({ reviewStatusValue, reviewGroupValue }) => {
        if (!reviewStatusValue || !reviewGroupValue) return false;
        return reviewStatusValue === 'PENDINGREVIEW' && reviewGroupValue === 'SIGNAL';
      },
      'signalReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
    },
  },
  REVIEWCONTRIBUTOR: {
    static: ['management-home:visit'],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },
    },
  },
  PERMITMANAGER: {
    static: [
      'management-home:visit',
      'application:addFee',
      'application:recordPayment',
      'application:modifyAndDeleteFees',
    ],
    dynamic: {
      'document:delete': ({ userId, documentOwnerId }) => {
        if (!userId || !documentOwnerId) return false;
        return userId === documentOwnerId;
      },
      'log:edit': ({ userId, logOwnerId }) => {
        if (!userId || !logOwnerId) return false;
        return userId === logOwnerId;
      },

      'application:approve': ({
        applicationReviews, // applicationReviews should exclude the permit manager review record
        requiredReviewStatusIds,
        reviewGroupValue,
        permitManagerReviewStatusValue,
      }) => {
        if (
          !applicationReviews ||
          !requiredReviewStatusIds ||
          !reviewGroupValue ||
          !permitManagerReviewStatusValue
        ) {
          return false;
        }
        if (reviewGroupValue !== 'PERMITMANAGER') {
          return false;
        }
        if (permitManagerReviewStatusValue !== 'PENDINGREVIEW') {
          return false;
        }
        const failedStatuses = applicationReviews.filter((r) => {
          return !requiredReviewStatusIds.includes(r.reviewStatusId);
        });
        return failedStatuses.length === 0;
      },
      'application:deny': ({
        applicationReviews, // applicationReviews should exclude the permit manager review record
        requiredReviewStatusIds,
        reviewGroupValue,
        permitManagerReviewStatusValue,
      }) => {
        if (
          !applicationReviews ||
          !requiredReviewStatusIds ||
          !reviewGroupValue ||
          !permitManagerReviewStatusValue
        ) {
          return false;
        }
        if (reviewGroupValue !== 'PERMITMANAGER') {
          return false;
        }
        if (permitManagerReviewStatusValue !== 'PENDINGREVIEW') {
          return false;
        }
        const failedStatuses = applicationReviews.filter((r) => {
          return !requiredReviewStatusIds.includes(r.reviewStatusId);
        });
        return failedStatuses.length === 0;
      },
      'application:reopen': ({ reviewGroupValue, applicationStatusValue }) => {
        if (!reviewGroupValue || !applicationStatusValue) return false;
        return reviewGroupValue === 'PERMITMANAGER' && applicationStatusValue === 'DENIED';
      },
      'application:execute': ({ reviewGroupValue, reviewStatusValue, applicationStatusValue }) => {
        if (!reviewGroupValue || !reviewStatusValue) return false;
        return (
          reviewGroupValue === 'PERMITMANAGER' &&
          reviewStatusValue === 'APPROVED' &&
          applicationStatusValue === 'APPROVED'
        );
      },

      'insuranceReviewer:add': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canAddReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'exhibitReviewer:add': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canAddReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'realEstateReviewer:add': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canAddReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'legalReviewer:add': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canAddReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'engineeringReviewer:add': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canAddReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'geotechnicalReviewer:add': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canAddReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'environmentalReviewer:add': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canAddReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'telecomReviewer:add': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canAddReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'railroadReviewer:add': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canAddReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'signalReviewer:add': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canAddReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },

      'insuranceReviewer:remove': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canRemoveReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'exhibitReviewer:remove': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canRemoveReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'realEstateReviewer:remove': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canRemoveReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'legalReviewer:remove': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canRemoveReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'engineeringReviewer:remove': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canRemoveReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'geotechnicalReviewer:remove': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canRemoveReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'environmentalReviewer:remove': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canRemoveReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'telecomReviewer:remove': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canRemoveReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'railroadReviewer:remove': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canRemoveReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'signalReviewer:remove': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canRemoveReviewer(reviewStatusValue, permitManagerReviewStatusValue);
      },

      'insuranceReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'exhibitReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'realEstateReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'legalReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'engineeringReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'geotechnicalReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'environmentalReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'telecomReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'railroadReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'signalReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'permitManagerReviewer:change': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canChangeReviewers(reviewStatusValue, permitManagerReviewStatusValue);
      },

      'insuranceReviewer:reopen': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canReopenReview(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'exhibitReviewer:reopen': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canReopenReview(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'realEstateReviewer:reopen': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canReopenReview(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'legalReviewer:reopen': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canReopenReview(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'engineeringReviewer:reopen': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canReopenReview(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'geotechnicalReviewer:reopen': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canReopenReview(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'environmentalReviewer:reopen': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canReopenReview(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'telecomReviewer:reopen': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canReopenReview(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'railroadReviewer:reopen': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canReopenReview(reviewStatusValue, permitManagerReviewStatusValue);
      },
      'signalReviewer:reopen': ({ reviewStatusValue, permitManagerReviewStatusValue }) => {
        return canReopenReview(reviewStatusValue, permitManagerReviewStatusValue);
      },
    },
  },
};

export default rules;
