import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import alertActions from '../../actions/alertActions';
import SnackBarContentWrapper from './SnackBarContentWrapper';

function AlertMessage({ alert }) {
  const dispatch = useDispatch();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open
      autoHideDuration={alert.type === 'error' || alert.type === 'warning' ? null : 60000}
      onClose={(evt, reason) => {
        if (reason !== 'clickaway') {
          dispatch(alertActions.clear());
        }
      }}
    >
      <div>
        <SnackBarContentWrapper
          onClose={() => {
            dispatch(alertActions.clear());
          }}
          variant={alert.type}
          message={alert.message}
          style={{ whiteSpace: 'pre-wrap' }}
        />
      </div>
    </Snackbar>
  );
}

AlertMessage.propTypes = {
  alert: PropTypes.object.isRequired,
};

export default AlertMessage;
