import alertConstants from '../constants/alertConstants';

function success(message) {
  return { type: alertConstants.success, message };
}

function error(message) {
  return { type: alertConstants.error, message };
}

function warning(message) {
  return { type: alertConstants.warning, message };
}

function info(message) {
  return { type: alertConstants.info, message };
}

function clear() {
  return { type: alertConstants.clear };
}

const alertActions = {
  success,
  error,
  warning,
  info,
  clear,
};

export default alertActions;
