import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import commonConstants from '../constants/commonConstants';
import { acquireAccessToken } from '../Auth/utils';
import alertConstants from '../constants/alertConstants';

function* getLookups() {
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/common/lookups`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: commonConstants.getLookupsSucceeded, lookups: data });
  } catch (e) {
    yield put({ type: commonConstants.getLookupsFailed, error: e });
  }
}

function* getConfig() {
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/common/config`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: commonConstants.getConfigSucceeded, config: data });
  } catch (e) {
    yield put({ type: commonConstants.getConfigFailed, error: e });
  }
}

function* sendHelpRequest(action) {
  const { userName, userEmail, description, pageUrl, applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/common/contactUs`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
      data: {
        name: userName,
        email: userEmail,
        description,
        requestedFromPageUrl: pageUrl,
        applicationId,
      },
    });
    yield put({ type: commonConstants.sendHelpRequestSucceeded, config: data });
    yield put({ type: alertConstants.success, message: 'Request sent' });
  } catch (e) {
    yield put({ type: commonConstants.sendHelpRequestFailed, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to send request' });
  }
}

const commonSagas = [
  takeLatest(commonConstants.getLookups, getLookups),
  takeLatest(commonConstants.getConfig, getConfig),
  takeLatest(commonConstants.sendHelpRequest, sendHelpRequest),
];

export default commonSagas;
