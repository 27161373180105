/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import formUpdateActions from '../../actions/formUpdate';
import config from '../../data/config.json';
import { applicationIsEditable, getLookup } from '../../helpers/utils';
import mapDrawActions from '../../actions/mapDrawActions';
import mapQueryActions from '../../actions/mapQueryActions';
import Sketch from '@arcgis/core/widgets/Sketch.js';
import MapView from '@arcgis/core/views/MapView';
import esriConfig from '@arcgis/core/config';
import WebMap from '@arcgis/core/WebMap.js';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer.js';
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle.js';
import Graphic from '@arcgis/core/Graphic.js';
import DistanceMeasurement2D from '@arcgis/core/widgets/DistanceMeasurement2D.js';
import Search from '@arcgis/core/widgets/Search.js';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer.js';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils.js';
import esriId from '@arcgis/core/identity/IdentityManager.js';
import ModalSpinner from '../../modules/components/ModalSpinner';

const useStyles = makeStyles(() => ({
  mapGrid: {
    height: '50vh',
    paddingBottom: 35,
  },
  topbar: {
    background: '#fff',
    padding: 10,
  },
  actionButton: {
    fontSize: 16,
    backgroundColor: 'transparent',
    border: '1px solid #d3d3d3',
    color: '#6e6e6e',
    height: 32,
    width: 32,
    textAlign: 'center',
    boxShadow: '0 0 1px rgba(0, 0, 0, 0.3)',
    '&:hover, &:focus': {
      background: '#0079c1',
      color: '#e4e4e4',
    },
  },
  active: {
    background: '#0079c1',
    color: '#e4e4e4',
  },
}));

export function getAvailableCreateTools(permitTypeValue, locationLayer) {
  if (!locationLayer) {
    return [];
  }
  const graphic = locationLayer.graphics.getItemAt(0);
  let geometryType = '';
  if (graphic) {
    geometryType = graphic.geometry.type;
  }
  switch (permitTypeValue) {
    case 'PIPELINE':
    case 'WIRELINE':
    case 'ROADWAYSURFACING':
    case 'PRIVATECROSSING':
    case 'OVERSIZELOADMOVE':
      if (graphic) {
        return [];
      }
      return ['point', 'polyline'];
    case 'TEMPORARYOCCUPANCY':
      if (graphic) {
        return [];
      }
      return ['point', 'polyline'];
    case 'GENERALLICENSE':
      if (graphic) {
        return [];
      }
      return ['point'];
    case 'ENVIRONMENTALRIGHTOFWAY':
      if (!graphic) {
        return ['point', 'polyline', 'polygon'];
      } else if (geometryType === 'point') {
        return ['point'];
      }
      return [];
    default:
      return [];
  }
}

const PermittingMap = () => {
  const mapViewContainerRef = React.useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { mapTokenRequired, esriApiKey } = useSelector((state) => state.common.config);
  const application = useSelector((state) => {
    return state.selectedApplication.application;
  });
  const { applicationStatuses } = useSelector((state) => state.common.lookups);
  const { esriTokenLoaded, esriToken } = useSelector((state) => state.mapQuery);
  const { points, mapView, locationDrawOption } = useSelector((state) => state.mapDraw);
  const { permitTypes } = useSelector((state) => state.common.lookups);
  const permitTypeLU = getLookup(permitTypes, application.permitTypeId);

  useEffect(() => {
    if (mapTokenRequired) {
      dispatch(mapQueryActions.getEsriToken(config.MapServices.MainLineRail.url));
    }
  }, [dispatch, mapTokenRequired]);
  useEffect(() => {
    esriId.registerToken({
      server: config.MapServices.PortalUrl,
      token: esriToken.token,
    });
  }, [esriToken.token]);

  let sketch = null;
  if (mapView) {
    sketch = mapView.ui.find('sketchWidget');
  }
  if (mapView && sketch && locationDrawOption === 'enterpoints') {
    sketch.visible = false;
  }
  if (mapView && sketch && locationDrawOption === 'drawonmap') {
    sketch.visible = true;
  }

  const editableApplication = applicationIsEditable(
    application.applicationStatusId,
    applicationStatuses
  );

  window.points = points;
  esriConfig.apiKey = esriApiKey;

  // Zoom to drawn location
  React.useEffect(() => {
    const { locationDetail } = application;
    const layerViewCreateHandler = null;
    if (mapView && locationDetail && locationDetail.graphicJson) {
      mapView.when(() => {
        reactiveUtils
          .whenOnce(() => mapView.map.findLayerById('location') != null)
          .then(() => {
            const locationLayer = mapView.map.findLayerById('location');
            reactiveUtils
              .whenOnce(() => locationLayer.loaded)
              .then(() => {
                dispatch(mapDrawActions.removeAllPoints(mapView.map));
                const graphics = [];
                if (Array.isArray(JSON.parse(locationDetail.graphicJson))) {
                  const graphicsJson = JSON.parse(locationDetail.graphicJson);
                  graphicsJson.forEach((g) => graphics.push(Graphic.fromJSON(JSON.parse(g))));
                } else {
                  graphics.push(Graphic.fromJSON(JSON.parse(locationDetail.graphicJson)));
                }
                graphics.forEach((g) => {
                  locationLayer.add(g);
                  if (g.geometry.type === 'point') {
                    dispatch(
                      mapDrawActions.addPoint(
                        String(g.geometry.latitude),
                        String(g.geometry.longitude),
                        g.attributes.uniqueId
                      )
                    );
                    mapView.center = g.geometry;
                    mapView.zoom = 15;
                  } else {
                    mapView.goTo(g);
                  }
                });
                const sketchWidget = mapView.ui.find('sketchWidget');
                sketchWidget.availableCreateTools = getAvailableCreateTools(
                  permitTypeLU.value,
                  locationLayer
                );
              });
          });
      });
    }

    return () => {
      if (layerViewCreateHandler) {
        layerViewCreateHandler.remove();
      }
    };
  }, [application, dispatch, mapView, permitTypeLU.value]);

  // Initialize map
  React.useEffect(() => {
    if (!mapViewContainerRef.current) {
      return;
    }
    const map = WebMap.fromJSON(config.WebMaps.UtilityPermitting);
    map.basemap = 'streets-vector';
    const view = new MapView({
      map,
      zoom: 4,
      center: [-95.7129, 37.0902],
    });

    view.container = mapViewContainerRef.current;
    view.when(() => {
      const locationLayer = new GraphicsLayer({ id: 'location' });
      const highlightsLayer = new GraphicsLayer({ id: 'highlights' });
      map.addMany([locationLayer, highlightsLayer]);

      const basemapToggle = new BasemapToggle({
        view,
        visibleElements: { title: true },
        nextBasemap: 'hybrid',
      });
      view.ui.add(basemapToggle, 'bottom-left');

      const searchWidget = new Search({
        view,
        sources: [
          {
            layer: new FeatureLayer({
              url: `${config.MapServices.FRAGradeXing.url}/${config.MapServices.FRAGradeXing.layers.RailCrossings.id}`,
            }),
            searchFields: ['CROSSING'],
            displayField: 'CROSSING',
            exactMatch: false,
            outFields: ['CROSSING', 'RAILROAD'],
            name: 'Crossing #',
            placeholder: 'ex: 353078U',
            maxResults: 6,
            maxSuggestions: 6,
            suggestionsEnabled: true,
            minSuggestCharacters: 3,
          },
        ],
      });
      view.ui.add(searchWidget, {
        position: 'top-left',
        index: 2,
      });

      let sketchWidget = null;
      if (editableApplication) {
        sketchWidget = new Sketch({
          view,
          id: 'sketchWidget',
          layer: locationLayer,
          availableCreateTools: getAvailableCreateTools(permitTypeLU.value, locationLayer),
          creationMode: 'single',
          defaultCreateOptions: 'click',
          defaultUpdateOptions: { enableRotation: false, enableScaling: false },
          visibleElements: {
            settingsMenu: false,
            selectionTools: {
              'lasso-selection': false,
            },
            undoRedoMenu: false,
          },
        });
        const pointSymbol = sketchWidget.viewModel.pointSymbol;
        pointSymbol.color = [65, 105, 225];
        pointSymbol.style = 'x';
        pointSymbol.size = 8;
        pointSymbol.outline = {
          color: [65, 105, 225],
          width: '4px',
        };
        const polygonSymbol = sketchWidget.viewModel.polygonSymbol;
        polygonSymbol.color = [255, 255, 255, 0];
        polygonSymbol.outline = {
          color: [65, 105, 225],
          width: '4px',
        };
        const polylineSymbol = sketchWidget.viewModel.polylineSymbol;
        polylineSymbol.color = [65, 105, 225];
        polylineSymbol.width = '4px';
        view.ui.add(sketchWidget, 'top-right');

        sketchWidget.watch('activeTool', (activeTool) => {
          if (activeTool) {
            document.getElementById('distanceButton').style.display = 'none';
          } else {
            document.getElementById('distanceButton').style.display = 'block';
          }
        });
      }

      view.ui.add('topbar', 'top-right');

      let activeWidget = null;
      function setActiveWidget(type) {
        switch (type) {
          case 'distance':
            if (sketchWidget) {
              sketchWidget.visible = false;
            }
            activeWidget = new DistanceMeasurement2D({
              view: view,
            });

            // skip the initial 'new measurement' button
            activeWidget.viewModel.start();

            view.ui.add(activeWidget, 'top-right');
            setActiveButton(document.getElementById('distanceButton'));
            break;
          case null:
            if (activeWidget) {
              if (sketchWidget) {
                sketchWidget.visible = true;
              }
              view.ui.remove(activeWidget);
              activeWidget.destroy();
              activeWidget = null;
            }
            break;
          default:
            break;
        }
      }
      function setActiveButton(selectedButton) {
        // focus the view to activate keyboard shortcuts for sketching
        view.focus();
        const elements = document.getElementsByClassName('active');
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.remove('active');
        }
        if (selectedButton) {
          selectedButton.classList.add('active');
        }
      }
      document.getElementById('distanceButton').addEventListener('click', function () {
        setActiveWidget(null);
        if (!this.classList.contains('active')) {
          setActiveWidget('distance');
        } else {
          setActiveButton(null);
        }
      });

      dispatch(mapDrawActions.mapCreated(view));
    });

    return () => {
      view && view.destroy();
      dispatch(mapDrawActions.mapCreated(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle sketch events
  React.useEffect(() => {
    if (!mapView) {
      return;
    }
    let onCreateHandler = null;
    let onUpdateHandler = null;
    let onDeleteHandler = null;
    mapView.when(() => {
      const sketchWidget = mapView.ui.find('sketchWidget');
      const { locationDetail } = application;
      const locationLayer = mapView.map.findLayerById('location');
      onCreateHandler = sketchWidget.on('create', (evt) => {
        if (evt.state === 'complete') {
          const uniqueId = Date.now();
          if (evt.graphic.geometry.type === 'point') {
            evt.graphic.attributes = {
              uniqueId: uniqueId,
            };
            dispatch(
              mapDrawActions.addPoint(
                String(evt.graphic.geometry.latitude),
                String(evt.graphic.geometry.longitude),
                uniqueId
              )
            );
          } else {
            evt.graphic.attributes = { uniqueId };
          }
          dispatch(
            mapDrawActions.mapDrawUpdateLocationDetail(locationDetail, mapView.map, mapView)
          );
          sketchWidget.availableCreateTools = getAvailableCreateTools(
            permitTypeLU.value,
            locationLayer
          );
        }
      });
      onUpdateHandler = sketchWidget.on('update', (evt) => {
        if (evt.state === 'complete') {
          const updatedGraphic = evt.graphics[0];
          dispatch(
            mapDrawActions.updatePointCoordinates(
              String(updatedGraphic.geometry.latitude),
              String(updatedGraphic.geometry.longitude),
              updatedGraphic.attributes.uniqueId
            )
          );
          dispatch(
            mapDrawActions.mapDrawUpdateLocationDetail(locationDetail, mapView.map, mapView)
          );
        }
      });
      onDeleteHandler = sketchWidget.on('delete', (evt) => {
        sketchWidget.availableCreateTools = getAvailableCreateTools(
          permitTypeLU.value,
          locationLayer
        );
        const graphic = evt.graphics[0];
        const matchingPoint = window.points.find((p) => p.uniqueId === graphic.attributes.uniqueId);
        dispatch(
          mapDrawActions.removePoint(
            mapView.map,
            mapView,
            sketchWidget,
            window.points.indexOf(matchingPoint),
            permitTypeLU.value
          )
        );
        const keys = Object.keys(locationDetail);
        keys.forEach((key) => {
          if (key !== 'locationDetailId') {
            locationDetail[key] = null;
          }
        });
        dispatch(formUpdateActions.updateLocationDetail(locationDetail, null, null, true));
        dispatch(
          formUpdateActions.updateApplication({
            applicationId: application.applicationId,
            followingStateStatute: null,
          })
        );
      });
    });

    return () => {
      if (onCreateHandler) {
        onCreateHandler.remove();
      }
      if (onUpdateHandler) {
        onUpdateHandler.remove();
      }
      if (onDeleteHandler) {
        onDeleteHandler.remove();
      }
    };
  }, [mapView, dispatch, permitTypeLU.value, application]);

  return (
    <>
      <ModalSpinner open={mapTokenRequired && !esriTokenLoaded} />
      <Grid item xs={12} className={classes.mapGrid}>
        <Box
          ref={mapViewContainerRef}
          style={{
            height: '100%',
          }}
        ></Box>
        <div id="topbar">
          <button
            className="action-button esri-icon-measure-line"
            id="distanceButton"
            type="button"
            title="Measure distance between two or more points"
          ></button>
        </div>
      </Grid>
    </>
  );
};

PermittingMap.propTypes = {};

export default PermittingMap;
