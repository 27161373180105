import React from 'react';
import PropTypes from 'prop-types';
import FormValue from '../Components/FormValue';
import SectionHeader from '../Components/SectionHeader';
import Applicant from '../Components/Applicant';
import { View } from '@react-pdf/renderer';
import ApplicantDetails from '../Components/ApplicantDetails';
import LocationDetails from '../Components/LocationDetails';
import SummaryLayout from '../Components/SummaryLayout';
import ApplicationDetails from '../Components/ApplicationDetails';

const EnvironmentalRightOfWaySummary = ({ appSummary, history }) => {
  return (
    <SummaryLayout
      appSummary={appSummary}
      history={history}
      headerTitle={`Application for ${appSummary.permitType}`}
    >
      <Applicant appSummary={appSummary} />
      <ApplicantDetails appSummary={appSummary} />
      <ApplicationDetails appSummary={appSummary} />
      <LocationDetails appSummary={appSummary} />
      <SectionHeader headerText="Environmental Right of Way" />
      <FormValue
        fieldText="How far from the track will the work be performed"
        valueText={appSummary.environmental.distanceFromTrack}
      />
      <FormValue
        fieldText="Is the work to be performed within 50ft of railroad property"
        valueText={appSummary.environmental.within50Ft}
      />
      <View style={{ marginLeft: '15px' }}>
        <FormValue
          fieldText="Percentage"
          valueText={appSummary.environmental.pctWithin50Ft}
          unitsLabel="%"
          hideIfEmpty
        />
      </View>
      <FormValue
        fieldText="Area to be occupied"
        valueText={`${appSummary.environmental.occupiedAreaXFt}ft x ${appSummary.environmental.occupiedAreaYFt}ft`}
      />
      <FormValue
        fieldText="Total cost of the project"
        valueText={`$${appSummary.environmental.totalCost}`}
      />
      <FormValue
        fieldText="Purpose of testing"
        valueText={appSummary.environmental.testingPurpose}
      />
    </SummaryLayout>
  );
};

EnvironmentalRightOfWaySummary.propTypes = {
  appSummary: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
};

export default EnvironmentalRightOfWaySummary;
