import React, { useEffect, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, withRouter, Redirect, Switch } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ScrollToTop from './modules/ScrollToTop';
import formChangeActions from './actions/formChange';
import Copyright from './modules/views/Copyright';
import HomeRoute from './Home/HomeRoute';
import CycleTimeRpt from './Reports/CycleTimeRpt';
import VarianceRpt from './Reports/VarianceRpt';
import PaymentRpt from './Reports/PaymentRpt';
import SummaryRpt from './Reports/SummaryRpt';
import FinanceRpt from './Reports/FinanceReport';
import ReviewerBacklogRpt from './Reports/ReviewerBacklogRpt';
import TerritoryAssignmentsRpt from './Reports/TerritoryAssignmentsRpt';
import FirstSubmissionRpt from './Reports/FirstSubmissionRpt';
import AlertMessage from './modules/components/AlertMessage';

const PermitForm = lazy(() => import('./PermitForms/PermitForm'));
const ProcessApplication = lazy(() => import('./ProcessApplication/ProcessApplication'));

function AppContainer({ children, location: { pathname } }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      !pathname.includes('/application') &&
      !pathname.includes('/processapplication') &&
      !pathname.includes('/authentication')
    ) {
      dispatch(formChangeActions.clearSelectedApplication());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <ScrollToTop>{children || null}</ScrollToTop>;
}

AppContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  location: PropTypes.object.isRequired,
};
AppContainer.defaultProps = {
  children: [],
};

const Container = withRouter(AppContainer);

const useStyles = makeStyles(() => ({
  progressContainer: {
    position: 'absolute',
    width: 'calc(100% - 30px)',
    height: 'calc(100% - 100px)',
    zIndex: 1,
    opacity: 0.8,
  },
  progress: {
    position: 'absolute',
    top: '40%',
    right: '50%',
  },
}));

function AppRouter() {
  const classes = useStyles();
  const alert = useSelector((state) => {
    return state.alert;
  });
  const { config } = useSelector((state) => state.common);

  const { sendingHelpRequest } = useSelector((state) => state.common);

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      {alert && alert.message && alert.type && <AlertMessage alert={alert} />}
      {sendingHelpRequest && (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progress} size={90} />
        </div>
      )}
      <Suspense
        fallback={
          <div className={classes.progressContainer}>
            <CircularProgress className={classes.progress} size={90} />
          </div>
        }
      >
        <Container>
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="/home" component={HomeRoute} />
            <Route path="/application/:id" component={PermitForm} />
            <Route path="/processapplication/:id" component={ProcessApplication} />
            <Route path="/rpt/cycletimereport" component={CycleTimeRpt} />
            <Route path="/rpt/variancereport" component={VarianceRpt} />
            <Route path="/rpt/paymentreport" component={PaymentRpt} />
            <Route path="/rpt/summaryreport" component={SummaryRpt} />
            <Route path="/rpt/reviewerbacklogreport" component={ReviewerBacklogRpt} />
            <Route path="/rpt/territoryassignmentsreport" component={TerritoryAssignmentsRpt} />
            <Route path="/rpt/firstsubmissionreport" component={FirstSubmissionRpt} />
            {config.includeFinanceReport && (
              <Route path="/rpt/financereport" component={FinanceRpt} />
            )}
            <Route path="*">
              <Redirect to="/home" />
            </Route>
          </Switch>
        </Container>
      </Suspense>
      <Copyright />
    </BrowserRouter>
  );
}

export default AppRouter;
