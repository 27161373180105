import adminConstants from '../constants/adminConstants';

function getAdminApplications(startDate, endDate) {
  return { type: adminConstants.getAdminApplications, startDate, endDate };
}

function submitApplication(applicationId, submittedStatus) {
  return { type: adminConstants.submitApplication, applicationId, submittedStatus };
}

function changeApplicationOwner(applicationId, user) {
  return { type: adminConstants.changeApplicationOwner, applicationId, user };
}

function undoPMApproval(applicationId) {
  return { type: adminConstants.undoPMApproval, applicationId };
}

function getUsers() {
  return { type: adminConstants.getUsers };
}

function deactivateUser(userId) {
  return { type: adminConstants.deactivateUser, userId };
}

function reactivateUser(userId) {
  return { type: adminConstants.reactivateUser, userId };
}

function getRoles() {
  return { type: adminConstants.getRoles };
}

function getClaims() {
  return { type: adminConstants.getClaims };
}

function getTerritoryTypes() {
  return { type: adminConstants.getTerritoryTypes };
}

function getTerritories() {
  return { type: adminConstants.getTerritories };
}

function getUserAssignedPermitTypes(userId) {
  return { type: adminConstants.getUserAssignedPermitTypes, userId };
}

function getUserAssignedTerritories(userId) {
  return { type: adminConstants.getUserAssignedTerritories, userId };
}

function saveUserEdits(userId, contractorId, roleIds, territoryIds, permitTypeIds, claimIds) {
  return {
    type: adminConstants.saveUserEdits,
    userId,
    contractorId,
    roleIds,
    territoryIds,
    permitTypeIds,
    claimIds,
  };
}

const adminActions = {
  getAdminApplications,
  submitApplication,
  changeApplicationOwner,
  undoPMApproval,
  getUsers,
  deactivateUser,
  reactivateUser,
  getRoles,
  getClaims,
  getTerritoryTypes,
  getTerritories,
  getUserAssignedPermitTypes,
  getUserAssignedTerritories,
  saveUserEdits,
};

export default adminActions;
