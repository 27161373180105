import rbacCan from './rbacCan';
import cbacCan from './cbacCan';

function Can({ roles, claims, action, data, yes, no }) {
  const rbacAllowed = rbacCan(roles, action, data);
  const cbacAllowed = cbacCan(claims, action, data);

  if (rbacAllowed || cbacAllowed) {
    return yes();
  }

  return no();
}

Can.defaultProps = {
  roles: [],
  claims: [],
  yes: () => null,
  no: () => null,
};

export default Can;
