import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import FormValue from './FormValue';
import ChangesSummary from '../ApplicationSummaries/Changes/ChangesSummary';

const styles = StyleSheet.create({
  headerId: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'right',
    color: 'grey',
  },
  headerTitle: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    this.setState({ hasError: true });
    // logErrorToMyService(error, errorInfo); // Implement this function based on your error logging service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Text style={{ fontSize: '12px', marginTop: '15px' }}>
          Could not render application changes
        </Text>
      );
    }
    return this.props.children;
  }
}

const SummaryLayout = ({ appSummary, history, headerTitle, children }) => {
  return (
    <>
      <View fixed>
        <Text style={styles.headerId}>Id: {appSummary.businessId}</Text>
        <Text style={styles.headerTitle}>{headerTitle}</Text>
      </View>
      <FormValue
        fieldText="Most recent submittal date"
        valueText={moment(appSummary.submittalDateTime).format('MM/DD/YYYY hh:mm A')}
      />
      <FormValue fieldText="Number of submissions" valueText={history.length} />

      {/* Application specific data */}
      {children}

      <ErrorBoundary>
        <ChangesSummary history={history || []} />
      </ErrorBoundary>

      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </>
  );
};

SummaryLayout.propTypes = {
  appSummary: PropTypes.object.isRequired,
  headerTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
  history: PropTypes.array.isRequired,
};

SummaryLayout.defaultProps = {
  children: null,
};

export default SummaryLayout;
