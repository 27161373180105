import React from 'react';
import PropTypes from 'prop-types';
import FormValue from '../Components/FormValue';
import SectionHeader from '../Components/SectionHeader';
import Applicant from '../Components/Applicant';
import ApplicantDetails from '../Components/ApplicantDetails';
import LocationDetails from '../Components/LocationDetails';
import ApplicationDetails from '../Components/ApplicationDetails';
import SummaryLayout from '../Components/SummaryLayout';

const RoadwaySurfacingSummary = ({ appSummary, history }) => {
  return (
    <SummaryLayout
      appSummary={appSummary}
      history={history}
      headerTitle={`Application for ${appSummary.permitType}`}
    >
      <Applicant appSummary={appSummary} />
      <ApplicantDetails appSummary={appSummary} />
      <ApplicationDetails appSummary={appSummary} />
      <LocationDetails appSummary={appSummary} />
      <SectionHeader headerText="Roadway Surfacing/Resurfacing" />
      <FormValue
        fieldText="Scope of services to be performed"
        valueText={appSummary.roadwaySurfacing.scope}
      />
      <FormValue
        fieldText="Is work to be performed within 25ft of track"
        valueText={appSummary.roadwaySurfacing.within50FtTrack}
      />
      <FormValue
        fieldText="Is work to be performed within 50ft of property"
        valueText={appSummary.roadwaySurfacing.within50FtProperty}
      />
      <FormValue
        fieldText="Percent within 50ft"
        valueText={appSummary.roadwaySurfacing.pctWithin50ft}
        hideIfEmpty
      />
      <FormValue
        fieldText="Area to be occupied"
        valueText={`${appSummary.roadwaySurfacing.occupiedAreaXFt} ft x ${appSummary.roadwaySurfacing.occupiedAreaYFt} ft`}
      />
      <FormValue
        fieldText="Total project cost"
        valueText={`$${appSummary.roadwaySurfacing.totalCost.toFixed(2)}`}
      />
    </SummaryLayout>
  );
};

RoadwaySurfacingSummary.propTypes = {
  appSummary: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
};

export default RoadwaySurfacingSummary;
