import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import SectionHeader from './SectionHeader';
import SummaryTable from './SummaryTable';

function prepareVarianceData(variances) {
  const varianceData = variances.map((variance) => [
    variance.requirement,
    variance.formValue,
    variance.explanation,
  ]);

  return varianceData;
}

const VariancesTable = ({ variances }) => {
  if (!variances.length) {
    return (
      <>
        <SectionHeader headerText="Variances" />
        <Text style={{ fontSize: '12px' }}>Application has no variances</Text>
      </>
    );
  }
  return (
    <View>
      <View style>
        <SectionHeader headerText="Variances" />
      </View>
      <SummaryTable
        headers={['REQUIREMENT', 'APPLICATION VALUE', 'EXPLANATION']}
        data={prepareVarianceData(variances)}
      />
    </View>
  );
};

VariancesTable.propTypes = {
  variances: PropTypes.array,
};

VariancesTable.defaultProps = {
  variances: [],
};

export default VariancesTable;
