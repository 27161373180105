const commonConstants = {
  getLookups: 'GET_LOOKUPS',
  getLookupsSucceeded: 'GET_LOOKUPS_SUCCEEDED',
  getLookupsFailed: 'GET_LOOKUPS_FAILED',

  getConfig: 'GET_CONFIG',
  getConfigSucceeded: 'GET_CONFIG_SUCCEEDED',
  getConfigFailed: 'GET_CONFIG_FAILED',

  sendHelpRequest: 'SEND_HELP_REQUEST',
  sendHelpRequestSucceeded: 'SEND_HELP_REQUEST_SUCCEEDED',
  sendHelpRequestFailed: 'SEND_HELP_REQUEST_FAILED',

  hideMessageBanner: 'HIDE_MESSAGE_BANNER',
};

export default commonConstants;
