import formConstants from '../constants/formConstants';

function insertApplication(permitType, resolve, reject) {
  return { type: formConstants.insertApplication, permitType, resolve, reject };
}

function insertPipeline(applicationId, pipeline) {
  return { type: formConstants.insertPipeline, applicationId, pipeline };
}

function insertPipelineCasing(pipelineId, pipelineCasing, resolve, reject) {
  return { type: formConstants.insertPipelineCasing, pipelineId, pipelineCasing, resolve, reject };
}

function insertPipelineCarrier(pipelineId, pipelineCarrier, resolve, reject) {
  return {
    type: formConstants.insertPipelineCarrier,
    pipelineId,
    pipelineCarrier,
    resolve,
    reject,
  };
}

function insertWireline(applicationId, wireline) {
  return { type: formConstants.insertWireline, applicationId, wireline };
}

function insertUndergroundWireline(wirelineId, undergroundWireline, resolve, reject) {
  return {
    type: formConstants.insertUndergroundWireline,
    wirelineId,
    undergroundWireline,
    resolve,
    reject,
  };
}

function insertOverheadWireline(wirelineId, overheadWireline, resolve, reject) {
  return {
    type: formConstants.insertOverheadWireline,
    wirelineId,
    overheadWireline,
    resolve,
    reject,
  };
}

function insertPole(overheadWirelineId, pole, resolve, reject) {
  return { type: formConstants.insertPole, overheadWirelineId, pole, resolve, reject };
}

function insertConduit(undergroundWirelineId, conduit, resolve, reject) {
  return { type: formConstants.insertConduit, undergroundWirelineId, conduit, resolve, reject };
}

function insertContract(assignmentId, contract, resolve, reject) {
  return { type: formConstants.insertContract, assignmentId, contract, resolve, reject };
}

const formInsertActions = {
  insertApplication,
  insertPipeline,
  insertPipelineCasing,
  insertPipelineCarrier,
  insertWireline,
  insertUndergroundWireline,
  insertOverheadWireline,
  insertPole,
  insertConduit,
  insertContract,
};

export default formInsertActions;
