import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import FormValue from './FormValue';
import SectionHeader from './SectionHeader';

const Applicant = ({ appSummary }) => {
  return (
    <View>
      <SectionHeader headerText="RailPermitting.com Applicant" />
      <FormValue fieldText="Name" valueText={appSummary.user.name} />
      <FormValue fieldText="Title" valueText={appSummary.user.title} />
      <FormValue fieldText="Company" valueText={appSummary.user.company} />
      <FormValue fieldText="Phone" valueText={appSummary.user.phoneNumber} />
      <FormValue fieldText="Email" valueText={appSummary.user.email} />
      <FormValue
        fieldText="Project Description"
        valueText={appSummary.applicationDetail.projectDescription}
      />
    </View>
  );
};

Applicant.propTypes = {
  appSummary: PropTypes.object.isRequired,
};

export default Applicant;
