import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import formConstants from '../constants/formConstants';
import { acquireAccessToken } from '../Auth/utils';

function* insertApplication(action) {
  const { permitType } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/application`,
      data: { permitTypeId: permitType.id },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    switch (permitType.value) {
      case 'PIPELINE':
        yield insertPipeline({ applicationId: data.applicationId, pipeline: {} });
        break;
      case 'WIRELINE':
        yield insertWireline({ applicationId: data.applicationId, wireline: {} });
        break;
      case 'TEMPORARYOCCUPANCY':
        yield insertTemporaryOccupancy({
          applicationId: data.applicationId,
          temporaryOccupancy: {},
        });
        break;
      case 'PRIVATECROSSING':
        yield insertPrivateCrossing({ applicationId: data.applicationId, privateCrossing: {} });
        break;
      case 'ROADWAYSURFACING':
        yield insertRoadwaySurfacing({ applicationId: data.applicationId, roadwaySurfacing: {} });
        break;
      case 'GENERALLICENSE':
        yield insertGeneralLicense({ applicationId: data.applicationId, generalLicense: {} });
        break;
      case 'OVERSIZELOADMOVE':
        yield insertOversizeLoadMove({
          applicationId: data.applicationId,
          oversizeLoadMove: {},
        });
        break;
      case 'ASSIGNMENT':
        yield insertAssignment({ applicationId: data.applicationId, assignment: {} });
        break;
      case 'ENVIRONMENTALRIGHTOFWAY':
        yield insertEnvironmental({ applicationId: data.applicationId, environmental: {} });
        break;
      default:
        break;
    }
    yield put({ type: formConstants.insertApplicationSucceeded, application: data });
    yield call(action.resolve, data.applicationId);
  } catch (e) {
    yield put({ type: formConstants.insertApplicationFailed, message: e.message });
    yield call(action.reject, e);
  }
}

function* insertPipeline(action) {
  const { applicationId, pipeline } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/pipeline`,
      params: { applicationId },
      data: pipeline,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertPipelineSucceeded, pipeline: data });
  } catch (e) {
    yield put({ type: formConstants.insertPipelineFailed, message: e.message });
  }
}

function* insertPipelineCasing(action) {
  const { pipelineId, pipelineCasing, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/pipeline/${Number(pipelineId)}/casing`,
      data: pipelineCasing,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertPipelineCasingSucceeded, pipelineCasing: data });
    yield call(resolve, data.pipelineId);
  } catch (e) {
    yield put({ type: formConstants.insertPipelineCasingFailed, message: e.message });
    yield call(reject, e);
  }
}

function* insertPipelineCarrier(action) {
  const { pipelineId, pipelineCarrier, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/pipeline/${Number(pipelineId)}/carrier`,
      data: pipelineCarrier,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertPipelineCarrierSucceeded, pipelineCarrier: data });
    yield call(resolve, data.pipelineId);
  } catch (e) {
    yield put({ type: formConstants.insertPipelineCarrierFailed, message: e.message });
    yield call(reject, e);
  }
}

function* insertWireline(action) {
  const { applicationId, wireline } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/wireline`,
      params: { applicationId },
      data: wireline,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertWirelineSucceeded, wireline: data });
  } catch (e) {
    yield put({ type: formConstants.insertWirelineFailed, message: e.message });
  }
}

function* insertUndergroundWireline(action) {
  const { wirelineId, undergroundWireline, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/wireline/${Number(wirelineId)}/underground`,
      data: undergroundWireline,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: formConstants.insertUndergroundWirelineSucceeded,
      undergroundWireline: data,
    });
    yield call(resolve, data.wirelineId);
  } catch (e) {
    yield put({ type: formConstants.insertUndergroundWirelineFailed, message: e.message });
    yield call(reject, e);
  }
}

function* insertOverheadWireline(action) {
  const { wirelineId, overheadWireline, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/wireline/${Number(wirelineId)}/overhead`,
      data: overheadWireline,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: formConstants.insertOverheadWirelineSucceeded,
      overheadWireline: data,
    });
    yield call(resolve, data.wirelineId);
  } catch (e) {
    yield put({ type: formConstants.insertOverheadWirelineFailed, message: e.message });
    yield call(reject, e);
  }
}

function* insertPole(action) {
  const { overheadWirelineId, pole, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/wireline/overhead/${Number(overheadWirelineId)}/pole`,
      data: pole,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertPoleSucceeded, pole: data });
    yield call(resolve, data);
  } catch (e) {
    yield put({ type: formConstants.insertPoleFailed, message: e.message });
    yield call(reject, e);
  }
}

function* insertConduit(action) {
  const { undergroundWirelineId, conduit, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/wireline/underground/${Number(
        undergroundWirelineId
      )}/conduit`,
      data: conduit,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertConduitSucceeded, conduit: data });
    yield call(resolve, data);
  } catch (e) {
    yield put({ type: formConstants.insertConduitFailed, message: e.message });
    yield call(reject, e);
  }
}

function* insertTemporaryOccupancy(action) {
  const { applicationId, temporaryOccupancy } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/temporaryoccupancy`,
      params: { applicationId },
      data: temporaryOccupancy,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertTemporaryOccupancySucceeded, temporaryOccupancy: data });
  } catch (e) {
    yield put({ type: formConstants.insertTemporaryOccupancyFailed, message: e.message });
  }
}

function* insertPrivateCrossing(action) {
  const { applicationId, privateCrossing } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/privatecrossing`,
      params: { applicationId },
      data: privateCrossing,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertPrivateCrossingSucceeded, privateCrossing: data });
  } catch (e) {
    yield put({ type: formConstants.insertPrivateCrossingFailed, message: e.message });
  }
}

function* insertRoadwaySurfacing(action) {
  const { applicationId, roadwaySurfacing } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/roadwaysurfacing`,
      params: { applicationId },
      data: roadwaySurfacing,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertRoadwaySurfacingSucceeded, privateCrossing: data });
  } catch (e) {
    yield put({ type: formConstants.insertRoadwaySurfacingFailed, message: e.message });
  }
}

function* insertGeneralLicense(action) {
  const { applicationId, generalLicense } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/generallicense`,
      params: { applicationId },
      data: generalLicense,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertGeneralLicenseSucceeded, privateCrossing: data });
  } catch (e) {
    yield put({ type: formConstants.insertGeneralLicenseFailed, message: e.message });
  }
}

function* insertOversizeLoadMove(action) {
  const { applicationId, oversizeLoadMove } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/oversizeloadmove`,
      params: { applicationId },
      data: oversizeLoadMove,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertOversizeLoadMoveSucceededd, oversizeLoadMove: data });
  } catch (e) {
    yield put({ type: formConstants.insertOversizeLoadMoveFailed, message: e.message });
  }
}

function* insertAssignment(action) {
  const { applicationId, assignment } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/assignment`,
      params: { applicationId },
      data: assignment,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertAssignmentSucceeded, assignment: data });
  } catch (e) {
    yield put({ type: formConstants.insertAssignmentFailed, message: e.message });
  }
}

function* insertContract(action) {
  const { assignmentId, contract, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/assignment/${Number(assignmentId)}/contract`,
      data: contract,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertContractSucceeded, contract: data });
    yield call(resolve, data);
  } catch (e) {
    yield put({ type: formConstants.insertContractFailed, message: e.message });
    yield call(reject, e);
  }
}

function* insertEnvironmental(action) {
  const { applicationId, environmental } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/environmental`,
      params: { applicationId },
      data: environmental,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.insertEnvironmentalSucceeded, environmental: data });
  } catch (e) {
    yield put({ type: formConstants.insertEnvironmentalFailed, message: e.message });
  }
}

const formInsertSagas = [
  takeLatest(formConstants.insertApplication, insertApplication),
  takeLatest(formConstants.insertPipeline, insertPipeline),
  takeLatest(formConstants.insertPipelineCasing, insertPipelineCasing),
  takeLatest(formConstants.insertPipelineCarrier, insertPipelineCarrier),
  takeLatest(formConstants.insertWireline, insertWireline),
  takeLatest(formConstants.insertUndergroundWireline, insertUndergroundWireline),
  takeLatest(formConstants.insertOverheadWireline, insertOverheadWireline),
  takeEvery(formConstants.insertPole, insertPole),
  takeEvery(formConstants.insertConduit, insertConduit),
  takeEvery(formConstants.insertContract, insertContract),
];

export default formInsertSagas;
