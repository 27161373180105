import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function Title(props) {
  return (
    <Typography variant="h4" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
};
