import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import withRoot from '../../../modules/withRoot';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../modules/AppInsights';
import ActiveUsers from './ActiveUsers';
import DeactivatedUsers from './DeactivatedUsers';
import adminActions from '../../../actions/adminActions';

appInsights.trackPageView('AdminApplications');

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0 15px',
  },
  button: {
    marginTop: 10,
  },
  dateContainer: {
    backgroundColor: 'white',
    padding: '10px 0px',
  },
  tabIndicator: { height: 5 },
}));

function Users() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminActions.getUsers());
    dispatch(adminActions.getRoles());
    dispatch(adminActions.getClaims());
    dispatch(adminActions.getTerritories());
    dispatch(adminActions.getTerritoryTypes());
  }, [dispatch]);

  const classes = useStyles();
  const [activeTabId, setActiveTab] = useState(0);

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Users
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" position="static" elevation={0}>
        <Tabs
          value={activeTabId}
          textColor="inherit"
          onChange={(evt, value) => setActiveTab(value)}
          classes={{ indicator: classes.tabIndicator }}
        >
          <Tab label="Active" icon={<PersonIcon />} />
          <Tab label="Deactivated" icon={<PersonOffIcon />} />
        </Tabs>
      </AppBar>
      {activeTabId === 0 && (
        <Grid container justifyContent="center" className={classes.wrapper}>
          <Grid item xs={12} style={{ overflow: 'auto' }}>
            <ActiveUsers />
          </Grid>
        </Grid>
      )}
      {activeTabId === 1 && (
        <Grid container justifyContent="center" className={classes.wrapper}>
          <Grid item xs={12} style={{ overflow: 'auto' }}>
            <DeactivatedUsers />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

Users.propTypes = {};

export default withRoot(withAITracking(reactPlugin, Users));
