const processApplicationConstants = {
  getApplicationReviews: 'GET_APPLICATION_REVIEWS',
  getApplicationReviewsSuccess: 'GET_APPLICATION_REVIEWS_SUCCESS',
  getApplicationReviewsError: 'GET_APPLICATION_REVIEWS_ERROR',

  addReviewGroup: 'ADD_REVIEW_GROUP',
  addReviewGroupSuccess: 'ADD_REVIEW_GROUP_SUCCESS',
  addReviewGroupError: 'ADD_REVIE_GROUP_ERROR',

  getAssignmentData: 'GET_ASSIGNMENT_DATA',
  getAssignmentDataSuccess: 'GET_ASSIGNMENT_DATA_SUCCESS',
  getAssignmentDataError: 'GET_ASSIGNMENT_DATA_ERROR',

  updateReviewAssignments: 'UPDATE_REVIEW_ASSIGNMENTS',
  updateReviewAssignmentsSuccess: 'UPDATE_REVIEW_ASSIGNMENTS_SUCCESS',
  updateReviewAssignmentsError: 'UPDATE_REVIEW_ASSIGNMENTS_ERROR',

  remindReviewGroup: 'REMIND_REVIEW_GROUP',
  remindReviewGroupSuccess: 'REMIND_REVIEW_GROUP_SUCCESS',
  remindReviewGroupError: 'REMIND_REVIEW_GROUP_ERROR',

  removeReviewGroup: 'REMOVE_REVIEW_GROUP',
  removeReviewGroupSuccess: 'REMOVE_REVIEW_GROUP_SUCCESS',
  removeReviewGroupError: 'REMOVE_REVIEW_GROUP_ERROR',

  approveReview: 'APPROVE_REVIEW',
  approveReviewSuccess: 'APPROVE_REVIEW_SUCCESS',
  approveReviewError: 'APPROVE_REVIEW_ERROR',

  denyReview: 'DENY_REVIEW',
  denyReviewSuccess: 'DENY_REVIEW_SUCCESS',
  denyReviewError: 'DENY_REVIEW_ERROR',

  reopenReview: 'REOPEN_REVIEW',
  reopenReviewSuccess: 'REOPEN_REVIEW_SUCCESS',
  reopenReviewError: 'REOPEN_REVIEW_ERROR',

  reopenApplication: 'REOPEN_APPLICATION',
  reopenApplicationSuccess: 'REOPEN_APPLICATION_SUCCESS',
  reopenApplicationError: 'REOPEN_APPLICATION_ERROR',

  executeApplication: 'EXECUTE_APPLICATION',
  executeApplicationSuccess: 'EXECUTE_APPLICATION_SUCCESS',
  executeApplicationError: 'EXECUTE_APPLICATION_ERROR',

  changeApplicationType: 'CHANGE_APPLICATION_TYPE',
  changeApplicationTypeSuccess: 'CHANGE_APPLICATION_TYPE_SUCCESS',
  changeApplicationTypeError: 'CHANGE_APPLICATION_TYPE_ERROR',

  getApplicationHistory: 'GET_APPLICATION_HISTORY',
  getApplicationHistorySuccess: 'GET_APPLICATION_HISTORY_SUCCESS',
  getApplicationHistoryError: 'GET_APPLICATION_HISTORY_ERROR',

  getApplicationHistoryCount: 'GET_APPLICATION_HISTORY_COUNT',
  getApplicationHistoryCountSuccess: 'GET_APPLICATION_HISTORY_COUNT_SUCCESS',
  getApplicationHistoryCountError: 'GET_APPLICATION_HISTORY_COUNT_ERROR',

  getApplicationSummaryData: 'GET_APPLICATION_SUMMARY_DATA',
  getApplicationSummaryDataSuccess: 'GET_APPLICATION_SUMMARY_DATA_SUCCESS',
  getApplicationSummaryDataError: 'GET_APPLICATION_SUMMARY_DATA_ERROR',
};

export default processApplicationConstants;
