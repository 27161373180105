import React from 'react';
import { Grid, Link } from '@mui/material';
import Typography from '../components/Typography';

function Copyright() {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ padding: '20px 0' }}
        >
          {'Copyright © '}
          <Link
            color="inherit"
            href="https://www.bartlettwest.com/"
            target="_blank"
            underline="hover"
          >
            Bartlett &amp; West
          </Link>{' '}
          {new Date().getFullYear()} - v{process.env.REACT_APP_VERSION}
        </Typography>
      </Grid>
    </Grid>
  );
}

Copyright.propTypes = {};

export default Copyright;
