import homeConstants from '../constants/homeConstants';

function getReviewerApplications(startDate, endDate) {
  return { type: homeConstants.getReviewerApplications, startDate, endDate };
}

function getAllUnpaidFees() {
  return { type: homeConstants.getAllUnpaidFees };
}

function getPaymentHistory() {
  return { type: homeConstants.getPaymentHistory };
}

function getSearchOptions() {
  return { type: homeConstants.getSearchOptions };
}

function searchApplications(searchParams) {
  return { type: homeConstants.searchApplications, searchParams };
}

function shareApplications(applicationIds, emailAddresses) {
  return { type: homeConstants.shareApplications, applicationIds, emailAddresses };
}

function deleteApplicationShare(applicationShareId, applicationId) {
  return { type: homeConstants.deleteApplicationShare, applicationShareId, applicationId };
}

function deleteApplication(applicationId) {
  return { type: homeConstants.deleteApplication, applicationId };
}

const homeActions = {
  getReviewerApplications,
  getAllUnpaidFees,
  getPaymentHistory,
  searchApplications,
  getSearchOptions,
  shareApplications,
  deleteApplicationShare,
  deleteApplication,
};

export default homeActions;
