import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import commonActions from './actions/commonActions';
import userActions from './actions/userActions';
import AppRouter from './AppRouter';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './Auth/authConfig';
import LoginError from './Auth/LoginError';

const useStyles = makeStyles(() => ({
  progressContainer: {
    position: 'absolute',
    width: 'calc(100% - 30px)',
    height: 'calc(100% - 100px)',
    zIndex: 1,
    opacity: 0.8,
  },
  progress: {
    position: 'absolute',
    top: '40%',
    right: '50%',
  },
}));

function LoadingComponent() {
  const classes = useStyles();

  return (
    <div className={classes.progressContainer}>
      <CircularProgress className={classes.progress} size={90} />
    </div>
  );
}

function ErrorComponent({ error }) {
  console.error(JSON.stringify(error));
  return <LoginError />;
}
ErrorComponent.propTypes = {
  error: PropTypes.any.isRequired,
};

function InitializeComponent() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.getConfig());
    dispatch(commonActions.getLookups());
    dispatch(userActions.getUser());
  }, [dispatch]);

  const { lookupsLoaded } = useSelector((state) => state.common);
  const { user } = useSelector((state) => state);

  if (lookupsLoaded === true && user && user.id) {
    localStorage.setItem('railpermitting_loginHint', user?.email);
    return <AppRouter />;
  }

  return <LoadingComponent />;
}

function App() {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <InitializeComponent />
    </MsalAuthenticationTemplate>
  );
}

export default App;
