import formConstants from '../constants/formConstants';

function varianceExplanationChange(varianceId, explanation) {
  return { type: formConstants.varianceExplanationChange, varianceId, explanation };
}

function clearSelectedApplication() {
  return { type: formConstants.clearSelectedApplication };
}

const formChangeActions = {
  varianceExplanationChange,
  clearSelectedApplication,
};

export default formChangeActions;
