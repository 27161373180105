import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FormValue from '../Components/FormValue';
import SectionHeader from '../Components/SectionHeader';
import Applicant from '../Components/Applicant';
import { View } from '@react-pdf/renderer';
import ApplicantDetails from '../Components/ApplicantDetails';
import LocationDetails from '../Components/LocationDetails';
import SummaryLayout from '../Components/SummaryLayout';

const OversizeLoadMoveSummary = ({ appSummary, history }) => {
  return (
    <SummaryLayout
      appSummary={appSummary}
      history={history}
      headerTitle={`Application for ${appSummary.permitType}`}
    >
      <Applicant appSummary={appSummary} />
      <ApplicantDetails appSummary={appSummary} />
      <View>
        <SectionHeader headerText="Application Details" />
        <FormValue
          fieldText="Expected start date"
          valueText={moment(appSummary.applicationDetail.startDate).format('MM/DD/YYYY')}
        />
      </View>
      <LocationDetails appSummary={appSummary} />
      <SectionHeader headerText="Oversize Load Move" />
      <FormValue
        fieldText="Will other crossings be used for this move"
        valueText={appSummary.oversizeLoadMove.otherCrossings}
      />
      {appSummary.oversizeLoadMove.otherCrossings && (
        <View style={{ marginLeft: 15 }}>
          <FormValue fieldText="Road name" valueText={appSummary.oversizeLoadMove.roadName1}>
            <FormValue fieldText="DOT number" valueText={appSummary.oversizeLoadMove.dotNumber1} />
          </FormValue>
          <FormValue fieldText="Road name" valueText={appSummary.oversizeLoadMove.roadName2}>
            <FormValue fieldText="DOT number" valueText={appSummary.oversizeLoadMove.dotNumber2} />
          </FormValue>
          <FormValue fieldText="Road name" valueText={appSummary.oversizeLoadMove.roadName3}>
            <FormValue fieldText="DOT number" valueText={appSummary.oversizeLoadMove.dotNumber3} />
          </FormValue>
        </View>
      )}
      <FormValue
        fieldText="Describe the structure to be moved"
        valueText={appSummary.oversizeLoadMove.structureToMove}
      />
      <FormValue fieldText="Dimensions of structure" valueText="" />
      <View style={{ marginLeft: 15 }}>
        <FormValue fieldText="Width" valueText={appSummary.oversizeLoadMove.structureWidth} />
        <FormValue fieldText="Height" valueText={appSummary.oversizeLoadMove.structureHeight} />
        <FormValue fieldText="Length" valueText={appSummary.oversizeLoadMove.structureLength} />
        <FormValue
          fieldText="Approx. weight"
          valueText={appSummary.oversizeLoadMove.approxWeight}
        />
      </View>
      <FormValue
        fieldText="One time crossing or multiple loads"
        valueText={appSummary.oversizeLoadMove.oversizeLoadMoveFrequency}
      />
      <FormValue
        fieldText="Date(s) and approximate time(s) crossing will be required"
        valueText={appSummary.oversizeLoadMove.requiredTime}
      />
    </SummaryLayout>
  );
};

OversizeLoadMoveSummary.propTypes = {
  appSummary: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
};

export default OversizeLoadMoveSummary;
