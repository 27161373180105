import formConstants from '../constants/formConstants';
import homeConstants from '../constants/homeConstants';
import documentConstants from '../constants/documentConstants';
import adminConstants from '../constants/adminConstants';

const initialState = {
  applications: [],
  reviewerApplications: {
    assigned: [],
    other: [],
  },
  unpaidFees: [],
  paymentHistory: [],
  gettingApplications: false,
  gettingAllUnpaidFees: false,
  gettingPaymentHistory: false,
  downloadingAgreement: false,
  gettingSearchOptions: false,
  searchOptions: { businessIds: [] },
  searchingApplications: false,
  searchResults: [],
  sharingApplications: false,
  deletingApplicationShare: false,
  deletingApplication: false,
};

function home(state = initialState, action) {
  let applicationsCopy = [];
  let reviewerApplicationsCopy = {
    other: [],
    assigned: [],
  };
  switch (action.type) {
    case formConstants.getAllApplications:
      return { ...state, gettingApplications: true };
    case formConstants.getAllApplicationsSucceeded:
      return { ...state, gettingApplications: false, applications: [...action.applications] };
    case formConstants.getAllApplicationsFailed:
      return { ...state, gettingApplications: false };

    case homeConstants.getReviewerApplications:
      return { ...state, gettingApplications: true };
    case homeConstants.getReviewerApplicationsSuccess:
      return { ...state, gettingApplications: false, reviewerApplications: action.result };
    case homeConstants.getReviewerApplicationsError:
      return { ...state, gettingApplications: false };

    case homeConstants.getAllUnpaidFees:
      return { ...state, gettingAllUnpaidFees: true };
    case homeConstants.getAllUnpaidFeesSuccess:
      return { ...state, gettingAllUnpaidFees: false, unpaidFees: [...action.unpaidFees] };
    case homeConstants.getAllUnpaidFeesError:
      return { ...state, gettingAllUnpaidFees: false };

    case homeConstants.getPaymentHistory:
      return { ...state, gettingPaymentHistory: true };
    case homeConstants.getPaymentHistorySuccess:
      return { ...state, gettingPaymentHistory: false, paymentHistory: [...action.paymentHistory] };
    case homeConstants.getPaymentHistoryError:
      return { ...state, gettingPaymentHistory: false };

    case documentConstants.downloadAgreement:
      return { ...state, downloadingAgreement: true };
    case documentConstants.downloadAgreementSuccess:
      return { ...state, downloadingAgreement: false };
    case documentConstants.downloadAgreementError:
      return { ...state, downloadingAgreement: false };

    case homeConstants.getSearchOptions:
      return { ...state, gettingSearchOptions: true };
    case homeConstants.getSearchOptionsSuccess:
      return {
        ...state,
        gettingSearchOptions: false,
        searchOptions: action.searchOptions,
      };
    case homeConstants.getSearchOptionsError:
      return { ...state, gettingSearchOptions: false };

    case homeConstants.searchApplications:
      return { ...state, searchingApplications: true };
    case homeConstants.searchApplicationsSuccess:
      return {
        ...state,
        searchingApplications: false,
        searchResults: action.applications,
      };
    case homeConstants.searchApplicationsError:
      return { ...state, searchingApplications: false };

    case homeConstants.shareApplications:
      return { ...state, sharingApplications: true };
    case homeConstants.shareApplicationsSuccess:
      const applicationShares = action.applicationShares;
      applicationsCopy = [...state.applications];
      applicationShares.forEach((share) => {
        const app = applicationsCopy.find((a) => a.applicationId === share.applicationId);
        if (app) {
          if (!app.applicationShares) {
            app.applicationShares = [];
          }
          app.applicationShares = [...app.applicationShares, share];
        }
      });
      return { ...state, sharingApplications: false, applications: applicationsCopy };
    case homeConstants.shareApplicationsError:
      return { ...state, sharingApplications: false };

    case homeConstants.deleteApplicationShare:
      return { ...state, deletingApplicationShare: true };
    case homeConstants.deleteApplicationShareSuccess:
      applicationsCopy = [...state.applications];
      const idx = applicationsCopy.findIndex((a) => a.applicationId === action.applicationId);
      if (idx !== -1) {
        applicationsCopy[idx].applicationShares = applicationsCopy[idx].applicationShares.filter(
          (s) => s.applicationShareId !== action.applicationShareId
        );
      }
      return { ...state, deletingApplicationShare: false, applications: [...applicationsCopy] };
    case homeConstants.deleteApplicationShareError:
      return { ...state, deletingApplicationShare: false };

    case homeConstants.deleteApplication:
      return { ...state, deletingApplication: true };
    case homeConstants.deleteApplicationSuccess:
      applicationsCopy = [...state.applications];
      const deletedAppRemoved = applicationsCopy.filter(
        (a) => a.applicationId !== action.applicationId
      );
      return { ...state, deletingApplication: false, applications: [...deletedAppRemoved] };
    case homeConstants.deleteApplicationError:
      return { ...state, deletingApplication: false };

    case adminConstants.submitApplicationSucceeded:
      reviewerApplicationsCopy = {
        other: [...state.reviewerApplications.other],
        assigned: [...state.reviewerApplications.assigned],
      };

      const submitResult = updateReviewerApplication(
        action.applicationId,
        reviewerApplicationsCopy,
        action.submittedStatus,
        null,
        null
      );

      if (submitResult.foundMatch === false) {
        console.warn('Did not find matching application after Admin submission');
        return { ...state };
      }

      return {
        ...state,
        reviewerApplications: submitResult.reviewerApplications,
      };
    case adminConstants.undoPMApprovalSucceeded:
      reviewerApplicationsCopy = {
        other: [...state.reviewerApplications.other],
        assigned: [...state.reviewerApplications.assigned],
      };

      const pmResult = updateReviewerApplication(
        action.applicationId,
        reviewerApplicationsCopy,
        action.applicationStatus,
        action.applicationReviews,
        null
      );

      if (pmResult.foundMatch === false) {
        console.warn('Did not find matching application after undoing PM approval');
        return { ...state };
      }

      return {
        ...state,
        reviewerApplications: pmResult.reviewerApplications,
      };
    case adminConstants.changeApplicationOwnerSucceeded:
      reviewerApplicationsCopy = {
        other: [...state.reviewerApplications.other],
        assigned: [...state.reviewerApplications.assigned],
      };
      const changeOwnerResult = updateReviewerApplication(
        action.applicationId,
        reviewerApplicationsCopy,
        null,
        null,
        action.user
      );

      if (changeOwnerResult.foundMatch === false) {
        console.warn('Did not find matching application after changing ownership');
        return { ...state };
      }

      return {
        ...state,
        reviewerApplications: changeOwnerResult.reviewerApplications,
      };

    default:
      return state;
  }
}

function updateReviewerApplication(
  applicationId,
  reviewerApplications,
  newStatus = null,
  newReviews = null,
  user = null
) {
  let foundMatch = false;
  reviewerApplications.other.forEach((a, idx) => {
    if (a.applicationId === applicationId) {
      foundMatch = true;
      if (newStatus) {
        reviewerApplications.other[idx].applicationStatus = newStatus;
      }
      if (newReviews) {
        reviewerApplications.other[idx].applicationReviews = newReviews;
      }
      if (user) {
        reviewerApplications.other[idx].user = user;
      }
    }
  });
  if (foundMatch === false) {
    reviewerApplications.assigned.forEach((a, idx) => {
      if (a.applicationId === applicationId) {
        foundMatch = true;
        if (newStatus) {
          reviewerApplications.assigned[idx].applicationStatus = newStatus;
        }
        if (newReviews) {
          reviewerApplications.assigned[idx].applicationReviews = newReviews;
        }
        if (user) {
          reviewerApplications.assigned[idx].user = user;
        }
      }
    });
  }

  return { foundMatch, reviewerApplications };
}

export default home;
