import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import formConstants from '../constants/formConstants';
import { acquireAccessToken } from '../Auth/utils';

function* deletePipelineCasing(action) {
  const { pipelineCasingId, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/pipeline/casing/${Number(pipelineCasingId)}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.deletePipelineCasingSucceeded });
    yield call(resolve);
  } catch (e) {
    yield put({ type: formConstants.deletePipelineCasingFailed, message: e.message });
    yield call(reject, e);
  }
}

function* deleteUndergroundWireline(action) {
  const { undergroundWirelineId, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/wireline/underground/${Number(undergroundWirelineId)}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.deleteUndergroundWirelineSucceeded });
    yield call(resolve);
  } catch (e) {
    yield put({ type: formConstants.deleteUndergroundWirelineFailed, message: e.message });
    yield call(reject, e);
  }
}

function* deleteOverheadWireline(action) {
  const { overheadWirelineId, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/wireline/overhead/${Number(overheadWirelineId)}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.deleteOverheadWirelineSucceeded });
    yield call(resolve);
  } catch (e) {
    yield put({ type: formConstants.deleteOverheadWirelineFailed, message: e.message });
    yield call(reject, e);
  }
}

function* deletePole(action) {
  const { poleId, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/wireline/overhead/pole/${Number(poleId)}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.deletePoleSucceeded, poleId });
    yield call(resolve);
  } catch (e) {
    yield put({ type: formConstants.deleteConduitFailed, message: e.message });
    yield call(reject, e);
  }
}

function* deleteConduit(action) {
  const { undergroundWirelineConduitId, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/wireline/underground/conduit/${Number(
        undergroundWirelineConduitId
      )}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.deleteConduitSucceeded, undergroundWirelineConduitId });
    yield call(resolve);
  } catch (e) {
    yield put({ type: formConstants.deleteConduitFailed, message: e.message });
    yield call(reject, e);
  }
}

function* deleteContract(action) {
  const { assignmentContractId, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/assignment/contract/${Number(assignmentContractId)}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: formConstants.deleteContractSucceeded, assignmentContractId });
    yield call(resolve);
  } catch (e) {
    yield put({ type: formConstants.deleteContractFailed, message: e.message });
    yield call(reject, e);
  }
}

const formDeleteSagas = [
  takeLatest(formConstants.deletePipelineCasing, deletePipelineCasing),
  takeLatest(formConstants.deleteUndergroundWireline, deleteUndergroundWireline),
  takeLatest(formConstants.deleteOverheadWireline, deleteOverheadWireline),
  takeEvery(formConstants.deletePole, deletePole),
  takeEvery(formConstants.deleteConduit, deleteConduit),
  takeEvery(formConstants.deleteContract, deleteContract),
];

export default formDeleteSagas;
