import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import withRoot from '../modules/withRoot';
import { getLookup } from '../helpers/utils';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginTop: 10,
    overflowX: 'auto',
  },
  table: {
    minWidth: 450,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

function createData(pending, approved, denied, reason) {
  return { pending, approved, denied, reason };
}

function createStatusData(applicationReviews, reviewStatuses, reviewGroups) {
  const statusData = [];
  applicationReviews.forEach((review) => {
    const reviewGroupLu = getLookup(reviewGroups, review.reviewGroupId);
    const reviewStatusLu = getLookup(reviewStatuses, review.reviewStatusId);
    if (reviewStatusLu.value === 'PENDINGREVIEW') {
      statusData.push(createData(reviewGroupLu.name, null, null, null));
    } else if (reviewStatusLu.value === 'APPROVED') {
      statusData.push(createData(null, reviewGroupLu.name, null, null));
    } else if (reviewStatusLu.value === 'DENIED') {
      statusData.push(createData(null, null, reviewGroupLu.name, review.reason));
    }
  });

  return statusData;
}

function StatusDetails({ applicationReviews, open, close }) {
  const classes = useStyles();
  const { reviewStatuses, reviewGroups } = useSelector((state) => state.common.lookups);
  const statusData = createStatusData(applicationReviews, reviewStatuses, reviewGroups);

  return (
    <div>
      <Dialog title="Status" open={open}>
        <DialogTitle>Status Details</DialogTitle>
        <DialogContent>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" className={classes.bold}>
                    Pending
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" className={classes.bold}>
                    Approved
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" className={classes.bold}>
                    Denied
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" className={classes.bold}>
                    Reason
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statusData.map((row, id) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={id}>
                  <TableCell>{row.pending}</TableCell>
                  <TableCell>{row.approved}</TableCell>
                  <TableCell>{row.denied}</TableCell>
                  <TableCell>{row.reason}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

StatusDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  applicationReviews: PropTypes.array.isRequired,
};

export default withRoot(StatusDetails);
