import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import reportConstants from '../constants/reportConstants';
import alertConstants from '../constants/alertConstants';
import { acquireAccessToken } from '../Auth/utils';

function* getCycleTimeRptData(action) {
  const { startDate, endDate } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/ctRptData`,
      params: { startDate, endDate },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: reportConstants.getCycleTimeRptDataSucceeded, cycleTimeRptData: data });
  } catch (e) {
    yield put({ type: reportConstants.getCycleTimeRptDataFailed, error: e });
  }
}

function* getApplicationCycleTime(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/applicationCycleTime`,
      params: { applicationId },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: reportConstants.getApplicationCycleTimeSucceeded, cycleTime: data });
  } catch (e) {
    yield put({ type: reportConstants.getApplicationCycleTimeFailed, error: e });
  }
}

function* getVarianceRptData(action) {
  const { startDate, endDate } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/varRptData`,
      params: { startDate, endDate },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: reportConstants.getVarianceRptDataSucceeded, varianceRptData: data });
  } catch (e) {
    yield put({ type: reportConstants.getVarianceRptDataFailed, error: e });
  }
}

function* getPaymentRptData(action) {
  const { startDate, endDate } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/paymentRptData`,
      params: { startDate, endDate },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: reportConstants.getPaymentRptDataSucceeded, paymentRptData: data });
  } catch (e) {
    yield put({ type: reportConstants.getPaymentRptDataFailed, error: e });
  }
}

function* getSummaryRptData(action) {
  const { startDate, endDate } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/summaryRptData`,
      params: { startDate, endDate },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: reportConstants.getSummaryRptDataSucceeded, summaryRptData: data });
    yield put({
      type: alertConstants.clear,
    });
  } catch (e) {
    yield put({ type: reportConstants.getSummaryRptDataFailed, error: e });
    yield put({
      type: alertConstants.error,
      message: 'Summary report query failed.',
    });
  }
}

function* getFinanceRptData(action) {
  const { startDate, endDate } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/finance`,
      params: { startDate, endDate },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: reportConstants.getFinanceRptDataSucceeded, data });
  } catch (e) {
    yield put({ type: reportConstants.getFinanceRptDataFailed, error: e });
  }
}

function* getReviwerBacklogReportData(action) {
  const { reviewGroupValue } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/reviewerBacklog`,
      params: { reviewGroupValue },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: reportConstants.getReviewerBacklogDataSucceeded, reviewerBacklogData: data });
  } catch (e) {
    yield put({ type: reportConstants.getReviewerBacklogDataFailed, error: e });
    yield put({
      type: alertConstants.error,
      message: 'Reviewer backlog report query failed.',
    });
  }
}

function* getTerritoryAssignmentsData() {
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/territoryAssignments`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: reportConstants.getTerritoryAssignmentsDataSucceeded,
      territoryAssignmentsData: data,
    });
  } catch (e) {
    yield put({ type: reportConstants.getTerritoryAssignmentsDataFailed, error: e });
    yield put({
      type: alertConstants.error,
      message: 'Territory assignments report query failed.',
    });
  }
}

function* getFirstSubmissionData(action) {
  const { startDate, endDate } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/firstsubmission`,
      params: { startDate, endDate },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: reportConstants.getFirstSubmissionDataSucceeded,
      firstSubmissionData: data,
    });
  } catch (e) {
    yield put({ type: reportConstants.getFirstSubmissionDataFailed, error: e });
    yield put({
      type: alertConstants.error,
      message: 'First submission report query failed.',
    });
  }
}

const commonSagas = [
  takeLatest(reportConstants.getCycleTimeRptData, getCycleTimeRptData),
  takeLatest(reportConstants.getApplicationCycleTime, getApplicationCycleTime),
  takeLatest(reportConstants.getVarianceRptData, getVarianceRptData),
  takeLatest(reportConstants.getPaymentRptData, getPaymentRptData),
  takeLatest(reportConstants.getSummaryRptData, getSummaryRptData),
  takeLatest(reportConstants.getFinanceRptData, getFinanceRptData),
  takeLatest(reportConstants.getReviewerBacklogData, getReviwerBacklogReportData),
  takeLatest(reportConstants.getTerritoryAssignmentsData, getTerritoryAssignmentsData),
  takeLatest(reportConstants.getFirstSubmissionData, getFirstSubmissionData),
];

export default commonSagas;
