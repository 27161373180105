import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import MaterialReactTable from 'material-react-table';
import withRoot from '../modules/withRoot';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppAppBar from '../modules/views/AppAppBar';
import reportActions from '../actions/reportActions';
import { CircularProgress, Modal } from '@mui/material';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Title from '../ProcessApplication/Title';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
TabPanel.defaultProps = {
  children: null,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(4),
  },
  textField: {
    marginRight: 10,
  },
  progress: {
    position: 'absolute',
    top: '40%',
    right: '50%',
  },
  csvLink: {
    fontWeight: 'bold',
  },
}));

function prepareReportData(data) {
  if (!data) {
    return {
      permitManagerAssignments: [],
      engineeringAssignments: [],
    };
  }
  const permitManagerAssignments = data.filter((d) => d.role === 'PERMITMANAGER');
  const engineeringAssignments = data.filter((d) => d.role === 'ENGINEERING');
  const railroadAssignments = data.filter((d) => d.role === 'RAILROAD');

  return {
    permitManagerAssignments,
    engineeringAssignments,
    railroadAssignments,
  };
}

function TerritoryAssignmentsRpt({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportActions.getTerritoryAssignmentsData());
  }, [dispatch]);

  const [selectedTab, setSelectedTab] = React.useState(0);

  const { territoryAssignmentsData, queryingTerritoryAssignmentsData } = useSelector(
    (state) => state.reports
  );

  const rptData = prepareReportData(territoryAssignmentsData);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'phoneNumber',
        header: 'Phone Number',
      },
      {
        accessorKey: 'territoryName',
        header: 'Territory Name',
      },
    ],
    []
  );

  return (
    <>
      <Modal open={queryingTerritoryAssignmentsData}>
        <div>
          <CircularProgress className={classes.progress} size={90} />
        </div>
      </Modal>

      <AppAppBar pathname={location.pathname} />
      <main className={classes.content}>
        <Container className={classes.container}>
          <Paper square style={{ marginBottom: 20 }}>
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(evt, newValue) => setSelectedTab(newValue)}
              aria-label="disabled tabs example"
            >
              <Tab label="Permit Manager"></Tab>
              <Tab label="Engineering"></Tab>
              <Tab label="Railroad"></Tab>
            </Tabs>
          </Paper>
          <TabPanel value={selectedTab} index={0}>
            <MaterialReactTable
              columns={columns}
              data={rptData.permitManagerAssignments}
              enableStickyHeader
              enableGlobalFilter={false}
              enablePagination={false}
              renderTopToolbarCustomActions={() => {
                return (
                  <Title>
                    <span style={{ fontSize: '1.2rem', marginRight: 20 }}>
                      Territory Assignments
                    </span>
                  </Title>
                );
              }}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <MaterialReactTable
              columns={columns}
              data={rptData.engineeringAssignments}
              enableStickyHeader
              enableGlobalFilter={false}
              enablePagination={false}
              renderTopToolbarCustomActions={() => {
                return (
                  <Title>
                    <span style={{ fontSize: '1.2rem', marginRight: 20 }}>
                      Territory Assignments
                    </span>
                  </Title>
                );
              }}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <MaterialReactTable
              columns={columns}
              data={rptData.railroadAssignments}
              enableStickyHeader
              enableGlobalFilter={false}
              enablePagination={false}
              renderTopToolbarCustomActions={() => {
                return (
                  <Title>
                    <span style={{ fontSize: '1.2rem', marginRight: 20 }}>
                      Territory Assignments
                    </span>
                  </Title>
                );
              }}
            />
          </TabPanel>

          <Grid container spacing={2}>
            <Grid item xs={12}></Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}

TerritoryAssignmentsRpt.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRoot(TerritoryAssignmentsRpt);
