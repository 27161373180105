import adminConstants from '../constants/adminConstants';

const initialState = {
  gettingApplications: false,
  applications: [],
  submittingApplication: false,
  undoingPMApproval: false,
  changingApplicationOwner: false,
  gettingUsers: false,
  deactivatingUser: false,
  reactivatingUser: false,
  users: [],
  roles: [],
  claims: [],
  territoryTypes: [],
  territories: [],
  gettingRoles: false,
  gettingTerritories: false,
  gettingTerritoryTypes: false,
  gettingUserAssignedPermitTypes: false,
  gettingUserAssignedTerritories: false,
  editUser: {
    assignedPermitTypes: [],
    assignedTerritories: [],
  },
  savingUserEdits: false,
  savedUserEditsCount: 0, // just used to trigger update of EditUser component.
};

function admin(state = initialState, action) {
  switch (action.type) {
    case adminConstants.getAdminApplications:
      return { ...state, gettingApplications: true };
    case adminConstants.getAdminApplicationsSucceeded:
      return { ...state, gettingApplications: false, applications: action.applications };
    case adminConstants.getAdminApplicationsFailed:
      return { ...state, gettingApplications: false };

    case adminConstants.submitApplication:
      return { ...state, submittingApplication: true };
    case adminConstants.submitApplicationSucceeded:
      return { ...state, submittingApplication: false };
    case adminConstants.submitApplicationFailed:
      return { ...state, submittingApplication: false };

    case adminConstants.undoPMApproval:
      return { ...state, undoingPMApproval: true };
    case adminConstants.undoPMApprovalSucceeded:
      return { ...state, undoingPMApproval: false };
    case adminConstants.undoPMApprovalFailed:
      return { ...state, undoingPMApproval: false };

    case adminConstants.changeApplicationOwner:
      return { ...state, changingApplicationOwner: true };
    case adminConstants.changeApplicationOwnerSucceeded:
      return { ...state, changingApplicationOwner: false };
    case adminConstants.changeApplicationOwnerFailed:
      return { ...state, changingApplicationOwner: false };

    case adminConstants.getUsers:
      return { ...state, gettingUsers: true };
    case adminConstants.getUsersSucceeded:
      return { ...state, gettingUsers: false, users: [...action.users] };
    case adminConstants.getUsersFailed:
      return { ...state, gettingUsers: false };

    case adminConstants.deactivateUser:
      return { ...state, deactivatingUser: true };
    case adminConstants.deactivateUserSucceeded:
      const deactivateUsersCopy = [...state.users];
      const deactivatedUserIdx = deactivateUsersCopy.findIndex((u) => u.id === action.userId);
      if (deactivatedUserIdx === -1) {
        console.warn('Did not find deactivated user in users list');
        return { ...state, reactivatingUser: false };
      }
      deactivateUsersCopy[deactivatedUserIdx].isDeactivated = true;
      return { ...state, deactivatingUser: false, users: [...deactivateUsersCopy] };
    case adminConstants.deactivateUserFailed:
      return { ...state, deactivatingUser: false };

    case adminConstants.reactivateUser:
      return { ...state, reactivatingUser: true };
    case adminConstants.reactivateUserSucceeded:
      const reactivateUsersCopy = [...state.users];
      const reactivatedUserIdx = reactivateUsersCopy.findIndex((u) => u.id === action.userId);
      if (deactivatedUserIdx === -1) {
        console.warn('Did not find deactivated user in users list');
        return { ...state, reactivatingUser: false };
      }
      reactivateUsersCopy[reactivatedUserIdx].isDeactivated = false;
      return { ...state, reactivatingUser: false, users: [...reactivateUsersCopy] };
    case adminConstants.reactivateUserFailed:
      return { ...state, reactivatingUser: false };

    case adminConstants.getRoles:
      return { ...state, gettingRoles: true };
    case adminConstants.getRolesSucceeded:
      return { ...state, gettingRoles: false, roles: [...action.roles] };
    case adminConstants.getRolesFailed:
      return { ...state, gettingRoles: false };

    case adminConstants.getClaims:
      return { ...state, gettingClaims: true };
    case adminConstants.getClaimsSucceeded:
      return { ...state, gettingClaims: false, claims: [...action.claims] };
    case adminConstants.getClaimsFailed:
      return { ...state, gettingClaims: false };

    case adminConstants.getTerritoryTypes:
      return { ...state, gettingTerritoryTypes: true };
    case adminConstants.getTerritoryTypesSucceeded:
      return { ...state, gettingTerritoryTypes: false, territoryTypes: [...action.territoryTypes] };
    case adminConstants.getTerritoryTypesFailed:
      return { ...state, gettingTerritoryTypes: false };

    case adminConstants.getTerritories:
      return { ...state, gettingTerritories: true };
    case adminConstants.getTerritoriesSucceeded:
      return { ...state, gettingTerritories: false, territories: [...action.territories] };
    case adminConstants.getTerritoriesFailed:
      return { ...state, gettingTerritories: false };

    case adminConstants.getUserAssignedPermitTypes:
      return {
        ...state,
        gettingUserAssignedPermitTypes: true,
        editUser: { ...state.editUser, assignedPermitTypes: [] },
      };
    case adminConstants.getUserAssignedPermitTypesSucceeded:
      return {
        ...state,
        gettingUserAssignedPermitTypes: false,
        editUser: { ...state.editUser, assignedPermitTypes: [...action.assignedPermitTypes] },
      };
    case adminConstants.getUserAssignedPermitTypesFailed:
      return { ...state, gettingUserAssignedPermitTypes: false };

    case adminConstants.getUserAssignedTerritories:
      return {
        ...state,
        gettingUserAssignedTerritories: true,
        editUser: { ...state.editUser, assignedTerritories: [] },
      };
    case adminConstants.getUserAssignedTerritoriesSucceeded:
      return {
        ...state,
        gettingUserAssignedTerritories: false,
        editUser: { ...state.editUser, assignedTerritories: [...action.assignedTerritories] },
      };
    case adminConstants.getUserAssignedTerritoriesFailed:
      return { ...state, gettingUserAssignedTerritories: false };

    case adminConstants.saveUserEdits:
      return { ...state, savingUserEdits: true };
    case adminConstants.saveUserEditsSucceeded:
      const usersCopy = [...state.users];
      const userIndex = usersCopy.findIndex((u) => u.id === action.userId);
      if (userIndex !== -1) {
        usersCopy[userIndex].contractorId = action.contractorId;
        usersCopy[userIndex].userRoles = action.roleIds.map((roleId) => {
          return {
            roleId,
          };
        });
        usersCopy[userIndex].userClaims = action.claimIds.map((claimId) => {
          return { ...state.claims.find((c) => c.id === claimId), claimId };
        });
      }
      return {
        ...state,
        users: [...usersCopy],
        savingUserEdits: false,
        savedUserEditsCount: state.savedUserEditsCount + 1,
      };
    case adminConstants.saveUserEditsFailed:
      return { ...state, savingUserEdits: false };

    default:
      return state;
  }
}

export default admin;
