import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Button, Link, Box } from '@mui/material';
import Typography from '../components/Typography';
import LayoutBody from '../components/LayoutBody';
import ContactUsDialog from '../components/ContactUsDialog';
import { GetInstructionJSX } from '../../Home/Applicant/SelectPermitType';
import { getLookup } from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#ebf4d7',
  },
  layoutBody: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
  },
  footerHeader: {
    marginBottom: 7,
    fontSize: 22,
  },
}));

function AppFooter(props) {
  const classes = useStyles();
  const { match } = props;
  const { config } = useSelector((state) => state.common);
  const { permitTypes } = useSelector((state) => state.common.lookups);
  const { application } = useSelector((state) => state.selectedApplication);
  const [contactUsDialogOpen, setContactUsDialogOpen] = React.useState(false);

  let permitTypeLU = null;
  if (application.permitTypeId) {
    permitTypeLU = getLookup(permitTypes, application.permitTypeId);
  }

  const gridItemSize =
    match.path.match(/^\/application\//) && permitTypeLU && permitTypeLU.instructions ? 3 : 4;

  return (
    <>
      <ContactUsDialog
        open={contactUsDialogOpen}
        handleClose={() => setContactUsDialogOpen(false)}
      />
      <Typography component="footer" className={classes.root}>
        <LayoutBody className={classes.layoutBody} width="large">
          <Grid container spacing={2}>
            <Grid item sm={gridItemSize} xs={12}>
              <Box sx={{ textAlign: 'center' }}>
                <div>
                  <Typography variant="body1" className={classes.footerHeader}>
                    Company Information
                  </Typography>
                </div>
                <div>
                  <Typography variant="body1">
                    <>
                      Bartlett &amp; West<br></br> 1200 SW Executive Dr.<br></br> Topeka, KS, USA
                      66615
                    </>
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid item sm={gridItemSize} xs={12}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="body1" className={classes.footerHeader}>
                  Need Assistance?
                </Typography>
                <Button size="small" color="primary" onClick={() => setContactUsDialogOpen(true)}>
                  Contact Us
                </Button>
              </Box>
            </Grid>
            {gridItemSize === 3 && (
              <Grid item sm={gridItemSize} xs={12}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body1" className={classes.footerHeader}>
                    Form Instructions
                  </Typography>
                  {GetInstructionJSX(permitTypeLU, 'Click Here')}
                </Box>
              </Grid>
            )}
            <Grid item sm={gridItemSize} xs={12}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="body1" className={classes.footerHeader}>
                  Terms and Conditions
                </Typography>
                <Button size="small" color="primary">
                  <Link
                    href={`${process.env.PUBLIC_URL}/${config.termsAndConditionsDocumentName}`}
                    target="terms"
                    underline="hover"
                  >
                    Click Here
                  </Link>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </LayoutBody>
      </Typography>
    </>
  );
}

AppFooter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(AppFooter);
