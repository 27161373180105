import userConstants from '../constants/userConstants';

const initialState = {
  userName: null,
  email: null,
  roles: [],
  error: null,
  requestedByRailroadPermitManagers: [],
};

function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.getUser:
      return {
        ...state,
        userName: null,
        email: null,
        error: null,
      };
    case userConstants.getUserSucceeded:
      return { ...state, ...action.user };
    case userConstants.getUserFailed:
      return {
        ...state,
        error: action.error,
      };

    case userConstants.getRequestedByRailroadPermitManagers:
      return {
        ...state,
      };
    case userConstants.getRequestedByRailroadPermitManagersSucceeded:
      return { ...state, requestedByRailroadPermitManagers: action.permitManagers };
    case userConstants.getRequestedByRailroadPermitManagersFailed:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}

export default user;
