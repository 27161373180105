import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as moment from 'moment';
import MaterialReactTable from 'material-react-table';
import makeStyles from '@mui/styles/makeStyles';
import withRoot from '../modules/withRoot';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CycleTimeChart from './CycleTimeChart';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { CSVLink } from 'react-csv';
import AppAppBar from '../modules/views/AppAppBar';
import reportActions from '../actions/reportActions';
import { getDateOffset } from '../helpers/utils';
import Title from '../ProcessApplication/Title';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(4),
  },
  textField: {
    marginRight: 10,
  },
  csvLink: {
    fontWeight: 'bold',
  },
}));

const defaultTimelineData = [
  [
    { type: 'string', id: 'Applicant' },
    { type: 'string', id: 'Reviewer' },
    { type: 'date', id: 'Start' },
    { type: 'date', id: 'End' },
  ],
];

function prepareReportData(data) {
  const newData = [];
  data.forEach((item, idx) => {
    const submittalDate = moment(item.submittalDate);
    const newNode = {
      key: idx,
      reviewId_: item.applicationReviewId,
      permitType: item.permitType,
      legalName: item.legalName,
      reviewGroup: item.reviewGroup,
      reviewStatus: item.reviewStatus,
      applicationId_: item.applicationId,
      businessId: item.businessId,
      state: item.state,
      submittalDate: submittalDate.format('MM/DD/YYYY'),
      startDate: moment(item.reviewStartDateTime).format('MM/DD/YYYY'),
      endDate: moment(item.approveDateTime).format('MM/DD/YYYY'),
      maxCycleDays: moment(item.approveDateTime).diff(moment(item.reviewStartDateTime), 'days'),
    };
    newData.push(newNode);
  });

  return newData.sort((a, b) => a.applicationId_ - b.applicationId_);
}

function handleSelectionChange(rows) {
  const temp = [];
  const timeLineData = [...defaultTimelineData];
  rows.forEach((item) => {
    let existingIndex = temp.findIndex((element) => {
      return element.title === item.reviewGroup;
    });
    if (existingIndex === -1) {
      existingIndex =
        temp.push({ title: item.reviewGroup, count: 1, total: item.maxCycleDays }) - 1;
    } else {
      temp[existingIndex].count++;
      temp[existingIndex].total = temp[existingIndex].total + item.maxCycleDays;
    }

    timeLineData.push([
      item.businessId + '-' + item.legalName,
      item.reviewGroup,
      new Date(item.startDate),
      new Date(item.endDate),
    ]);
  });

  const barChartData = [['ReviewGroup', 'AvgDays']];
  temp.forEach((item) => {
    barChartData.push([item.title, Math.round(item.total / item.count)]);
  });

  return { selectionBarChartData: barChartData, selectionTimeLineData: timeLineData };
}

function createCsvData(reportData) {
  const data = [];
  reportData.forEach((row) => {
    data.push({
      applicationId: row.applicationId_,
      businessId: row.businessId,
      permitType: row.permitType,
      legalName: row.legalName,
      state: row.state,
      reviewGroup: row.reviewGroup,
      reviewStatus: row.reviewStatus,
      reviewStartDate: row.startDate,
      reviewEnd: row.endDate,
      cycleDays: row.maxCycleDays,
    });
  });
  return data;
}

function CycleTimeRpt({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialStartDate = getDateOffset(-60);
  const initialEndDate = getDateOffset(0);
  useEffect(() => {
    dispatch(reportActions.getCycleTimeRptData(initialStartDate, initialEndDate));
  }, [dispatch, initialEndDate, initialStartDate]);
  const [dateExtents, setDateExtents] = useState({
    begin: initialStartDate,
    end: initialEndDate,
  });

  const reportData = useSelector((state) => state.reports.cycleTimeRptData);
  const [barChartData, setBarCharData] = useState([]);
  const [timeLineData, setTimeLineData] = useState([]);

  const tableData = prepareReportData(reportData);
  const [csvData, setCsvData] = useState(createCsvData(tableData));

  const [rowSelection, setRowSelection] = useState({});
  React.useEffect(() => {
    const selectionKeys = Object.keys(rowSelection).map((k) => Number(k));
    const data = tableData.filter((td) => selectionKeys.includes(td.key));
    const { selectionBarChartData, selectionTimeLineData } = handleSelectionChange(data);
    setBarCharData(selectionBarChartData);
    setTimeLineData(selectionTimeLineData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection, dispatch]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'permitType',
        header: 'Permit Type',
      },
      {
        accessorKey: 'legalName',
        header: 'Legal Name',
      },
      {
        accessorKey: 'businessId',
        header: 'Business Id',
      },
      {
        accessorKey: 'reviewGroup',
        header: 'Review Group',
      },
      {
        accessorKey: 'state',
        header: 'State',
      },
      {
        accessorKey: 'reviewStatus',
        header: 'Review Status',
      },
      {
        accessorKey: 'startDate',
        header: 'Review Start',
      },
      {
        accessorKey: 'endDate',
        header: 'Review End',
      },
      {
        accessorKey: 'maxCycleDays',
        header: 'Cycle Days',
      },
    ],
    []
  );

  return (
    <>
      <AppAppBar pathname={location.pathname} />
      <main className={classes.content}>
        <Container className={classes.container}>
          <CycleTimeChart barChartData={barChartData} timelineData={timeLineData} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="fromDate"
                label="Begin Date"
                type="date"
                defaultValue={getDateOffset(-60)}
                className={classes.textField}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, begin: event.target.value };
                  setDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="toDate"
                label="End Date"
                type="date"
                defaultValue={getDateOffset(0)}
                className={classes.textField}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, end: event.target.value };
                  setDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  dispatch(reportActions.getCycleTimeRptData(dateExtents.begin, dateExtents.end));
                  setBarCharData([]);
                  setTimeLineData([]);
                }}
              >
                Get Cycle-Time Data
              </Button>
            </Grid>
            <Grid item xs={12}>
              <MaterialReactTable
                columns={columns}
                data={tableData}
                enableStickyHeader
                enableGlobalFilter={false}
                getRowId={(row) => row.key}
                enablePagination={false}
                enableRowSelection
                onRowSelectionChange={setRowSelection}
                state={{
                  rowSelection,
                }}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: row.getToggleSelectedHandler(),
                  sx: {
                    cursor: 'pointer',
                  },
                })}
                renderTopToolbarCustomActions={() => {
                  return (
                    <Title>
                      <span style={{ fontSize: '1.2rem' }}>Cycle-Time Report</span>
                      <CSVLink
                        data={csvData}
                        onClick={() => {
                          setCsvData(createCsvData(tableData));
                        }}
                        filename="Cycle_Time_Data.csv"
                        className={classes.csvLink}
                        style={{ fontSize: '1.1rem', marginLeft: '20px' }}
                      >
                        Export cycle time data to CSV
                      </CSVLink>
                    </Title>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}

CycleTimeRpt.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRoot(CycleTimeRpt);
