const documentConstants = {
  uploadDocuments: 'UPLOAD_DOCUMENTS',
  uploadDocumentsSuccess: 'UPLOAD_DOCUMENTS_SUCCESS',
  uploadDocumentsError: 'UPLOAD_DOCUMENTS_ERROR',

  deleteDocument: 'DELETE_DOCUMENT',
  deleteDocumentSuccess: 'DELETE_DOCUMENT_SUCCESS',
  deleteDocumentError: 'DELETE_DOCUMENT_ERROR',

  downloadDocument: 'DOWNLOAD_DOCUMENT',
  downloadDocumentSuccess: 'DOWNLOAD_DOCUMENT_SUCCESS',
  downloadDocumentError: 'DOWNLOAD_DOCUMENT_ERROR',

  downloadAgreement: 'DOWNLOAD_AGREEMENT',
  downloadAgreementSuccess: 'DOWNLOAD_AGREEMENT_SUCCESS',
  downloadAgreementError: 'DOWNLOAD_AGREEMENT_ERROR',

  getDraftExhibit: 'GET_DRAFT_EXHIBIT',
  getDraftExhibitSuccess: 'GET_DRAFT_EXHIBIT_SUCCESS',
  getDraftExhibitError: 'GET_DRAFT_EXHIBIT_ERROR',

  downloadAllAttachments: 'DOWNLOAD_ALL_ATTACHMENTS',
  downloadAllAttachmentsSuccess: 'DOWNLOAD_ALL_ATTACHMENTS_SUCCESS',
  downloadAllAttachmentsError: 'DOWNLOAD_ALL_ATTACHMENTS_ERROR',

  applicantSummaryDownload: 'APPLICANT_SUMMARY_DOWNLOAD',
  applicantSummaryDownloadSuccess: 'APPLICANT_SUMMARY_DOWNLOAD_SUCCESS',
  applicantSummaryDownloadError: 'APPLICANT_SUMMARY_DOWNLOAD_ERROR',

  downloadInvoice: 'DOWNLOAD_INVOICE',
  downloadInvoiceSuccess: 'DOWNLOAD_INVOICE_SUCCESS',
  downloadInvoiceError: 'DOWNLOAD_INVOICE_ERROR',
};

export default documentConstants;
