import mapDrawConstants from '../constants/mapDrawConstants';

function mapCreated(mapView) {
  return { type: mapDrawConstants.mapCreated, mapView };
}

function setLocationDrawOption(drawOption) {
  return { type: mapDrawConstants.setLocationDrawOption, drawOption };
}

function addPoint(latitude, longitude, uniqueId) {
  return { type: mapDrawConstants.addPoint, latitude, longitude, uniqueId };
}

function updatePointCoordinates(latitude, longitude, uniqueId) {
  return { type: mapDrawConstants.updatePointCoordinates, latitude, longitude, uniqueId };
}

function drawPoint(map, mapView, latitude, longitude, uniqueId, sketch = null) {
  return {
    type: mapDrawConstants.drawPoint,
    map,
    mapView,
    latitude,
    longitude,
    uniqueId,
    sketch,
  };
}

function highlightPoint(index) {
  return { type: mapDrawConstants.highlightPoint, index };
}

function removeHighlights() {
  return { type: mapDrawConstants.removeHighlights };
}

function removePoint(map, mapView, sketch, index, permitTypeValue) {
  return {
    type: mapDrawConstants.removePoint,
    map,
    mapView,
    sketch,
    index,
    permitTypeValue,
  };
}

function removeAllPoints(map) {
  return { type: mapDrawConstants.removeAllPoints, map };
}

function mapDrawUpdateLocationDetail(locationDetail, map, mapView) {
  return { type: mapDrawConstants.mapDrawUpdateLocationDetail, locationDetail, map, mapView };
}

function closeConfirmRailroadDialog() {
  return { type: mapDrawConstants.closeConfirmRailroadDialog };
}

const mapDrawActions = {
  mapCreated,
  setLocationDrawOption,
  addPoint,
  updatePointCoordinates,
  drawPoint,
  highlightPoint,
  removeHighlights,
  removePoint,
  removeAllPoints,
  mapDrawUpdateLocationDetail,
  closeConfirmRailroadDialog,
};

export default mapDrawActions;
