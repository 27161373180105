import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import adminActions from '../../../actions/adminActions';
import {
  AppBar,
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import ModalSpinner from '../../../modules/components/ModalSpinner';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function roleIsDisabled(roleName, checkedRolesState) {
  let isDisabled = false;
  const roleStateKeys = Object.keys(checkedRolesState);
  if (roleName.toUpperCase() === 'APPLICANT') {
    roleStateKeys.forEach((k) => {
      if (k.toUpperCase() !== 'APPLICANT' && checkedRolesState[k] === true) {
        isDisabled = true;
      }
    });
  } else {
    isDisabled = checkedRolesState['Applicant'];
  }

  return isDisabled;
}

function hasSelectedRole(checkedRolesState) {
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(checkedRolesState)) {
    if (value === true) {
      return true;
    }
  }
  return false;
}

function addSpacesToCamelCase(str) {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

function handleRoleChange(
  e,
  rolesCheckedState,
  setRolesCheckedState,
  permitTypesCheckedState,
  setPermitTypesCheckedState,
  territoriesCheckedState,
  setTerritoriesCheckedState,
  setClaimsCheckedState,
  claimsCheckedState
) {
  const rolesCheckedStateCopy = { ...rolesCheckedState };
  rolesCheckedStateCopy[e.target.name] = e.target.checked;
  setRolesCheckedState(rolesCheckedStateCopy);

  if (e.target.name.toUpperCase() === 'APPLICANT' || !hasSelectedRole(rolesCheckedStateCopy)) {
    const permitTypesCheckedCopy = { ...permitTypesCheckedState };
    for (const key in permitTypesCheckedCopy) {
      permitTypesCheckedCopy[key] = false;
    }
    setPermitTypesCheckedState(permitTypesCheckedCopy);

    const territoriesCheckedCopy = { ...territoriesCheckedState };
    for (const key in territoriesCheckedCopy) {
      territoriesCheckedCopy[key] = false;
    }
    setTerritoriesCheckedState(territoriesCheckedCopy);

    const claimsCheckedCopy = { ...claimsCheckedState };
    for (const key in claimsCheckedCopy) {
      claimsCheckedCopy[key] = false;
    }
    setClaimsCheckedState(claimsCheckedCopy);
  }
}

export default function EditUser({ open, handleClose, user }) {
  const dispatch = useDispatch();
  const {
    roles,
    claims,
    territories,
    territoryTypes,
    gettingUserAssignedPermitTypes,
    gettingUserAssignedTerritories,
    savingUserEdits,
    savedUserEditsCount,
    editUser: { assignedPermitTypes, assignedTerritories },
  } = useSelector((state) => state.admin);
  const { permitTypes } = useSelector((state) => state.common.lookups);

  const [contractorId, setContractorId] = React.useState(user.contractorId || '');
  const [territoryTypeId, setTerritoryTypeId] = React.useState('');
  const [selectAll, setSelectAll] = React.useState(false);

  React.useEffect(() => {
    dispatch(adminActions.getUserAssignedPermitTypes(user.id));
    dispatch(adminActions.getUserAssignedTerritories(user.id));
  }, [user.id, dispatch, savedUserEditsCount]);

  // Initialize roles checked state
  const rolesCheckedInitialState = {};
  roles.forEach((r) => {
    const matchingUserRole = user.userRoles.findIndex((userRole) => userRole.roleId === r.id);
    rolesCheckedInitialState[r.name] = matchingUserRole === -1 ? false : true;
  });
  const [rolesCheckedState, setRolesCheckedState] = useState(rolesCheckedInitialState);

  // Initialize claims checked state
  const claimsCheckedInitialState = {};
  claims.forEach((c) => {
    const matchingUserClaim = user.userClaims.findIndex((userClaim) => userClaim.claimId === c.id);
    claimsCheckedInitialState[c.name] = matchingUserClaim === -1 ? false : true;
  });
  const [claimsCheckedState, setClaimsCheckedState] = useState(claimsCheckedInitialState);

  const [permitTypesCheckedState, setPermitTypesCheckedState] = useState({});
  React.useEffect(() => {
    const permitTypesInitialState = {};
    permitTypes.forEach((pt) => {
      const matchingPermitType = assignedPermitTypes.findIndex(
        (assignedPermitType) => assignedPermitType.id === pt.id
      );
      permitTypesInitialState[pt.name] = matchingPermitType === -1 ? false : true;
    });
    setPermitTypesCheckedState(permitTypesInitialState);
  }, [assignedPermitTypes, permitTypes]);

  const [territoriesCheckedState, setTerritoriesCheckedState] = useState({});
  React.useEffect(() => {
    const territoriesInitialState = {};
    territories.forEach((t) => {
      const matchingTerritory = assignedTerritories.findIndex(
        (assignedTerritory) => assignedTerritory.id === t.id
      );
      territoriesInitialState[t.name] = matchingTerritory === -1 ? false : true;
    });
    setTerritoriesCheckedState(territoriesInitialState);
  }, [assignedTerritories, territories]);

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <ModalSpinner
        open={gettingUserAssignedPermitTypes || gettingUserAssignedTerritories || savingUserEdits}
      />
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color="white">
            Edit User
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              const roleIds = roles
                .filter((r) => rolesCheckedState[r.name] === true)
                .map((r) => r.id);
              const territoryIds = territories
                .filter((t) => territoriesCheckedState[t.name] === true)
                .map((t) => t.id);
              const permitTypeIds = permitTypes
                .filter((pt) => permitTypesCheckedState[pt.name] === true)
                .map((pt) => pt.id);
              const claimIds = claims
                .filter((c) => claimsCheckedState[c.name] === true)
                .map((c) => c.id);
              dispatch(
                adminActions.saveUserEdits(
                  user.id,
                  contractorId,
                  roleIds,
                  territoryIds,
                  permitTypeIds,
                  claimIds
                )
              );
            }}
          >
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ paddingTop: '20px' }} maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <AppBar position="static" color="secondary">
              <Toolbar variant="dense">
                <Typography variant="h6" color="inherit" component="div">
                  User Info
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ paddingTop: '20px' }}>
              <TextField
                label="Name"
                defaultValue={user.name}
                fullWidth
                disabled
                sx={{ marginBottom: '20px' }}
              />
              <TextField
                label="Title"
                defaultValue={user.title}
                fullWidth
                disabled
                sx={{ marginBottom: '20px' }}
              />
              <TextField
                label="Company"
                defaultValue={user.company}
                fullWidth
                disabled
                sx={{ marginBottom: '20px' }}
              />
              <TextField
                label="Email"
                defaultValue={user.email}
                fullWidth
                disabled
                sx={{ marginBottom: '20px' }}
              />
              <TextField
                label="Phone"
                defaultValue={user.phoneNumber}
                fullWidth
                disabled
                sx={{ marginBottom: '20px' }}
              />
              <TextField
                label="Contractor Id"
                fullWidth
                sx={{ marginBottom: '20px' }}
                value={contractorId}
                onChange={(evt) => setContractorId(evt.target.value)}
              />
            </Box>
            {rolesCheckedState['PermitManager'] === true && (
              <>
                <AppBar position="static" color="secondary">
                  <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit" component="div">
                      Assigned Permit Types
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Box>
                  <Stack direction="row" spacing={2}>
                    <FormGroup>
                      {permitTypes
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .filter((pt, idx) => idx <= permitTypes.length / 2)
                        .map((pt, idx) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                checked={permitTypesCheckedState[pt.name]}
                                name={pt.name}
                                onChange={(e) => {
                                  const permitTypesCheckedCopy = { ...permitTypesCheckedState };
                                  permitTypesCheckedCopy[e.target.name] = e.target.checked;
                                  setPermitTypesCheckedState(permitTypesCheckedCopy);
                                }}
                              />
                            }
                            label={pt.name}
                            key={idx}
                          />
                        ))}
                    </FormGroup>
                    <FormGroup>
                      {permitTypes
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .filter((pt, idx) => idx > permitTypes.length / 2)
                        .map((pt, idx) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                checked={permitTypesCheckedState[pt.name]}
                                name={pt.name}
                                onChange={(e) => {
                                  const permitTypesCheckedCopy = { ...permitTypesCheckedState };
                                  permitTypesCheckedCopy[e.target.name] = e.target.checked;
                                  setPermitTypesCheckedState(permitTypesCheckedCopy);
                                }}
                              />
                            }
                            label={pt.name}
                            key={idx}
                          />
                        ))}
                    </FormGroup>
                  </Stack>
                </Box>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <AppBar position="static" color="secondary">
              <Toolbar variant="dense">
                <Typography variant="h6" color="inherit" component="div">
                  Roles
                </Typography>
              </Toolbar>
            </AppBar>
            <Box>
              <Stack direction="row" spacing={2}>
                <FormGroup>
                  {roles
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .filter((r, idx) => idx <= roles.length / 2)
                    .map((r, idx) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={rolesCheckedState[r.name]}
                            disabled={roleIsDisabled(r.name, rolesCheckedState)}
                            name={r.name}
                            onChange={(e) =>
                              handleRoleChange(
                                e,
                                rolesCheckedState,
                                setRolesCheckedState,
                                permitTypesCheckedState,
                                setPermitTypesCheckedState,
                                territoriesCheckedState,
                                setTerritoriesCheckedState,
                                setClaimsCheckedState,
                                claimsCheckedState
                              )
                            }
                          />
                        }
                        label={r.name}
                        key={idx}
                      />
                    ))}
                </FormGroup>
                <FormGroup>
                  {roles
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .filter((r, idx) => idx > roles.length / 2)
                    .map((r, idx) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={rolesCheckedState[r.name]}
                            disabled={roleIsDisabled(r.name, rolesCheckedState)}
                            name={r.name}
                            onChange={(e) =>
                              handleRoleChange(
                                e,
                                rolesCheckedState,
                                setRolesCheckedState,
                                permitTypesCheckedState,
                                setPermitTypesCheckedState,
                                territoriesCheckedState,
                                setTerritoriesCheckedState,
                                setClaimsCheckedState,
                                claimsCheckedState
                              )
                            }
                          />
                        }
                        label={r.name}
                        key={idx}
                      />
                    ))}
                </FormGroup>
              </Stack>
            </Box>
            {rolesCheckedState['Applicant'] === false && hasSelectedRole(rolesCheckedState) && (
              <>
                <AppBar position="static" color="secondary">
                  <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit" component="div">
                      Claims
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Box>
                  <Stack direction="row" spacing={2}>
                    <FormGroup>
                      {claims
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .filter((c, idx) => idx <= claims.length / 2)
                        .map((c, idx) => (
                          <Tooltip
                            title={c.description}
                            enterDelay={500}
                            arrow
                            slotProps={{ tooltip: { sx: { fontSize: '0.9em' } } }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={claimsCheckedState[c.name]}
                                  name={c.name}
                                  onChange={(e) => {
                                    const claimsCheckedStateCopy = { ...claimsCheckedState };
                                    claimsCheckedStateCopy[e.target.name] = e.target.checked;
                                    setClaimsCheckedState(claimsCheckedStateCopy);
                                  }}
                                />
                              }
                              label={addSpacesToCamelCase(c.name)}
                              key={idx}
                            />
                          </Tooltip>
                        ))}
                    </FormGroup>
                    <FormGroup>
                      {claims
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .filter((c, idx) => idx > claims.length / 2)
                        .map((c, idx) => (
                          <Tooltip
                            title={c.description}
                            enterDelay={500}
                            arrow
                            slotProps={{ tooltip: { sx: { fontSize: '0.9em' } } }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={claimsCheckedState[c.name]}
                                  name={c.name}
                                  onChange={(e) => {
                                    const claimsCheckedStateCopy = { ...claimsCheckedState };
                                    claimsCheckedStateCopy[e.target.name] = e.target.checked;
                                    setClaimsCheckedState(claimsCheckedStateCopy);
                                  }}
                                />
                              }
                              label={addSpacesToCamelCase(c.name)}
                              key={idx}
                            />
                          </Tooltip>
                        ))}
                    </FormGroup>
                  </Stack>
                </Box>
              </>
            )}
            {rolesCheckedState['Applicant'] === false && hasSelectedRole(rolesCheckedState) && (
              <>
                <AppBar position="static" color="secondary">
                  <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit" component="div">
                      Assigned Territories
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Box sx={{ minWidth: 120, paddingTop: '15px' }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="territory-type-label">Territory Type</InputLabel>
                    <Select
                      labelId="territory-type-label"
                      value={territoryTypeId}
                      label="Territory Type"
                      onChange={(evt) => {
                        setSelectAll(false);
                        setTerritoryTypeId(evt.target.value);
                      }}
                    >
                      {territoryTypes
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((t, idx) => (
                          <MenuItem value={t.id} key={idx}>
                            {t.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
                  <FormGroup>
                    {territoryTypeId !== '' && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={selectAll}
                            name="Select All"
                            onChange={() => {
                              const territoriesCheckedCopy = { ...territoriesCheckedState };
                              const filteredTerritories = territories.filter(
                                (t) => t.territoryTypeId === territoryTypeId
                              );
                              filteredTerritories.forEach((ft) => {
                                territoriesCheckedCopy[ft.name] = !selectAll;
                              });
                              setSelectAll(!selectAll);
                              setTerritoriesCheckedState(territoriesCheckedCopy);
                            }}
                          />
                        }
                        label="Select All"
                      />
                    )}
                    {territories
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .filter((t) => t.territoryTypeId === territoryTypeId)
                      .map((t, idx) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={territoriesCheckedState[t.name]}
                              name={t.name}
                              onChange={(e) => {
                                const territoriesCheckedCopy = { ...territoriesCheckedState };
                                territoriesCheckedCopy[e.target.name] = e.target.checked;
                                setTerritoriesCheckedState(territoriesCheckedCopy);
                              }}
                            />
                          }
                          label={t.name}
                          key={idx}
                        />
                      ))}
                  </FormGroup>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}

EditUser.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
