const userConstants = {
  getUser: 'GET_USER',
  getUserSucceeded: 'GET_USER_SUCCEEDED',
  getUserFailed: 'GET_USER_FAILED',

  getRequestedByRailroadPermitManagers: 'GET_REQUESTED_BY_RAILROAD_PERMIT_MANAGERS',
  getRequestedByRailroadPermitManagersSucceeded:
    'GET_REQUESTED_BY_RAILROAD_PERMIT_MANAGERS_SUCCEEDED',
  getRequestedByRailroadPermitManagersFailed: 'GET_REQUESTED_BY_RAILROAD_PERMIT_MANAGERS_FAILED',
};

export default userConstants;
