const mapQueryConstants = {
  getEsriToken: 'GET_ESRI_TOKEN',
  getEsriTokenSuccess: 'GET_ESRI_TOKEN_SUCCESS',
  getEsriTokenError: 'GET_ESRI_TOKEN_ERROR',

  queryLocationDetails: 'QUERY_LOCATION_DETAILS',
  queryLocationDetailsSuccess: 'QUERY_LOCATION_DETAILS_SUCCESS',
  queryLocationDetailsError: 'QUERY_LOCATION_DETAILS_ERROR',
};

export default mapQueryConstants;
