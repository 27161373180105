import { put, takeEvery, select } from 'redux-saga/effects';
import mapDrawConstants from '../constants/mapDrawConstants';
import alertConstants from '../constants/alertConstants';
import formUpdateConstants from '../constants/formConstants';
import { queryLocationDetailInfo } from '../PermitForms/Map/mapQueries';
import mapQueryConstants from '../constants/mapQueryConstants';
import { getAvailableCreateTools } from '../PermitForms/Map/PermittingMap';
import config from '../data/config.json';
import Graphic from '@arcgis/core/Graphic.js';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils.js';

function* drawPoint(action) {
  const { map, mapView, sketch, latitude, longitude, uniqueId } = action;
  try {
    const layer = map.allLayers.find((l) => l.id === 'location');
    const point = {
      type: 'point',
      longitude,
      latitude,
    };
    const markerSymbol = {
      type: 'simple-marker',
      size: 8,
      style: 'x',
      outline: {
        color: [65, 105, 225],
        width: '4px',
      },
    };
    let pointGraphic = new Graphic({
      geometry: point,
      symbol: markerSymbol,
      attributes: {
        uniqueId,
      },
    });
    if (
      pointGraphic.geometry.spatialReference.isGeographic &&
      mapView.spatialReference.isWebMercator
    ) {
      const newGeometry = webMercatorUtils.geographicToWebMercator(pointGraphic.geometry);
      pointGraphic = new Graphic({
        geometry: newGeometry,
        symbol: markerSymbol,
        attributes: {
          uniqueId,
        },
      });
    }
    layer.add(pointGraphic);
    if (layer.graphics.length === 1) {
      mapView.zoom = 10;
      mapView.center = layer.graphics.getItemAt(0).geometry;
    }
    if (sketch) {
      sketch.availableCreateTools = getAvailableCreateTools(layer);
    }
    const locationDetail = yield select(
      (state) => state.selectedApplication.application.locationDetail
    );
    yield mapDrawUpdateLocationDetail({ locationDetail, map, mapView });
  } catch (e) {
    console.error(e);
    yield put({ type: alertConstants.error, message: 'Failed to add point' });
  }
}

function* removePoint(action) {
  const { map, mapView, sketch, index, permitTypeValue } = action;
  try {
    let layer = null;
    const locationDetail = yield select(
      (state) => state.selectedApplication.application.locationDetail
    );
    layer = map.allLayers.find((l) => l.id === 'location');
    layer.remove(layer.graphics.getItemAt(index));
    if (layer.graphics.length === 0) {
      const emptyLocationDetail = {};
      Object.keys(locationDetail).forEach((key) => (emptyLocationDetail[key] = null));
      yield put({
        type: formUpdateConstants.updateLocationDetail,
        locationDetail: {
          ...emptyLocationDetail,
          locationDetailId: locationDetail.locationDetailId,
        },
      });
    } else {
      yield mapDrawUpdateLocationDetail({ locationDetail, map, mapView });
    }
    sketch.availableCreateTools = getAvailableCreateTools(permitTypeValue, layer);
  } catch (e) {
    console.error(e);
    yield put({ type: alertConstants.error, message: 'Failed to remove point' });
  }
}

function* removeAllPoints(action) {
  const { map } = action;
  try {
    const locationLayer = map.findLayerById('location');
    locationLayer.removeAll();
  } catch (e) {
    console.error(e);
    yield put({ type: alertConstants.error, message: 'Failed to remove points' });
  }
}

function* mapDrawUpdateLocationDetail({ locationDetail, map, mapView }) {
  const locationLayer = map.allLayers.find((l) => l.id === 'location');
  const graphic = locationLayer.graphics.getItemAt(0);
  if (!graphic) {
    return;
  }
  let startPoint = null;
  if (graphic.geometry.type === 'polyline') {
    startPoint = graphic.geometry.getPoint(0, 0);
  } else if (graphic.geometry.type === 'polygon') {
    startPoint = graphic.geometry.centroid;
  } else if (graphic.geometry.type === 'point') {
    startPoint = graphic.geometry;
  }
  try {
    yield put({ type: mapQueryConstants.queryLocationDetails });
    const { queryMilepost } = yield select((state) => state.common.config);
    const results = yield queryLocationDetailInfo(graphic.geometry, queryMilepost);
    if (!results.railroad) {
      yield put({
        type: alertConstants.warning,
        message: `Attempting to identify railroad owner returned no results.\nEnsure you have indicated the correct installation location before submitting application for ${config.Queries.Railroad.expectedValues} permit.`,
      });
    } else if (!config.Queries.Railroad.expectedValues.includes(results.railroad)) {
      yield put({
        type: mapDrawConstants.openConfirmRailroadDialog,
        identifiedRailroad: results.railroad,
      });
    }
    yield put({ type: mapQueryConstants.queryLocationDetailsSuccess });
    let graphicJson = null;
    if (graphic.geometry.type === 'point' && locationLayer.graphics.length > 1) {
      const graphicsJson = [];
      locationLayer.graphics.forEach((g) => graphicsJson.push(JSON.stringify(g)));
      graphicJson = JSON.stringify(graphicsJson);
    } else {
      graphicJson = JSON.stringify(graphic);
    }
    yield put({
      type: formUpdateConstants.updateLocationDetail,
      locationDetail: {
        ...locationDetail,
        ...results,
        latitude: startPoint.latitude,
        longitude: startPoint.longitude,
        graphicJson: graphicJson,
      },
      resolve: null,
      reject: null,
      populateEngDivision: true,
    });
  } catch (e) {
    console.error(e);
    yield put({ type: mapQueryConstants.queryLocationDetailsError });
    yield put({ type: alertConstants.error, message: 'Failed to save location' });
    if (locationDetail.graphicJson) {
      const locationLayer = map.allLayers.find((l) => l.id === 'location');
      const graphic = Graphic.fromJSON(JSON.parse(locationDetail.graphicJson));
      locationLayer.removeAll();
      locationLayer.add(graphic);
      mapView.goTo(graphic);
    }
  }
}

function* highlightPoint(action) {
  const { index } = action;
  try {
    const mapView = yield select((state) => state.mapDraw.mapView);
    const highlightsLayer = mapView.map.allLayers.find((l) => l.id === 'highlights');
    const locationLayer = mapView.map.allLayers.find((l) => l.id === 'location');
    const selectedPoint = locationLayer.graphics.getItemAt(index);
    if (!selectedPoint) {
      return;
    }
    const point = {
      type: 'point',
      longitude: selectedPoint.geometry.longitude,
      latitude: selectedPoint.geometry.latitude,
    };
    const markerSymbol = {
      type: 'simple-marker',
      size: 8,
      style: 'x',
      outline: {
        color: [255, 255, 0],
        width: '4px',
      },
    };
    const pointGraphic = new Graphic({
      geometry: point,
      symbol: markerSymbol,
    });
    highlightsLayer.add(pointGraphic);
  } catch (e) {
    console.error(e);
  }
}

function* removeHighlights() {
  try {
    const mapView = yield select((state) => state.mapDraw.mapView);
    const highlightsLayer = mapView.map.allLayers.find((l) => l.id === 'highlights');
    highlightsLayer.removeAll();
  } catch (e) {
    console.error(e);
  }
}

const mapQuerySagas = [
  takeEvery(mapDrawConstants.drawPoint, drawPoint),
  takeEvery(mapDrawConstants.removePoint, removePoint),
  takeEvery(mapDrawConstants.removeAllPoints, removeAllPoints),
  takeEvery(mapDrawConstants.highlightPoint, highlightPoint),
  takeEvery(mapDrawConstants.removeHighlights, removeHighlights),
  takeEvery(mapDrawConstants.mapDrawUpdateLocationDetail, mapDrawUpdateLocationDetail),
];

export default mapQuerySagas;
