import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MaterialReactTable from 'material-react-table';
import { Grid, IconButton, Stack, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PublishIcon from '@mui/icons-material/Publish';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import UndoIcon from '@mui/icons-material/Undo';
import withRoot from '../../../modules/withRoot';
import StatusDetails from '../../StatusDetails';
import {
  getLookup,
  updateWirelinePermitType,
  getReviewGroupStatusLu,
} from '../../../helpers/utils';
import SubmitApplicationDialog from './SubmitApplicationDialog';
import UndoPMApprovalDialog from './UndoPMApprovalDialog';
import ChangeApplicationOwnerDialog from './ChangeApplicationOwnerDialog';

function prepareApplicationData(
  data,
  toggleStatusDetails,
  permitTypesLu,
  engineeringDivisions,
  reviewGroups,
  reviewStatuses
) {
  const applicationsAwaitingReview = [];
  data.forEach((application) => {
    const status = application.applicationStatus || {};
    let { applicationDetail } = application;
    if (!applicationDetail) {
      applicationDetail = {};
    }

    let cityState = '';
    if (application.locationDetail && application.locationDetail.nearestTown) {
      cityState = `${application.locationDetail.nearestTown}, ${application.locationDetail.state}`;
    } else if (application.locationDetail && application.locationDetail.state) {
      cityState = application.locationDetail.state;
    }

    let permitTypeLU = getLookup(permitTypesLu, application.permitTypeId);
    if (permitTypeLU.value === 'WIRELINE') {
      permitTypeLU = updateWirelinePermitType(permitTypeLU, application.wireline);
    }

    const statusDetails = (
      <>
        <Link
          to="#"
          onClick={(evt) => {
            toggleStatusDetails({ open: true, applicationReviews: application.applicationReviews });
            evt.preventDefault();
          }}
          style={{ textDecoration: 'none' }}
        >
          {status.name}
        </Link>
      </>
    );

    const link = (
      <>
        <Grid container spacing={1}>
          <Grid item>
            <Link
              to={`/processapplication/${application.applicationId}`}
              style={{ display: 'block' }}
            >
              {application.businessId}
            </Link>
          </Grid>
          {(applicationDetail.rushProcessing || applicationDetail.requestedByRR) && (
            <Grid item>
              {applicationDetail.rushProcessing && (
                <Tooltip title="Rush Processing Requested">
                  <PriorityHighIcon fontSize="small" color="error" style={{ display: 'block' }} />
                </Tooltip>
              )}
              {applicationDetail.requestedByRR && (
                <Tooltip title="Service Requested By RR">
                  <Avatar
                    style={{
                      width: 20,
                      height: 20,
                      fontSize: 18,
                      backgroundColor: 'white',
                      color: 'blue',
                    }}
                  >
                    R
                  </Avatar>
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
      </>
    );
    const engineeringDivision = engineeringDivisions.find(
      (lu) => lu.id === application.locationDetail?.engineeringDivisionTerritoryId
    );

    const permitManagerReviewStatusLu = getReviewGroupStatusLu(
      'PERMITMANAGER',
      application.applicationReviews,
      reviewGroups,
      reviewStatuses
    );

    const applicationData = {
      applicationId: application.applicationId,
      applicant: application.user.firstName + ' ' + application.user.lastName,
      businessId_: application.businessId,
      applicationLink: link,
      permitType: permitTypeLU.name,
      companyName: applicationDetail.legalName,
      cityState: cityState,
      engineeringDivision: engineeringDivision?.name,
      submittalDate: application.submittalDateTime
        ? moment.utc(application.submittalDateTime).local().format('YYYY-MM-DD')
        : null,
      status: statusDetails,
      status_: status.name,
      rushProcessing_: applicationDetail.rushProcessing,
      requestedByRR_: applicationDetail.requestedByRR,
      statusValue_: status.value,
      pmReviewStatusValue_: permitManagerReviewStatusLu.value,
    };

    applicationsAwaitingReview.push(applicationData);
  });

  const sortedByDate = applicationsAwaitingReview.sort(
    (a, b) => new Date(b.submittalDate) - new Date(a.submittalDate)
  );
  const notPriorityApps = sortedByDate.filter((d) => !d.rushProcessing_ && !d.requestedByRR_);
  const rushProcessingApps = sortedByDate.filter((d) => d.rushProcessing_ === true);
  const requestedByRRApps = sortedByDate.filter(
    (d) => d.requestedByRR_ === true && !d.rushProcessing_
  );

  return [...rushProcessingApps, ...requestedByRRApps, ...notPriorityApps];
}

function AdminApplicationsTable({ applications }) {
  const { permitTypes, engineeringDivisions } = useSelector((state) => state.common.lookups);
  const { gettingApplications } = useSelector((state) => state.admin);
  const {
    lookups: { reviewGroups, reviewStatuses },
  } = useSelector((state) => state.common);

  const [submitApplicationData, setSubmitApplicationData] = useState({
    open: false,
    applicationid: -1,
    businessId: '',
  });
  const [undoPMApprovalData, setUndoPMApprovalData] = useState({
    open: false,
    applicationid: -1,
    businessId: '',
  });
  const [changeAppOwnerData, setChangeAppOwnerData] = useState({
    open: false,
    applicationid: -1,
    businessId: '',
  });

  const [statusDetails, toggleStatusDetails] = useState({ open: false, applicationReviews: [] });
  const applicationsAwaitingReview = prepareApplicationData(
    applications,
    toggleStatusDetails,
    permitTypes,
    engineeringDivisions,
    reviewGroups,
    reviewStatuses
  );

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'applicationLink',
        header: 'Bussines Id',
        filterFn: (row, id, filterValue) => {
          if (row.original['businessId_']) {
            return row.original['businessId_'].toLowerCase().includes(filterValue.toLowerCase());
          }
          return false;
        },
        sortingFn: (rowA, rowB) => {
          if (rowA.original['businessId_'] > rowB.original['businessId_']) {
            return 1;
          }
          if (rowB.original['businessId_'] > rowA.original['businessId_']) {
            return -1;
          }
          return 0;
        },
      },
      {
        accessorKey: 'applicationId',
        header: 'Application Id',
      },
      {
        accessorKey: 'applicant',
        header: 'Applicant',
      },
      {
        accessorKey: 'permitType',
        header: 'Permit Type',
      },
      {
        accessorKey: 'companyName',
        header: 'Company Name',
      },
      {
        accessorKey: 'cityState',
        header: 'City State',
      },
      {
        accessorKey: 'engineeringDivision',
        header: 'Division',
      },
      {
        accessorKey: 'submittalDate',
        header: 'Submittal Date',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        filterFn: (row, id, filterValue) => {
          if (row.original['status_']) {
            return row.original['status_'].toLowerCase().includes(filterValue.toLowerCase());
          }
          return false;
        },
        sortingFn: (rowA, rowB) => {
          if (rowA.original['status_'] > rowB.original['status_']) {
            return 1;
          }
          if (rowB.original['status_'] > rowA.original['status_']) {
            return -1;
          }
          return 0;
        },
      },
    ],
    []
  );

  return (
    <div style={{ overflow: 'auto' }}>
      {submitApplicationData.open && (
        <SubmitApplicationDialog
          open={submitApplicationData.open}
          applicationId={submitApplicationData.applicationId || -1}
          businessId={submitApplicationData.businessId || 'NONE'}
          handleClose={() => setSubmitApplicationData({ open: false })}
        />
      )}
      {changeAppOwnerData.open && (
        <ChangeApplicationOwnerDialog
          open={changeAppOwnerData.open}
          applicationId={changeAppOwnerData.applicationId || -1}
          businessId={changeAppOwnerData.businessId || 'NONE'}
          handleClose={() => setChangeAppOwnerData({ open: false })}
          applications={applications}
        />
      )}
      {statusDetails.open && (
        <StatusDetails
          open={statusDetails.open}
          applicationReviews={statusDetails.applicationReviews}
          close={() => {
            toggleStatusDetails({ open: false, applicationReviews: [] });
          }}
        />
      )}
      {undoPMApprovalData.open && (
        <UndoPMApprovalDialog
          open={undoPMApprovalData.open}
          applicationId={undoPMApprovalData.applicationId || -1}
          businessId={undoPMApprovalData.businessId || 'NONE'}
          handleClose={() => setUndoPMApprovalData({ open: false })}
        />
      )}
      <MaterialReactTable
        columns={columns}
        data={applicationsAwaitingReview}
        enableStickyHeader
        enableGlobalFilter={false}
        state={{
          isLoading: gettingApplications,
        }}
        enableRowActions
        renderRowActions={({ row }) => (
          <Stack direction="row" spacing={1}>
            {
              <Tooltip title="Change Owner">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    setChangeAppOwnerData({
                      open: true,
                      applicationId: row.original['applicationId'],
                      businessId: row.original['businessId_'],
                    });
                  }}
                  size="large"
                >
                  <ManageAccountsIcon sx={{ color: '#212121' }} />
                </IconButton>
              </Tooltip>
            }
            {row.original['statusValue_'] === 'UNSUBMITTED' && (
              <Tooltip title="Submit Application">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    setSubmitApplicationData({
                      open: true,
                      applicationId: row.original['applicationId'],
                      businessId: row.original['businessId_'],
                    });
                  }}
                  size="large"
                >
                  <PublishIcon sx={{ color: '#212121' }} />
                </IconButton>
              </Tooltip>
            )}
            {row.original['pmReviewStatusValue_'] === 'APPROVED' && (
              <Tooltip title="Undo PM Approval">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    setUndoPMApprovalData({
                      open: true,
                      applicationId: row.original['applicationId'],
                      businessId: row.original['businessId_'],
                    });
                  }}
                  size="large"
                >
                  <UndoIcon sx={{ color: '#212121' }} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        )}
      />
    </div>
  );
}

AdminApplicationsTable.propTypes = {
  applications: PropTypes.array.isRequired,
};

export default withRoot(AdminApplicationsTable);
