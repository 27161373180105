import { getLookup } from './utils';
import config from '../data/config.json';
import Graphic from '@arcgis/core/Graphic.js';

export default function getPrintExhibitParams(application, lookups) {
  const graphic = Graphic.fromJSON(JSON.parse(application.locationDetail.graphicJson));
  const extent = graphic.geometry.extent.expand(1.5);

  return {
    Web_Map_as_JSON: {
      operationalLayers: [
        {
          url: config.MapServices.World_Imagery.url,
          id: 'satellite-base-layer',
          title: 'World Imagery',
          opacity: 1,
          minScale: 0,
          maxScale: 0,
        },
        {
          layers: [
            {
              id: 0,
              name: 'fTrack',
              layerDefinition: {
                source: { mapLayerId: 0, type: 'mapLayer' },
              },
            },
          ],
          visibleLayers: [0],
          url: config.MapServices.MainLineRail.url,
          id: 'main-line-rail',
          title: 'Main LIne Rail',
          opacity: 1,
          minScale: 0,
          maxScale: 0,
        },
        {
          featureCollection: {
            layers: [
              {
                layerDefinition: {
                  name: 'polylineLayer',
                  geometryType: 'esriGeometryPolyline',
                },
                featureSet: {
                  geometryType: 'esriGeometryPolyline',
                  features: [
                    {
                      geometry: graphic.geometry.toJSON(),
                      symbol: {
                        type: 'esriSLS',
                        color: [65, 105, 225, 255],
                        width: 2,
                        style: 'esriSLSSolid',
                      },
                    },
                  ],
                },
              },
            ],
          },
          id: 'utility-sketch',
          title: null,
          opacity: 1,
          minScale: 0,
          maxScale: 0,
        },
      ],
      mapOptions: {
        extent: extent.toJSON(),
        spatialReference: graphic.geometry.spatialReference.toJSON(),
      },
      exportOptions: { dpi: 300 },
      layoutOptions: {
        customTextElements: getCustomTextElements(application, lookups),
      },
    },
    Format: 'PDF',
    Layout_Template: getLayoutTemplate(application),
    returnFeatureCollection: false,
    returnM: false,
    returnZ: false,
    f: 'json',
  };
}

function getCustomTextElements(application, lookups) {
  const [section, township, range] = application.locationDetail.encroachmentLocation
    ? application.locationDetail.encroachmentLocation.split('/')
    : ['', '', ''];

  let elements = [
    { trackingNumber: application.businessId },
    {
      licensee: application.applicationDetail.legalName,
    },
    {
      milepost: Number(application.locationDetail.milepost).toFixed(2),
    },
    {
      county: application.locationDetail.county,
    },
    {
      state: application.locationDetail.state,
    },
    {
      meridian: '',
    },
    {
      townshipRange: `${township.trim()} ${range.trim()}`,
    },
    {
      section: section,
    },
  ];
  if (application.pipeline) {
    elements = [...elements, ...getPipelineElements(application, lookups)];
  }
  if (application.wireline && application.wireline.overheadWireline) {
    elements = [...elements, ...getOverheadWirelineElements(application)];
  }
  if (application.wireline && application.wireline.undergroundWireline) {
    elements = [...elements, ...getUndergroundWirelineElements(application, lookups)];
  }

  return elements;
}

function getOverheadWirelineElements(application) {
  const { overheadWireline } = application.wireline;
  return [
    { wireType: getWireType(application) },
    { numberWires: '' },
    { voltage: overheadWireline.lineVoltage ? String(overheadWireline.lineVoltage) : '' },
    {
      heightAboveRail: overheadWireline.topOfRailClearanceFt
        ? `${overheadWireline.topOfRailClearanceFt}' ${
            overheadWireline.topOfRailClearanceIn ? overheadWireline.topOfRailClearanceIn : ''
          }${overheadWireline.topOfRailClearanceIn ? '"' : ''}`
        : '',
    },
  ];
}

function getUndergroundWirelineElements(application, lookups) {
  const { undergroundWireline } = application.wireline;
  const conduitMaterial = getLookup(
    lookups.undergroundWirelineCasingMaterials,
    undergroundWireline.casingMaterialId
  );
  return [
    { wireType: getWireType(application) },
    { numberConduits: '' },
    { voltage: getConduitVoltage(undergroundWireline.conduits) },
    { conduitSize: `${undergroundWireline.casingInsideDiameter}"` },
    { conduitMaterial: conduitMaterial.name },
    { wallThickness: `${undergroundWireline.casingWallThickness}"` },
    { lengthOnROW: `${application.wireline.encroachmentLengthFt}'` },
    {
      baseRailToTopConduit: undergroundWireline.bottomRailClearanceFt
        ? `${undergroundWireline.bottomRailClearanceFt}' ${
            undergroundWireline.bottomRailClearanceIn
              ? undergroundWireline.bottomRailClearanceIn
              : ''
          }${undergroundWireline.bottomRailClearanceIn ? '"' : ''}`
        : '',
    },
  ];
}

function getConduitVoltage(conduits) {
  let voltage = '';
  conduits.forEach((c) => {
    if (c.conduitVoltage) {
      voltage = String(c.conduitVoltage);
    }
  });
  return voltage;
}

function getWireType(application) {
  const wireTypes = [];
  if (application.wireline.electricCommodity) {
    wireTypes.push('Electric');
  }
  if (application.wireline.communicationCommodity) {
    wireTypes.push('Communication');
  }
  if (application.wireline.otherCommodity) {
    wireTypes.push(application.wireline.otherCommodityDescription);
  }

  return wireTypes.join(' / ');
}

function getPipelineElements(application, lookups) {
  let { pipelineCarrier, pipelineCasing } = application.pipeline;
  if (!pipelineCasing) {
    pipelineCasing = {};
  }

  const contents = getLookup(lookups.pipelineCommodities, application.pipeline.contentsId);
  const carrierMaterial = getLookup(
    lookups.pipelineCarrierMaterials,
    pipelineCarrier.pipelineCarrierMaterialId
  );
  let casingMaterial = { name: '' };
  if (pipelineCasing.pipelineCasingMaterialId) {
    casingMaterial = getLookup(
      lookups.pipelineCasingMaterials,
      pipelineCasing.pipelineCasingMaterialId
    );
  }

  let ventHeightAboveGround = '';
  if (pipelineCasing.ventHeightAboveGroundFt) {
    ventHeightAboveGround = Math.round(Number(pipelineCasing.ventHeightAboveGroundFt) * 100) / 100;
  }
  if (pipelineCasing.ventHeightAboveGroundIn) {
    ventHeightAboveGround += Math.round(Number(pipelineCasing.ventHeightAboveGroundIn) * 100) / 100;
  }
  return [
    {
      casingCathodicProtection: application.pipeline.hasCasing
        ? pipelineCasing.cathodicProtection
          ? 'YES'
          : 'NO'
        : '',
    },
    {
      casingRoadwayDitches: pipelineCasing.minimumGroundCover
        ? `${pipelineCasing.minimumGroundCover}'`
        : '',
    },
    {
      casingNaturalGround: pipelineCasing.minimumGroundCover
        ? `${pipelineCasing.minimumGroundCover}'`
        : '',
    },
    {
      casingBaseRailToTopCasing: pipelineCasing.railToPipeFt
        ? `${pipelineCasing.railToPipeFt}'`
        : '',
    },
    {
      casingLengthOnROW: application.pipeline.hasCasing
        ? `${application.pipeline.pipeLength}'`
        : '',
    },
    {
      carrierLengthOnROW: `${application.pipeline.pipeLength}'`,
    },
    {
      carrierWorkingPressure: `${pipelineCarrier.workingPressure || ''} PSI`,
    },
    {
      casingSize: pipelineCasing.pipeInnerDiameter ? `${pipelineCasing.pipeInnerDiameter}"` : '',
    },
    {
      casingCoating: pipelineCasing.coatingDescription || '',
    },
    {
      casingWallThickness: pipelineCasing.wallThickness ? `${pipelineCasing.wallThickness}"` : '',
    },
    {
      casingSpecificationGrade: pipelineCasing.specificationGrade || '',
    },
    {
      casingMaterial: casingMaterial.name,
    },
    {
      carrierSize: `${pipelineCarrier.pipeInnerDiameter}"`,
    },
    {
      carrierCoating: pipelineCarrier.coatingDescription,
    },
    {
      carrierWallThickness: `${pipelineCarrier.wallThickness}"`,
    },
    {
      carrierSpecificationGrade: pipelineCarrier.specificationGrade,
    },
    {
      carrierMaterial: carrierMaterial.name,
    },
    {
      carrierContents: contents.name,
    },
    {
      numberVents: pipelineCasing.ventNumber ? String(pipelineCasing.ventNumber) : '',
    },
    {
      ventSize: pipelineCasing.ventSize
        ? `${Math.round(Number(pipelineCasing.ventSize) * 100) / 100}"`
        : '',
    },
    { ventHeightAboveGround: ventHeightAboveGround ? `${ventHeightAboveGround}'` : '' },
  ];
}

function getLayoutTemplate(application) {
  if (application.pipeline) {
    return 'EXH_UNDGRD_PIPE_letter';
  }
  if (application.wireline.overheadWireline) {
    return 'EXH_OVRHD_WIRE_Letter';
  }
  if (application.wireline.undergroundWireline) {
    return 'EXH_UNDGRD_WIRE_Letter';
  }
  return 'MAP_ONLY';
}
