import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import ChangeTypeHeader from './ChangeTypeHeader';

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    border: '1px solid black',
    fontSize: 12,
    fontFamily: 'Times-Bold',
  },
  headerCell: {
    flex: 1,
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    textAlign: 'left',
    backgroundColor: 'white',
    fontSize: 12,
    border: '1px solid black',
    borderTop: 'none',
  },
  tableCell: {
    alignItems: 'center',
    flex: 1,
    padding: 2.5,
    fontWeight: 'bolder',
  },
});

const ArrayChanges = ({ changes }) => {
  const headers = ['Field', 'Old Value', 'New Value'];
  const renderHeader = () => {
    return headers.map((header, index) => {
      return (
        <View key={`header_${index}`} style={[styles.headerCell]}>
          <Text style={{ textTransform: 'uppercase' }}>{header}</Text>
        </View>
      );
    });
  };

  const renderCells = (values, rowIndex, color) => {
    return values.map((value, index) => {
      return (
        <View
          key={`cell_${rowIndex}_${index}`}
          style={[
            styles.tableCell,
            index === 0
              ? { color: 'black', alignItems: 'flex-start' }
              : {
                  color: color,
                },
          ]}
        >
          <Text>{value}</Text>
        </View>
      );
    });
  };

  const renderRows = (data) => {
    let color = 'black';
    if (data.changeType === 'UPDATE') {
      return Object.entries(data.newArrayItem).map(
        ([arrayChangeKey, arrayChangeItem], arrayChangeIdx) => {
          if (arrayChangeItem.hideField) {
            return null;
          }
          const oldArrayValue = data.oldArrayItem[arrayChangeKey].value;
          const newArrayValue = arrayChangeItem.value;
          return (
            <View
              key={`update_row_${arrayChangeIdx}`}
              style={[styles.row, arrayChangeIdx % 2 !== 0 && { backgroundColor: 'lightgray' }]}
              wrap={true}
            >
              {renderCells(
                [arrayChangeItem.description, oldArrayValue, newArrayValue],
                arrayChangeIdx,
                oldArrayValue === newArrayValue ? 'black' : 'blue'
              )}
            </View>
          );
        }
      );
    } else if (data.changeType === 'ADD') {
      color = 'green';
      return Object.entries(data.newArrayItem).map(([, arrayChangeItem], arrayChangeIdx) => {
        if (arrayChangeItem.hideField) {
          return null;
        }
        return (
          <View
            key={`add_row_${arrayChangeIdx}`}
            style={[styles.row, arrayChangeIdx % 2 !== 0 && { backgroundColor: 'lightgray' }]}
            wrap={true}
          >
            {renderCells(
              [arrayChangeItem.description, null, arrayChangeItem.value],
              arrayChangeIdx,
              color
            )}
          </View>
        );
      });
    } else if (data.changeType === 'REMOVE') {
      color = 'red';
      return Object.entries(data.oldArrayItem).map(([, arrayChangeItem], arrayChangeIdx) => {
        if (arrayChangeItem.hideField) {
          return null;
        }
        return (
          <View
            key={`remove_row_${arrayChangeIdx}`}
            style={[styles.row, arrayChangeIdx % 2 !== 0 && { backgroundColor: 'lightgray' }]}
            wrap={true}
          >
            {renderCells(
              [arrayChangeItem.description, arrayChangeItem.value, null],
              arrayChangeIdx,
              color
            )}
          </View>
        );
      });
    }
  };

  const updates = changes.filter((c) => c.changeType === 'UPDATE');
  const adds = changes.filter((c) => c.changeType === 'ADD');
  const removals = changes.filter((c) => c.changeType === 'REMOVE');

  return (
    <>
      {updates.length > 0 && (
        <>
          <ChangeTypeHeader color="blue">Updated</ChangeTypeHeader>
          <View
            style={[{ backgroundColor: 'white', paddingBottom: 16, paddingHorizontal: 5 }]}
            wrap={false}
          >
            {updates.map((change, index) => {
              return (
                <View key={`updates_table_${index}`} style={{ marginBottom: '10px' }}>
                  <View fixed style={styles.header}>
                    {renderHeader()}
                  </View>
                  {renderRows(change)}
                </View>
              );
            })}
          </View>
        </>
      )}
      {adds.length > 0 && (
        <>
          <ChangeTypeHeader color="green">Added</ChangeTypeHeader>
          <View
            style={[{ backgroundColor: 'white', paddingBottom: 16, paddingHorizontal: 5 }]}
            wrap={false}
          >
            {adds.map((change, index) => {
              return (
                <View key={`adds_table_${index}`} style={{ marginBottom: '10px' }}>
                  <View fixed style={styles.header}>
                    {renderHeader()}
                  </View>
                  {renderRows(change)}
                </View>
              );
            })}
          </View>
        </>
      )}
      {removals.length > 0 && (
        <>
          <ChangeTypeHeader color="red">Removed</ChangeTypeHeader>
          <View
            style={[{ backgroundColor: 'white', paddingBottom: 16, paddingHorizontal: 5 }]}
            wrap={false}
          >
            {removals.map((change, index) => {
              return (
                <View key={`removed_table_${index}`} style={{ marginBottom: '10px' }}>
                  <View fixed style={styles.header}>
                    {renderHeader()}
                  </View>
                  {renderRows(change)}
                </View>
              );
            })}
          </View>
        </>
      )}
    </>
  );
};

ArrayChanges.propTypes = {
  changes: PropTypes.array.isRequired,
};

export default ArrayChanges;
