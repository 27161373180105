import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ExportToCsv } from 'export-to-csv';
import MaterialReactTable from 'material-react-table';
import makeStyles from '@mui/styles/makeStyles';
import withRoot from '../modules/withRoot';
import Container from '@mui/material/Container';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Grid, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import AppAppBar from '../modules/views/AppAppBar';
import reportActions from '../actions/reportActions';
import { getDateOffset } from '../helpers/utils';
import Title from '../ProcessApplication/Title';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    maxWidth: '98vw',
  },
  textField: {
    marginRight: 10,
  },
}));

export function formatCellCurrency(cell) {
  const value = Number(cell.getValue());
  return formatCurrency(value);
}

function formatCurrency(value) {
  if (value) {
    if (value >= 0) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    return `(${(value * -1).toLocaleString('en-US', { style: 'currency', currency: 'USD' })})`;
  } else {
    return Number(0).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
}

const defaultDateOffset = -60;

function FinanceRpt({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialStartDate = getDateOffset(defaultDateOffset);
  const initialEndDate = getDateOffset(0);
  useEffect(() => {
    dispatch(reportActions.getFinanceRptData(initialStartDate, initialEndDate));
  }, [dispatch, initialEndDate, initialStartDate]);
  const [dateExtents, setDateExtents] = useState({
    begin: initialStartDate,
    end: initialEndDate,
  });

  const { financeRptData } = useSelector((state) => state.reports);
  let rptData = [...financeRptData];
  const totals = {};
  if (rptData.length) {
    const keys = Object.keys(rptData[0]);
    keys.forEach((k) => (totals[k] = null));
    let paymentAmountTotal = 0;
    let bwProcessingFeeTotal = 0;
    let railPermittingLicenseFeeTotal = 0;
    let convenienceFeeTotal = 0;
    let cpAmountTotal = 0;
    rptData.forEach((d) => {
      d.paymentDate = moment.utc(d.paymentDateTimeUTC).format('MM-DD-YYYY');
      paymentAmountTotal += Number(d.paymentAmount);
      bwProcessingFeeTotal += d.bwProcessingFee;
      railPermittingLicenseFeeTotal += d.railPermittingLicenseFee;
      convenienceFeeTotal += d.convenienceFee;
      cpAmountTotal += d.cpAmount;
    });
    totals.paymentAmount = paymentAmountTotal;
    totals.bwProcessingFee = bwProcessingFeeTotal;
    totals.railPermittingLicenseFee = railPermittingLicenseFeeTotal;
    totals.convenienceFee = convenienceFeeTotal;
    totals.cpAmount = cpAmountTotal;
  }
  rptData = rptData.sort(
    (a, b) => new moment(a.paymentDate).valueOf() - new moment(b.paymentDate).valueOf()
  );

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'businessId',
        header: 'Business Id',
      },
      {
        accessorKey: 'permitType',
        header: 'Permit Type',
      },
      {
        accessorKey: 'legalName',
        header: 'Applicant',
      },
      {
        accessorKey: 'ownershipContact',
        header: 'Contact',
      },
      {
        accessorKey: 'nearestTown',
        header: 'Town',
      },
      {
        accessorKey: 'state',
        header: 'State',
      },
      {
        accessorKey: 'paymentDate',
        header: 'Payment Date',
      },
      {
        accessorKey: 'paymentAmount',
        header: 'Payment Amount',
        Cell: ({ cell }) => formatCellCurrency(cell),
        footer: formatCurrency(totals.paymentAmount),
      },
      {
        accessorKey: 'bwProcessingFee',
        header: 'BW Processing',
        Cell: ({ cell }) => formatCellCurrency(cell),
        footer: formatCurrency(totals.bwProcessingFee),
      },
      {
        accessorKey: 'railPermittingLicenseFee',
        header: 'RP License',
        Cell: ({ cell }) => formatCellCurrency(cell),
        footer: formatCurrency(totals.railPermittingLicenseFee),
      },
      {
        accessorKey: 'convenienceFee',
        header: 'Convenience Fee',
        Cell: ({ cell }) => formatCellCurrency(cell),
        footer: formatCurrency(totals.convenienceFee),
      },
      {
        accessorKey: 'cpAmount',
        header: 'CP Amount',
        Cell: ({ cell }) => formatCellCurrency(cell),
        footer: formatCurrency(totals.cpAmount),
      },
    ],
    [
      totals.bwProcessingFee,
      totals.convenienceFee,
      totals.cpAmount,
      totals.paymentAmount,
      totals.railPermittingLicenseFee,
    ]
  );
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    filename: 'FinanceRpt',
  };
  const csvExporter = new ExportToCsv(csvOptions);

  return (
    <>
      <AppAppBar pathname={location.pathname} />
      <main>
        <Container className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="fromDate"
                label="Begin Date"
                type="date"
                defaultValue={getDateOffset(defaultDateOffset)}
                className={classes.textField}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, begin: event.target.value };
                  setDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="toDate"
                label="End Date"
                type="date"
                defaultValue={getDateOffset(0)}
                className={classes.textField}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, end: event.target.value };
                  setDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  dispatch(reportActions.getFinanceRptData(dateExtents.begin, dateExtents.end));
                }}
              >
                Get Finance Data
              </Button>
            </Grid>
            <Grid item xs={12}>
              <MaterialReactTable
                columns={columns}
                data={rptData}
                enableStickyHeader
                enableGlobalFilter={false}
                enablePagination={false}
                enableStickyFooter
                renderTopToolbarCustomActions={() => {
                  return (
                    <Title>
                      <span style={{ fontSize: '1.2rem', marginRight: 20 }}>
                        Utility Permitting Finance Report
                      </span>
                      <Button
                        color="primary"
                        onClick={() => csvExporter.generateCsv([...rptData, totals])}
                        startIcon={<FileDownloadIcon />}
                        variant="outlined"
                      >
                        Export Data
                      </Button>
                    </Title>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}

FinanceRpt.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRoot(FinanceRpt);
