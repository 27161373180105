import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    border: '1px solid black',
    fontSize: 12,
    fontFamily: 'Times-Bold',
  },
  headerCell: {
    flex: 1,
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    textAlign: 'left',
    backgroundColor: 'white',
    fontSize: 12,
    border: '1px solid black',
    borderTop: 'none',
  },
  tableCell: {
    alignItems: 'center',
    flex: 1,
    padding: 2.5,
  },
});

const SummaryTable = ({ headers, data, leftAlignFirstColumn, valuesColor }) => {
  const renderHeader = () => {
    return headers.map((header, index) => {
      return (
        <View key={`header_${index}`} style={[styles.headerCell]}>
          <Text style={{ textTransform: 'uppercase' }}>{header}</Text>
        </View>
      );
    });
  };

  const renderCells = (values, rowIndex) => {
    return values.map((value, index) => {
      return (
        <View
          key={`cell_${rowIndex}_${index}`}
          style={[
            styles.tableCell,
            index === 0 && {
              alignItems: leftAlignFirstColumn ? 'flex-start' : 'center',
              fontWeight: 'bolder',
            },
            index !== 0 && { color: valuesColor },
          ]}
        >
          <Text>{value}</Text>
        </View>
      );
    });
  };

  const renderRows = () => {
    return data.map((row, index) => {
      return (
        <View
          key={`row_${index}`}
          style={[styles.row, index % 2 !== 0 && { backgroundColor: 'lightgray' }]}
          wrap={false}
        >
          {renderCells(row, index)}
        </View>
      );
    });
  };

  return (
    <View
      style={[{ backgroundColor: 'white', paddingBottom: 16, paddingHorizontal: 5 }]}
      wrap={false}
    >
      <View fixed style={styles.header}>
        {renderHeader()}
      </View>
      {renderRows()}
    </View>
  );
};

SummaryTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  leftAlignFirstColumn: PropTypes.bool,
  valuesColor: PropTypes.string,
};

SummaryTable.defaultProps = {
  leftAlignFirstColumn: true,
  valuesColor: 'black',
};

export default SummaryTable;
