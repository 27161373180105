import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import FormValue from './FormValue';
import SectionHeader from './SectionHeader';

const LocationDetails = ({ appSummary }) => {
  return (
    <View>
      <SectionHeader headerText="Location Details" />
      {appSummary.permitTypeValue === 'PIPELINE' && (
        <FormValue
          fieldText="Type of encroachment"
          valueText={appSummary.pipeline.encroachmentType}
        />
      )}
      {appSummary.permitTypeValue === 'WIRELINE' && (
        <FormValue
          fieldText="Type of encroachment"
          valueText={appSummary.wireline.encroachmentType}
        />
      )}
      <FormValue
        fieldText="Name of nearest town on RR"
        valueText={appSummary.locationDetail.nearestTown}
      />
      <FormValue fieldText="County" valueText={appSummary.locationDetail.county} />
      <FormValue fieldText="State" valueText={appSummary.locationDetail.state} />
      <FormValue
        fieldText="Name of nearest roadway crossing RR"
        valueText={appSummary.locationDetail.nearestRoadwayCrossing}
      />
      <FormValue fieldText="DOT Number" valueText={appSummary.locationDetail.dotNumber} />
      <FormValue fieldText="PLSS" valueText={appSummary.locationDetail.encroachmentLocation} />
      <FormValue fieldText="Latitude" valueText={appSummary.locationDetail.latitude} />
      <FormValue fieldText="Longitude" valueText={appSummary.locationDetail.longitude} />
      <FormValue fieldText="Railroad" valueText={appSummary.locationDetail.railroad} />
    </View>
  );
};

LocationDetails.propTypes = {
  appSummary: PropTypes.object.isRequired,
};

export default LocationDetails;
