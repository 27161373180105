import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MaterialReactTable from 'material-react-table';
import { Grid, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import withRoot from '../../modules/withRoot';
import StatusDetails from '../StatusDetails';
import { getLookup, updateWirelinePermitType } from '../../helpers/utils';

function prepareApplicationData(data, toggleStatusDetails, permitTypesLu, engineeringDivisions) {
  const applicationsAwaitingReview = [];
  data.forEach((application) => {
    const status = application.applicationStatus || {};
    let { applicationDetail } = application;
    if (!applicationDetail) {
      applicationDetail = {};
    }

    let cityState = '';
    if (application.locationDetail && application.locationDetail.nearestTown) {
      cityState = `${application.locationDetail.nearestTown}, ${application.locationDetail.state}`;
    } else if (application.locationDetail && application.locationDetail.state) {
      cityState = application.locationDetail.state;
    }

    let permitTypeLU = getLookup(permitTypesLu, application.permitTypeId);
    if (permitTypeLU.value === 'WIRELINE') {
      permitTypeLU = updateWirelinePermitType(permitTypeLU, application.wireline);
    }

    const statusDetails = (
      <>
        <Link
          to="#"
          onClick={(evt) => {
            toggleStatusDetails({ open: true, applicationReviews: application.applicationReviews });
            evt.preventDefault();
          }}
          style={{ textDecoration: 'none' }}
        >
          {status.name}
        </Link>
      </>
    );

    const link = (
      <>
        <Grid container spacing={1}>
          <Grid item>
            <Link
              to={`/processapplication/${application.applicationId}`}
              style={{ display: 'block' }}
            >
              {application.businessId}
            </Link>
          </Grid>
          {(applicationDetail.rushProcessing || applicationDetail.requestedByRR) && (
            <Grid item>
              {applicationDetail.rushProcessing && (
                <Tooltip title="Rush Processing Requested">
                  <PriorityHighIcon fontSize="small" color="error" style={{ display: 'block' }} />
                </Tooltip>
              )}
              {applicationDetail.requestedByRR && (
                <Tooltip title="Service Requested By RR">
                  <Avatar
                    style={{
                      width: 20,
                      height: 20,
                      fontSize: 18,
                      backgroundColor: 'white',
                      color: 'blue',
                    }}
                  >
                    R
                  </Avatar>
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
      </>
    );
    const engineeringDivision = engineeringDivisions.find(
      (lu) => lu.id === application.locationDetail.engineeringDivisionTerritoryId
    );
    const applicationData = {
      applicationId_: application.applicationId,
      businessId_: application.businessId,
      applicationLink: link,
      permitType: permitTypeLU.name,
      companyName: applicationDetail.legalName,
      cityState: cityState,
      engineeringDivision: engineeringDivision?.name,
      submittalDate: application.submittalDateTime
        ? moment.utc(application.submittalDateTime).local().format('YYYY-MM-DD')
        : null,
      status: statusDetails,
      status_: status.name,
      rushProcessing_: applicationDetail.rushProcessing,
      requestedByRR_: applicationDetail.requestedByRR,
    };

    applicationsAwaitingReview.push(applicationData);
  });

  const sortedByDate = applicationsAwaitingReview.sort(
    (a, b) => new Date(b.submittalDate) - new Date(a.submittalDate)
  );
  const notPriorityApps = sortedByDate.filter((d) => !d.rushProcessing_ && !d.requestedByRR_);
  const rushProcessingApps = sortedByDate.filter((d) => d.rushProcessing_ === true);
  const requestedByRRApps = sortedByDate.filter(
    (d) => d.requestedByRR_ === true && !d.rushProcessing_
  );

  return [...rushProcessingApps, ...requestedByRRApps, ...notPriorityApps];
}

function AssignedApplicationsTable({ applications }) {
  const { permitTypes, engineeringDivisions } = useSelector((state) => state.common.lookups);
  const { gettingApplications } = useSelector((state) => state.home);

  const [statusDetails, toggleStatusDetails] = useState({ open: false, applicationReviews: [] });
  const applicationsAwaitingReview = prepareApplicationData(
    applications,
    toggleStatusDetails,
    permitTypes,
    engineeringDivisions
  );

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'applicationLink',
        header: 'Application Id',
        filterFn: (row, id, filterValue) => {
          if (row.original['businessId_']) {
            return row.original['businessId_'].toLowerCase().includes(filterValue.toLowerCase());
          }
          return false;
        },
        sortingFn: (rowA, rowB) => {
          if (rowA.original['businessId_'] > rowB.original['businessId_']) {
            return 1;
          }
          if (rowB.original['businessId_'] > rowA.original['businessId_']) {
            return -1;
          }
          return 0;
        },
      },
      {
        accessorKey: 'permitType',
        header: 'Permit Type',
      },
      {
        accessorKey: 'companyName',
        header: 'Company Name',
      },
      {
        accessorKey: 'cityState',
        header: 'City State',
      },
      {
        accessorKey: 'engineeringDivision',
        header: 'Division',
      },
      {
        accessorKey: 'submittalDate',
        header: 'Submittal Date',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        filterFn: (row, id, filterValue) => {
          if (row.original['status_']) {
            return row.original['status_'].toLowerCase().includes(filterValue.toLowerCase());
          }
          return false;
        },
        sortingFn: (rowA, rowB) => {
          if (rowA.original['status_'] > rowB.original['status_']) {
            return 1;
          }
          if (rowB.original['status_'] > rowA.original['status_']) {
            return -1;
          }
          return 0;
        },
      },
    ],
    []
  );

  return (
    <div>
      <StatusDetails
        open={statusDetails.open}
        applicationReviews={statusDetails.applicationReviews}
        close={() => {
          toggleStatusDetails({ open: false, applicationReviews: [] });
        }}
      />
      <MaterialReactTable
        columns={columns}
        data={applicationsAwaitingReview}
        enableStickyHeader
        enableGlobalFilter={false}
        state={{
          isLoading: gettingApplications,
        }}
      />
    </div>
  );
}

AssignedApplicationsTable.propTypes = {
  applications: PropTypes.array.isRequired,
};

export default withRoot(AssignedApplicationsTable);
