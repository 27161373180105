import formConstants from '../constants/formConstants';
import documentConstants from '../constants/documentConstants';
import logConstants from '../constants/logConstants';
import processApplicationConstants from '../constants/processApplicationConstants';
import paymentConstants from '../constants/paymentConstants';

const initialState = {
  insertingApplication: false,
  gettingApplication: false,
  failedToGetApplication: false,
  application: { variances: [], documents: [], processLogs: [], applicationReviews: [] },
  checkingApplicationSpecs: false,
  updatingLocationDetail: false,
  uploadingDocuments: false,
  updatingVariances: false,
  deletingDocument: false,
  downloadingDocument: false,
  gettingDraftExhibit: false,
  insertingLog: false,
  updatingLog: false,
  gettingApplicationReviews: false,
  addingReviewer: false,
  remindingReviewer: false,
  removingReviewer: false,
  approvingReview: false,
  denyingReview: false,
  reopeningReview: false,
  reopeningApplication: false,
  executingApplication: false,
  submittingApplication: false,
  applicationSubmissionFailed: false,
  speckCheckerError: false,
  error: null,
  assignmentData: [],
  gettingAssignmentData: false,
  updatingAssignments: false,
  changingApplicationType: false,
  gettingApplicationHistory: false,
  history: [],
  historyCount: 0,
  summaryData: null,
  gettingApplicationSummaryData: false,
};

function selectedApplication(state = initialState, action) {
  switch (action.type) {
    case formConstants.clearSelectedApplication:
      return initialState;

    // Get application
    case formConstants.getApplication:
      return { ...state, gettingApplication: true, failedToGetApplication: false, error: null };
    case formConstants.getApplicationSucceeded:
      return { ...state, gettingApplication: false, application: action.application };
    case formConstants.getApplicationFailed:
      return {
        ...state,
        gettingApplication: false,
        failedToGetApplication: true,
        error: action.error,
      };

    // Insert application
    case formConstants.insertApplication:
      return { ...state, insertingApplication: true };
    case formConstants.insertApplicationSucceeded:
      return { ...state, insertingApplication: false, application: action.application };
    case formConstants.insertApplicationFailed:
      return { ...state, insertingApplication: false };

    // Update application
    case formConstants.updateApplication:
      return { ...state };
    case formConstants.updateApplicationSucceeded:
      return {
        ...state,
        application: { ...state.application, ...action.application },
      };
    case formConstants.updateApplicationFailed:
      return { ...state };

    // Set application detail
    case formConstants.setApplicationDetail:
      return {
        ...state,
        application: {
          ...state.application,
          applicationDetail: {
            ...state.application.applicationDetail,
            ...action.applicationDetail,
          },
        },
      };

    // Update application detail
    case formConstants.updateApplicationDetail:
      return { ...state };
    case formConstants.updateApplicationDetailSucceeded:
      return {
        ...state,
        application: { ...state.application, applicationDetail: { ...action.applicationDetail } },
      };
    case formConstants.updateApplicationDetailFailed:
      return { ...state };

    // Update location detail
    case formConstants.updateLocationDetail:
      return { ...state, updatingLocationDetail: true };
    case formConstants.updateLocationDetailSucceeded:
      return {
        ...state,
        updatingLocationDetail: false,
        application: {
          ...state.application,
          locationDetail: { ...state.application.locationDetail, ...action.locationDetail },
        },
      };
    case formConstants.updateLocationDetailFailed:
      return { ...state, updatingLocationDetail: false };

    // Insert pipeline
    case formConstants.insertPipeline:
      return { ...state };
    case formConstants.insertPipelineSucceeded:
      return { ...state, application: { ...state.application, pipeline: action.pipeline } };
    case formConstants.insertPipelineFailed:
      return { ...state };

    // Update pipeline
    case formConstants.updatePipeline:
      return { ...state };
    case formConstants.updatePipelineSucceeded:
      return { ...state, application: { ...state.application, pipeline: { ...action.pipeline } } };
    case formConstants.updatePipelineFailed:
      return { ...state };

    // Insert pipeline casing
    case formConstants.insertPipelineCasing:
      return { ...state };
    case formConstants.insertPipelineCasingSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          pipeline: { ...state.application.pipeline, pipelineCasing: action.pipelineCasing },
        },
      };
    case formConstants.insertPipelineCasingFailed:
      return { ...state };

    // Update pipeline casing
    case formConstants.updatePipelineCasing:
      return { ...state };
    case formConstants.updatePipelineCasingSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          pipeline: { ...state.application.pipeline, pipelineCasing: action.pipelineCasing },
        },
      };
    case formConstants.updatePipelineCasingFailed:
      return { ...state };

    // Delete pipeline casing
    case formConstants.deletePipelineCasing:
      return { ...state };
    case formConstants.deletePipelineCasingSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          pipeline: { ...state.application.pipeline, hasCasing: false, pipelineCasing: null },
        },
      };
    case formConstants.deletePipelineCasingFailed:
      return { ...state };

    // Insert pipeline carrier
    case formConstants.insertPipelineCarrier:
      return { ...state };
    case formConstants.insertPipelineCarrierSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          pipeline: { ...state.application.pipeline, pipelineCarrier: action.pipelineCarrier },
        },
      };
    case formConstants.insertPipelineCarrierFailed:
      return { ...state };

    // Update pipeline carrier
    case formConstants.updatePipelineCarrier:
      return { ...state };
    case formConstants.updatePipelineCarrierSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          pipeline: { ...state.application.pipeline, pipelineCarrier: action.pipelineCarrier },
        },
      };
    case formConstants.updatePipelineCarrierFailed:
      return { ...state };

    // Insert wireline
    case formConstants.insertWireline:
      return { ...state };
    case formConstants.insertWirelineSucceeded:
      return { ...state, application: { ...state.application, wireline: action.wireline } };
    case formConstants.insertWirelineFailed:
      return { ...state };

    // Update wireline
    case formConstants.updateWireline:
      return { ...state };
    case formConstants.updateWirelineSucceeded:
      return { ...state, application: { ...state.application, wireline: { ...action.wireline } } };
    case formConstants.updateWirelineFailed:
      return { ...state };

    // Insert underground wireline
    case formConstants.insertUndergroundWireline:
      return { ...state };
    case formConstants.insertUndergroundWirelineSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            undergroundWireline: action.undergroundWireline,
          },
        },
      };
    case formConstants.insertUndergroundWirelineFailed:
      return { ...state };

    // Update underground wireline
    case formConstants.updateUndergroundWireline:
      return { ...state };
    case formConstants.updateUndergroundWirelineSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            undergroundWireline: {
              ...action.undergroundWireline,
              conduits: state.application.wireline.undergroundWireline.conduits,
            },
          },
        },
      };
    case formConstants.updateUndergroundWirelineFailed:
      return { ...state };

    // Delete underground wireline
    case formConstants.deleteUndergroundWireline:
      return { ...state };
    case formConstants.deleteUndergroundWirelineSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            undergroundWireline: null,
          },
        },
      };
    case formConstants.deleteUndergroundWirelineFailed:
      return { ...state };

    // Insert overhead wireline
    case formConstants.insertOverheadWireline:
      return { ...state };
    case formConstants.insertOverheadWirelineSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            overheadWireline: action.overheadWireline,
          },
        },
      };
    case formConstants.insertOverheadWirelineFailed:
      return { ...state };

    // Update overhead wireline
    case formConstants.updateOverheadWireline:
      return { ...state };
    case formConstants.updateOverheadWirelineSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            overheadWireline: {
              ...action.overheadWireline,
              poles: [...state.application.wireline.overheadWireline.poles],
            },
          },
        },
      };
    case formConstants.updateOverheadWirelineFailed:
      return { ...state };

    // Delete overhead wireline
    case formConstants.deleteOverheadWireline:
      return { ...state };
    case formConstants.deleteOverheadWirelineSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            overheadWireline: null,
          },
        },
      };
    case formConstants.deleteOverheadWirelineFailed:
      return { ...state };

    // Insert pole
    case formConstants.insertPole:
      return { ...state };
    case formConstants.insertPoleSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            overheadWireline: {
              ...state.application.wireline.overheadWireline,
              poles: [...state.application.wireline.overheadWireline.poles, action.pole],
            },
          },
        },
      };
    case formConstants.insertPoleFailed:
      return { ...state };

    // Update pole
    case formConstants.updatePole:
      return { ...state };
    case formConstants.updatePoleSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            overheadWireline: {
              ...state.application.wireline.overheadWireline,
              poles: state.application.wireline.overheadWireline.poles.map((p) => {
                if (p.poleId === action.pole.poleId) {
                  return action.pole;
                }
                return p;
              }),
            },
          },
        },
      };
    case formConstants.updatePoleFailed:
      return { ...state };

    // Delete pole
    case formConstants.deletePole:
      return { ...state };
    case formConstants.deletePoleSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            overheadWireline: {
              ...state.application.wireline.overheadWireline,
              poles: state.application.wireline.overheadWireline.poles.filter(
                (p) => Number(p.poleId) !== Number(action.poleId)
              ),
            },
          },
        },
      };
    case formConstants.deletePoleFailed:
      return { ...state };

    // Insert conduit
    case formConstants.insertConduit:
      return { ...state };
    case formConstants.insertConduitSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            undergroundWireline: {
              ...state.application.wireline.undergroundWireline,
              conduits: [
                ...state.application.wireline.undergroundWireline.conduits,
                action.conduit,
              ],
            },
          },
        },
      };
    case formConstants.insertConduitFailed:
      return { ...state };

    // Update conduit
    case formConstants.updateConduit:
      return { ...state };
    case formConstants.updateConduitSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            undergroundWireline: {
              ...state.application.wireline.undergroundWireline,
              conduits: state.application.wireline.undergroundWireline.conduits.map((c) => {
                if (
                  c.undergroundWirelineConduitId === action.conduit.undergroundWirelineConduitId
                ) {
                  return action.conduit;
                }
                return c;
              }),
            },
          },
        },
      };
    case formConstants.updateConduitFailed:
      return { ...state };

    // Delete conduit
    case formConstants.deleteConduit:
      return { ...state };
    case formConstants.deleteConduitSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          wireline: {
            ...state.application.wireline,
            undergroundWireline: {
              ...state.application.wireline.undergroundWireline,
              conduits: state.application.wireline.undergroundWireline.conduits.filter(
                (c) =>
                  Number(c.undergroundWirelineConduitId) !==
                  Number(action.undergroundWirelineConduitId)
              ),
            },
          },
        },
      };
    case formConstants.deleteConduitFailed:
      return { ...state };

    // Check application specs
    case formConstants.checkApplicationSpecs:
      return {
        ...state,
        checkingApplicationSpecs: true,
        speckCheckerError: false,
        application: {
          ...state.application,
          variances: [],
        },
      };
    case formConstants.checkApplicationSpecsSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          variances: [...action.variances],
        },
        checkingApplicationSpecs: false,
      };
    case formConstants.checkApplicationSpecsFailed:
      return { ...state, checkingApplicationSpecs: false, speckCheckerError: true };

    case formConstants.varianceExplanationChange:
      return {
        ...state,
        application: {
          ...state.application,
          variances: state.application.variances.map((v) => {
            if (action.varianceId === v.varianceId) {
              v.explanation = action.explanation;
            }
            return v;
          }),
        },
      };

    // Update variances
    case formConstants.updateVariances:
      return { ...state, updatingVariances: true };
    case formConstants.updateVariancesSucceeded:
      return { ...state, updatingVariances: false };
    case formConstants.updateVariancesFailed:
      return { ...state, updatingVariances: false };

    case documentConstants.uploadDocuments:
      return {
        ...state,
        uploadingDocuments: true,
      };
    case documentConstants.uploadDocumentsSuccess:
      return {
        ...state,
        application: {
          ...state.application,
          documents: [...state.application.documents, ...action.uploadedDocuments],
        },
        uploadingDocuments: false,
      };
    case documentConstants.uploadDocumentsError:
      return {
        ...state,
        uploadingDocuments: false,
      };

    case documentConstants.deleteDocument:
      return {
        ...state,
        deletingDocument: true,
      };
    case documentConstants.deleteDocumentSuccess:
      return {
        ...state,
        application: {
          ...state.application,
          documents: state.application.documents.filter(
            (doc) => doc.documentId !== action.documentId
          ),
        },
        deletingDocument: false,
      };
    case documentConstants.deleteDocumentError:
      return {
        ...state,
        deletingDocument: false,
      };

    case documentConstants.downloadDocument:
      return { ...state, downloadingDocument: true };
    case documentConstants.downloadDocumentSuccess:
      return { ...state, downloadingDocument: false };
    case documentConstants.downloadDocumentError:
      return { ...state, downloadingDocument: false };

    case documentConstants.getDraftExhibit:
      return { ...state, gettingDraftExhibit: true };
    case documentConstants.getDraftExhibitSuccess:
      return { ...state, gettingDraftExhibit: false };
    case documentConstants.getDraftExhibitError:
      return { ...state, gettingDraftExhibit: false };

    case logConstants.getLogs:
      return { ...state };
    case logConstants.getLogsSuccess:
      return { ...state, application: { ...state.application, processLogs: action.logs } };
    case logConstants.getLogsError:
      return { ...state };

    case logConstants.insertLog:
      return { ...state, insertingLog: true };
    case logConstants.insertLogSuccess:
      return {
        ...state,
        application: {
          ...state.application,
          processLogs: [...state.application.processLogs, action.log],
        },
        insertingLog: false,
      };
    case logConstants.insertLogError:
      return { ...state, insertingLog: false };

    case logConstants.updateLog:
      return { ...state, updatingLog: true };
    case logConstants.updateLogSuccess:
      return {
        ...state,
        updatingLog: false,
        application: {
          ...state.application,
          processLogs: state.application.processLogs.map((log) => {
            if (log.processLogId === action.log.processLogId) {
              return action.log;
            }
            return log;
          }),
        },
      };
    case logConstants.updateLogError:
      return { ...state, updatingLog: false };

    case processApplicationConstants.getApplicationReviews:
      return { ...state, gettingApplicationReviews: true };
    case processApplicationConstants.getApplicationReviewsSuccess:
      return {
        ...state,
        application: { ...state.application, applicationReviews: action.reviews },
        gettingApplicationReviews: false,
      };
    case processApplicationConstants.getApplicationReviewsError:
      return { ...state, gettingApplicationReviews: false };

    case processApplicationConstants.addReviewGroup:
      return { ...state, addingReviewer: true };
    case processApplicationConstants.addReviewGroupSuccess:
      return {
        ...state,
        application: { ...state.application, applicationReviews: action.reviews },
        addingReviewer: false,
      };
    case processApplicationConstants.addReviewGroupError:
      return { ...state, addingReviewer: false };

    case processApplicationConstants.getAssignmentData:
      return { ...state, gettingAssignmentData: true };
    case processApplicationConstants.getAssignmentDataSuccess:
      const filteredAssignmentData = state.assignmentData.filter(
        (a) => a.reviewGroupValue !== action.reviewGroupValue
      );
      return {
        ...state,
        assignmentData: [
          ...filteredAssignmentData,
          {
            assignments: action.result,
            reviewGroupValue: action.reviewGroupValue,
          },
        ],
        gettingAssignmentData: false,
      };
    case processApplicationConstants.getAssignmentDataError:
      return {
        ...state,
        gettingAssignmentData: false,
      };

    case processApplicationConstants.updateReviewAssignments:
      return { ...state, updatingAssignments: true };
    case processApplicationConstants.updateReviewAssignmentsSuccess:
      const assignmentDataIndex = state.assignmentData.findIndex(
        (a) => a.reviewGroupValue === action.reviewGroupValue
      );
      const updatedAssignmentData = [...state.assignmentData];
      updatedAssignmentData[assignmentDataIndex].assignments = action.currentAssignments;
      return {
        ...state,
        updatingAssignments: false,
        assignmentData: [...updatedAssignmentData],
      };
    case processApplicationConstants.updateReviewAssignmentsError:
      return { ...state, updatingAssignments: false };

    case processApplicationConstants.remindReviewGroup:
      return { ...state, remindingReviewer: true };
    case processApplicationConstants.remindReviewGroupSuccess:
      return {
        ...state,
        remindingReviewer: false,
      };
    case processApplicationConstants.remindReviewGroupError:
      return { ...state, remindingReviewer: false };

    case processApplicationConstants.removeReviewGroup:
      return { ...state, removingReviewer: true };
    case processApplicationConstants.removeReviewGroupSuccess:
      return {
        ...state,
        application: { ...state.application, applicationReviews: action.reviews },
        removingReviewer: false,
      };
    case processApplicationConstants.removeReviewGroupError:
      return { ...state, removingReviewer: false };

    case processApplicationConstants.approveReview:
      return { ...state, approvingReview: true };
    case processApplicationConstants.approveReviewSuccess:
      return {
        ...state,
        application: { ...state.application, applicationReviews: action.reviews },
        approvingReview: false,
      };
    case processApplicationConstants.approveReviewError:
      return { ...state, approvingReview: false };

    case processApplicationConstants.denyReview:
      return { ...state, denyingReview: true };
    case processApplicationConstants.denyReviewSuccess:
      return {
        ...state,
        application: { ...state.application, applicationReviews: action.reviews },
        denyingReview: false,
      };
    case processApplicationConstants.denyReviewError:
      return { ...state, denyingReview: false };

    case processApplicationConstants.reopenReview:
      return { ...state, reopeningReview: true };
    case processApplicationConstants.reopenReviewSuccess:
      return {
        ...state,
        application: { ...state.application, applicationReviews: action.reviews },
        reopeningReview: false,
      };
    case processApplicationConstants.reopenReviewError:
      return { ...state, reopeningReview: false };

    case processApplicationConstants.reopenApplication:
      return { ...state, reopeningApplication: true };
    case processApplicationConstants.reopenApplicationSuccess:
      return {
        ...state,
        application: {
          ...state.application,
          applicationStatusId: action.applicationStatus.id,
          applicationStatus: action.applicationStatus,
        },
        reopeningApplication: false,
      };
    case processApplicationConstants.reopenApplicationError:
      return { ...state, reopeningApplication: false };

    case processApplicationConstants.executeApplication:
      return { ...state, executingApplication: true };
    case processApplicationConstants.executeApplicationSuccess:
      return {
        ...state,
        application: {
          ...state.application,
          applicationStatusId: action.applicationStatus.id,
          applicationStatus: action.applicationStatus,
        },
        executingApplication: false,
      };
    case processApplicationConstants.executeApplicationError:
      return { ...state, executingApplication: false };

    case processApplicationConstants.changeApplicationType:
      return { ...state, changingApplicationType: true };
    case processApplicationConstants.changeApplicationTypeSuccess:
      return {
        ...state,
        application: {
          ...state.application,
          permitTypeId: action.permitTypeId,
        },
        changingApplicationType: false,
      };
    case processApplicationConstants.changeApplicationTypeError:
      return { ...state, changingApplicationType: false };

    case processApplicationConstants.getApplicationHistory:
      return { ...state, gettingApplicationHistory: true };
    case processApplicationConstants.getApplicationHistorySuccess:
      return { ...state, gettingApplicationHistory: false, history: action.history };
    case processApplicationConstants.getApplicationHistoryError:
      return { ...state, gettingApplicationHistory: false };

    case processApplicationConstants.getApplicationHistoryCount:
      return { ...state };
    case processApplicationConstants.getApplicationHistoryCountSuccess:
      return { ...state, historyCount: action.historyCount };
    case processApplicationConstants.getApplicationHistoryCountError:
      return { ...state };

    case processApplicationConstants.getApplicationSummaryData:
      return { ...state, gettingApplicationSummaryData: true };
    case processApplicationConstants.getApplicationSummaryDataSuccess:
      return { ...state, summaryData: action.summaryData, gettingApplicationSummaryData: false };
    case processApplicationConstants.getApplicationSummaryDataError:
      return { ...state, gettingApplicationSummaryData: false };

    case formConstants.submitApplication:
      return { ...state, submittingApplication: true, applicationSubmissionFailed: false };
    case formConstants.submitApplicationSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          applicationStatusId: action.applicationStatus.id,
          applicationStatus: action.applicationStatus,
          submittalDateTime: new Date().toISOString(),
        },
        submittingApplication: false,
      };
    case formConstants.submitApplicationFailed:
      return { ...state, submittingApplication: false, applicationSubmissionFailed: true };

    case paymentConstants.savePaymentSuccess:
      return {
        ...state,
        application: {
          ...state.application,
          applicationStatusId: action.applicationStatus.id,
          applicationStatus: action.applicationStatus,
        },
      };

    // Insert temporary occupancy
    case formConstants.insertTemporaryOccupancy:
      return { ...state };
    case formConstants.insertTemporaryOccupancySucceeded:
      return {
        ...state,
        application: { ...state.application, temporaryOccupancy: action.temporaryOccupancy },
      };
    case formConstants.insertTemporaryOccupancyFailed:
      return { ...state };

    // Update temporary occupancy
    case formConstants.updateTemporaryOccupancy:
      return { ...state };
    case formConstants.updateTemporaryOccupancySucceeded:
      return {
        ...state,
        application: { ...state.application, temporaryOccupancy: { ...action.temporaryOccupancy } },
      };
    case formConstants.updateTemporaryOccupancyFailed:
      return { ...state };

    // Insert private crossing
    case formConstants.insertPrivateCrossing:
      return { ...state };
    case formConstants.insertPrivateCrossingSucceeded:
      return {
        ...state,
        application: { ...state.application, privateCrossing: action.privateCrossing },
      };
    case formConstants.insertPrivateCrossingFailed:
      return { ...state };

    // Update private crossing
    case formConstants.updatePrivateCrossing:
      return { ...state };
    case formConstants.updatePrivateCrossingSucceeded:
      return {
        ...state,
        application: { ...state.application, privateCrossing: { ...action.privateCrossing } },
      };
    case formConstants.updatePrivateCrossingFailed:
      return { ...state };

    // Insert roadway surfacing
    case formConstants.insertRoadwaySurfacing:
      return { ...state };
    case formConstants.insertRoadwaySurfacingSucceeded:
      return {
        ...state,
        application: { ...state.application, roadwaySurfacing: action.roadwaySurfacing },
      };
    case formConstants.insertRoadwaySurfacingFailed:
      return { ...state };

    // Update roadway surfacing
    case formConstants.updateRoadwaySurfacing:
      return { ...state };
    case formConstants.updateRoadwaySurfacingSucceeded:
      return {
        ...state,
        application: { ...state.application, roadwaySurfacing: { ...action.roadwaySurfacing } },
      };
    case formConstants.updateRoadwaySurfacingFailed:
      return { ...state };

    // Insert general license
    case formConstants.insertGeneralLicense:
      return { ...state };
    case formConstants.insertGeneralLicenseSucceeded:
      return {
        ...state,
        application: { ...state.application, generalLicense: action.generalLicense },
      };
    case formConstants.insertGeneralLicenseFailed:
      return { ...state };

    // Update general license
    case formConstants.updateGeneralLicense:
      return { ...state };
    case formConstants.updateGeneralLicenseSucceeded:
      return {
        ...state,
        application: { ...state.application, generalLicense: { ...action.generalLicense } },
      };
    case formConstants.updateGeneralLicenseFailed:
      return { ...state };

    // Insert Oversize Load Move
    case formConstants.insertOversizeLoadMove:
      return { ...state };
    case formConstants.insertOversizeLoadMoveSucceeded:
      return {
        ...state,
        application: { ...state.application, oversizeLoadMove: action.oversizeLoadMove },
      };
    case formConstants.insertOversizeLoadMoveFailed:
      return { ...state };

    // Update Oversize Load Move
    case formConstants.updateOversizeLoadMove:
      return { ...state };
    case formConstants.updateOversizeLoadMoveSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          oversizeLoadMove: { ...action.oversizeLoadMove },
        },
      };
    case formConstants.updateOversizeLoadMoveFailed:
      return { ...state };

    // Insert assignment
    case formConstants.insertAssignment:
      return { ...state };
    case formConstants.insertAssignmentSucceeded:
      return {
        ...state,
        application: { ...state.application, assignment: action.assignment },
      };
    case formConstants.insertAssignmentFailed:
      return { ...state };

    // Update assignment
    case formConstants.updateAssignment:
      return { ...state };
    case formConstants.updateAssignmentSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          assignment: { ...action.assignment, contracts: state.application.assignment.contracts },
        },
      };
    case formConstants.updateAssignmentFailed:
      return { ...state };

    // Insert environmental
    case formConstants.insertEnvironmental:
      return { ...state };
    case formConstants.insertEnvironmentalSucceeded:
      return {
        ...state,
        application: { ...state.application, environmental: action.environmental },
      };
    case formConstants.insertEnvironmentalFailed:
      return { ...state };

    // Update environmental
    case formConstants.updateEnvironmental:
      return { ...state };
    case formConstants.updateEnvironmentalSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          environmental: { ...action.environmental },
        },
      };
    case formConstants.updateEnvironmentalFailed:
      return { ...state };

    default:
      return state;

    case formConstants.insertContract:
      return { ...state };
    case formConstants.insertContractSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          assignment: {
            ...state.application.assignment,
            contracts: [...state.application.assignment.contracts, action.contract],
          },
        },
      };
    case formConstants.insertContractFailed:
      return { ...state };

    // Updte contract
    case formConstants.updateContract:
      return { ...state };
    case formConstants.updateContractSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          assignment: {
            ...state.application.assignment,
            contracts: state.application.assignment.contracts.map((c) => {
              if (c.assignmentContractId === action.contract.assignmentContractId) {
                return action.contract;
              }
              return c;
            }),
          },
        },
      };
    case formConstants.updateContractFailed:
      return { ...state };

    // Delete contract
    case formConstants.deleteContract:
      return { ...state };
    case formConstants.deleteContractSucceeded:
      return {
        ...state,
        application: {
          ...state.application,
          assignment: {
            ...state.application.assignment,
            contracts: state.application.assignment.contracts.filter(
              (c) => Number(c.assignmentContractId) !== Number(action.assignmentContractId)
            ),
          },
        },
      };
    case formConstants.deleteContractFailed:
      return { ...state };
  }
}

export default selectedApplication;
