const homeConstants = {
  getReviewerApplications: 'GET_REVIEWER_APPLICATIONS',
  getReviewerApplicationsSuccess: 'GET_REVIEWER_APPLICATIONS_SUCCESS',
  getReviewerApplicationsError: 'GET_REVIEWER_APPLICATIONS_ERROR',

  getAllUnpaidFees: 'GET_ALL_UNPAID_FEES',
  getAllUnpaidFeesSuccess: 'GET_ALL_UNPAID_FEES_SUCCESS',
  getAllUnpaidFeesError: 'GET_ALL_UNPAID_FEES_ERROR',

  getPaymentHistory: 'GET_PAYMENT_HISTORY',
  getPaymentHistorySuccess: 'GET_PAYMENT_HISTORY_SUCCESS',
  getPaymentHistoryError: 'GET_PAYMENT_HISTORY_ERROR',

  getSearchOptions: 'GET_SEARCH_OPTIONS',
  getSearchOptionsSuccess: 'GET_SEARCH_OPTIONS_SUCCESS',
  getSearchOptionsError: 'GET_SEARCH_OPTIONS_ERROR',

  searchApplications: 'SEARCH_APPLICATIONS',
  searchApplicationsSuccess: 'SEARCH_APPLICATIONS_SUCCESS',
  searchApplicationsError: 'SEARCH_APPLICATIONS_ERROR',

  shareApplications: 'SHARE_APPLICATIONS',
  shareApplicationsSuccess: 'SHARE_APPLICATIONS_SUCCESS',
  shareApplicationsError: 'SHARE_APPLICATIONS_ERROR',

  deleteApplicationShare: 'DELETE_APPLICATION_SHARE',
  deleteApplicationShareSuccess: 'DELETE_APPLICATION_SHARE_SUCCESS',
  deleteApplicationShareError: 'DELETE_APPLICATION_SHARE_ERROR',

  deleteApplication: 'DELETE_APPLICATION',
  deleteApplicationSuccess: 'DELETE_APPLICATION_SUCCESS',
  deleteApplicationError: 'DELETE_APPLICATION_ERROR',
};

export default homeConstants;
