import userConstants from '../constants/userConstants';

function getUser() {
  return { type: userConstants.getUser };
}

function getRequestedByRailroadPermitManagers() {
  return { type: userConstants.getRequestedByRailroadPermitManagers };
}

const userActions = {
  getUser,
  getRequestedByRailroadPermitManagers,
};

export default userActions;
