import applicantConstants from '../constants/applicantConstants';

function acceptDeclinedApplicationShare(accept, code) {
  return { type: applicantConstants.acceptDeclineApplicationShare, accept, code };
}

const userActions = {
  acceptDeclinedApplicationShare,
};

export default userActions;
