import applicantConstants from '../constants/applicantConstants';

const initialState = {
  acceptDeclineInProgress: false,
  acceptDeclineError: false,
  acceptDeclineSuccessMessage: null,
};

function applicant(state = initialState, action) {
  switch (action.type) {
    case applicantConstants.acceptDeclineApplicationShare:
      return {
        ...state,
        acceptDeclineInProgress: true,
        acceptDeclineError: false,
        acceptDeclineSuccessMessage: null,
      };
    case applicantConstants.acceptDeclineApplicationShareSucceeded:
      return {
        ...state,
        acceptDeclineInProgress: true,
        acceptDeclineSuccessMessage: action.successMessage,
      };
    case applicantConstants.acceptDeclineApplicationShareFailed:
      return {
        ...state,
        acceptDeclineInProgress: true,
        acceptDeclineError: true,
      };

    default:
      return state;
  }
}

export default applicant;
