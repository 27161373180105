import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as moment from 'moment';
import ModalSpinner from '../../modules/components/ModalSpinner';
import { diff, atomizeChangeset } from 'json-diff-ts';
import { JSONPath } from 'jsonpath-plus';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';

function getFieldDescription(jsonPath, jsonDocument) {
  const parts = jsonPath.split('.');

  // Replace the last .value with .description if it exists
  if (parts.length > 0 && parts[parts.length - 1] === 'value') {
    parts[parts.length - 1] = 'description';
  } else {
    // console.warn('Could not find value prop JSONPath', jsonPath);
  }

  // Join the remaining parts back into a string
  const modifiedPath = parts.join('.');

  const value = JSONPath({ path: modifiedPath, json: jsonDocument });
  if (value.length > 0) {
    return value[0];
  }
  return '';
}

function getHideFieldPropertyValue(jsonPath, jsonDocument) {
  const parts = jsonPath.split('.');

  // Replace the last .value with .hideField if it exists
  if (parts.length > 0 && parts[parts.length - 1] === 'value') {
    parts[parts.length - 1] = 'hideField';
  } else {
    // console.warn('Could not find hideField prop JSONPath', jsonPath);
  }

  // Join the remaining parts back into a string
  const modifiedPath = parts.join('.');

  const value = JSONPath({ path: modifiedPath, json: jsonDocument });
  if (value.length > 0) {
    return value[0];
  }
  return false;
}

function getRootDescription(jsonPath, jsonDocument) {
  const parts = jsonPath.split('.');

  // Check if the length of parts is greater than 2 to ensure we have enough elements to remove
  if (parts.length > 2) {
    // Remove the last two elements
    parts.splice(-2, 2);
  }

  // Replace the last .value with .description if it exists
  if (parts.length > 0 && parts[parts.length - 1] === 'value') {
    parts[parts.length - 1] = 'description';
  } else {
    // console.warn('Could not find value prop JSONPath', jsonPath);
  }

  // Join the remaining parts back into a string
  const clippedPath = parts.join('.');

  const value = JSONPath({ path: clippedPath, json: jsonDocument });
  if (value.length > 0) {
    return value[0];
  }
  return '';
}

function getRootKey(jsonPath) {
  const parts = jsonPath.split('.');

  // Check if the length of parts is greater than 2 to ensure we have enough elements to remove
  if (parts.length > 3) {
    // Remove the last 3 elements
    parts.splice(-3, 3);
    return parts[parts.length - 1];
  }
  // console.warn('Could not find root key JSONPath', jsonPath);
  return 'Unknown';
}

function addChanges(changes, change, fieldDescription, rootKey, hideField) {
  const changeObject = {
    description: fieldDescription,
    rootKey,
    changeType: change.type,
    hideField,
  };
  if (change.type === 'ADD') {
    changeObject['oldValue'] = '';
    changeObject['value'] = change.value;
  } else if (change.type === 'REMOVE') {
    changeObject['oldValue'] = change.value;
    changeObject['value'] = '';
  } else if (change.type === 'UPDATE') {
    changeObject['oldValue'] = change.oldValue;
    changeObject['value'] = change.value;
  }

  changes.push(changeObject);
}

function isUTCFormattedTimestamp(timestamp) {
  return (
    moment(timestamp, 'YYYY-MM-DD HH:mm:ssZ', true).isValid() ||
    moment(timestamp, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid()
  );
}

function formatChangeIfTimestamp(change) {
  if (change.valueType === 'String') {
    // use moment to check if string is a utc formatted timestamp
    if (isUTCFormattedTimestamp(change.value)) {
      change.value = moment(change.value).format('MM/DD/YYYY hh:mm A');
    }
    if (change.oldValue && isUTCFormattedTimestamp(change.oldValue)) {
      change.oldValue = moment(change.oldValue).format('MM/DD/YYYY hh:mm A');
    }
  }
  return change;
}

function getArrayRootKey(jsonPath) {
  const parts = jsonPath.split('.');
  const targetPattern = /^value\[\d+\]$/;
  const index = parts.findIndex((element) => targetPattern.test(element));
  if (index - 1 < 0) {
    // console.warn('Could not find root key JSONPath', jsonPath);
    return '';
  }

  return parts[index - 1];
}

function getArrayRootDescription(jsonPath, jsonDocument) {
  const parts = jsonPath.split('.');
  const targetPattern = /^value\[\d+\]$/;
  const index = parts.findIndex((element) => targetPattern.test(element));
  if (index - 1 < 0) {
    // console.warn('Could not find root key JSONPath', jsonPath);
    return '';
  }

  parts.splice(index);
  const clippedPath = parts.join('.') + '.description';
  const value = JSONPath({ path: clippedPath, json: jsonDocument });
  if (value.length > 0) {
    return value[0];
  }
  return '';
}

function getArrayItem(jsonPath, jsonDocument) {
  const parts = jsonPath.split('.');
  const targetPattern = /^value\[\d+\]$/;
  const index = parts.findIndex((element) => targetPattern.test(element));
  if (index < 0) {
    // console.warn('Could not find array item JSONPath', jsonPath);
    return '';
  }

  parts.splice(index + 1);
  const clippedPath = parts.join('.');
  const value = JSONPath({ path: clippedPath, json: jsonDocument });
  if (value.length > 0) {
    return value[0];
  }
  return null;
}

function setSimpleChanges(dataA, dataB, changeData) {
  const change = diff(dataA, dataB, {
    treatTypeChangeAsReplace: false,
  });
  const atomizedChange = atomizeChangeset(change);

  atomizedChange.forEach((change) => {
    if (change.valueType === 'Object') {
      // console.warn('Change not supported', change.path, change);
    } else {
      const hideField = getHideFieldPropertyValue(change.path, dataB);
      const rootDescription = getRootDescription(change.path, dataA);
      const rootKey = getRootKey(change.path);
      const fieldDescription = getFieldDescription(change.path, dataB);
      if (changeData.changeset.simpleChanges[rootKey] === undefined) {
        changeData.changeset.simpleChanges[rootKey] = {
          rootDescription,
          changes: [],
        };
      }
      addChanges(
        changeData.changeset.simpleChanges[rootKey].changes,
        formatChangeIfTimestamp(change),
        fieldDescription,
        rootKey,
        hideField
      );
    }
  });
  return changeData;
}

function extractAndRemoveIsArrayTrue(inputObject) {
  const extractedObject = {};

  // Extract properties recursively
  function extractProperties(obj) {
    const newObj = JSON.parse(JSON.stringify(obj)); // Deep copy of obj

    for (const key in newObj) {
      if (newObj.hasOwnProperty(key)) {
        const property = newObj[key];
        if (typeof property === 'object' && property !== null) {
          // Check if isArray is true and extract value
          if (property.isArray === true) {
            extractedObject[key] = {
              value: property.value,
              description: property.description, // Include description in extracted object
            };
            delete newObj[key]; // Remove the property from copied object
          } else {
            newObj[key] = extractProperties(property); // Recursively search for isArray properties
          }
        }
      }
    }

    return newObj;
  }

  const copiedInputObject = JSON.parse(JSON.stringify(inputObject));
  const modifiedInputObject = extractProperties(copiedInputObject);

  return { extractedObject, modifiedInputObject };
}

function transformArrayHistoryData(jsonData) {
  const transformedData = {};

  // Iterate over each key in jsonData
  for (const key in jsonData) {
    if (jsonData.hasOwnProperty(key)) {
      const { value, description } = jsonData[key];

      // Create a new array for storing transformed objects
      transformedData[key] = {
        value: value.map((item) => {
          // Extract the Id and construct a new object without the Id property
          const id = item.Id.value;
          const transformedItem = { ...item };
          delete transformedItem.Id;

          // Store the transformed item with Id as key
          return { [id]: transformedItem };
        }),
        description: description,
      };
    }
  }

  return transformedData;
}

function setArrayChanges(arrayDataA, arrayDataB, changeData) {
  const transformedArrayDataA = transformArrayHistoryData(arrayDataA);
  const transformedArrayDataB = transformArrayHistoryData(arrayDataB);
  const change = diff(transformedArrayDataA, transformedArrayDataB, {
    treatTypeChangeAsReplace: false,
  });
  const atomizedChange = atomizeChangeset(change);
  atomizedChange.forEach((change) => {
    let rootDescription = '';
    let rootKey = '';
    let oldArrayItem = null;
    let newArrayItem = null;
    if (change.type === 'ADD') {
      rootDescription = getArrayRootDescription(change.path, arrayDataB);
      rootKey = getArrayRootKey(change.path);
      newArrayItem = getArrayItem(change.path, arrayDataB);
    } else if (change.type === 'REMOVE') {
      rootDescription = getArrayRootDescription(change.path, arrayDataA);
      rootKey = getArrayRootKey(change.path);
      oldArrayItem = getArrayItem(change.path, arrayDataA);
    } else if (change.type === 'UPDATE') {
      rootDescription = getArrayRootDescription(change.path, arrayDataA);
      rootKey = getArrayRootKey(change.path);
      oldArrayItem = getArrayItem(change.path, arrayDataA);
      newArrayItem = getArrayItem(change.path, arrayDataB);

      // Prevent duplicate array item updates in the changeset
      const arrayItemId = oldArrayItem.Id.value;
      const matchingArrayItem = changeData.changeset.arrayChanges[rootKey]?.changes.find((c) => {
        return c.changeType === 'UPDATE' && c.oldArrayItem.Id.value === arrayItemId;
      });
      if (matchingArrayItem) {
        return;
      }
    }
    if (changeData.changeset.arrayChanges[rootKey] === undefined && rootKey !== '') {
      changeData.changeset.arrayChanges[rootKey] = {
        rootDescription,
        changes: [],
      };
    }

    if (!oldArrayItem && !newArrayItem) {
      // This can occur when the permit type was changed.
      return;
    }
    // TODO: format utc timestamps in array items formatChangeIfTimestamp
    changeData.changeset.arrayChanges[rootKey].changes.push({
      changeType: change.type,
      oldArrayItem,
      newArrayItem,
      change,
    });
  });
}

function permitTypeChanged(historyA, historyB) {
  return (
    historyA.Application.value.PermitType.value !== historyB.Application.value.PermitType.value
  );
}

function modifyHistoryObjectForNullOjects(historyObjectA, historyObjectB) {
  if (historyObjectA == null || historyObjectB == null) {
    return;
  }
  if (Array.isArray(historyObjectA.value) && Array.isArray(historyObjectB.value)) {
    return;
  }
  Object.entries(historyObjectB.value).forEach(([key, value]) => {
    if (
      value.value == null &&
      historyObjectA.value[key].value != null &&
      typeof historyObjectA.value[key].value === 'object'
    ) {
      historyObjectA.value[key].value = null;
    } else if (
      value.value != null &&
      historyObjectA.value[key].value == null &&
      typeof value.value === 'object'
    ) {
      historyObjectA.value[key] = {
        value: {},
        description: value.description,
        isArray: value.isArray,
      };
    } else if (
      value.value != null &&
      historyObjectA.value[key].value != null &&
      typeof value.value === 'object'
    ) {
      modifyHistoryObjectForNullOjects(historyObjectA.value[key], value);
    }
  });
}

export function getChangesets(history) {
  function _getChangesets(history) {
    const changesets = [];
    for (let i = 0; i < history.length - 1; i++) {
      const permitTypeHasChanged = permitTypeChanged(history[i].Summary, history[i + 1].Summary);
      modifyHistoryObjectForNullOjects(
        history[i].Summary.Application,
        history[i + 1].Summary.Application
      );

      const changeData = {
        permitTypeHasChanged,
        previousVersion: history[i].Version,
        currentVersion: history[i + 1].Version,
        previousVersionDate: moment
          .utc(history[i].DateTimeUtc)
          .local()
          .format('MM/DD/YYYY hh:mm A'),
        currentVersionDate: moment
          .utc(history[i + 1].DateTimeUtc)
          .local()
          .format('MM/DD/YYYY hh:mm A'),
        changeset: { simpleChanges: {}, arrayChanges: {} },
      };
      const { extractedObject: arrayDataA, modifiedInputObject: simpleDataA } =
        extractAndRemoveIsArrayTrue(history[i].Summary);
      const { extractedObject: arrayDataB, modifiedInputObject: simpleDataB } =
        extractAndRemoveIsArrayTrue(history[i + 1].Summary);

      setSimpleChanges(simpleDataA, simpleDataB, changeData);
      setArrayChanges(arrayDataA, arrayDataB, changeData);

      changesets.push(changeData);
    }
    return changesets;
  }

  const historyCopy = JSON.parse(JSON.stringify(history));

  return _getChangesets(historyCopy);
}

function ApplicationChanges() {
  const dispatch = useDispatch();
  const [changesets, setChangesets] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [locationChangeMapOpen, setLocationChangeMapOpen] = useState(false);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
  const goToNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, changesets.length - 1));
  };

  const { history, gettingApplicationHistory } = useSelector((state) => state.selectedApplication);
  const { applicationId } = useSelector((state) => {
    return state.selectedApplication.application;
  });

  React.useEffect(() => {
    setChangesets([]);
    setCurrentIndex(0);
  }, [dispatch, applicationId]);
  React.useEffect(() => {
    if (history.length > 1) {
      // create copy of history so that when the objects are modified in other functions the original history is not affected
      const cs = getChangesets(history);
      setChangesets(cs);
    }
  }, [history]);

  if (!gettingApplicationHistory && history.length < 2) {
    return <Typography variant="h6">No history available</Typography>;
  }

  return (
    <Box sx={{ maxWidth: '1200px' }}>
      <ModalSpinner open={gettingApplicationHistory} />
      <Box sx={{ width: '100%', marginBottom: '10px' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="subtitle1" gutterBottom>
            Changeset {currentIndex + 1} of {changesets.length}
          </Typography>
          <Box sx={{ marginLeft: '20px' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={goToPrevious}
              disabled={currentIndex === 0}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={goToNext}
              disabled={currentIndex === changesets.length - 1}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        {changesets[currentIndex] && changesets[currentIndex].permitTypeHasChanged && (
          <Typography variant="h3" sx={{ color: 'red' }}>
            Permit Type was changed
          </Typography>
        )}
      </Box>
      <Box id="simple-changes">
        {changesets.length &&
          Object.entries(changesets[currentIndex].changeset.simpleChanges).map(
            ([, formChange], index) => {
              const filteredChange = formChange.changes.filter((c) => c.hideField === false);
              if (filteredChange.length === 0) {
                return null;
              }
              // let showLocationChangeMapButton = false;
              // let graphicJsonChange = null;
              // if (formChange.rootDescription.replace(' ', '').toUpperCase() === 'LOCATIONDETAIL') {
              //   graphicJsonChange = formChange.changes.find(
              //     (c) => c.description.toUpperCase() === 'GRAPHICJSON'
              //   );
              //   if (graphicJsonChange) {
              //     showLocationChangeMapButton = true;
              //   }
              // }
              return (
                <Accordion key={index} defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{ backgroundColor: '#f5f5f5' }}
                  >
                    {/* <Stack direction="column" sx={{ alignItems: 'flex-start' }}>
                      <Typography variant="h6">{formChange.rootDescription}</Typography>
                      {showLocationChangeMapButton && (
                        <>
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setLocationChangeMapOpen(true);
                              return;
                            }}
                          >
                            View Map
                          </Button>
                          <LocationChangeMap
                            graphicJsonA={graphicJsonChange.oldValue}
                            graphicJsonB={graphicJsonChange.value}
                            open={locationChangeMapOpen}
                            onClose={() => setLocationChangeMapOpen(false)}
                          />
                        </>
                      )}
                    </Stack> */}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" sx={{ fontSize: '16px', color: 'blue' }}>
                      Updated
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell align="left">Old Value</TableCell>
                            <TableCell align="left">New Value</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {formChange.changes
                            .filter((c) => c.hideField === false)
                            .map((change, idx) => {
                              return (
                                <TableRow
                                  key={idx}
                                  sx={{
                                    backgroundColor: idx % 2 === 0 ? '#f9f9f9' : '#e0e0e0',
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {change.description}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: 'blue',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {change.oldValue}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: 'blue',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {change.value}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              );
            }
          )}
      </Box>
      <Box id="array-changes" sx={{ marginTop: '10px' }}>
        {changesets.length &&
          Object.entries(changesets[currentIndex].changeset.arrayChanges).map(
            ([, arrayChange], index) => (
              <Accordion key={index} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{ backgroundColor: '#f5f5f5' }}
                >
                  <Typography variant="h6">{arrayChange.rootDescription}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {arrayChange.changes.filter((c) => c.changeType === 'UPDATE').length > 0 && (
                    <Box id="updated-array-items" sx={{ marginBottom: '15px' }}>
                      {arrayChange.changes.filter((c) => c.changeType === 'UPDATE').length > 0 && (
                        <Typography variant="h6" sx={{ fontSize: '16px', color: 'blue' }}>
                          Updated
                        </Typography>
                      )}
                      {arrayChange.changes
                        .filter((c) => c.changeType === 'UPDATE')
                        .map((change, idx) => (
                          <Box key={idx}>
                            <TableContainer component={Paper}>
                              <Table size="small" aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Field</TableCell>
                                    <TableCell align="left">Old Value</TableCell>
                                    <TableCell align="left">New Value</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {Object.entries(change.newArrayItem).map(
                                    ([arrayChangeKey, arrayChangeItem], arrayChangeIdx) => {
                                      if (arrayChangeItem.hideField) {
                                        return null;
                                      }
                                      const oldArrayValue =
                                        change.oldArrayItem[arrayChangeKey].value;
                                      const newArrayValue = arrayChangeItem.value;
                                      return (
                                        <TableRow
                                          key={arrayChangeKey}
                                          sx={{
                                            backgroundColor:
                                              arrayChangeIdx % 2 === 0 ? '#f9f9f9' : '#e0e0e0',
                                          }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {arrayChangeItem.description}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            sx={{
                                              color:
                                                oldArrayValue === newArrayValue ? 'black' : 'blue',
                                              fontWeight:
                                                oldArrayValue === newArrayValue ? 'normal' : 'bold',
                                            }}
                                          >
                                            {oldArrayValue}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            sx={{
                                              color:
                                                oldArrayValue === newArrayValue ? 'black' : 'blue',
                                              fontWeight:
                                                oldArrayValue === newArrayValue ? 'normal' : 'bold',
                                            }}
                                          >
                                            {newArrayValue}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        ))}
                    </Box>
                  )}

                  {arrayChange.changes.filter((c) => c.changeType === 'ADD').length > 0 && (
                    <Box id="added-array-items" sx={{ marginBottom: '10px' }}>
                      <Typography variant="h6" sx={{ fontSize: '16px', color: 'green' }}>
                        Added
                      </Typography>
                      {arrayChange.changes
                        .filter((c) => c.changeType === 'ADD')
                        .map((change, idx) => (
                          <Box key={idx}>
                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Field</TableCell>
                                    <TableCell align="left">Old Value</TableCell>
                                    <TableCell align="left">New Value</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {Object.entries(change.newArrayItem).map(
                                    ([arrayChangeKey, arrayChangeItem], arrayChangeIdx) => {
                                      if (arrayChangeItem.hideField) {
                                        return null;
                                      }
                                      return (
                                        <TableRow
                                          key={arrayChangeKey}
                                          sx={{
                                            backgroundColor:
                                              arrayChangeIdx % 2 === 0 ? '#f9f9f9' : '#e0e0e0',
                                          }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {arrayChangeItem.description}
                                          </TableCell>
                                          <TableCell align="left"> </TableCell>
                                          <TableCell
                                            align="left"
                                            sx={{
                                              color: 'green',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {arrayChangeItem.value}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        ))}
                    </Box>
                  )}

                  {arrayChange.changes.filter((c) => c.changeType === 'REMOVE').length > 0 && (
                    <Box id="removed-array-items">
                      <Typography variant="h6" sx={{ fontSize: '16px', color: 'red' }}>
                        Removed
                      </Typography>
                      {arrayChange.changes
                        .filter((c) => c.changeType === 'REMOVE')
                        .map((change, idx) => (
                          <Box key={idx}>
                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Field</TableCell>
                                    <TableCell align="left">Old Value</TableCell>
                                    <TableCell align="left">New Value</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {Object.entries(change.oldArrayItem).map(
                                    ([arrayChangeKey, arrayChangeItem], arrayChangeIdx) => {
                                      if (arrayChangeItem.hideField) {
                                        return null;
                                      }
                                      return (
                                        <TableRow
                                          key={arrayChangeKey}
                                          sx={{
                                            backgroundColor:
                                              arrayChangeIdx % 2 === 0 ? '#f9f9f9' : '#e0e0e0',
                                          }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {arrayChangeItem.description}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            sx={{
                                              color: 'red',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {arrayChangeItem.value}
                                          </TableCell>
                                          <TableCell align="left"> </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        ))}
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            )
          )}
      </Box>
    </Box>
  );
}

export default ApplicationChanges;
