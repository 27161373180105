import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);
window.m = msalInstance;

export function getClaims() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    return {};
  }

  return account.idTokenClaims;
}

export async function acquireAccessToken() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const request = {
    scopes: loginRequest.scopes,
    account: account,
  };

  const authResult = await msalInstance
    .acquireTokenSilent(request)
    .catch(async (e) => {
      if (e instanceof InteractionRequiredAuthError) {
        return await msalInstance.acquireTokenRedirect(request);
      } else if (
        e.errorMessage.includes('AADB2C90037') &&
        localStorage.getItem('last_rp_reload') < Date.now() - 1000 * 60
      ) {
        console.warn('Reloaded window due to AADB2C90037 error');
        window.location.reload();
        localStorage.setItem('last_rp_reload', Date.now());
      } else {
        console.error(e);
      }
    })
    .catch((e) => console.error(e));

  return authResult.accessToken;
}
