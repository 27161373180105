import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import FormValue from './FormValue';
import SectionHeader from './SectionHeader';

const ApplicationDetails = ({ appSummary }) => {
  return (
    <View>
      <SectionHeader headerText="Application Details" />
      <FormValue
        fieldText="Construction start date"
        valueText={moment(appSummary.applicationDetail.startDate).format('MM/DD/YYYY')}
      />
      <FormValue
        fieldText="Construction end date"
        valueText={moment(appSummary.applicationDetail.endDate).format('MM/DD/YYYY')}
      />
      <FormValue
        fieldText="Is this applicant a condemning authority"
        valueText={appSummary.applicationDetail.condemingAuthority}
      />
      <FormValue
        fieldText="Is this installation in the public right of way"
        valueText={appSummary.applicationDetail.inPublicROW}
      />
      {appSummary.applicationDetail.inPublicROW && (
        <FormValue
          fieldText="Distance to centerline"
          valueText={appSummary.applicationDetail.distanceToCenterlineFt}
        />
      )}
      <FormValue
        fieldText="Is this applicant a railroad shipper?"
        valueText={appSummary.applicationDetail.railroadShipper}
      />
      {appSummary.applicationDetail.railroadShipper && (
        <>
          <FormValue
            fieldText="RR marketing rep name"
            valueText={appSummary.applicationDetail.marketingRepName}
          />
          <FormValue
            fieldText="Phone #"
            valueText={appSummary.applicationDetail.marketingRepPhoneNumber}
          />
        </>
      )}
      <FormValue
        fieldText="Is this installation requested by RR"
        valueText={appSummary.applicationDetail.requestedByRR}
      />
      {appSummary.applicationDetail.requestedByRR && (
        <>
          <FormValue
            fieldText="Person requesting service"
            valueText={appSummary.applicationDetail.personRequestingService}
          />
          <FormValue
            fieldText="Phone #"
            valueText={appSummary.applicationDetail.personRequestingServicePhoneNumber}
          />
          <FormValue
            fieldText="Project is for sole use of the RR exclusively"
            valueText={appSummary.applicationDetail.projectForRRExclusively}
          />
        </>
      )}
      <FormValue
        fieldText="Is this installation in conjunction with a track or track expansion project"
        valueText={appSummary.applicationDetail.trackProject}
      />
      {appSummary.applicationDetail.trackProject && (
        <>
          <FormValue
            fieldText="RR contact name"
            valueText={appSummary.applicationDetail.trackProjectContactName}
          />
          <FormValue
            fieldText="Phone #"
            valueText={appSummary.applicationDetail.trackProjectContactPhoneNumber}
          />
          <FormValue
            fieldText="HDR or ROW advisors"
            valueText={appSummary.applicationDetail.trackProjectSecondContact}
          />
        </>
      )}
      <FormValue
        fieldText="Is this installation associated with a public road crossing/widening or a grade separation project"
        valueText={appSummary.applicationDetail.crossingGradeProject}
      />
      {appSummary.applicationDetail.crossingGradeProject && (
        <FormValue
          fieldText="Road/Hightway name"
          valueText={appSummary.applicationDetail.roadName}
        />
      )}
    </View>
  );
};

ApplicationDetails.propTypes = {
  appSummary: PropTypes.object.isRequired,
};

export default ApplicationDetails;
