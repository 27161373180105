import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AppBar from '../components/AppBar';
import MessageBanner from '../components/MessageBanner';
import Toolbar from '../components/Toolbar';
import Typography from '../components/Typography';
import AccountButton from './AccountButton';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 30,
    color: theme.palette.primary.contrastText,
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  breadcrumbContainer: {
    padding: '5px 23px',
    borderTop: '1px solid white',
    backgroundColor: theme.palette.primary.light,
  },
  breadcrumbLink: {
    color: theme.palette.secondary.main,
  },
  breadcrumbSeparator: {
    color: theme.palette.primary.contrastText,
  },
  currentRoute: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      cursor: 'default',
      textDecoration: 'none',
    },
  },
}));

const conditions = {
  isPipeline: {
    name: 'ISPIPELINE',
    pass: function (application) {
      if (application && application.pipeline && application.pipeline.pipelineId) {
        return true;
      }
      return false;
    },
  },
  isCasedPipeline: {
    name: 'ISCASEDPIPELINE',
    pass: function (application) {
      if (application && application.pipeline && application.pipeline.pipelineCasing) {
        return true;
      }
      return false;
    },
  },
  isWireline: {
    name: 'ISWIRELINE',
    pass: function (application) {
      if (application && application.wireline && application.wireline.wirelineId) {
        return true;
      }
      return false;
    },
  },
  isUndergroundWireline: {
    name: 'ISUNDERGROUNDWIRELINE',
    pass: function (application) {
      if (application && application.wireline && application.wireline.undergroundWireline) {
        return true;
      }
      return false;
    },
  },
  isOverheadWireline: {
    name: 'ISOVERHEADWIRELINE',
    pass: function (application) {
      if (application && application.wireline && application.wireline.overheadWireline) {
        return true;
      }
      return false;
    },
  },
  isUnsubmitted: {
    name: 'ISUNSUBMITTED',
    pass: function (application) {
      if (application.applicationStatus.value === 'UNSUBMITTED') {
        return true;
      }
      return false;
    },
  },
  isTemporaryOccupancy: {
    name: 'ISTEMPORARYOCCUPANCY',
    pass: function (application) {
      if (
        application &&
        application.temporaryOccupancy &&
        application.temporaryOccupancy.temporaryOccupancyId
      ) {
        return true;
      }
      return false;
    },
  },
  isPrivateCrossing: {
    name: 'ISPRIVATECROSSING',
    pass: function (application) {
      if (
        application &&
        application.privateCrossing &&
        application.privateCrossing.privateCrossingId
      ) {
        return true;
      }
      return false;
    },
  },
  isRoadwaySurfacing: {
    name: 'ISROADWAYSURFACING',
    pass: function (application) {
      if (
        application &&
        application.roadwaySurfacing &&
        application.roadwaySurfacing.roadwaySurfacingId
      ) {
        return true;
      }
      return false;
    },
  },
  isGeneralLicense: {
    name: 'ISGENERALLICENSE',
    pass: function (application) {
      if (
        application &&
        application.generalLicense &&
        application.generalLicense.generalLicenseId
      ) {
        return true;
      }
      return false;
    },
  },
  isOversizeLoadMove: {
    name: 'ISOVERSIZELOADMOVE',
    pass: function (application) {
      if (
        application &&
        application.oversizeLoadMove &&
        application.oversizeLoadMove.oversizeLoadMoveId
      ) {
        return true;
      }
      return false;
    },
  },
  isAssignment: {
    name: 'ISASSIGNMENT',
    pass: function (application) {
      if (application && application.assignment && application.assignment.assignmentId) {
        return true;
      }
      return false;
    },
  },
  isNotAssignment: {
    name: 'ISASSIGNMENT',
    pass: function (application) {
      if (application && !application.assignment) {
        return true;
      }
      return false;
    },
  },
  isEnvironmental: {
    name: 'ISENVIRONMENTAL',
    pass: function (application) {
      if (application && application.environmental && application.environmental.environmentalId) {
        return true;
      }
      return false;
    },
  },
  hasLocationPoint: {
    name: 'HASLOCATIONPOINT',
    pass: function (application) {
      if (
        application &&
        application.locationDetail &&
        application.locationDetail.longitude &&
        application.locationDetail.latitude
      ) {
        return true;
      }
      return false;
    },
  },
};

const routes = [
  {
    route: '/application/{id}',
    routeName: 'APPLICATIONDETAILS',
    linkText: 'Application {id} Details',
    subRoutes: [],
  },
  {
    route: '/application/{id}/location',
    routeName: 'LOCATION',
    linkText: 'Location',
    subRoutes: [{ order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] }],
  },
  {
    route: '/application/{id}/pipeline',
    routeName: 'PIPELINE',
    linkText: 'Pipeline',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
    ],
  },
  {
    route: '/application/{id}/pipeline/casing',
    linkText: 'Casing',
    routeName: 'PIPELINECASING',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
      { order: 2, routeName: 'PIPELINE', conditions: [] },
    ],
  },
  {
    route: '/application/{id}/pipeline/carrier',
    linkText: 'Carrier',
    routeName: 'PIPELINECARRIER',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
      { order: 2, routeName: 'PIPELINE', conditions: [] },
      { order: 3, routeName: 'PIPELINECASING', conditions: [conditions.isCasedPipeline] },
    ],
  },
  {
    route: '/application/{id}/wireline',
    routeName: 'WIRELINE',
    linkText: 'Wireline',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
    ],
  },
  {
    route: '/application/{id}/wireline/underground',
    routeName: 'UNDERGROUNDWIRELINE',
    linkText: 'Underground',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
      { order: 2, routeName: 'WIRELINE', conditions: [] },
      { order: 3, routeName: 'OVERHEADWIRELINE', conditions: [conditions.isOverheadWireline] },
    ],
  },
  {
    route: '/application/{id}/wireline/overhead',
    routeName: 'OVERHEADWIRELINE',
    linkText: 'Overhead',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
      { order: 2, routeName: 'WIRELINE', conditions: [] },
    ],
  },
  {
    route: '/application/{id}/temporaryoccupancy',
    routeName: 'TEMPORARYOCCUPANCY',
    linkText: 'Temporary Occupancy',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
    ],
  },
  {
    route: '/application/{id}/privatecrossing',
    routeName: 'PRIVATECROSSING',
    linkText: 'Private Crossing',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
    ],
  },
  {
    route: '/application/{id}/roadwaysurfacing',
    routeName: 'ROADWAYSURFACING',
    linkText: 'Roadway Surfacing/Resurfacing',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
    ],
  },
  {
    route: '/application/{id}/generallicense',
    routeName: 'GENERALLICENSE',
    linkText: 'General License',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
    ],
  },
  {
    route: '/application/{id}/oversizeloadmove',
    routeName: 'OVERSIZELOADMOVE',
    linkText: 'Oversize Load Move',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
    ],
  },
  {
    route: '/application/{id}/assignment',
    routeName: 'ASSIGNMENT',
    linkText: 'Assignment',
    subRoutes: [{ order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] }],
  },
  {
    route: '/application/{id}/environmental',
    routeName: 'ENVIRONMENTAL',
    linkText: 'Environmental',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [] },
    ],
  },
  {
    route: '/application/{id}/review',
    routeName: 'REVIEW',
    linkText: 'Review',
    subRoutes: [
      { order: 0, routeName: 'APPLICATIONDETAILS', conditions: [] },
      { order: 1, routeName: 'LOCATION', conditions: [conditions.isNotAssignment] },
      { order: 2, routeName: 'PIPELINE', conditions: [conditions.isPipeline] },
      { order: 3, routeName: 'PIPELINECASING', conditions: [conditions.isCasedPipeline] },
      { order: 4, routeName: 'PIPELINECARRIER', conditions: [conditions.isPipeline] },
      { order: 2, routeName: 'WIRELINE', conditions: [conditions.isWireline] },
      { order: 3, routeName: 'OVERHEADWIRELINE', conditions: [conditions.isOverheadWireline] },
      {
        order: 4,
        routeName: 'UNDERGROUNDWIRELINE',
        conditions: [conditions.isUndergroundWireline],
      },
      { order: 2, routeName: 'TEMPORARYOCCUPANCY', conditions: [conditions.isTemporaryOccupancy] },
      { order: 2, routeName: 'PRIVATECROSSING', conditions: [conditions.isPrivateCrossing] },
      { order: 2, routeName: 'ROADWAYSURFACING', conditions: [conditions.isRoadwaySurfacing] },
      { order: 2, routeName: 'GENERALLICENSE', conditions: [conditions.isGeneralLicense] },
      {
        order: 2,
        routeName: 'OVERSIZELOADMOVE',
        conditions: [conditions.isOversizeLoadMove],
      },
      { order: 1, routeName: 'ASSIGNMENT', conditions: [conditions.isAssignment] },
      { order: 2, routeName: 'ENVIRONMENTAL', conditions: [conditions.isEnvironmental] },
    ],
  },
  {
    route: '/application/{id}/payment',
    routeName: 'PAYMENT',
    linkText: 'Payment {id}',
    subRoutes: [],
  },
  {
    route: '/processapplication/{id}',
    routeName: 'PROCESSAPPLICATION',
    linkText: 'Process Application',
    subRoutes: [],
  },
  {
    route: '/rpt/cycletimereport',
    routeName: 'CYCLETIMEREPORT',
    linkText: 'Cycle Time Report',
    subRoutes: [],
  },
  {
    route: '/rpt/variancereport',
    routeName: 'VARIANCEREPORT',
    linkText: 'Variance Report',
    subRoutes: [],
  },
  {
    route: '/rpt/paymentreport',
    routeName: 'PAYMENTREPORT',
    linkText: 'Payment Report',
    subRoutes: [],
  },
  {
    route: '/rpt/summaryreport',
    routeName: 'SUMMARYREPORT',
    linkText: 'Summary Report',
    subRoutes: [],
  },
  {
    route: '/rpt/financereport',
    routeName: 'FINANCEREPORT',
    linkText: 'Finance Report',
    subRoutes: [],
  },
  {
    route: '/rpt/reviewerbacklogreport',
    routeName: 'REVIEWERBACKLOGREPORT',
    linkText: 'Reviewer Backlog Report',
    subRoutes: [],
  },
  {
    route: '/rpt/territoryassignmentsreport',
    routeName: 'TERRITORYASSIGNMENTSREPORT',
    linkText: 'Territory Assignments Report',
    subRoutes: [],
  },
  {
    route: '/rpt/firstsubmissionreport',
    routeName: 'FIRSTSUBMISSIONREPORT',
    linkText: 'First Submission Report',
    subRoutes: [],
  },
];

const BreadcrumbLink = (props) => {
  return <Link component={RouterLink} {...props} underline="hover" />;
};

function createBreadcrumbLinks(application, pathname, classes) {
  const links = [];

  if (pathname.includes('/rpt')) {
    links.push(
      <BreadcrumbLink to="/home/reports" key={0} classes={{ root: classes.breadcrumbLink }}>
        Home
      </BreadcrumbLink>
    );
    return links;
  }

  if (!pathname || pathname === '/' || !application || !application.applicationId) {
    return [];
  }

  // Add Home link
  let linkIndex = 0;
  links.push(
    <BreadcrumbLink to="/" key={linkIndex} classes={{ root: classes.breadcrumbLink }}>
      Home
    </BreadcrumbLink>
  );

  // Add sub-routes
  const numberPattern = '[1-9][0-9]*';
  const matchingRoute = routes.find((r) => {
    const re = new RegExp(`^${r.route.replace('{id}', numberPattern)}$`);
    return re.test(pathname);
  });
  if (matchingRoute) {
    matchingRoute.subRoutes.sort((a, b) => (a.order > b.order ? 1 : -1));
    matchingRoute.subRoutes.forEach((sr) => {
      if (sr.conditions && sr.conditions.length > 0) {
        const allPass = sr.conditions.every((c) => c.pass(application));
        if (!allPass) {
          return;
        }
      }
      const route = routes.find((r) => r.routeName === sr.routeName);
      if (route) {
        linkIndex += 1;
        links.push(
          <BreadcrumbLink
            to={route.route.replace('{id}', application.applicationId)}
            classes={{ root: classes.breadcrumbLink }}
            key={linkIndex}
          >
            {route.linkText.replace('{id}', application.businessId || '')}
          </BreadcrumbLink>
        );
      }
    });

    // Add current route
    linkIndex += 1;
    links.push(
      <BreadcrumbLink to="#" key={linkIndex} classes={{ root: classes.currentRoute }}>
        {matchingRoute.linkText.replace('{id}', application.businessId || '')}
      </BreadcrumbLink>
    );
  }

  return links;
}

function AppAppBar({ pathname }) {
  const hasWebGLSupport = React.useRef(null);
  useEffect(() => {
    const canvas = document.createElement('canvas');
    // Get WebGLRenderingContext from canvas element.
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    // Report the result.
    if (gl && gl instanceof WebGLRenderingContext) {
      hasWebGLSupport.current = true;
    } else {
      hasWebGLSupport.current = false;
    }
  });

  const classes = useStyles();
  const { bannerMessage } = useSelector((state) => state.common.config);

  const { railroadName } = useSelector((state) => state.common.config);
  const { application } = useSelector((state) => state.selectedApplication);
  const breadcrumbLinks = createBreadcrumbLinks(application, pathname, classes);

  return (
    <div>
      {bannerMessage && bannerMessage.trim().length && (
        <MessageBanner message={bannerMessage.trim()} type="Warning" />
      )}
      {hasWebGLSupport.current === false && (
        <MessageBanner
          message="Your browser does not support WebGL, some parts of the site will not function correctly. Please switch to a browser like Chrome, Edge, or Firefox."
          type="Error"
        />
      )}
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            {`${railroadName} - Rail Permitting`}
          </Typography>
          <AccountButton />
        </Toolbar>
        {breadcrumbLinks.length > 0 && (
          <div className={classes.breadcrumbContainer}>
            <Breadcrumbs
              separator={<ChevronRightIcon classes={{ root: classes.breadcrumbSeparator }} />}
            >
              {breadcrumbLinks}
            </Breadcrumbs>
          </div>
        )}
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  pathname: PropTypes.string.isRequired,
};

AppAppBar.defaultProps = {};

export default AppAppBar;
