import formConstants from '../constants/formConstants';

function getApplication(applicationId) {
  return { type: formConstants.getApplication, applicationId };
}

function getAllApplications(startDate, endDate) {
  return { type: formConstants.getAllApplications, startDate, endDate };
}

function checkApplicationSpecs(applicationId) {
  return { type: formConstants.checkApplicationSpecs, applicationId };
}

const formGetActions = {
  getApplication,
  getAllApplications,
  checkApplicationSpecs,
};

export default formGetActions;
