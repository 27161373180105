import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  sectionHeader: {
    fontSize: 14,
    marginTop: 10,
    marginBottom: 10,
  },
});

const SectionHeader = ({ headerText }) => {
  return <Text style={styles.sectionHeader}>{headerText}</Text>;
};

SectionHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
};

export default SectionHeader;
