import { all } from 'redux-saga/effects';
import formGetSagas from './formGetSagas';
import formDeleteSagas from './formDeleteSagas';
import formUpdateSagas from './formUpdateSagas';
import formInsertSagas from './formInsertSagas';
import userSagas from './userSagas';
import documentSagas from './documentsSagas';
import logSagas from './logSagas';
import processApplicationSagas from './processApplicationSagas';
import paymentSagas from './paymentSagas';
import mapQuerySagas from './mapQuerySagas';
import mapDrawSagas from './mapDrawSagas';
import commonSagas from './commonSagas';
import reportSagas from './reportSagas';
import homeSagas from './homeSagas';
import adminSagas from './adminSagas';
import applicantSagas from './applicantSagas';

export default function* rootSaga() {
  yield all([
    ...formGetSagas,
    ...formDeleteSagas,
    ...formInsertSagas,
    ...formUpdateSagas,
    ...userSagas,
    ...documentSagas,
    ...logSagas,
    ...processApplicationSagas,
    ...paymentSagas,
    ...mapQuerySagas,
    ...mapDrawSagas,
    ...commonSagas,
    ...reportSagas,
    ...homeSagas,
    ...adminSagas,
    ...applicantSagas,
  ]);
}
