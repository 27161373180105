import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import applicantConstants from '../constants/applicantConstants';
import { acquireAccessToken } from '../Auth/utils';

function* acceptDeclineApplicationShare(action) {
  const { accept, code } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/application/share/acceptdecline`,
      params: { accept, code },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: applicantConstants.acceptDeclineApplicationShareSucceeded,
      successMessage: accept ? 'Application share accepted' : 'Application share declined',
    });
  } catch (e) {
    yield put({ type: applicantConstants.acceptDeclineApplicationShareFailed, error: e });
  }
}

const userSagas = [
  takeLatest(applicantConstants.acceptDeclineApplicationShare, acceptDeclineApplicationShare),
];

export default userSagas;
