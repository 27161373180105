import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import adminConstants from '../constants/adminConstants';
import { acquireAccessToken } from '../Auth/utils';
import alertConstants from '../constants/alertConstants';

function* getAdminApplications(action) {
  const { startDate, endDate } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/applicationmanagement/admin/all`,
      params: { startDate, endDate },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({ type: adminConstants.getAdminApplicationsSucceeded, applications: data });
  } catch (e) {
    yield put({ type: adminConstants.getAdminApplicationsFailed, error: e });
  }
}

function* submitApplication(action) {
  const { applicationId, submittedStatus } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/admin/submitapplication/${applicationId}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: adminConstants.submitApplicationSucceeded,
      applicationId,
      submittedStatus,
    });
    yield put({ type: alertConstants.success, message: 'Application submitted' });
  } catch (e) {
    yield put({ type: alertConstants.error, message: 'Failed to submit application' });
    yield put({ type: adminConstants.submitApplicationFailed, error: e });
  }
}

function* undoPMApproval(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/admin/undopmapproval/${applicationId}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.undoPMApprovalSucceeded,
      applicationId,
      applicationReviews: data.applicationReviews,
      applicationStatus: data.applicationStatus,
    });
    yield put({ type: alertConstants.success, message: 'PM approval undone' });
  } catch (e) {
    yield put({ type: alertConstants.error, message: 'Failed to undo PM approval' });
    yield put({ type: adminConstants.undoPMApprovalFailed, error: e });
  }
}

function* changeApplicationOwner(action) {
  const { applicationId, user } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/admin/changeapplicationowner/${applicationId}`,
      data: user,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.changeApplicationOwnerSucceeded,
      applicationId,
      user,
    });
    yield put({
      type: alertConstants.success,
      message: 'Application owner changed',
    });
  } catch (e) {
    yield put({ type: alertConstants.error, message: 'Failed to change application owner' });
    yield put({ type: adminConstants.changeApplicationOwnerFailed, error: e });
  }
}

function* getUsers() {
  try {
    const token = yield acquireAccessToken();
    const { data: users } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/admin/users`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.getUsersSucceeded,
      users,
    });
  } catch (e) {
    yield put({ type: alertConstants.error, message: 'Failed to users' });
    yield put({ type: adminConstants.getUsersFailed, error: e });
  }
}

function* deactivateUser(action) {
  const { userId } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/admin/user/deactivate/${userId}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.deactivateUserSucceeded,
      userId,
    });
    yield put({ type: alertConstants.success, message: 'User deactivated' });
  } catch (e) {
    yield put({ type: alertConstants.error, message: 'Failed to deactivate user' });
    yield put({ type: adminConstants.deactivateUserFailed, error: e });
  }
}

function* reactivateUser(action) {
  const { userId } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/admin/user/reactivate/${userId}`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.reactivateUserSucceeded,
      userId,
    });
    yield put({ type: alertConstants.success, message: 'User reactivated' });
  } catch (e) {
    yield put({ type: alertConstants.error, message: 'Failed to reactivate user' });
    yield put({ type: adminConstants.reactivateUserFailed, error: e });
  }
}

function* getRoles() {
  try {
    const token = yield acquireAccessToken();
    const { data: roles } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/admin/roles`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.getRolesSucceeded,
      roles,
    });
  } catch (e) {
    yield put({ type: alertConstants.error, message: 'Failed to get roles' });
    yield put({ type: adminConstants.getRolesFailed, error: e });
  }
}

function* getClaims() {
  try {
    const token = yield acquireAccessToken();
    const { data: claims } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/admin/claims`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.getClaimsSucceeded,
      claims,
    });
  } catch (e) {
    yield put({ type: alertConstants.error, message: 'Failed to get claims' });
    yield put({ type: adminConstants.getClaimsFailed, error: e });
  }
}

function* getTerritoryTypes() {
  try {
    const token = yield acquireAccessToken();
    const { data: territoryTypes } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/admin/territorytypes`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.getTerritoryTypesSucceeded,
      territoryTypes,
    });
  } catch (e) {
    yield put({ type: alertConstants.error, message: 'Failed to get territory types' });
    yield put({ type: adminConstants.getTerritoryTypesFailed, error: e });
  }
}

function* getTerritories() {
  try {
    const token = yield acquireAccessToken();
    const { data: territories } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/admin/territories`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.getTerritoriesSucceeded,
      territories,
    });
  } catch (e) {
    yield put({ type: alertConstants.error, message: 'Failed to get territories' });
    yield put({ type: adminConstants.getTerritoriesFailed, error: e });
  }
}

function* getUserAssignedPermitTypes(action) {
  const { userId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data: assignedPermitTypes } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/admin/user/${userId}/assignedpermittypes`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.getUserAssignedPermitTypesSucceeded,
      assignedPermitTypes,
    });
  } catch (e) {
    yield put({
      type: alertConstants.error,
      message: 'Failed to get user permit type assignments',
    });
    yield put({ type: adminConstants.getUserAssignedPermitTypesFailed, error: e });
  }
}

function* getUserAssignedTerritories(action) {
  const { userId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data: assignedTerritories } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/admin/user/${userId}/assignedterritories`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.getUserAssignedTerritoriesSucceeded,
      assignedTerritories,
    });
  } catch (e) {
    yield put({
      type: alertConstants.error,
      message: 'Failed to get user territory assignments',
    });
    yield put({ type: adminConstants.getUserAssignedTerritoriesFailed, error: e });
  }
}

function* saveUserEdits(action) {
  const { userId, contractorId, roleIds, territoryIds, permitTypeIds, claimIds } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/admin/user/${userId}/saveedits`,
      data: { contractorId, roleIds, territoryIds, permitTypeIds, claimIds },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: adminConstants.saveUserEditsSucceeded,
      userId,
      contractorId,
      roleIds,
      claimIds,
    });
    yield put({
      type: alertConstants.success,
      message: 'User edits saved',
    });
  } catch (e) {
    yield put({
      type: alertConstants.error,
      message: 'Failed to save user edits',
    });
    yield put({ type: adminConstants.saveUserEditsFailed, error: e });
  }
}

const adminSagas = [
  takeLatest(adminConstants.getAdminApplications, getAdminApplications),
  takeLatest(adminConstants.submitApplication, submitApplication),
  takeLatest(adminConstants.undoPMApproval, undoPMApproval),
  takeLatest(adminConstants.changeApplicationOwner, changeApplicationOwner),
  takeLatest(adminConstants.getUsers, getUsers),
  takeLatest(adminConstants.deactivateUser, deactivateUser),
  takeLatest(adminConstants.reactivateUser, reactivateUser),
  takeLatest(adminConstants.getRoles, getRoles),
  takeLatest(adminConstants.getClaims, getClaims),
  takeLatest(adminConstants.getTerritoryTypes, getTerritoryTypes),
  takeLatest(adminConstants.getTerritories, getTerritories),
  takeLatest(adminConstants.getUserAssignedPermitTypes, getUserAssignedPermitTypes),
  takeLatest(adminConstants.getUserAssignedTerritories, getUserAssignedTerritories),
  takeLatest(adminConstants.saveUserEdits, saveUserEdits),
];

export default adminSagas;
