import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress, Modal } from '@mui/material';

const useStyles = makeStyles(() => ({
  progress: {
    position: 'absolute',
    top: '40%',
    right: '50%',
  },
}));

const ModalSpinner = ({ open }) => {
  const classes = useStyles();
  return (
    <Modal open={open}>
      <div>
        <CircularProgress className={classes.progress} size={90} />
      </div>
    </Modal>
  );
};

ModalSpinner.propTypes = {
  open: PropTypes.bool,
};
ModalSpinner.defaultProps = {
  open: false,
};

export default ModalSpinner;
