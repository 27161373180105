import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import withRoot from '../../../modules/withRoot';
import AdminApplicationsTable from './AdminApplicationsTable';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../modules/AppInsights';
import ModalSpinner from '../../../modules/components/ModalSpinner';
import adminActions from '../../../actions/adminActions';
import { debounce } from 'debounce';
import { getDateOffset } from '../../../helpers/utils';

appInsights.trackPageView('AdminApplications');

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0 15px',
  },
  button: {
    marginTop: 10,
  },
  dateContainer: {
    backgroundColor: 'white',
    padding: '10px 0px',
  },
  tabIndicator: { height: 5 },
}));

const defaultDateOffset = -120;

function AdminApplications() {
  const classes = useStyles();
  const [activeTabId, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  const initialStartDate = getDateOffset(defaultDateOffset);
  const initialEndDate = getDateOffset(0);
  React.useEffect(() => {
    dispatch(adminActions.getAdminApplications(initialStartDate, initialEndDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [dateExtents, setDateExtents] = useState({
    begin: initialStartDate,
    end: initialEndDate,
  });
  const debounceSetDateExtents = debounce((newExtents) => {
    setDateExtents(newExtents);
    dispatch(adminActions.getAdminApplications(newExtents.begin, newExtents.end));
  }, 1000);

  const applications = useSelector((state) => state.admin.applications);
  const { submittingApplication, gettingUsers, changingApplicationOwner } = useSelector(
    (state) => state.admin
  );

  return (
    <div>
      <ModalSpinner open={submittingApplication || gettingUsers || changingApplicationOwner} />
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Admin Applications
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" className={classes.dateContainer} position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={4}>
              <TextField
                id="fromDate"
                label="Begin Date"
                type="date"
                defaultValue={dateExtents.begin}
                style={{ marginRight: 15 }}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, begin: event.target.value };
                  debounceSetDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="toDate"
                label="End Date"
                type="date"
                defaultValue={dateExtents.end}
                onChange={(event) => {
                  const newExtents = { ...dateExtents, end: event.target.value };
                  debounceSetDateExtents(newExtents);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" position="static" elevation={0}>
        <Tabs
          value={activeTabId}
          textColor="inherit"
          onChange={(evt, value) => setActiveTab(value)}
          classes={{ indicator: classes.tabIndicator }}
        >
          <Tab label="All" />
        </Tabs>
      </AppBar>
      {activeTabId === 0 && (
        <Grid container justifyContent="center" className={classes.wrapper}>
          <Grid item xs={12} style={{ overflow: 'auto' }}>
            <AdminApplicationsTable applications={applications} />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

AdminApplications.propTypes = {};

export default withRoot(withAITracking(reactPlugin, AdminApplications));
