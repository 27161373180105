import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  formValues: {
    fontSize: 11,
    marginBottom: 10,
  },
  value: {
    textDecoration: 'underline',
  },
});

const FormValue = ({ fieldText, valueText, hideIfEmpty, children, unitsLabel = null }) => {
  if (hideIfEmpty && !valueText) {
    return null;
  }

  let valueTextCopy = valueText;
  if (valueTextCopy === null || valueTextCopy === undefined) {
    valueTextCopy = '';
  }
  if (typeof valueText === 'boolean') {
    valueTextCopy = valueText ? 'Yes' : 'No';
  }
  if (valueTextCopy && unitsLabel) {
    valueTextCopy = `${valueTextCopy} ${unitsLabel}`;
  }

  return (
    <View style={styles.formValues}>
      <Text>
        {fieldText}: <Text style={styles.value}>{valueTextCopy}</Text>
        {children && (
          <Text>
            {'    '}
            {children}
          </Text>
        )}
      </Text>
    </View>
  );
};

FormValue.propTypes = {
  fieldText: PropTypes.string.isRequired,
  valueText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  hideIfEmpty: PropTypes.bool,
  children: PropTypes.node,
  unitsLabel: PropTypes.string,
};

FormValue.defaultProps = {
  valueText: '',
  hideIfEmpty: false,
  children: null,
  unitsLabel: null,
};

export default FormValue;
