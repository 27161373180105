import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import userConstants from '../constants/userConstants';
import { acquireAccessToken } from '../Auth/utils';

function* getUser() {
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/user`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({ type: userConstants.getUserSucceeded, user: data });
  } catch (e) {
    yield put({ type: userConstants.getUserFailed, error: e });
  }
}

function* getRequestedByRailroadPermitManagers() {
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/user/requestedbyrailroadpermitmanagers`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    yield put({
      type: userConstants.getRequestedByRailroadPermitManagersSucceeded,
      permitManagers: data,
    });
  } catch (e) {
    yield put({ type: userConstants.getRequestedByRailroadPermitManagersFailed, error: e });
  }
}

const userSagas = [
  takeLatest(userConstants.getUser, getUser),
  takeLatest(
    userConstants.getRequestedByRailroadPermitManagers,
    getRequestedByRailroadPermitManagers
  ),
];

export default userSagas;
