import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import paymentConstants from '../constants/paymentConstants';
import alertConstants from '../constants/alertConstants';
import logConstants from '../constants/logConstants';
import { acquireAccessToken } from '../Auth/utils';
import formConstants from '../constants/formConstants';

function* setApplicationFees(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/payment/setApplicationFees`,
      params: { applicationId },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: paymentConstants.setApplicationFeesSuccess,
      response: data,
    });
  } catch (e) {
    yield put({ type: paymentConstants.setApplicationFeesError, error: e });
  }
}

function* getAdditionalApplicationFees(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/payment/additionalapplicationfees`,
      params: { applicationId },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: paymentConstants.getAdditionalApplicationFeesSuccess,
      response: data,
    });
  } catch (e) {
    yield put({ type: paymentConstants.getAdditionalApplicationFeesError, error: e });
  }
}

function* getUnpaidFees(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/payment/unpaidfees`,
      params: { applicationId },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: paymentConstants.getUnpaidFeesSuccess,
      response: data,
    });
  } catch (e) {
    yield put({ type: paymentConstants.getUnpaidFeesError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to get fees' });
  }
}

function* getPaidApplicationFees(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/payment/paidapplicationfees`,
      params: { applicationId },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: paymentConstants.getPaidApplicationFeesSuccess,
      response: data,
    });
  } catch (e) {
    yield put({ type: paymentConstants.getPaidApplicationFeesError, error: e });
  }
}

function* updatePermitFeeIsApplied(action) {
  const { permitFeeId, isApplied } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/payment/permitFeeIsApplied`,
      data: { permitFeeId, isApplied },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: paymentConstants.updatePermitFeeIsAppliedSuccess,
      permitFee: data,
    });
  } catch (e) {
    yield put({ type: paymentConstants.updatePermitFeeIsAppliedError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to update permit fee applied' });
  }
}

function* getConvenienceFee(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/payment/convenienceFee`,
      params: { applicationId },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: paymentConstants.getConvenienceFeeSuccess,
      response: data,
    });
  } catch (e) {
    yield put({ type: paymentConstants.getConvenienceFeeError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to get convenience fee' });
  }
}

function* makePayment(action) {
  const { paymentInfo, fees, applicationId, businessId, resolve, reject, dispatch } = action;
  try {
    yield put({ type: paymentConstants.initiatePaymentStarted });
    const accessToken = yield acquireAccessToken();

    const { data: initiated } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/payment/${applicationId}/initiate`,
      headers: !accessToken ? {} : { Authorization: `Bearer ${accessToken}` },
      data: fees,
    });
    if (initiated.canProceed === false) {
      reject(initiated.message);
      dispatch({ type: paymentConstants.makePaymentError, error: initiated.message, businessId });
      yield put({ type: alertConstants.error, message: initiated.message });
      return;
    }

    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/payment/${applicationId}/token`,
      headers: !accessToken ? {} : { Authorization: `Bearer ${accessToken}` },
      data: { ...paymentInfo, ssl_merchant_txn_id: initiated.ssl_merchant_txn_id },
    });

    const paymentFields = {
      ssl_txn_auth_token: data.token,
    };
    const callback = {
      onError: function (error) {
        dispatch({ type: paymentConstants.makePaymentError, error, businessId });
        dispatch({ type: paymentConstants.cancelPayment, fees, applicationId });
        reject(error);
      },
      onCancelled: function () {
        dispatch({ type: paymentConstants.cancelPayment, fees, applicationId });
        reject('Payment Cancelled');
      },
      onDeclined: function (response) {
        dispatch({
          type: paymentConstants.makePaymentError,
          error: JSON.stringify(response, null, '\t'),
          businessId,
        });
        dispatch({ type: paymentConstants.cancelPayment, fees, applicationId });
        reject(response);
      },
      onApproval: function (response) {
        dispatch({ type: paymentConstants.makePaymentSuccess, response });
        resolve(response);
      },
    };
    yield put({ type: paymentConstants.initiatePaymentDone });
    window.PayWithConverge.open(paymentFields, callback);
  } catch (e) {
    yield put({ type: paymentConstants.initiatePaymentDone });
    if (e.response && e.response.status === 409) {
      yield put({
        type: alertConstants.error,
        message: `Failed to initiate payment. ${e.response.data}`,
      });
    } else {
      yield put({ type: alertConstants.error, message: 'Failed to initiate payment' });
    }
    yield put({ type: paymentConstants.makePaymentError, error: e });
    reject(e);
    throw e;
  }
}

function* cancelPayment(action) {
  const { fees, applicationId } = action;
  try {
    const accessToken = yield acquireAccessToken();

    yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/payment/${applicationId}/cancel`,
      headers: !accessToken ? {} : { Authorization: `Bearer ${accessToken}` },
      data: fees,
    });
    yield put({ type: paymentConstants.cancelPaymentSuccess });
  } catch (error) {
    yield put({ type: paymentConstants.cancelPaymentError, error });
    throw error;
  }
}

function* savePayment(action) {
  const {
    applicationId,
    approvedPaymentResponse,
    paidPermitFees,
    convenienceFee,
    resolve,
    reject,
  } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/payment/save`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
      data: {
        applicationId,
        ...approvedPaymentResponse,
        permitFees: paidPermitFees,
        convenienceFee,
      },
    });
    yield put({ type: paymentConstants.savePaymentSuccess, applicationStatus: data });
    resolve();
  } catch (e) {
    yield put({ type: paymentConstants.savePaymentError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to save payment' });
    reject(e);
  }
}

function* logPaymentError(action) {
  const { error, businessId } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/payment/error`,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
      data: { businessId, error },
    });
  } catch (e) {
    console.error(e);
  }
}

function* getFeesAndPayments(action) {
  const { applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/payment/feespayments`,
      params: { applicationId },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: paymentConstants.getFeesAndPaymentsSuccess,
      response: data,
    });
  } catch (e) {
    yield put({ type: paymentConstants.getFeesAndPaymentsError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to get fees' });
  }
}

function* updateFee(action) {
  const { permitFeeId, amount, applicationId, note, versionId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/payment/fee/${permitFeeId}`,
      params: { amount, note, versionId },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: paymentConstants.updateFeeSuccess,
      response: data,
    });
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
  } catch (e) {
    yield put({ type: paymentConstants.updateFeeError, error: e });
    if (e.response && e.response.status === 409) {
      yield put({
        type: alertConstants.error,
        message: `Failed to update fee. ${e.response.data}`,
      });
    } else {
      yield put({ type: alertConstants.error, message: 'Failed to update fee' });
    }
  }
}

function* deleteFee(action) {
  const { permitFeeId, applicationId, note, versionId, resolve, reject } = action;
  try {
    const token = yield acquireAccessToken();
    yield call(axios, {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/payment/fee/${permitFeeId}`,
      params: { note, versionId },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: paymentConstants.deleteFeeSuccess,
      permitFeeId,
    });

    if (resolve) {
      resolve();
    }
    const userRoles = yield select((state) => state.user.roles);
    // Get updated logs
    if (userRoles.find((role) => role !== 'APPLICANT')) {
      yield put({ type: logConstants.getLogs, applicationId });
    }
  } catch (e) {
    yield put({ type: paymentConstants.deleteFeeError, error: e });
    if (e.response && e.response.status === 409) {
      yield put({
        type: alertConstants.error,
        message: `Failed to delete fee. ${e.response.data}`,
      });
    } else {
      yield put({ type: alertConstants.error, message: 'Failed to delete fee' });
    }
    if (reject) {
      reject();
    }
  }
}

function* addFee(action) {
  const { feeLookupId, amount, applicationId } = action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/payment/fee`,
      params: { feeLookupId, amount, applicationId },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: paymentConstants.addFeeSuccess,
      response: data,
    });
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
  } catch (e) {
    yield put({ type: paymentConstants.addFeeError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to add fee' });
  }
}

function* recordManualPayment(action) {
  const { applicationId, amount, convenienceFee, permitFees, checkNumber, checkDate, note } =
    action;
  try {
    const token = yield acquireAccessToken();
    const { data } = yield call(axios, {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/payment/recordpayment`,
      data: {
        applicationId,
        amount,
        convenienceFee,
        fees: permitFees,
        checkNumber,
        checkDate,
        note,
      },
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    yield put({
      type: paymentConstants.recordManualPaymentSuccess,
      response: data,
    });
    const { applicationStatusId } = yield select((state) => state.selectedApplication.application);
    if (applicationStatusId !== data.applicationStatusId) {
      // Application status was updated so refresh application
      yield put({ type: formConstants.getApplication, applicationId });
    }
    // Get updated logs
    yield put({ type: logConstants.getLogs, applicationId });
  } catch (e) {
    yield put({ type: paymentConstants.recordManualPaymentError, error: e });
    yield put({ type: alertConstants.error, message: 'Failed to record payment' });
  }
}

const processApplicationSagas = [
  takeEvery(paymentConstants.setApplicationFees, setApplicationFees),
  takeLatest(paymentConstants.getAdditionalApplicationFees, getAdditionalApplicationFees),
  takeLatest(paymentConstants.getUnpaidFees, getUnpaidFees),
  takeLatest(paymentConstants.getPaidApplicationFees, getPaidApplicationFees),
  takeLatest(paymentConstants.updatePermitFeeIsApplied, updatePermitFeeIsApplied),
  takeLatest(paymentConstants.getConvenienceFee, getConvenienceFee),
  takeLatest(paymentConstants.makePayment, makePayment),
  takeLatest(paymentConstants.cancelPayment, cancelPayment),
  takeLatest(paymentConstants.makePaymentError, logPaymentError),
  takeEvery(paymentConstants.savePayment, savePayment),
  takeEvery(paymentConstants.getFeesAndPayments, getFeesAndPayments),
  takeEvery(paymentConstants.updateFee, updateFee),
  takeEvery(paymentConstants.deleteFee, deleteFee),
  takeEvery(paymentConstants.addFee, addFee),
  takeEvery(paymentConstants.recordManualPayment, recordManualPayment),
];

export default processApplicationSagas;
