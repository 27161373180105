import mapQueryConstants from '../constants/mapQueryConstants';

const initialState = {
  esriToken: '',
  esriTokenLoaded: false,
  queryingLocationDetails: false,
  locationDetailsQueryError: false,
};

function mapQuery(state = initialState, action) {
  switch (action.type) {
    case mapQueryConstants.queryLocationDetails:
      return { ...state, queryingLocationDetails: true, locationDetailsQueryError: false };
    case mapQueryConstants.queryLocationDetailsSuccess:
      return { ...state, queryingLocationDetails: false, locationDetailsQueryError: false };
    case mapQueryConstants.queryLocationDetailsError:
      return { ...state, queryingLocationDetails: false, locationDetailsQueryError: true };

    case mapQueryConstants.getEsriToken:
      return { ...state, esriTokenLoaded: false, mapTokenUrl: action.serviceUrl };
    case mapQueryConstants.getEsriTokenSuccess:
      return { ...state, esriToken: action.esriToken, esriTokenLoaded: true };
    case mapQueryConstants.getEsriTokenError:
      return { ...state, esriToken: '' };
    default:
      return state;
  }
}

export default mapQuery;
